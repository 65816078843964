import {useMemo} from "react";
import {useTranslation} from "../../services/i18n";
import {Option} from "../bootstrap";

export const CALENDAR_EVENT_TYPE_FILTER_OPTION_ALL = "all";
export const CALENDAR_EVENT_TYPE_APPOINTMENT = "appointment";
export const CALENDAR_EVENT_TYPE_SLOT = "slot";

export type CalendarEventTypeFilter =
  | typeof CALENDAR_EVENT_TYPE_FILTER_OPTION_ALL
  | typeof CALENDAR_EVENT_TYPE_APPOINTMENT
  | typeof CALENDAR_EVENT_TYPE_SLOT;

export const useCalendarEventTypeFilterOptions = () => {
  const all = useTranslation("calendar-event-type-option-all");
  const appointment = useTranslation("calendar-event-type-option-appointment");
  const slot = useTranslation("calendar-event-type-option-slot");

  return useMemo<Option[]>(
    () => [
      {label: all, value: CALENDAR_EVENT_TYPE_FILTER_OPTION_ALL},
      {label: appointment, value: CALENDAR_EVENT_TYPE_APPOINTMENT},
      {label: slot, value: CALENDAR_EVENT_TYPE_SLOT},
    ],
    [all, appointment, slot]
  );
};

export const isCalendarEventTypeFilter = (
  value: unknown
): value is CalendarEventTypeFilter =>
  value === CALENDAR_EVENT_TYPE_FILTER_OPTION_ALL ||
  value === CALENDAR_EVENT_TYPE_APPOINTMENT ||
  value === CALENDAR_EVENT_TYPE_SLOT;
