import {ReactNode} from "react";
import {Link} from "react-router-dom";
import {
  generatePathToSlotType,
  RouteOperation,
} from "../../../services/routing";

export default SlotTypeLink;

interface SlotTypeLinkProps {
  children: ReactNode;
  clinicId: string;
  slotTypeId: string;
  className?: string;
  edit?: boolean;
}

function SlotTypeLink(props: SlotTypeLinkProps) {
  const {children, clinicId, slotTypeId, edit} = props;

  const slotTypeLocation = edit
    ? generatePathToSlotType({
        clinicId,
        slotTypeId,
        operation: RouteOperation.EDIT,
      })
    : generatePathToSlotType({clinicId, slotTypeId});

  return <Link to={slotTypeLocation}>{children}</Link>;
}
