import {addDays} from "./date-time-add";
import {inDaysUtc} from "./date-time-compare";

const DAYS_IN_A_WEEK = 7;
const JANUARY = 1;
const DECEMBER = 12;

const MONDAY = 1;
const TUESDAY = 2;
const WEDNESDAY = 3;
const THURSDAY = 4;
const FRIDAY = 5;
const SATURDAY = 6;
const SUNDAY = 7;

export {
  getDateMatrixForMonth,
  getFirstDateOfMonth,
  getLastDateOfMonth,
  getMondayOfWeek,
  getNextDay,
  getNumberOfDaysInMonth,
  getPreviousDay,
  getSundayOfWeek,
  getWeekNumber,
};

function getDateMatrixForMonth(value: Date) {
  const firstDateOfMonth = getFirstDateOfMonth(value);
  const firstDateOfRange = getMondayOfWeek(firstDateOfMonth);
  const matrix: Date[][] = [];

  let next = firstDateOfRange;

  for (let week = 0; week < 6; week++) {
    matrix[week] = [];
    for (let day = 0; day < 7; day++) {
      matrix[week].push(next);
      next = addDays(next, 1);
    }
  }
  return matrix;
}

function getFirstDateOfMonth(value: Date) {
  const year = value.getFullYear();
  const month = value.getMonth();

  return new Date(year, month, 1);
}

function getLastDateOfMonth(value: Date) {
  const year = value.getFullYear();
  const month = value.getMonth();
  const day = getNumberOfDaysInMonth(value);

  return new Date(year, month, day);
}

function getNumberOfDaysInMonth(value: Date) {
  const month = value.getMonth();
  const isFebruary = month === 1;

  if (isFebruary) {
    return isLeapYear(value) ? 29 : 28;
  }
  const isAprilJuneSeptemberOrNovember =
    month === 3 || month === 5 || month === 8 || month === 10;

  return isAprilJuneSeptemberOrNovember ? 30 : 31;
}

function getMondayOfWeek(value: Date) {
  // Make Sunday be represented as 7 instead of 0
  const day = value.getDay() || SUNDAY;
  const isMonday = day === 1;

  if (isMonday) {
    return new Date(value);
  }
  return addDays(value, -day + 1);
}

function getSundayOfWeek(value: Date) {
  // Make Sunday be represented as 7 instead of 0
  const day = value.getDay() || SUNDAY;
  const isSunday = day === 7;

  if (isSunday) {
    return new Date(value);
  }
  return addDays(value, 7 - day);
}

/**
 * Returns the week number the given date is in.
 */
function getWeekNumber(value: Date) {
  /**
   * There are several mutually equivalent and compatible descriptions of week 1:
   * - the week with the year's first Thursday in it (the formal ISO definition),
   * - the week with 4 January in it,
   * - the first week with the majority (four or more) of its days in the starting year, and
   * - the week starting with the Monday in the period 29 December – 4 January.
   *
   * As a consequence, if 1 January is on a Monday, Tuesday, Wednesday or
   * Thursday, it is in week 01. If 1 January is on a Friday, Saturday or
   * Sunday, it is in week 52 or 53 of the previous year (there is no week 0).
   * 28 December is always in the last week of its year.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Week_dates
   */

  const year = value.getFullYear();
  // Make month be represented as 1 through 12 instead of 0 through 11
  const month = value.getMonth() + 1;
  const date = value.getDate();
  // Make Sunday be represented as 7 instead of 0
  const day = value.getDay() || SUNDAY;

  /**
   * Special treatment for the dates around the new year. This is a bit
   * complicated. But to not make the code too difficult to understand, it
   * can be visualized with this table:
   *
   * Mo | Tu | We | Th | Fr | Sa | Su | Week
   * ------------------------------------------------
   *  1 |  2 |  3 |  4 |  5 |  6 |  7 | Week 1 (first if-statement)
   * 31 |  1 |  2 |  3 |  4 |  5 |  6 | Week 1 (first if-statement)
   * 30 | 31 |  1 |  2 |  3 |  4 |  5 | Week 1 (first if-statement)
   * 29 | 30 | 31 |  1 |  2 |  3 |  4 | Week 1 (first if-statement)
   * 28 | 29 | 30 | 31 |  1 |  2 |  3 | Week 53
   * 27 | 28 | 29 | 30 | 31 |  1 |  2 | Week 52, but 53 on leap year
   * 26 | 27 | 28 | 29 | 30 | 31 |  1 | Week 52
   */
  if (
    (month === DECEMBER && date === 29 && day === MONDAY) ||
    (month === DECEMBER && date === 30 && day <= TUESDAY) ||
    (month === DECEMBER && date === 31 && day <= WEDNESDAY) ||
    (month === JANUARY && date === 1 && day <= THURSDAY) ||
    (month === JANUARY && date === 2 && day <= FRIDAY) ||
    (month === JANUARY && date === 3 && day <= SATURDAY) ||
    (month === JANUARY && date === 4)
  ) {
    return 1;
  }
  if (
    (month === DECEMBER && date === 28 && day === MONDAY) ||
    (month === DECEMBER && date === 29 && day === TUESDAY) ||
    (month === DECEMBER && date === 30 && day === WEDNESDAY) ||
    (month === DECEMBER && date === 31 && day === THURSDAY) ||
    (month === JANUARY && date === 1 && day === FRIDAY) ||
    (month === JANUARY && date === 2 && day === SATURDAY) ||
    (month === JANUARY && date === 3 && day === SUNDAY)
  ) {
    return 53;
  }
  if (
    (month === DECEMBER && date === 27 && day === MONDAY) ||
    (month === DECEMBER && date === 28 && day === TUESDAY) ||
    (month === DECEMBER && date === 29 && day === WEDNESDAY) ||
    (month === DECEMBER && date === 30 && day === THURSDAY) ||
    (month === DECEMBER && date === 31 && day === FRIDAY) ||
    (month === JANUARY && date === 1 && day === SATURDAY) ||
    (month === JANUARY && date === 2 && day === SUNDAY)
  ) {
    return isLeapYear(value) ? 53 : 52;
  }
  if (
    (month === DECEMBER && date === 26 && day === MONDAY) ||
    (month === DECEMBER && date === 27 && day === TUESDAY) ||
    (month === DECEMBER && date === 28 && day === WEDNESDAY) ||
    (month === DECEMBER && date === 29 && day === THURSDAY) ||
    (month === DECEMBER && date === 30 && day === FRIDAY) ||
    (month === DECEMBER && date === 31 && day === SATURDAY) ||
    (month === JANUARY && date === 1 && day === SUNDAY)
  ) {
    return 52;
  }
  /**
   * This calculates the week number for the rest of the year
   */
  // Find the Monday of week 1 (Jan 4th is always in week 1)
  const mondayOfWeek1 = getMondayOfWeek(new Date(year, 0, 4));
  // Find the Monday of the week the passed date is in
  const mondayOfDateTimeWeek = getMondayOfWeek(value);
  // For both dates, get the number of days since epoch and calculate the difference in days
  const daysDifference =
    inDaysUtc(mondayOfDateTimeWeek) - inDaysUtc(mondayOfWeek1);
  // Calculate the difference in in weeks by dividing with the number of days in a week (7)
  const weeksDiff = daysDifference / DAYS_IN_A_WEEK;
  // Finally add 1 to get the week number
  const weekNumber = weeksDiff + 1;

  return Math.floor(weekNumber);
}

function getNextDay(date: Date): Date {
  const next = addDays(date, 1);

  return next;
}

function getPreviousDay(date: Date): Date {
  const next = addDays(date, -1);

  return next;
}

function isLeapYear(date: Date) {
  const year = date.getFullYear();

  if (year % 400 === 0) {
    return true;
  }
  if (year % 100 === 0) {
    return false;
  }
  return year % 4 === 0;
}
