import {useMemo} from "react";
import {useTranslation} from "../../../i18n";
import {Resident} from "../../../time-book-scheduling-api";
import {NotFoundError} from "../../errors";
import {createPersonalIdentityNumberFilter} from "../../search-query";
import {getPersonalIdentityNumberValidity} from "../../utils/personal-identity-number";
import {SearchResidentsQueryOptions} from "./use-resident-query-creators";
import useSearchResidentsQuery from "./use-search-residents-query";

export default useSearchResidentQuery;

interface UseSearchResidentQueryVariables {
  personalIdentityNumber: string;
}

type UseSearchResidentQueryOptions = SearchResidentsQueryOptions<Resident>;

function useSearchResidentQuery(
  variables: UseSearchResidentQueryVariables,
  options?: UseSearchResidentQueryOptions
) {
  const {personalIdentityNumber} = variables;
  const tErrorMessage = useTranslation("site-search-resident-not-found", {
    query: personalIdentityNumber,
  });

  const filter = useMemo(() => {
    return getPersonalIdentityNumberValidity(personalIdentityNumber).valid
      ? createPersonalIdentityNumberFilter(personalIdentityNumber)
      : null;
  }, [personalIdentityNumber]);

  return useSearchResidentsQuery(
    {filter},
    {
      ...options,
      select(residents) {
        if (residents.length === 0) {
          throw new NotFoundError(tErrorMessage);
        }

        return residents[0];
      },
    }
  );
}
