import {ReactElement, useEffect} from "react";
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  Authorization,
  AuthorizationError,
} from "../../components/authorization";
import BookingApp from "../../components/booking-app";
import ClinicApp from "../../components/clinic-app";
import {CenteredCardLayout, DefaultLayout} from "../../components/layouts";
import {Loading} from "../../components/loading";
import {LoadingError} from "../../components/loading-error";
import {SearchResidentWithResults} from "../../components/search-resident";
import {useClinicId, useCurrentClaims} from "../../hooks";
import {useGetClinicQuery} from "../../services/api/clinics";
import {Permission, useAuthService} from "../../services/auth";
import {Translation} from "../../services/i18n";
import {
  ClinicRouteParams,
  generatePathToBooking,
  generatePathToClinic,
  generatePathToResidents,
  generatePathToScheduling,
} from "../../services/routing";
import {isNonEmptyString} from "../../utils";
import {ResidentsRoute} from "../residents/residents-route";
import {SchedulingRoute} from "../scheduling";
import {SlotTypesRoute} from "../slot-types";

export default ClinicRoute;

function ClinicRoute() {
  const params = useParams<ClinicRouteParams>();
  const routeMatch = useRouteMatch();

  const [clinicId, setClinicId] = useClinicId();

  useEffect(() => {
    setClinicId(params.clinicId);
  }, [params.clinicId, setClinicId]);

  const getClinicQuery = useGetClinicQuery(
    {clinicId: clinicId as string},
    {enabled: isNonEmptyString(clinicId)}
  );

  if (getClinicQuery.isIdle || getClinicQuery.isLoading) {
    return (
      <CenteredCardLayout>
        <Loading>
          <Translation tKey="loading-clinic" />
        </Loading>
      </CenteredCardLayout>
    );
  }

  if (getClinicQuery.isError) {
    return (
      <CenteredCardLayout>
        <LoadingError error={getClinicQuery.error} />
      </CenteredCardLayout>
    );
  }

  if (getClinicQuery.isSuccess) {
    const clinic = getClinicQuery.data;

    return (
      <Switch>
        <Route path={`${routeMatch.path}/booking`}>
          <Authorization
            requiredPermission={Permission.BOOK}
            unauthorized={<Unauthorized />}
          >
            <DefaultLayout header={<SearchResidentWithResults />}>
              <BookingApp clinicId={clinic.id} />
            </DefaultLayout>
          </Authorization>
        </Route>

        {/*TODO: Replace AuthorizedRoute when we have proper permissions*/}
        <AuthorizedRoute
          path={`${routeMatch.path}/residents`}
          permissions={[Permission.BOOK, Permission.BOOK_EXTERNAL]}
          unauthorized={<Unauthorized />}
        >
          <ResidentsRoute />
        </AuthorizedRoute>

        <Route path={`${routeMatch.path}/scheduling`}>
          <Authorization
            requiredPermission={Permission.SCHEDULE}
            unauthorized={<Unauthorized />}
          >
            <SchedulingRoute clinicId={clinic.id} />
          </Authorization>
        </Route>

        <Route path={`${routeMatch.path}/slot-types`}>
          <Authorization
            requiredPermission={Permission.SCHEDULE}
            unauthorized={<Unauthorized />}
          >
            <DefaultLayout>
              <SlotTypesRoute clinicId={clinic.id} />
            </DefaultLayout>
          </Authorization>
        </Route>

        <Route path={`${routeMatch.path}`}>
          <ClinicApp clinic={clinic} />
        </Route>

        <Route>
          <RedirectBasedOnClaims clinicId={clinic.id} />
        </Route>
      </Switch>
    );
  }

  return null;
}

function Unauthorized() {
  return (
    <CenteredCardLayout>
      <AuthorizationError />
    </CenteredCardLayout>
  );
}

interface RestrictedRouteProps extends RouteProps {
  permissions: Permission[];
  unauthorized: ReactElement;
}

function AuthorizedRoute(props: RestrictedRouteProps) {
  const {permissions, unauthorized, ...otherProps} = props;
  const claims = useCurrentClaims();
  const authService = useAuthService(claims);

  const isAuthorized = permissions.some((permission) =>
    authService.isAuthorizedForPermission(permission)
  );

  if (isAuthorized) {
    return <Route {...otherProps} />;
  }
  return unauthorized;
}

interface RedirectBasedOnClaimsProps {
  clinicId: string;
}

// TODO: Make a more generic, eg. <Redirect permissionRequired={Permission.BOOK} />
function RedirectBasedOnClaims(props: RedirectBasedOnClaimsProps) {
  const {clinicId} = props;
  const claims = useCurrentClaims();
  const authService = useAuthService(claims);

  const isAuthorizedToAdministrate = authService.isAuthorizedForPermission(
    Permission.ADMINISTRATE
  );
  const isAuthorizedToBook = authService.isAuthorizedForPermission(
    Permission.BOOK
  );
  const isAuthorizedToBookExternal = authService.isAuthorizedForPermission(
    Permission.BOOK_EXTERNAL
  );
  const isAuthorizedToSchedule = authService.isAuthorizedForPermission(
    Permission.SCHEDULE
  );

  if (isAuthorizedToBookExternal) {
    return <Redirect to={generatePathToResidents({clinicId})} />;
  }
  if (isAuthorizedToBook) {
    return <Redirect to={generatePathToBooking({clinicId})} />;
  }
  if (isAuthorizedToSchedule) {
    return <Redirect to={generatePathToScheduling({clinicId})} />;
  }
  if (isAuthorizedToAdministrate) {
    return <Redirect to={generatePathToClinic({clinicId})} />;
  }
  throw new Error("RedirectBasedOnClaims Error: No known claim found.");
}
