import {TimeUnit} from "./date-time-units";
import {isValidDate} from "./is-date";

const createInUnitUtc = (unit: TimeUnit) => (date: Date) => {
  if (!isValidDate(date)) {
    throw new TypeError("Invalid date");
  }
  return Math.floor(date.getTime() / unit);
};

/** Returns the number of seconds since epoch */
export const inSecondsUtc = createInUnitUtc(TimeUnit.Second);
/** Returns the number of minutes since epoch */
export const inMinutesUtc = createInUnitUtc(TimeUnit.Minute);
/** Returns the number of hours since epoch */
export const inHoursUtc = createInUnitUtc(TimeUnit.Hour);
/** Returns the number of days since epoch */
export const inDaysUtc = createInUnitUtc(TimeUnit.Day);

const internalSameYear = (date1: Date, date2: Date) =>
  date1.getFullYear() === date2.getFullYear();

const internalSameMonth = (date1: Date, date2: Date) =>
  internalSameYear(date1, date2) && date1.getMonth() === date2.getMonth();

const internalSameDate = (date1: Date, date2: Date) =>
  internalSameYear(date1, date2) &&
  internalSameMonth(date1, date2) &&
  date1.getDate() === date2.getDate();

const internalSameHour = (date1: Date, date2: Date) =>
  internalSameYear(date1, date2) &&
  internalSameMonth(date1, date2) &&
  internalSameDate(date1, date2) &&
  date1.getHours() === date2.getHours();

const internalSameMinute = (date1: Date, date2: Date) =>
  internalSameYear(date1, date2) &&
  internalSameMonth(date1, date2) &&
  internalSameDate(date1, date2) &&
  internalSameHour(date1, date2) &&
  date1.getMinutes() === date2.getMinutes();

const internalSameSecond = (date1: Date, date2: Date) =>
  internalSameYear(date1, date2) &&
  internalSameMonth(date1, date2) &&
  internalSameDate(date1, date2) &&
  internalSameHour(date1, date2) &&
  internalSameMinute(date1, date2) &&
  date1.getSeconds() === date2.getSeconds();

const internalSameMillisecond = (date1: Date, date2: Date) =>
  date1.getTime() === date2.getTime();

const internalSameWeekDay = (date1: Date, date2: Date) =>
  date1.getDay() === date2.getDay();

type DateTimeComparer = (date1: Date, date2: Date) => boolean;

const createComparer = (fn: DateTimeComparer) => (date1: Date, date2: Date) => {
  if (isValidDate(date1) && isValidDate(date2)) {
    return fn(date1, date2);
  }
  throw new TypeError("Invalid date");
};

export const isSameYear = createComparer(internalSameYear);
export const isSameMonth = createComparer(internalSameMonth);
export const isSameDate = createComparer(internalSameDate);
export const isSameHour = createComparer(internalSameHour);
export const isSameMinute = createComparer(internalSameMinute);
export const isSameSecond = createComparer(internalSameSecond);
export const isSameMillisecond = createComparer(internalSameMillisecond);
export const isSameWeekDay = createComparer(internalSameWeekDay);
export const isToday = (date: Date) => isSameDate(date, new Date());

const SUNDAY = 0;
const SATURDAY = 6;

export const isWeekend = (date: Date) => {
  const day = date.getDay();

  return day === SATURDAY || day === SUNDAY;
};
export const isWeekday = (date: Date) => !isWeekend(date);
