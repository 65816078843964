import {selectClaimsByHsaId} from "../services/auth";
import {useSessionClient} from "../services/session";
import {isClinic} from "../services/time-book-scheduling-api";
import useCurrentClinic from "./use-current-clinic";

export default useCurrentClaims;

function useCurrentClaims() {
  const allClaims = useClaims();
  const currentClinic = useCurrentClinic();

  return isClinic(currentClinic)
    ? selectClaimsByHsaId(allClaims, currentClinic.hsaId)
    : [];
}

function useClaims() {
  const sessionClient = useSessionClient();

  return sessionClient.claims;
}
