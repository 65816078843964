import React, {Fragment} from "react";
import {Translation} from "../../../services/i18n";
import {AppointmentStatus} from "../../../services/time-book-scheduling-api/entities/appointment-status";

export default AppointmentStatusText;

interface AppointmentStatusTextProps {
  status: AppointmentStatus;
}

function AppointmentStatusText(props: AppointmentStatusTextProps) {
  const {status} = props;

  switch (status) {
    case AppointmentStatus.BOOKED:
      return <Translation tKey="appointment-status-booked" />;
    case AppointmentStatus.CANCELLED:
      return <Translation tKey="appointment-status-cancelled" />;
    case AppointmentStatus.FULFILLED:
      return <Translation tKey="appointment-status-fulfilled" />;
    case AppointmentStatus.NO_SHOW:
      return <Translation tKey="appointment-status-no-show" />;
    default:
      console.error(`Unknown appointment status: '${status}'`);
      return <Fragment>Unknown Status</Fragment>;
  }
}
