import {useGetSlotTypeQuery} from "../../services/api/slot-types";
import {Slot} from "../../services/time-book-scheduling-api";
import {SlotResource} from "../calendar/slot-resource";
import {DateText, TimeInterval} from "../date-time";
import SlotTypeAndDuration from "../slot-type-and-duration";
import SlotTypePlaque from "../slot-type-plaque";
import styles from "./slot-info.module.scss";

export default SlotInfo;

interface SlotInfoDetailsProps {
  slot: Slot;
}

function SlotInfo(props: SlotInfoDetailsProps) {
  const {slot} = props;

  const clinicId = slot.clinic.id;
  const slotTypeId = slot.slotType.id;
  const getSlotTypeQuery = useGetSlotTypeQuery({clinicId, slotTypeId});

  if (!getSlotTypeQuery.isSuccess) {
    return null;
  }

  const start = new Date(slot.start);
  const end = new Date(slot.end);

  return (
    <SlotTypePlaque
      active={slot.appointment != null}
      className={styles.root}
      slotType={getSlotTypeQuery.data}
    >
      <div className={styles.startAndEndTime}>
        <TimeInterval start={start} end={end} timeStyle="short" />
      </div>
      <div className={styles.date}>
        <DateText date={start} dateStyle="full" />
      </div>
      <div className={styles.resource}>
        <SlotResource index={slot.resourceIndex} />
      </div>
      <div className={styles.slotTypeAndDuration}>
        <SlotTypeAndDuration slotType={getSlotTypeQuery.data} />
      </div>
    </SlotTypePlaque>
  );
}
