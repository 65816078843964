import {Translation} from "../../../services/i18n";
import {SlotType} from "../../../services/time-book-scheduling-api";
import {toMinutes} from "../../../utils/time/duration";

export default SlotTypeDuration;

interface SlotTypeDurationProps {
  className?: string;
  slotType: SlotType;
}

function SlotTypeDuration(props: SlotTypeDurationProps) {
  const {className, slotType} = props;
  const durationInMinutes = toMinutes(slotType.duration);

  return (
    <span className={className}>
      {durationInMinutes} <Translation tKey="duration-minutes" />
    </span>
  );
}
