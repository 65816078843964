import {ForwardedRef, forwardRef} from "react";
import classNames from "classnames";
import {LoadingIcon} from "../../icon";
import Button, {ButtonProps} from "../button";
import styles from "./action-button.module.scss";

export default forwardRef<HTMLButtonElement, ActionButtonProps>(ActionButton);

export interface ActionButtonProps extends ButtonProps {
  loading?: boolean;
}

function ActionButton(
  props: ActionButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const {children, className, disabled, loading = false, ...otherProps} = props;

  const rootClassNames = classNames(styles.root, className, {
    [styles.isLoading]: loading,
  });

  return (
    <Button
      {...otherProps}
      className={rootClassNames}
      disabled={disabled || loading}
      ref={ref}
    >
      <div className={styles.content}>
        {loading ? <LoadingIcon className={styles.spinner} spin /> : null}
        <span className={styles.children}>{children}</span>
      </div>
    </Button>
  );
}
