import {isFunction} from "../is-function";

export default hasOwn;

function hasOwn(instance: object, prop: string) {
  // @ts-ignore: TS2339: Property 'hasOwn' does not exist on type 'ObjectConstructor'.
  if (isFunction(Object.hasOwn)) {
    // @ts-ignore: TS2339: Property 'hasOwn' does not exist on type 'ObjectConstructor'.
    return Object.hasOwn(instance, prop);
  }

  return Object.prototype.hasOwnProperty.call(instance, prop);
}
