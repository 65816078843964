import {ReactNode} from "react";

export default EventTrap;

export const EventType = {
  CHANGE: "change",
  CLICK: "click",
} as const;

/* eslint-disable-next-line @typescript-eslint/no-redeclare -- We know what we are doing here */
export type EventType = typeof EventType[keyof typeof EventType];

interface EventTrapProps {
  children: ReactNode;
  eventTypes: EventType[];
}

function EventTrap(props: EventTrapProps) {
  const {children, eventTypes} = props;

  const eventHandlers = eventTypes.reduce<
    Record<string, typeof stopPropagation>
  >((eventHandlers, eventType) => {
    const eventProp = getEventPropByEventType(eventType);

    eventHandlers[eventProp] = stopPropagation;

    return eventHandlers;
  }, {});

  return <div {...eventHandlers}>{children}</div>;
}

const eventPropMap = {
  [EventType.CHANGE]: "onChange",
  [EventType.CLICK]: "onClick",
};

function getEventPropByEventType(eventType: EventType): string {
  return eventPropMap[eventType];
}

function stopPropagation(event: Event) {
  event.stopPropagation();
}
