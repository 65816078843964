import {
  FormEvent,
  ReactChild,
  ReactFragment,
  useCallback,
  useState,
} from "react";
import {Translation} from "../../../../services/i18n";
import {Appointment} from "../../../../services/time-book-scheduling-api";
import {AppointmentStatusInput} from "../../../appointment-status";
import {Form} from "../../../bootstrap";
import {ButtonContainer} from "../../../calendar/calendar-event-details/button-container";
import {ClinicNotesInput} from "../../../calendar/calendar-event-details/clinic-notes-input";
import {ActionButton} from "../../../form";
import styles from "./edit-appointment-form.module.scss";

const APPOINTMENT_ID = "APPOINTMENT_ID";
const APPOINTMENT_VERSION = "APPOINTMENT_VERSION";
const CLINIC_ID = "CLINIC_ID";
const CLINIC_NOTES = "CLINIC_NOTES";
const STATUS = "STATUS";

const FormDataEntryName = {
  APPOINTMENT_ID,
  APPOINTMENT_VERSION,
  CLINIC_ID,
  CLINIC_NOTES,
  STATUS,
} as const;

export {EditAppointmentForm as default, FormDataEntryName};

interface EditAppointmentFormProps {
  appointment: Appointment;
  error?: ReactChild | ReactFragment | null;
  loading?: boolean;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

function EditAppointmentForm(props: EditAppointmentFormProps) {
  const {appointment, error, loading, onSubmit: dispatchSubmitEvent} = props;

  const [clinicNotes, setClinicNotes] = useState(appointment.clinicNotes);
  const [status, setStatus] = useState(appointment.status);

  const [validated, setValidated] = useState(false);
  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setValidated(true);

      if (event.currentTarget.checkValidity()) {
        dispatchSubmitEvent(event);
      }
    },
    [dispatchSubmitEvent, setValidated]
  );

  const isFormSubmittable =
    clinicNotes !== appointment.clinicNotes || status !== appointment.status;

  return (
    <Form
      className={styles.root}
      // @ts-ignore - noValidate DO exist. STFU TypeScript!
      noValidate
      onSubmit={onSubmit}
      validated={validated}
    >
      <input type="hidden" name={APPOINTMENT_ID} value={appointment.id} />
      <input
        type="hidden"
        name={APPOINTMENT_VERSION}
        value={appointment.version}
      />
      <input type="hidden" name={CLINIC_ID} value={appointment.clinic.id} />

      <ClinicNotesInput
        name={CLINIC_NOTES}
        onValueChange={setClinicNotes}
        value={clinicNotes}
      />

      <AppointmentStatusInput
        name={STATUS}
        onValueChange={setStatus}
        value={status}
      />

      {error != null ? error : null}

      <ButtonContainer>
        <ActionButton
          block
          disabled={!isFormSubmittable}
          loading={loading}
          type="submit"
        >
          <Translation tKey="calendar-event-details-save-appointment" />
        </ActionButton>
      </ButtonContainer>
    </Form>
  );
}
