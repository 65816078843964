import {TimeUnit} from "./date-time-units";
import {isValidDate} from "./is-date";

const getDateDifferenceInDays = createDateDifference(TimeUnit.Day);
const getDateDifferenceInHours = createDateDifference(TimeUnit.Hour);
const getDateDifferenceInMinutes = createDateDifference(TimeUnit.Minute);
const getDateDifferenceInSeconds = createDateDifference(TimeUnit.Second);

export {
  getDateDifferenceInDays,
  getDateDifferenceInHours,
  getDateDifferenceInMinutes,
  getDateDifferenceInSeconds,
  getDateDifferenceInMilliseconds,
};

class InvalidDateError extends Error {}

/**
 * Returns the number of milliseconds between two dates.
 * If `a` > `b`, the result is negative.
 * @param a A Date or a parsable date string
 * @param b A Date or a parsable date string
 * @returns
 */
function getDateDifferenceInMilliseconds(a: Date | string, b: Date | string) {
  const dateA = new Date(a);
  const dateB = new Date(b);

  if (!isValidDate(dateA) || !isValidDate(dateB)) {
    throw new InvalidDateError();
  }
  return dateB.getTime() - dateA.getTime();
}

function createDateDifference(unit: TimeUnit) {
  return (...args: Parameters<typeof getDateDifferenceInMilliseconds>) => {
    const diff = getDateDifferenceInMilliseconds(...args);
    const diffInUnit = diff / unit;

    // To avoid -0
    if (diffInUnit > -1 && diffInUnit < 1) {
      return 0;
    }
    return diffInUnit < 0 ? Math.ceil(diffInUnit) : Math.floor(diffInUnit);
  };
}
