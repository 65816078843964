import {useEffect, useRef} from "react";
import {QueryKey, useQuery, UseQueryOptions, UseQueryResult} from "react-query";
import createCompoundQueryResult from "./create-compound-query-result";
import getCompoundStatus from "./get-compound-status";
import getDerivedBooleans from "./get-derived-booleans";

export default useDerivedQuery;

type UseDerivedQueryOptions = Pick<
  UseQueryOptions,
  "cacheTime" | "keepPreviousData" | "staleTime"
>;

function useDerivedQuery<T extends any[], R extends any>(
  queryKey: QueryKey,
  queryResults: readonly [...{[I in keyof T]: UseQueryResult<T[I]>}],
  queryResultFactory: (compoundQueryResultData: readonly [...T]) => R,
  options?: UseDerivedQueryOptions
) {
  const compoundQueryResult = createCompoundQueryResult<T>(queryResults);

  const queryResultRef = useRef<UseQueryResult | null>(null);

  const queryResult = useQuery(
    queryKey,
    () => {
      return queryResultFactory(compoundQueryResult.data);
    },
    {
      ...options,
      enabled:
        compoundQueryResult.isSuccess &&
        !(compoundQueryResult.isFetching || compoundQueryResult.isRefetching),
    }
  );

  queryResultRef.current = queryResult;

  useEffect(() => {
    if (queryResultRef.current?.isFetched) {
      queryResultRef.current?.refetch({cancelRefetch: true});
    }
  }, [compoundQueryResult.dataUpdatedAt]);

  const status = getCompoundStatus([compoundQueryResult, queryResult]);
  const derivedBooleans = getDerivedBooleans(status);
  const isFetching = compoundQueryResult.isFetching; // || queryResult.isFetching;
  const isRefetching = compoundQueryResult.isRefetching; // || queryResult.isRefetching;

  return {
    ...queryResult,
    status,
    ...derivedBooleans,
    isFetching,
    isRefetching,
  } as typeof queryResult;
}
