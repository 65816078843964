import React, {useCallback, useState} from "react";
import {Translation} from "../../services/i18n";
import {trim} from "../../utils";
import {Button} from "../bootstrap";
import {BookingData, CalendarData} from "../calendar";
import {BookingModal} from "./booking-modal";
import {BookingModalTitle} from "./booking-modal-title";
import {useRescheduleBookingMutation} from "./use-reschedule-booking-mutation";

type RescheduleBookingProps = {
  booking: BookingData;
  onFailure?: (error: Error) => void;
  onHide: () => void;
  onSuccess?: (data: BookingData) => void;
  show: boolean;
};

export const RescheduleBookingModal = (props: RescheduleBookingProps) => {
  const {booking, onFailure, onHide, onSuccess, show} = props;
  const {appointment: existingAppointment, resident} = booking;
  const clinicId = existingAppointment.clinic.id;

  const [clinicNotes, setClinicNotes] = useState(
    existingAppointment.clinicNotes
  );
  const rescheduleMutation = useRescheduleBookingMutation();

  const reschedule = useCallback(
    (calendarData: CalendarData) => {
      const {slot: nextSlot} = calendarData;

      rescheduleMutation
        .mutateAsync({
          clinicNotes: trim(clinicNotes),
          existingAppointment,
          nextSlot,
        })
        .then(
          (nextAppointment) => {
            onSuccess?.({
              ...booking,
              appointment: nextAppointment,
            });
          },
          (error) => {
            onFailure?.(error);
          }
        );
    },
    [
      booking,
      clinicNotes,
      existingAppointment,
      onFailure,
      onSuccess,
      rescheduleMutation,
    ]
  );

  const title = (
    <BookingModalTitle resident={resident}>
      <Translation tKey="booking-modal-reschedule-title" />
    </BookingModalTitle>
  );

  const renderSlotTableActions = useCallback(
    (calendarData: CalendarData) => (
      <Button onClick={() => reschedule(calendarData)}>
        <Translation tKey="booking-modal-reschedule-cta" />
      </Button>
    ),
    [reschedule]
  );

  return (
    <BookingModal
      clinicId={clinicId}
      clinicNotes={clinicNotes}
      existingBooking={booking}
      isLoading={rescheduleMutation.isLoading}
      onClinicNotesChange={setClinicNotes}
      onHide={onHide}
      renderSlotTableActions={renderSlotTableActions}
      show={show}
      title={title}
    />
  );
};
