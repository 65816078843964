import {Filter as ApiSearchFilter} from "../../search-query";

const ROOT = "appointments";

export const createResidentAppointmentsAllKey = () => [ROOT];

export const createResidentAppointmentDetailsKey = (
  clinicId: string,
  appointmentId: string,
  residentId: string
) => [ROOT, "detail", {clinicId, residentId, appointmentId}];

export const createResidentAppointmentsListKey = (
  clinicId: string,
  residentId: string
) => [ROOT, "list", {clinicId}, {residentId}];

export const createResidentAppointmentsSearchKey = (
  clinicId: string,
  residentId: string,
  filter: ApiSearchFilter | null
) => [...createResidentAppointmentsListKey(clinicId, residentId), {filter}];
