import React, {ReactNode} from "react";
import ApiClient from "./api-client";
import ApiClientContext from "./api-client-context";

export default ApiClientProvider;

interface ApiClientProviderProps {
  children: ReactNode;
  client: ApiClient;
}

function ApiClientProvider(props: ApiClientProviderProps) {
  const {children, client} = props;

  return (
    <ApiClientContext.Provider value={client}>
      {children}
    </ApiClientContext.Provider>
  );
}
