import React, {useCallback} from "react";
import {useHistory} from "react-router-dom";
import {Alert, Button} from "../../components/bootstrap";
import {Card} from "../../components/card";
import {Loading} from "../../components/loading";
import {LoadingError} from "../../components/loading-error";
import {LoadingSuccess} from "../../components/loading-success";
import {useUpdateSlotTypeMutation} from "../../services/api/slot-types/hooks";
import {Translation} from "../../services/i18n";
import {SlotType} from "../../services/time-book-scheduling-api";
import {SlotTypeForm, SlotTypeFormValue} from "./components/slot-type-form";
import styles from "./edit-slot-type-route.module.scss";

interface UpdateSlotTypeProps {
  slotType: SlotType;
}

export const UpdateSlotType = (props: UpdateSlotTypeProps) => {
  const {slotType} = props;
  const history = useHistory();

  const clinicId = slotType.clinic.id;
  const slotTypeId = slotType.id;

  const updateSlotTypeMutation = useUpdateSlotTypeMutation({
    clinicId,
    slotTypeId,
  });

  const onCancel = useCallback(() => history.goBack(), [history]);

  const onSlotTypeSubmit = useCallback(
    (slotTypeFormValue: SlotTypeFormValue) => {
      const input = {
        ...slotType,
        ...slotTypeFormValue,
      };

      updateSlotTypeMutation.mutate({input});
    },
    [slotType, updateSlotTypeMutation]
  );

  return (
    <Card className={styles.root}>
      {updateSlotTypeMutation.isLoading ? (
        <Alert variant="info">
          <Loading>
            <Translation tKey="slot-type-update-loading" />
          </Loading>
        </Alert>
      ) : null}
      {updateSlotTypeMutation.isError ? (
        <LoadingError error={updateSlotTypeMutation.error} />
      ) : null}
      {updateSlotTypeMutation.isSuccess ? (
        <div>
          <Button className={styles.backLink} onClick={onCancel} variant="link">
            <Translation tKey="slot-type-form-button-back" />
          </Button>

          <LoadingSuccess>
            <Translation tKey="slot-type-update-success" />
          </LoadingSuccess>
        </div>
      ) : null}

      <SlotTypeForm
        slotType={slotType}
        onSlotTypeSubmit={onSlotTypeSubmit}
        onCancel={onCancel}
      />
    </Card>
  );
};
