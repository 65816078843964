import {useTranslation} from "../../../../services/i18n";
import {createOption} from "./utils";

export default useSlotStatusOptions;

function useSlotStatusOptions() {
  const tBlocked = useTranslation("calendar-slot-status-option-blocked");
  const tCreated = useTranslation("calendar-slot-status-option-created");

  const tAll = useTranslation("calendar-slot-status-option-all");

  return [
    createOption(tAll, ""),
    createOption(tBlocked, "BLOCKED"),
    createOption(tCreated, "CREATED"),
  ];
}
