import {SlotStatus} from "../../../../services/time-book-scheduling-api";
import {isNonEmptyString} from "../../../../utils";

const AVEC_APPOINTMENT = "AVEC_APPOINTMENT";
const SANS_APPOINTMENT = "SANS_APPOINTMENT";

export const BookingType = {
  AVEC_APPOINTMENT,
  SANS_APPOINTMENT,
} as const;

/* eslint-disable-next-line @typescript-eslint/no-redeclare -- We know what we are doing here */
export type BookingType = typeof BookingType[keyof typeof BookingType];

export function isBookingType(value: unknown): value is BookingType {
  // @ts-ignore - STFU TypeScript
  return isNonEmptyString(value) && Object.values(BookingType).includes(value);
}

export function isSlotStatus(value: unknown): value is SlotStatus {
  // @ts-ignore - STFU TypeScript
  return isNonEmptyString(value) && Object.values(SlotStatus).includes(value);
}
