import invariant from "invariant";
import memoize from "mem";

interface ProcessEnv extends NodeJS.ProcessEnv {
  REACT_APP_COMMIT_SHA: string;
  REACT_APP_DESCRIPTION: string;
  REACT_APP_NAME: string;
  REACT_APP_REACT_QUERY_REFETCH_ON_WINDOW_FOCUS: string;
  REACT_APP_VERSION: string;
}

interface EnvironmentConfiguration {
  COMMIT_SHA: string;
  DESCRIPTION: string;
  NAME: string;
  REACT_QUERY_REFETCH_ON_WINDOW_FOCUS: boolean | "always";
  VERSION: string;
}

const getEnvironmentConfigurationMemoized = memoize(
  getEnvironmentConfiguration
);

export default getEnvironmentConfigurationMemoized;

function getEnvironmentConfiguration(): EnvironmentConfiguration {
  const result = process.env;
  const {
    REACT_APP_COMMIT_SHA,
    REACT_APP_DESCRIPTION,
    REACT_APP_NAME,
    REACT_APP_REACT_QUERY_REFETCH_ON_WINDOW_FOCUS,
    REACT_APP_VERSION,
  } = result as ProcessEnv;

  invariant(
    isValidRefetchOnWindowFocusEnvironmentValue(
      REACT_APP_REACT_QUERY_REFETCH_ON_WINDOW_FOCUS
    ),
    'Environment variable REACT_APP_REACT_QUERY_REFETCH_ON_WINDOW_FOCUS must be `"always" | "false" | "true"`'
  );

  return {
    COMMIT_SHA: REACT_APP_COMMIT_SHA,
    DESCRIPTION: REACT_APP_DESCRIPTION,
    NAME: REACT_APP_NAME,
    REACT_QUERY_REFETCH_ON_WINDOW_FOCUS:
      getReactQueryRefetchOnWindowFocusValueFromEnvironmentValue(
        REACT_APP_REACT_QUERY_REFETCH_ON_WINDOW_FOCUS
      ),
    VERSION: REACT_APP_VERSION,
  };
}

function getReactQueryRefetchOnWindowFocusValueFromEnvironmentValue(
  environmentValue: string
): boolean | "always" {
  if (environmentValue === "always") {
    return "always";
  }

  return environmentValue === "true";
}

function isValidRefetchOnWindowFocusEnvironmentValue(
  environmentValue: string
): boolean {
  return (
    environmentValue === "always" ||
    environmentValue === "false" ||
    environmentValue === "true"
  );
}
