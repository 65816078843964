import React from "react";
import styles from "./slot-type-list.module.scss";

export const SlotTypeList = (props) => {
  const {children, gap} = props;
  const style = {
    ...(gap != null ? {"--item-gap": gap} : null),
  };

  return (
    <ul className={styles.root} style={style}>
      {React.Children.map(children, (child) => {
        return <li>{child}</li>;
      })}
    </ul>
  );
};
