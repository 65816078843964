import {useCallback} from "react";
import {
  setClinicIdAction,
  useBookingAppState,
} from "../services/booking-app-state";

export default useClinicId;

function useClinicId() {
  const [state, dispatch] = useBookingAppState();

  const setClinicId = useCallback(
    (clinicId: string) => {
      dispatch(setClinicIdAction(clinicId));
    },
    [dispatch]
  );

  return [state.clinicId, setClinicId] as const;
}
