export const COLOR_GRASS = "grass";
export const COLOR_PLUM = "plum";
export const COLOR_SKY = "sky";
export const COLOR_STONE = "stone";
export const COLOR_SUN = "sun";

export type ColorName =
  | typeof COLOR_GRASS
  | typeof COLOR_PLUM
  | typeof COLOR_SKY
  | typeof COLOR_STONE
  | typeof COLOR_SUN;
