import {useQuery, useQueryClient} from "react-query";
import {toMilliseconds} from "../../../../utils/time/duration";
import {SlotType} from "../../../time-book-scheduling-api";
import {createSlotTypesListKey} from "../query-key-creators";
import useSlotTypeQueryCreators, {
  GetSlotTypeQueryVariables,
  GetSlotTypeQueryOptions,
} from "./use-slot-type-query-creators";

export default useGetSlotTypeQuery;

function useGetSlotTypeQuery(
  variables: GetSlotTypeQueryVariables,
  options?: GetSlotTypeQueryOptions
) {
  const queryClient = useQueryClient();
  const {createGetSlotTypeQuery} = useSlotTypeQueryCreators();

  return useQuery(
    createGetSlotTypeQuery(variables, {
      initialData: () => {
        const queryKey = createSlotTypesListKey(variables.clinicId);

        return queryClient
          .getQueryData<SlotType[]>(queryKey)
          ?.find((slotType) => slotType.id === variables.slotTypeId);
      },
      // slot types rarely changes and if they do
      // the mutator should invalidate this query
      cacheTime: toMilliseconds({minutes: 30}),
      staleTime: Infinity,
      ...options,
    })
  );
}
