import {Fragment, ReactElement, useCallback, useRef, useState} from "react";
import {getNextDay, getPreviousDay, isValidDate} from "../../../utils";
import {createMiddayDate} from "../../../utils/date-time";
import {Button, ButtonGroup, Overlay, Popover} from "../../bootstrap";
import {ConditionalTooltip} from "../../conditional-tooltip";
import {DayPicker} from "../../day-picker";
import {CalendarIcon} from "../../icon";
import styles from "./date-paginator.module.scss";

export default DatePaginator;

interface DatePaginatorProps {
  date: Date;
  maxDate?: Date;
  minDate?: Date;
  onDateChange: (date: Date) => void;
  tooltipCalendar?: ReactElement | string;
  tooltipNext?: ReactElement | string;
  tooltipPrevious?: ReactElement | string;
}

function DatePaginator(props: DatePaginatorProps) {
  const {
    date: selectedDate,
    maxDate,
    minDate,
    onDateChange,
    tooltipCalendar,
    tooltipNext,
    tooltipPrevious,
  } = props;
  const [show, setShow] = useState(false);
  const containerRef = useRef(null);

  const selectedMiddayDate = isValidDate(selectedDate)
    ? createMiddayDate(selectedDate)
    : undefined;
  const minMiddayDate = isValidDate(minDate)
    ? createMiddayDate(minDate)
    : undefined;
  const maxMiddayDate = isValidDate(maxDate)
    ? createMiddayDate(maxDate)
    : undefined;

  const isPrevButtonDisabled =
    isValidDate(selectedMiddayDate) &&
    isValidDate(minMiddayDate) &&
    selectedMiddayDate <= minMiddayDate;
  const isNextButtonDisabled =
    isValidDate(selectedMiddayDate) &&
    isValidDate(maxMiddayDate) &&
    selectedMiddayDate >= maxMiddayDate;
  const dayPickerDisabledDays = getDisabledDaysModifier(
    maxMiddayDate,
    minMiddayDate
  );

  const onPreviousButtonClick = useCallback(() => {
    onDateChange(getPreviousDay(selectedDate));
  }, [selectedDate, onDateChange]);

  const onNextButtonClick = useCallback(() => {
    onDateChange(getNextDay(selectedDate));
  }, [selectedDate, onDateChange]);

  const onDayPickerClick = useCallback(
    (newDate) => {
      onDateChange(newDate);
      setShow(false);
    },
    [onDateChange]
  );

  const toggleDayPicker = useCallback(() => {
    setShow((show) => !show);
  }, [setShow]);

  return (
    <Fragment>
      <ButtonGroup className={styles.root} ref={containerRef}>
        <ConditionalTooltip id="tooltip-previous" tooltip={tooltipPrevious}>
          <Button
            className={styles.previousButton}
            data-testid="date-paginator-previous-btn"
            disabled={isPrevButtonDisabled}
            onClick={onPreviousButtonClick}
          >
            &lt;
          </Button>
        </ConditionalTooltip>
        <ConditionalTooltip id="tooltip-calendar" tooltip={tooltipCalendar}>
          <Button
            className={styles.calendarButton}
            data-testid="date-paginator-calendar-btn"
            onClick={toggleDayPicker}
          >
            <CalendarIcon />
          </Button>
        </ConditionalTooltip>
        <ConditionalTooltip id="tooltip-next" tooltip={tooltipNext}>
          <Button
            className={styles.nextButton}
            data-testid="calendar-navigation-next-btn"
            disabled={isNextButtonDisabled}
            onClick={onNextButtonClick}
          >
            &gt;
          </Button>
        </ConditionalTooltip>
      </ButtonGroup>
      <Overlay
        onHide={toggleDayPicker}
        placement="bottom"
        rootClose
        show={show}
        target={containerRef.current}
      >
        <Popover id="popover-basic" className={styles.popover}>
          <Popover.Content>
            <DayPicker
              disabledDays={dayPickerDisabledDays}
              initialMonth={selectedDate}
              onDayClick={onDayPickerClick}
            />
          </Popover.Content>
        </Popover>
      </Overlay>
    </Fragment>
  );
}

function getDisabledDaysModifier(maxDate?: Date, minDate?: Date) {
  if (isValidDate(maxDate) && isValidDate(minDate)) {
    return {after: maxDate, before: minDate};
  }
  if (isValidDate(maxDate)) {
    return {after: maxDate};
  }
  if (isValidDate(minDate)) {
    return {before: minDate};
  }
  return undefined;
}
