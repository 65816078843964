import React from "react";
import {SlotType} from "../../../services/time-book-scheduling-api";
import {toMinutes} from "../../../utils/time/duration";
import styles from "./slot-type-event-content.module.scss";

interface SlotTypeEventContentProps {
  slotType: SlotType;
}

export default SlotTypeEventContent;

function SlotTypeEventContent(props: SlotTypeEventContentProps) {
  const {slotType} = props;

  const heading = slotType.name;
  const durationInMinutes = toMinutes(slotType.duration);

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <h6 className={styles.heading}>{heading}</h6>
        <div className={styles.duration}>({durationInMinutes} min)</div>
      </div>
    </div>
  );
}
