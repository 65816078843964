import React, {ReactNode} from "react";
import styles from "./scheduling-wizard-content-constrainer.module.scss";

export default SchedulingWizardContentConstrainer;

type SchedulingWizardContentConstrainerProps = {
  children: ReactNode;
};

function SchedulingWizardContentConstrainer(
  props: SchedulingWizardContentConstrainerProps
) {
  const {children} = props;

  return <div className={styles.root}>{children}</div>;
}
