import {Translation} from "../../services/i18n";
import {SlotType} from "../../services/time-book-scheduling-api";
import {toMinutes} from "../../utils/time/duration";
import styles from "./slot-type-and-duration.module.scss";

export default SlotTypeAndDuration;

interface SlotTypeAndDurationProps {
  slotType: SlotType;
}

function SlotTypeAndDuration(props: SlotTypeAndDurationProps) {
  const {slotType} = props;
  const name = slotType.name;
  const duration = toMinutes(slotType.duration);

  return (
    <div className={styles.root}>
      <span className={styles.name}>{name}</span>{" "}
      <span className={styles.duration}>
        (
        <Translation
          tKey="calendar-events-list-duration"
          tValues={{duration}}
        />
        )
      </span>
    </div>
  );
}
