import {ChangeEvent, useCallback} from "react";
import {Translation, useTranslation} from "../../../services/i18n";
import {
  FormControl,
  FormControlProps,
  FormGroup,
  FormText,
} from "../../bootstrap";

export {ClinicNotesInput};

interface ClinicNotesInputProps extends FormControlProps {
  name: string;
  onValueChange: (value: string) => void;
  value: string;
}

function ClinicNotesInput(props: ClinicNotesInputProps) {
  const {className, name, onValueChange, value, ...otherProps} = props;

  const placeholder = useTranslation("calendar-event-details-clinic-notes");
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onValueChange(event.currentTarget.value);
    },
    [onValueChange]
  );

  return (
    <FormGroup className={className} controlId={name}>
      <FormControl
        as="textarea"
        maxLength={150}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        rows={4}
        value={value}
        {...otherProps}
      />
      <FormText className="text-muted">
        <Translation tKey="calendar-event-details-clinic-notes-help" />
      </FormText>
    </FormGroup>
  );
}
