import {useRouteMatch} from "react-router-dom";
import {ErrorBoundary, useError} from "../error-boundary";
import BookingAppRoot from "./booking-app-root";
import BookingAppShell from "./booking-app-shell";
import {BookingAppStateInit} from "./services/booking-app-state";

export default BookingApp;

interface BookingAppProps {
  clinicId: string;
  initialBookingDate?: Date;
  initialSlotId?: string;
}

function BookingApp(props: BookingAppProps) {
  const {clinicId, initialBookingDate, initialSlotId} = props;
  const routeMatch = useRouteMatch();

  const initialState: BookingAppStateInit = {
    clinicId,
    bookingDate: initialBookingDate,
    routePath: routeMatch.path,
    routeUrl: routeMatch.url,
    slotId: initialSlotId,
  };

  return (
    <ErrorBoundary name="BookingApp" fallback={<BookingAppError />}>
      <BookingAppShell initialState={initialState}>
        <BookingAppRoot />
      </BookingAppShell>
    </ErrorBoundary>
  );
}

function BookingAppError() {
  const error = useError();

  return (
    <div>
      <h5>An error occurred</h5>
      {error != null ? <p>{error.message}</p> : null}
    </div>
  );
}
