import type {RowSelectionState, VisibilityState} from "@tanstack/react-table";
import {
  APPOINTMENT_CLINIC_NOTES,
  APPOINTMENT_ORIGIN,
  APPOINTMENT_STATUS,
  RESIDENT_NAME,
  RESIDENT_PERSONAL_IDENTITY_NUMBER,
  RESOURCE_INDEX,
  ROW_ACTIONS,
  ROW_NUMBER,
  ROW_SELECT,
  SLOT_TYPE,
  START_DATE,
  START_TIME,
} from "./column-ids";
import {BookingsTableColumnIdKey, BookingsTableColumnIdValue} from "./types";

export {
  getColumnIdEntries,
  getColumnIdKey,
  createColumnVisibilityState,
  createRowSelectionState,
};

function getColumnIdEntries() {
  return {
    ROW_NUMBER,
    ROW_SELECT,
    START_DATE,
    START_TIME,
    SLOT_TYPE,
    RESIDENT_PERSONAL_IDENTITY_NUMBER,
    RESIDENT_NAME,
    RESOURCE_INDEX,
    APPOINTMENT_ORIGIN,
    APPOINTMENT_CLINIC_NOTES,
    APPOINTMENT_STATUS,
    ROW_ACTIONS,
  } as const;
}

function getColumnIdKey(
  columnIdValue: BookingsTableColumnIdValue
): BookingsTableColumnIdKey {
  const foundEntry = Object.entries(getColumnIdEntries()).find(
    (bookingsTableColumnIdEntry) => {
      const [, value] = bookingsTableColumnIdEntry;

      return columnIdValue === value;
    }
  );

  if (foundEntry == null) {
    throw new Error("Column not found.");
  }

  return foundEntry[0] as BookingsTableColumnIdKey;
}

function createColumnVisibilityState(
  visibleColumns: BookingsTableColumnIdValue
): VisibilityState {
  return Object.entries(getColumnIdEntries()).reduce(
    (visibilityState, bookingsTableColumnIdEntry) => {
      const [key, value] = bookingsTableColumnIdEntry;

      visibilityState[key] = Boolean(visibleColumns & value);

      return visibilityState;
    },
    {} as VisibilityState
  );
}

function createRowSelectionState(slotIds: string[]): RowSelectionState {
  return slotIds.reduce((rowSelectionState, slotId) => {
    rowSelectionState[slotId] = true;

    return rowSelectionState;
  }, {} as RowSelectionState);
}
