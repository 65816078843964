import React from "react";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {CreateSlotType} from "./create-slot-type";
import {ListSlotTypesRoute} from "./list-slot-types-route";
import SlotTypeRoute from "./slot-type-route";

export default SlotTypesRoute;

type SlotTypesRouteProps = {
  clinicId: string;
};

function SlotTypesRoute(props: SlotTypesRouteProps) {
  const {clinicId} = props;
  const routeMatch = useRouteMatch();

  return (
    <Switch>
      <Route path={routeMatch.path} exact>
        <ListSlotTypesRoute clinicId={clinicId} />
      </Route>
      <Route path={`${routeMatch.path}/_new`} exact>
        <CreateSlotType clinicId={clinicId} />
      </Route>
      <Route path={`${routeMatch.path}/:slotTypeId`}>
        <SlotTypeRoute />
      </Route>
    </Switch>
  );
}
