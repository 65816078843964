import React from "react";
import {AppointmentStatus} from "../../../services/time-book-scheduling-api/entities/appointment-status";
import ActionButton, {ActionButtonProps} from "../../form/action-button";
import AppointmentStatusText from "../appointment-status-text";
import styles from "./appointment-status-button.module.scss";

export default AppointmentStatusButton;

interface AppointmentStatusButtonProps extends ActionButtonProps {
  value: AppointmentStatus;
}

function AppointmentStatusButton(props: AppointmentStatusButtonProps) {
  const {value} = props;

  return (
    <ActionButton
      {...props}
      className={styles.root}
      size="xs"
      type="button"
      variant="sans-variant"
    >
      <AppointmentStatusText status={value} />
    </ActionButton>
  );
}
