import {Fragment, ReactNode} from "react";
import {useCurrentClaims} from "../../hooks";
import {Permission, useAuthService} from "../../services/auth";

export default Authorization;

interface AuthorizationProps {
  children: ReactNode;
  requiredPermission: Permission;
  unauthorized?: ReactNode;
}

function Authorization(props: AuthorizationProps) {
  const {children, requiredPermission, unauthorized} = props;
  const claims = useCurrentClaims();
  const authService = useAuthService(claims);

  if (authService.isAuthorizedForPermission(requiredPermission)) {
    return <Fragment>{children}</Fragment>;
  }

  return unauthorized ? <Fragment>{unauthorized}</Fragment> : null;
}
