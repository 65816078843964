import {
  BOOKING_DATE_SET,
  CLINIC_ID_SET,
  MAIN_NAVIGATION_COLLAPSED,
  BookingDateAction,
  ClinicIdAction,
  MainNavigationCollapsedAction,
} from "./app-state-actions";
import {AppState} from "./app-state-context";

export default appStateReducer;

export function appStateReducer(
  state: AppState,
  action: BookingDateAction | ClinicIdAction | MainNavigationCollapsedAction
) {
  switch (action.type) {
    case BOOKING_DATE_SET:
      return {
        ...state,
        bookingDate: action.payload,
      };

    case CLINIC_ID_SET:
      return {
        ...state,
        clinicId: action.payload,
      };

    case MAIN_NAVIGATION_COLLAPSED:
      return {
        ...state,
        isMainNavigationCollapsed: action.payload,
      };

    default:
      return state;
  }
}
