import Appointment, {AppointmentJSON} from "./entities/appointment";
import Clinic, {ClinicJSON} from "./entities/clinic";
import Resident, {ResidentJSON} from "./entities/resident";
import ScheduleDefinition, {
  ScheduleDefinitionJSON,
} from "./entities/schedule-definition";
import Slot, {SlotJSON} from "./entities/slot";
import SlotRange from "./entities/slot-range";
import SlotType, {SlotTypeJSON} from "./entities/slot-type";
import * as HTTP_HEADER from "./http/http-header";
import * as HTTP_METHOD from "./http/http-method";
import {APPLICATION_JSON as MEDIA_TYPE_APPLICATION_JSON} from "./http/media-type";
import {HeadersFactoryOrObject} from "./http/types";
import {
  CreateAppointmentInput,
  PatchAppointmentInput,
  SearchAppointmentsInput,
  SearchAppointmentsResponse,
} from "./types/appointment";
import {
  CreateClinicInput,
  GetClinicsResponse,
  UpdateClinicInput,
} from "./types/clinic";
import {
  CreateResidentInput,
  SearchResidentsInput,
  SearchResidentsResponse,
  UpdateResidentInput,
} from "./types/resident";
import {
  CreateScheduleDefinitionInput,
  GetScheduleDefinitionsResponse,
} from "./types/schedule-definition";
import {
  CountSlotsResponse,
  CreateSlotInput,
  CreateSlotResponse,
  GetSlotResponse,
  PatchSlotInput,
  SearchSlotsInput,
  SearchSlotsResponse,
} from "./types/slot";
import {
  CreateSlotTypeInput,
  DeleteSlotTypesResponse,
  GetSlotTypeResponse,
  GetSlotTypesResponse,
  SearchSlotTypesInput,
  SearchSlotTypesResponse,
  UpdateSlotTypeInput,
} from "./types/slot-type";
import {mergeHeaders} from "./utils";

type AppointmentIdentifier = {
  clinicId: string;
  appointmentId: string;
};

export type StrictAppointmentIdentifier = AppointmentIdentifier & {
  appointmentVersion: string;
};

export type PatchAppointmentData = {
  identifier: StrictAppointmentIdentifier;
  input: PatchAppointmentInput;
};

type ClinicResidentIdentifier = {
  clinicId: string;
  residentId: string;
};

type ResidentAppointmentIdentifier = {
  appointmentId: string;
  clinicId: string;
  residentId: string;
};

export type StrictResidentAppointmentIdentifier =
  ResidentAppointmentIdentifier & {
    appointmentVersion: string;
  };

interface ClinicIdentifier {
  clinicId: string;
}

interface StrictClinicIdentifier extends ClinicIdentifier {
  clinicVersion: string;
}

interface SlotIdentifier {
  clinicId: string;
  slotId: string;
}

export interface StrictSlotIdentifier extends SlotIdentifier {
  slotVersion: string;
}

export type PatchSlotData = {
  identifier: StrictSlotIdentifier;
  input: PatchSlotInput;
};

interface ResidentIdentifier {
  residentId: string;
}

interface SlotTypeIdentifier {
  clinicId: string;
  slotTypeId: string;
}

interface ApiClientInit {
  serviceRootUrl: string;
  headers?: HeadersFactoryOrObject;
}

class ApiClient {
  #serviceRootUrl: string;
  #headers: HeadersFactoryOrObject;

  constructor(init: ApiClientInit) {
    const {serviceRootUrl, headers = getDefaultPresetHeaders()} = init;

    this.#serviceRootUrl = serviceRootUrl;
    this.#headers = headers;
  }
  fetch<HttpResult>(
    resource: string,
    init: RequestInit = {}
  ): Promise<HttpResult> {
    const serviceRootUrl = this.#serviceRootUrl;
    const presetHeaders = this.#headers;
    const requestHeaders = init.headers;

    const headers = mergeHeaders(presetHeaders, requestHeaders);

    const request = new Request(`${serviceRootUrl}${resource}`, {
      ...init,
      headers,
    });

    return fetch(request)
      .then((response) => {
        if (response.ok) {
          return response;
        }

        throw response;
      })
      .then(
        (response) => {
          return response.json();
        },
        async (response) => {
          const error = await response.json();

          throw new Error(error.detail);
        }
      );
  }

  // Appointments
  getAppointment(identifier: AppointmentIdentifier, init: RequestInit = {}) {
    const {appointmentId, clinicId} = identifier;

    return this.fetch<AppointmentJSON>(
      `/clinics/${clinicId}/appointments/${appointmentId}`,
      {
        ...init,
        method: HTTP_METHOD.GET,
      }
    );
  }
  createAppointment(
    identifier: ClinicIdentifier,
    input: CreateAppointmentInput,
    init: RequestInit = {}
  ) {
    const {clinicId} = identifier;
    const {clinic, clinicNotes, resident, slot} = input;
    const appointment = new Appointment({clinic, clinicNotes, resident, slot});

    return this.fetch<AppointmentJSON>(`/clinics/${clinicId}/appointments`, {
      ...init,
      body: JSON.stringify(appointment),
      method: HTTP_METHOD.POST,
    });
  }
  patchAppointment(
    identifier: StrictAppointmentIdentifier,
    input: PatchAppointmentInput,
    init: RequestInit = {}
  ) {
    const {clinicId, appointmentId, appointmentVersion} = identifier;

    return this.fetch<AppointmentJSON>(
      `/clinics/${clinicId}/appointments/${appointmentId}`,
      {
        ...init,
        body: JSON.stringify(input),
        headers: {[HTTP_HEADER.IF_MATCH]: appointmentVersion},
        method: HTTP_METHOD.PATCH,
      }
    );
  }
  searchAppointments(
    identifier: ClinicIdentifier,
    input: SearchAppointmentsInput,
    init: RequestInit = {}
  ) {
    const {clinicId} = identifier;
    const {filter} = input;

    return this.fetch<SearchAppointmentsResponse>(
      `/clinics/${clinicId}/appointments/search`,
      {
        ...init,
        body: JSON.stringify(filter),
        method: HTTP_METHOD.POST,
      }
    );
  }

  // Resident Appointments - for external booking
  createResidentAppointment(
    identifier: ClinicResidentIdentifier,
    input: CreateAppointmentInput,
    init: RequestInit = {}
  ) {
    const {clinicId, residentId} = identifier;
    const {clinic, clinicNotes, resident, slot} = input;
    const appointment = new Appointment({clinic, clinicNotes, resident, slot});

    return this.fetch<AppointmentJSON>(
      `/clinics/${clinicId}/residents/${residentId}/appointments`,
      {
        ...init,
        body: JSON.stringify(appointment),
        method: HTTP_METHOD.POST,
      }
    );
  }
  getResidentAppointment(
    identifier: ResidentAppointmentIdentifier,
    init: RequestInit = {}
  ) {
    const {appointmentId, clinicId, residentId} = identifier;

    return this.fetch<AppointmentJSON>(
      `/clinics/${clinicId}/residents/${residentId}/appointments/${appointmentId}`,
      {
        ...init,
        method: HTTP_METHOD.GET,
      }
    );
  }
  patchResidentAppointment(
    identifier: StrictResidentAppointmentIdentifier,
    input: PatchAppointmentInput,
    init: RequestInit = {}
  ) {
    const {appointmentId, appointmentVersion, clinicId, residentId} =
      identifier;

    return this.fetch<AppointmentJSON>(
      `/clinics/${clinicId}/residents/${residentId}/appointments/${appointmentId}`,
      {
        ...init,
        body: JSON.stringify(input),
        headers: {[HTTP_HEADER.IF_MATCH]: appointmentVersion},
        method: HTTP_METHOD.PATCH,
      }
    );
  }
  searchResidentAppointments(
    identifier: ClinicResidentIdentifier,
    input: SearchAppointmentsInput,
    init: RequestInit = {}
  ) {
    const {clinicId, residentId} = identifier;
    const {filter} = input;

    return this.fetch<SearchAppointmentsResponse>(
      `/clinics/${clinicId}/residents/${residentId}/appointments/search`,
      {
        ...init,
        body: JSON.stringify(filter),
        method: HTTP_METHOD.POST,
      }
    );
  }

  // Clinics
  getClinics(init: RequestInit = {}) {
    return this.fetch<GetClinicsResponse>(`/clinics`, {
      ...init,
      method: HTTP_METHOD.GET,
    });
  }
  getClinic(identifier: ClinicIdentifier, init: RequestInit = {}) {
    const {clinicId} = identifier;

    return this.fetch<ClinicJSON>(`/clinics/${clinicId}`, {
      ...init,
      method: HTTP_METHOD.GET,
    });
  }
  createClinic(input: CreateClinicInput, init: RequestInit = {}) {
    const {
      address,
      hsaId,
      name,
      phone,
      externalBookingStartRestriction,
      enableSelectSlotType,
    } = input;

    const clinic = new Clinic({
      address,
      hsaId,
      name,
      phone,
      externalBookingStartRestriction,
      enableSelectSlotType,
    });

    return this.fetch<ClinicJSON>(`/clinics`, {
      ...init,
      body: JSON.stringify(clinic),
      method: HTTP_METHOD.POST,
    });
  }
  updateClinic(
    identifier: StrictClinicIdentifier,
    input: UpdateClinicInput,
    init: RequestInit = {}
  ) {
    const {clinicId, clinicVersion} = identifier;
    const {
      address,
      enableSelectSlotType,
      externalBookingStartRestriction,
      hsaId,
      name,
      phone,
    } = input;

    const clinic = new Clinic({
      address,
      externalBookingStartRestriction,
      hsaId,
      id: clinicId,
      name,
      phone,
      version: clinicVersion,
      enableSelectSlotType,
    });

    return this.fetch<ClinicJSON>(`/clinics/${clinicId}`, {
      ...init,
      body: JSON.stringify(clinic),
      method: HTTP_METHOD.PUT,
    });
  }

  // Residents
  getResident(identifier: ResidentIdentifier, init: RequestInit = {}) {
    const {residentId} = identifier;

    return this.fetch<ResidentJSON>(`/residents/${residentId}`, {
      ...init,
      method: HTTP_METHOD.GET,
    });
  }
  createResident(input: CreateResidentInput, init: RequestInit = {}) {
    const {address, email, name, personalIdentityNumber, phone} = input;

    const resident = new Resident({
      address,
      email,
      name,
      personalIdentityNumber,
      phone,
    });

    return this.fetch<ResidentJSON>(`/residents`, {
      ...init,
      body: JSON.stringify(resident),
      method: HTTP_METHOD.POST,
    });
  }
  updateResident(
    identifier: ResidentIdentifier,
    input: UpdateResidentInput,
    init: RequestInit = {}
  ) {
    const {residentId} = identifier;
    const {id, address, email, name, personalIdentityNumber, phone, version} =
      input;

    const resident = new Resident({
      id,
      address,
      email,
      name,
      personalIdentityNumber,
      phone,
      version,
    });

    return this.fetch<ResidentJSON>(`/residents/${residentId}`, {
      ...init,
      body: JSON.stringify(resident),
      method: HTTP_METHOD.PUT,
    });
  }
  searchResidents(input: SearchResidentsInput, init: RequestInit = {}) {
    const {filter} = input;

    return this.fetch<SearchResidentsResponse>(`/residents/search`, {
      ...init,
      body: JSON.stringify(filter),
      method: HTTP_METHOD.POST,
    });
  }

  // ScheduleDefinitions
  getScheduleDefinitions(identifier: ClinicIdentifier, init: RequestInit = {}) {
    const {clinicId} = identifier;

    return this.fetch<GetScheduleDefinitionsResponse>(
      `/clinics/${clinicId}/scheduledefinitions`,
      {
        ...init,
        method: HTTP_METHOD.GET,
      }
    );
  }
  createScheduleDefinition(
    identifier: ClinicIdentifier,
    input: CreateScheduleDefinitionInput,
    init: RequestInit = {}
  ) {
    const {clinicId} = identifier;
    const {slotRanges: slotRangesInput} = input;

    const slotRanges = slotRangesInput.map((slotRangeInput) => {
      const {start, end, slotType, resourceIndex} = slotRangeInput;

      return new SlotRange({start, end, slotType, resourceIndex});
    });

    const scheduleDefinition = new ScheduleDefinition({
      slotRanges,
    });

    return this.fetch<ScheduleDefinitionJSON>(
      `/clinics/${clinicId}/scheduledefinitions`,
      {
        ...init,
        body: JSON.stringify(scheduleDefinition),
        method: HTTP_METHOD.POST,
      }
    );
  }

  // Slots
  getSlot(identifier: SlotIdentifier, init: RequestInit = {}) {
    const {clinicId, slotId} = identifier;

    return this.fetch<GetSlotResponse>(`/clinics/${clinicId}/slots/${slotId}`, {
      ...init,
      method: HTTP_METHOD.GET,
    });
  }
  searchSlots(
    identifier: ClinicIdentifier,
    input: SearchSlotsInput,
    init: RequestInit = {}
  ) {
    const {clinicId} = identifier;
    const {filter} = input;

    return this.fetch<SearchSlotsResponse>(
      `/clinics/${clinicId}/slots/search`,
      {
        ...init,
        body: JSON.stringify(filter),
        method: HTTP_METHOD.POST,
      }
    );
  }
  countSlots(
    identifier: ClinicIdentifier,
    input: SearchSlotsInput,
    init: RequestInit = {}
  ) {
    const {clinicId} = identifier;
    const {filter} = input;

    return this.fetch<CountSlotsResponse>(
      `/clinics/${clinicId}/slots/search/count`,
      {
        ...init,
        body: JSON.stringify(filter),
        method: HTTP_METHOD.POST,
      }
    );
  }
  createSlots(
    identifier: ClinicIdentifier,
    input: CreateSlotInput[],
    init: RequestInit = {}
  ) {
    const {clinicId} = identifier;

    const slots = input.map((createSlotInput) => {
      const {start, end, appointment, clinic, slotType, resourceIndex, status} =
        createSlotInput;

      return new Slot({
        start,
        end,
        appointment,
        clinic,
        slotType,
        resourceIndex,
        status,
      });
    });

    return this.fetch<CreateSlotResponse>(`/clinics/${clinicId}/slots`, {
      ...init,
      body: JSON.stringify(slots),
      method: HTTP_METHOD.POST,
    });
  }
  patchSlot(
    identifier: StrictSlotIdentifier,
    input: PatchSlotInput,
    init: RequestInit = {}
  ) {
    const {clinicId, slotId, slotVersion} = identifier;

    return this.fetch<SlotJSON>(`/clinics/${clinicId}/slots/${slotId}`, {
      ...init,
      body: JSON.stringify(input),
      headers: {[HTTP_HEADER.IF_MATCH]: slotVersion},
      method: HTTP_METHOD.PATCH,
    });
  }

  // SlotTypes
  getSlotType(identifier: SlotTypeIdentifier, init: RequestInit = {}) {
    const {clinicId, slotTypeId} = identifier;

    return this.fetch<GetSlotTypeResponse>(
      `/clinics/${clinicId}/slottypes/${slotTypeId}`,
      {
        ...init,
        method: HTTP_METHOD.GET,
      }
    );
  }
  getSlotTypes(identifier: ClinicIdentifier, init: RequestInit = {}) {
    const {clinicId} = identifier;

    return this.fetch<GetSlotTypesResponse>(`/clinics/${clinicId}/slottypes`, {
      ...init,
      method: HTTP_METHOD.GET,
    });
  }
  createSlotType(
    identifier: ClinicIdentifier,
    input: CreateSlotTypeInput,
    init: RequestInit = {}
  ) {
    const {clinicId} = identifier;
    const {color, duration, visibility, name} = input;

    const slotType = new SlotType({
      color,
      duration,
      visibility,
      name,
    });

    return this.fetch<SlotTypeJSON>(`/clinics/${clinicId}/slottypes`, {
      ...init,
      body: JSON.stringify(slotType),
      method: HTTP_METHOD.POST,
    });
  }
  updateSlotType(
    identifier: SlotTypeIdentifier,
    input: UpdateSlotTypeInput,
    init: RequestInit = {}
  ) {
    const {clinicId, slotTypeId} = identifier;
    const {id, color, duration, visibility, name, clinic, version} = input;

    const slotType = new SlotType({
      id,
      color,
      duration,
      visibility,
      name,
      clinic,
      version,
    });

    return this.fetch<SlotTypeJSON>(
      `/clinics/${clinicId}/slottypes/${slotTypeId}`,
      {
        ...init,
        body: JSON.stringify(slotType),
        method: HTTP_METHOD.PUT,
      }
    );
  }
  deleteSlotType(identifier: SlotTypeIdentifier, init: RequestInit = {}) {
    const {clinicId, slotTypeId} = identifier;

    return this.fetch<DeleteSlotTypesResponse>(
      `/clinics/${clinicId}/slottypes/${slotTypeId}`,
      {
        ...init,
        method: HTTP_METHOD.DELETE,
      }
    );
  }
  searchSlotTypes(
    identifier: SlotTypeIdentifier,
    input: SearchSlotTypesInput,
    init: RequestInit = {}
  ) {
    const {clinicId} = identifier;

    return this.fetch<SearchSlotTypesResponse>(
      `/clinics/${clinicId}/slottypes/search`,
      {
        ...init,
        body: JSON.stringify(input),
        method: HTTP_METHOD.POST,
      }
    );
  }
}

export default ApiClient;

function getDefaultPresetHeaders() {
  return {
    [HTTP_HEADER.ACCEPT]: MEDIA_TYPE_APPLICATION_JSON,
    [HTTP_HEADER.CONTENT_TYPE]: MEDIA_TYPE_APPLICATION_JSON,
  };
}
