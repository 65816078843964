import {useGetClinicQuery} from "../services/api/clinics/hooks";
import {isNonEmptyString} from "../utils";
import useClinicId from "./use-clinic-id";

export default useCurrentClinic;

function useCurrentClinic() {
  const [clinicId] = useClinicId();
  // No call when undefined
  const getClinicQuery = useGetClinicQuery(
    {clinicId: clinicId as string},
    {
      enabled: isNonEmptyString(clinicId),
    }
  );

  return getClinicQuery.data;
}
