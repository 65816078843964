import {isNonEmptyString} from "../../utils";

const EQUAL = "eq";
const NOT_EQUAL = "neq";
const GREATER_THAN = "gt";
const GREATER_THAN_OR_EQUAL = "gte";
const LESS_THAN = "lt";
const LESS_THAN_OR_EQUAL = "lte";

export const ComparisonOperator = {
  EQUAL,
  NOT_EQUAL,
  GREATER_THAN,
  GREATER_THAN_OR_EQUAL,
  LESS_THAN,
  LESS_THAN_OR_EQUAL,
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare -- We know what we do here
export type ComparisonOperator =
  typeof ComparisonOperator[keyof typeof ComparisonOperator];

const AND = "and";
const OR = "or";

export const LogicalOperator = {AND, OR} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare -- We know what we do here
export type LogicalOperator =
  typeof LogicalOperator[keyof typeof LogicalOperator];

type ConditionValue = boolean | null | number | string;

interface Condition {
  operator: ComparisonOperator;
  property: string;
  value: ConditionValue;
}

export interface Filter {
  conditions: Condition[];
  operator: LogicalOperator;
}

export {
  createCondition,
  createDateRangeFilter,
  createDateRangeStatusFilter,
  createFilter,
  createPersonalIdentityNumberFilter,
  createSearchEntitiesByIdFilter,
};

function createCondition(
  property: string,
  operator: ComparisonOperator,
  value: ConditionValue
): Condition {
  return {
    property,
    operator,
    value,
  };
}

function createSearchEntitiesByIdFilter(entityIds: string[]): Filter {
  const conditions = entityIds.map((entityId) => {
    return createCondition("id", ComparisonOperator.EQUAL, entityId);
  });

  return createFilter(conditions, LogicalOperator.OR);
}

function createDateRangeStatusFilter(
  start: Date | string,
  end: Date | string,
  prefix = "",
  status: string
): Filter {
  return createFilter([
    {
      operator: ComparisonOperator.GREATER_THAN,
      property: `${prefix}start`,
      value: new Date(start).toISOString(),
    },
    {
      operator: ComparisonOperator.LESS_THAN,
      property: `${prefix}end`,
      value: new Date(end).toISOString(),
    },
    {
      operator: ComparisonOperator.NOT_EQUAL,
      property: "status",
      value: status,
    },
  ]);
}

function createDateRangeFilter(
  start: Date | string,
  end: Date | string,
  prefix = ""
): Filter {
  return createFilter([
    {
      operator: ComparisonOperator.GREATER_THAN_OR_EQUAL,
      property: `${prefix}start`,
      value: new Date(start).toISOString(),
    },
    {
      operator: ComparisonOperator.LESS_THAN_OR_EQUAL,
      property: `${prefix}end`,
      value: new Date(end).toISOString(),
    },
  ]);
}

function createEqualsFilter(property: string, value: ConditionValue): Filter {
  return createFilter([
    {
      operator: ComparisonOperator.EQUAL,
      property,
      value,
    },
  ]);
}

function createPersonalIdentityNumberFilter(personalIdentityNumber: string) {
  return isNonEmptyString(personalIdentityNumber)
    ? createEqualsFilter("personal_identity_number", personalIdentityNumber)
    : createFilter();
}

function createFilter(
  conditions: Condition[] = [],
  operator: LogicalOperator = LogicalOperator.AND
) {
  return {conditions, operator};
}
