import {useContext} from "react";
import {ConfirmContext} from "./confirm-context";

export {useConfirm};

function useConfirm() {
  const confirm = useContext(ConfirmContext);

  if (confirm === null) {
    throw new Error("Cannot call useConfirm outside <ConfirmProvider>");
  }
  return confirm;
}
