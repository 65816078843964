import {useTranslation} from "../../../../services/i18n";
import {AppointmentStatus} from "../../../../services/time-book-scheduling-api";
import {createOption} from "./utils";

export default useAppointmentStatusOptions;

function useAppointmentStatusOptions() {
  const tAll = useTranslation("calendar-appointment-status-option-all");
  const tBooked = useTranslation("appointment-status-booked");
  const tFulfilled = useTranslation("appointment-status-fulfilled");
  const tNoShow = useTranslation("appointment-status-no-show");

  return [
    createOption(tAll, ""),
    createOption(tBooked, AppointmentStatus.BOOKED),
    createOption(tFulfilled, AppointmentStatus.FULFILLED),
    createOption(tNoShow, AppointmentStatus.NO_SHOW),
  ];
}
