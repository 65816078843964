import {FormEvent, useCallback} from "react";
import {usePatchAppointmentMutation} from "../../../../services/api/appointments";
import {Appointment} from "../../../../services/time-book-scheduling-api";
import {getFormDataEntryValue} from "../../../../utils/form-data";
import {createReplaceOperation} from "../../../../utils/json-patch";
import {LoadingError} from "../../../loading-error";
import EditAppointmentForm, {FormDataEntryName} from "./edit-appointment-form";

export default UpdateAppointmentPanel;

interface UpdateAppointmentPanelProps {
  appointment: Appointment;
}

function UpdateAppointmentPanel(props: UpdateAppointmentPanelProps) {
  const {appointment} = props;

  const patchAppointmentMutation = usePatchAppointmentMutation({
    appointmentId: appointment.id,
    appointmentVersion: appointment.version,
    clinicId: appointment.clinic.id,
  });

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const formData = new FormData(event.currentTarget);
      const clinicNotes = getFormDataEntryValue(
        formData,
        FormDataEntryName.CLINIC_NOTES
      );
      const status = getFormDataEntryValue(formData, FormDataEntryName.STATUS);

      const patch = [];

      if (clinicNotes !== appointment.clinicNotes) {
        patch.push(createReplaceOperation("clinic_notes", clinicNotes));
      }

      if (status !== appointment.status) {
        patch.push(createReplaceOperation("status", status));
      }

      if (patch.length > 0) {
        patchAppointmentMutation.mutate({input: patch});
      }
    },
    [appointment, patchAppointmentMutation]
  );

  return (
    <EditAppointmentForm
      key={`${appointment.id}-${appointment.version}`}
      appointment={appointment}
      error={
        patchAppointmentMutation.isError ? (
          <LoadingError error={patchAppointmentMutation.error} />
        ) : null
      }
      loading={patchAppointmentMutation.isLoading}
      onSubmit={onSubmit}
    />
  );
}
