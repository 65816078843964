import React, {ForwardedRef, MutableRefObject, useEffect} from "react";

export default useCombinedRefs;

/*
 * Used to combine multiple refs into one. Use case is when
 * you need to have an internal ref and the component is
 * already using React.forwardRef or vice versa.
 */
function useCombinedRefs<T>(
  ...refs: Array<ForwardedRef<T> | MutableRefObject<T>>
) {
  const targetRef = React.useRef<T>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) {
        return;
      }

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}
