// ValidityState on MDN:
// https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
import {formatPersonalIdentityNumber} from "./format-personal-identity-number";

export {
  createValidityState,
  formatPersonalIdentityNumber,
  getPersonalIdentityNumberValidity,
};

function getPersonalIdentityNumberValidity(input: string): ValidityState {
  const patternMismatch = isInputPatternMismatch(input);
  const valueMissing = isInputValueMissing(input);

  return createValidityState({patternMismatch, valueMissing});
}

// We support four different type of PINs; "Personnummer",
// "Samordningsnummer", "Nationell reservidentitet" and
// "regional reservidentitet" (Skåne only!).
// See https://confluence.cgiostersund.se/display/PU/Identitetsformat

// Matches 6 or 8 digits followed by 4 digits or letters
const validPattern = /^(\d{6}|\d{8})[0-9a-z]{4}$/i;

function isInputPatternMismatch(input: string) {
  return !validPattern.test(input);
}

function isInputValueMissing(input: string) {
  return input.length === 0;
}

function createValidityState(
  partialValidityState: Partial<ValidityState>
): ValidityState {
  const defaultValidityState = {
    badInput: false,
    customError: false,
    patternMismatch: false,
    rangeOverflow: false,
    rangeUnderflow: false,
    stepMismatch: false,
    tooLong: false,
    tooShort: false,
    typeMismatch: false,
    valueMissing: false,
    valid: true,
  };

  const valid = !Object.values(partialValidityState).some(
    (validityState) => validityState
  );

  return Object.assign(defaultValidityState, partialValidityState, {valid});
}
