import React, {ReactNode} from "react";
import {useTranslation} from "../../services/i18n";
import {Alert} from "../bootstrap";

type LoadingSuccessProps = {
  children?: ReactNode;
  className?: string;
};

export const LoadingSuccess = (props: LoadingSuccessProps) => {
  const defaultMessage = useTranslation("generic-loading-success");
  const {children = defaultMessage, className} = props;

  return (
    <Alert className={className} variant="success">
      {children}
    </Alert>
  );
};
