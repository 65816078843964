import React, {ReactNode} from "react";
import {useTranslation} from "../../../services/i18n";
import {Alert} from "../../bootstrap";
import {LoadingIcon} from "../../icon";
import SchedulingWizardContentConstrainer from "./scheduling-wizard-content-constrainer";

export default SchedulingWizardMutationLoading;

type SchedulingWizardMutationLoadingProps = {
  children?: ReactNode;
};

function SchedulingWizardMutationLoading(
  props: SchedulingWizardMutationLoadingProps
) {
  const tDefaultMessage = useTranslation("scheduling-wizard-mutation-loading");
  const {children = tDefaultMessage} = props;

  return (
    <SchedulingWizardContentConstrainer>
      <Alert variant={"info"}>
        <LoadingIcon spin /> {children}
      </Alert>
    </SchedulingWizardContentConstrainer>
  );
}
