import {useCallback, useMemo} from "react";
import {UseQueryOptions} from "react-query";
import {isNull} from "../../../../utils";
import {Appointment} from "../../../time-book-scheduling-api";
import {createFilter, Filter as ApiSearchFilter} from "../../search-query";
import useApi from "../../use-api";
import {
  createResidentAppointmentDetailsKey,
  createResidentAppointmentsListKey,
  createResidentAppointmentsSearchKey,
} from "./resident-appointment-query-key-creators";

export type GetResidentAppointmentQueryVariables = {
  appointmentId: string;
  clinicId: string;
  residentId: string;
};

export type GetResidentAppointmentQueryOptions = UseQueryOptions<
  Appointment,
  Error
>;

export type GetResidentAppointmentsQueryVariables = {
  clinicId: string;
  residentId: string;
};

export type GetResidentAppointmentsQueryOptions = UseQueryOptions<
  Appointment[],
  Error
>;

export type SearchResidentAppointmentsQueryVariables = {
  clinicId: string;
  residentId: string;
  filter: ApiSearchFilter | null;
};

export type SearchResidentAppointmentsQueryOptions = UseQueryOptions<
  Appointment[],
  Error
>;

export const useResidentAppointmentQueryCreators = () => {
  const api = useApi();

  const createGetResidentAppointmentQuery = useCallback(
    (
      variables: GetResidentAppointmentQueryVariables,
      options: GetResidentAppointmentQueryOptions = {}
    ) => {
      const {appointmentId, clinicId, residentId} = variables;

      return {
        ...options,
        queryKey: createResidentAppointmentDetailsKey(
          clinicId,
          residentId,
          appointmentId
        ),
        queryFn() {
          return api
            .getResidentAppointment({appointmentId, clinicId, residentId})
            .then((appointmentData) => Appointment.fromJSON(appointmentData));
        },
      };
    },
    [api]
  );

  const createGetResidentAppointmentsQuery = useCallback(
    (
      variables: GetResidentAppointmentsQueryVariables,
      options: GetResidentAppointmentsQueryOptions = {}
    ) => {
      const {clinicId, residentId} = variables;
      const filter = createFilter();

      return {
        ...options,
        queryKey: createResidentAppointmentsListKey(clinicId, residentId),
        queryFn() {
          // TODO: Replace with getResidentAppointments once we have an endpoint
          return api
            .searchResidentAppointments({clinicId, residentId}, {filter})
            .then(({data}) =>
              data.map((appointmentData) =>
                Appointment.fromJSON(appointmentData)
              )
            );
        },
      };
    },
    [api]
  );

  const createSearchResidentAppointmentsQuery = useCallback(
    (
      variables: SearchResidentAppointmentsQueryVariables,
      options: SearchResidentAppointmentsQueryOptions = {}
    ) => {
      const {clinicId, residentId, filter} = variables;

      return {
        ...options,
        queryKey: createResidentAppointmentsSearchKey(
          clinicId,
          residentId,
          filter
        ),
        queryFn() {
          return isNull(filter)
            ? Promise.reject(new TypeError("Provided `filter` is `null"))
            : api
                .searchResidentAppointments({clinicId, residentId}, {filter})
                .then(({data}) =>
                  data.map((appointmentData) =>
                    Appointment.fromJSON(appointmentData)
                  )
                );
        },
      };
    },
    [api]
  );

  return useMemo(
    () => ({
      createGetResidentAppointmentQuery,
      createGetResidentAppointmentsQuery,
      createSearchResidentAppointmentsQuery,
    }),
    [
      createGetResidentAppointmentQuery,
      createGetResidentAppointmentsQuery,
      createSearchResidentAppointmentsQuery,
    ]
  );
};
