import {SlotType} from "../../services/time-book-scheduling-api";
import {classNames} from "../../utils";
import styles from "./slot-type-color.module.scss";

type SlotTypeColorProps = {
  className?: string;
  slotType: SlotType;
};

export function SlotTypeColor(props: SlotTypeColorProps) {
  const {className, slotType} = props;
  const classes = classNames(styles.root, className);

  return (
    <span
      className={classes}
      data-testid="slot-type-label-color"
      style={{borderColor: slotType.color}}
    />
  );
}
