import {useCallback, useRef, Dispatch, SetStateAction} from "react";

export default useRefState;

/*
 * Gives you a useState-like API without triggering a render
 */
function useRefState<S>(initialState: S) {
  const refState = useRef<S>(initialState);
  const setRefState = useCallback<Dispatch<SetStateAction<S>>>((value) => {
    if (typeof value === "function") {
      // @ts-ignore
      refState.current = value(refState.current);
    } else {
      refState.current = value;
    }
  }, []);

  return [refState, setRefState] as const;
}
