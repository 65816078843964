/**
 * DayPicker Localization
 * @see https://react-day-picker.js.org/docs/localization
 */
import {LocaleUtils} from "react-day-picker";
import {enGB, svSE} from "../../services/i18n/locales";
import {
  getLongMonthNames,
  getLongWeekdayNames,
  getNarrowWeekdayNames,
} from "../../utils/i18n";

const WEEKDAYS_LONG = {
  en: getLongWeekdayNames(enGB.tag),
  sv: getLongWeekdayNames(svSE.tag),
};

const WEEKDAYS_SHORT = {
  en: getNarrowWeekdayNames(enGB.tag),
  sv: getNarrowWeekdayNames(svSE.tag),
};

// prettier-ignore
type ListOfMonths = [string, string, string, string, string, string, string, string, string, string, string, string];

const MONTHS = {
  en: getLongMonthNames(enGB.tag) as ListOfMonths,
  sv: getLongMonthNames(svSE.tag) as ListOfMonths,
};

const FIRST_DAY = {
  en: 0,
  sv: 1, // Use Monday as first day of the week
};

type SupportedLocale = "en" | "sv";
const DEFAULT_LOCALE: SupportedLocale = "en";

const getSupportedLocale = (locale?: string): SupportedLocale =>
  locale === "sv" ? "sv" : DEFAULT_LOCALE;

function formatDay(date: Date, locale: string = DEFAULT_LOCALE) {
  const supportedLocale = getSupportedLocale(locale);

  return `${WEEKDAYS_LONG[supportedLocale][date.getDay()]}, ${date.getDate()} ${
    MONTHS[supportedLocale][date.getMonth()]
  } ${date.getFullYear()}`;
}

function formatMonthTitle(date: Date, locale: string = DEFAULT_LOCALE) {
  const supportedLocale = getSupportedLocale(locale);

  return `${MONTHS[supportedLocale][date.getMonth()]} ${date.getFullYear()}`;
}

function formatWeekdayShort(weekday: number, locale: string = DEFAULT_LOCALE) {
  const supportedLocale = getSupportedLocale(locale);

  return WEEKDAYS_SHORT[supportedLocale][weekday];
}

function formatWeekdayLong(weekday: number, locale: string = DEFAULT_LOCALE) {
  const supportedLocale = getSupportedLocale(locale);

  return WEEKDAYS_SHORT[supportedLocale][weekday];
}

function getFirstDayOfWeek(locale: string = DEFAULT_LOCALE) {
  const supportedLocale = getSupportedLocale(locale);

  return FIRST_DAY[supportedLocale];
}

function getMonths(locale: string = DEFAULT_LOCALE) {
  const supportedLocale = getSupportedLocale(locale);

  return MONTHS[supportedLocale];
}

export const localeSettings = {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
  getMonths,
} as LocaleUtils;
