import {isValidDate} from "./is-date";
import {isFiniteNumber} from "./is-number";

export {resetLocalTime, resetUtcTime, setLocalTime, setUtcTime};

export interface TimeDescriptor {
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}

const time0: TimeDescriptor = {
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
};

/** Returns a new date with the time set to 00:00:00.000 */
function resetLocalTime(date: Date) {
  return setLocalTime(date, time0);
}

/** Returns a new date with the UTC time set to 00:00:00.000 */
function resetUtcTime(date: Date) {
  return setUtcTime(date, time0);
}

/** Returns a new date with the time set according to passed changes */
function setLocalTime(date: Date, changes: TimeDescriptor) {
  return setTimeInternal(date, changes, false);
}

/** Returns a new date with the UTC time set according to passed changes */
function setUtcTime(date: Date, changes: TimeDescriptor) {
  return setTimeInternal(date, changes, true);
}

function setTimeInternal(date: Date, changes: TimeDescriptor, utc: boolean) {
  if (!isValidDate(date)) {
    throw new TypeError("Invalid date");
  }
  const {hours, minutes, seconds, milliseconds} = changes;
  let changed = new Date(date);

  if (isFiniteNumber(hours)) {
    changed = new Date(
      utc ? changed.setUTCHours(hours) : changed.setHours(hours)
    );
  }
  if (isFiniteNumber(minutes)) {
    changed = new Date(
      utc ? changed.setUTCMinutes(minutes) : changed.setMinutes(minutes)
    );
  }
  if (isFiniteNumber(seconds)) {
    changed = new Date(
      utc ? changed.setUTCSeconds(seconds) : changed.setSeconds(seconds)
    );
  }
  if (isFiniteNumber(milliseconds)) {
    changed = new Date(
      utc
        ? changed.setUTCMilliseconds(milliseconds)
        : changed.setMilliseconds(milliseconds)
    );
  }
  return changed;
}
