// Note that the role values are in Swedish
const ADMIN = "ADMIN";
const BOOKING = "BOKNING";
const EXTERNBOKNING = "EXTERNBOKNING";
const SCHEDULING = "SCHEMA";

export const Role = {
  ADMIN,
  BOOKING,
  EXTERNBOKNING,
  SCHEDULING,
} as const;

/* eslint-disable-next-line @typescript-eslint/no-redeclare -- We know what we are doing here */
export type Role = typeof Role[keyof typeof Role];
