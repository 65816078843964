import React, {useCallback, useState} from "react";
import {Translation} from "../../../services/i18n";
import {SlotType} from "../../../services/time-book-scheduling-api";
import {isFunction} from "../../../utils";
import {useIsMounted} from "../../../utils/react";
import {Button} from "../../bootstrap";
import {WizardCalendarEvent} from "../wizard-calendar-event";
import {SchedulingOverview} from "./scheduling-overview";
import styles from "./scheduling-wizard-step-3.module.scss";

export default SchedulingWizardStep3;

type SchedulingWizardStep3Props = {
  startDate: Date;
  endDate: Date;
  onSubmit: () => void;
  calendarEvents: WizardCalendarEvent[];
  slotTypes: SlotType[];
};

function SchedulingWizardStep3(props: SchedulingWizardStep3Props) {
  const {calendarEvents, onSubmit: doSubmit, slotTypes} = props;

  const isMounted = useIsMounted();
  const [submitting, setSubmitting] = useState(false);

  const onButtonClick = useCallback(async () => {
    if (isFunction(doSubmit)) {
      try {
        setSubmitting(true);

        await doSubmit();
      } finally {
        if (isMounted()) {
          setSubmitting(false);
        }
      }
    }
  }, [setSubmitting, doSubmit, isMounted]);

  return (
    <div className={styles.root}>
      <SchedulingOverview
        calendarEvents={calendarEvents}
        slotTypes={slotTypes}
      />
      <div className={styles.buttonContainer}>
        <Button disabled={submitting} onClick={onButtonClick}>
          <Translation tKey="schedule-wizard-step-3-button-submit" />
        </Button>
      </div>
      <div className={styles.clarification}>
        <Translation tKey="schedule-wizard-step-3-clarification" />
      </div>
    </div>
  );
}
