import React from "react";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {faCircle, faComment} from "@fortawesome/free-regular-svg-icons";
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faBusinessTime,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarPlus,
  faChartPie,
  faClinicMedical,
  faClipboardList,
  faClock,
  faCogs,
  faCopy,
  faCheckCircle,
  faFileInvoice,
  faGift,
  faGlobe,
  faHistory,
  faHome,
  faHospital,
  faIdCardAlt,
  faInfoCircle,
  faLock,
  faPaste,
  faPrint,
  faQuestionCircle,
  faSearch,
  faSignOutAlt,
  faSlidersH,
  faSpinner,
  faStream,
  faTasks,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUserCircle,
  faUserPlus,
  faUsers,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

// For available icons, see:
// https://fontawesome.com/icons?d=gallery&s=regular,solid&m=free

// For more info about the React implementation, see:
// https://fontawesome.com/how-to-use/on-the-web/using-with/react

export type IconProps = Omit<FontAwesomeIconProps, "icon">;

const createIcon = (icon: IconDefinition) => (props: IconProps) =>
  <FontAwesomeIcon {...props} icon={icon} />;

export const ArrowCircleLeftIcon = createIcon(faArrowAltCircleLeft);
export const ArrowCircleRightIcon = createIcon(faArrowAltCircleRight);
export const AccessCardIcon = createIcon(faIdCardAlt);
export const AddUserIcon = createIcon(faUserPlus);
export const BusinessTimeIcon = createIcon(faBusinessTime);
export const CalendarCheckIcon = createIcon(faCalendarCheck);
export const CalendarIcon = createIcon(faCalendarAlt);
export const CalendarPlusIcon = createIcon(faCalendarPlus);
export const CancelAppointmentIcon = createIcon(faTrashAlt);
export const CheckCircleIcon = createIcon(faCheckCircle);
export const ChecklistIcon = createIcon(faTasks);
export const CircleIcon = createIcon(faCircle);
export const ClinicIcon = createIcon(faClinicMedical);
export const ClockIcon = createIcon(faClock);
export const CogsIcon = createIcon(faCogs);
export const CommentIcon = createIcon(faComment);
export const CopyIcon = createIcon(faCopy);
export const GiftIcon = createIcon(faGift);
export const GlobeIcon = createIcon(faGlobe);
export const HelpIcon = createIcon(faQuestionCircle);
export const HomeIcon = createIcon(faHome);
export const HospitalIcon = createIcon(faHospital);
export const InfoIcon = createIcon(faInfoCircle);
export const ListIcon = createIcon(faStream);
export const LoadingIcon = createIcon(faSpinner);
export const LockIcon = createIcon(faLock);
export const LogIcon = createIcon(faClipboardList);
export const LogOutIcon = createIcon(faSignOutAlt);
export const PasteIcon = createIcon(faPaste);
export const PrintIcon = createIcon(faPrint);
export const RemoveIcon = createIcon(faTimesCircle);
export const RemoveSlotRangeIcon = createIcon(faTrashAlt);
export const ReportIcon = createIcon(faFileInvoice);
export const RescheduleIcon = createIcon(faHistory);
export const SearchIcon = createIcon(faSearch);
export const SettingsIcon = createIcon(faSlidersH);
export const StatisticsIcon = createIcon(faChartPie);
export const TimesIcon = createIcon(faTimes);
export const TrashIcon = createIcon(faTrashAlt);
export const UserIcon = createIcon(faUserCircle);
export const UsersIcon = createIcon(faUsers);
export const UsersAdminIcon = createIcon(faUsersCog);
