import {useBookingDate, useClinicId, useCurrentClaims} from "../../hooks";
import {Permission, useAuthService} from "../../services/auth";
import {useTranslation} from "../../services/i18n";
import {
  ClinicView,
  generatePathToBooking,
  generatePathToClinic,
  generatePathToResidents,
  generatePathToScheduling,
} from "../../services/routing";
import {isNonEmptyString, toLocalIsoDateString} from "../../utils";
import {
  BusinessTimeIcon,
  CalendarPlusIcon,
  HospitalIcon,
  ListIcon,
  UsersIcon,
} from "../icon";
import {Navigation, NavigationItemProps} from "../navigation";

export default MainNavigation;

function MainNavigation() {
  const [bookingDate] = useBookingDate();
  const [clinicId] = useClinicId();
  const claims = useCurrentClaims();
  const authService = useAuthService(claims);

  const isAuthorizedToBook = authService.isAuthorizedForPermission(
    Permission.BOOK
  );
  const isAuthorizedToBookExternal = authService.isAuthorizedForPermission(
    Permission.BOOK_EXTERNAL
  );
  const isAuthorizedToSchedule = authService.isAuthorizedForPermission(
    Permission.SCHEDULE
  );

  const tBookings = useTranslation("main-nav-bookings");
  const tClinic = useTranslation("main-nav-clinic");
  const tResidents = useTranslation("main-nav-residents");
  const tScheduling = useTranslation("main-nav-scheduling-new");
  const tSlotTypes = useTranslation("main-nav-slot-types-list");

  if (!isNonEmptyString(clinicId)) {
    return null;
  }

  const mainNavigationItems: NavigationItemProps[] = [];

  if (isAuthorizedToBook) {
    mainNavigationItems.push({
      Icon: ListIcon,
      text: tBookings,
      to: generatePathToBooking({
        clinicId,
        date: bookingDate ? toLocalIsoDateString(bookingDate) : undefined,
      }),
    });
  }

  if (isAuthorizedToBook || isAuthorizedToBookExternal) {
    mainNavigationItems.push({
      text: tResidents,
      Icon: UsersIcon,
      to: generatePathToResidents({clinicId}),
    });
  }

  if (isAuthorizedToSchedule) {
    mainNavigationItems.push({
      Icon: CalendarPlusIcon,
      text: tScheduling,
      to: generatePathToScheduling({clinicId}),
    });
  }

  const children = [];
  /* for future milestone
  {
    text: "tResources",
    Icon: BusinessTimeIcon,
    to: generatePathToClinic({clinicId, view: ClinicView.RESOURCES}),
  },
  */
  if (isAuthorizedToSchedule) {
    children.push({
      text: tSlotTypes,
      Icon: BusinessTimeIcon,
      to: generatePathToClinic({clinicId, view: ClinicView.SLOT_TYPES}),
    });
  }

  mainNavigationItems.push({
    children,
    exact: true,
    Icon: HospitalIcon,
    text: tClinic,
    to: generatePathToClinic({clinicId}),
  });

  return <Navigation items={mainNavigationItems} />;
}
