import React, {useCallback, ReactNode, useState} from "react";
import {ConfirmContext} from "./confirm-context";
import {ConfirmDialog} from "./confirm-dialog";
import {ConfirmFunction, ConfirmOptions} from "./types";

export {ConfirmProvider};

interface ConfirmProviderState {
  message: string;
  onCancelClick: () => void;
  onOkClick: () => void;
  options: ConfirmOptions;
  show: boolean;
  version: number;
}

interface ConfirmProviderProps {
  children: ReactNode;
}

function ConfirmProvider(props: ConfirmProviderProps) {
  const {children} = props;
  const [state, setState] = useState<ConfirmProviderState>(
    createConfirmProviderState(0)
  );
  const {message, onCancelClick, onOkClick, options, show, version} = state;

  /**
   * The `confirm` function is the provider value. It will get regenerated
   * every time the confirm dialog is resolved (resolving the promise will
   * generate a new `version` that will regenerate the function).
   */
  const confirm: ConfirmFunction = useCallback(
    (message: string, options: ConfirmOptions = {}) => {
      return new Promise<boolean>((resolve) => {
        setState({
          message,
          options,
          onCancelClick: () => {
            resolve(false);
            setState(createConfirmProviderState(version + 1));
          },
          onOkClick: () => {
            resolve(true);
            setState(createConfirmProviderState(version + 1));
          },
          show: true,
          version,
        });
      });
    },
    [version]
  );

  return (
    <ConfirmContext.Provider value={confirm}>
      {children}
      <ConfirmDialog
        {...options}
        message={message}
        onCancelClick={onCancelClick}
        onOkClick={onOkClick}
        show={show}
      />
    </ConfirmContext.Provider>
  );
}

function createConfirmProviderState(version: number): ConfirmProviderState {
  return {
    message: "",
    onCancelClick: () => {},
    onOkClick: () => {},
    options: {},
    show: false,
    version,
  };
}
