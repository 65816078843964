import {useQuery} from "react-query";
import useAppointmentQueryCreators, {
  GetAppointmentQueryOptions,
  GetAppointmentQueryVariables,
} from "./use-appointment-query-creators";

export default useGetAppointmentQuery;

function useGetAppointmentQuery(
  variables: GetAppointmentQueryVariables,
  options?: GetAppointmentQueryOptions
) {
  const {createGetAppointmentQuery} = useAppointmentQueryCreators();

  return useQuery(createGetAppointmentQuery(variables, options));
}
