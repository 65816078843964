import {useMutation, useQueryClient} from "react-query";
import {createPatch} from "../../../../utils/json-patch";
import {
  Appointment,
  AppointmentStatus,
} from "../../../time-book-scheduling-api";
import {createSlotDetailsKey} from "../../slots";
import {createAppointmentsListKey} from "../query-key-creators";
import usePatchAppointmentMutation from "./use-patch-appointment-mutation";

export default useSetAppointmentStatusMutation;

interface SetAppointmentStatusMutationProps {
  appointment: Appointment;
}

interface SetAppointmentStatusMutationVariables {
  status: AppointmentStatus;
}

function useSetAppointmentStatusMutation(
  props: SetAppointmentStatusMutationProps
) {
  const queryClient = useQueryClient();

  const appointmentId = selectIdFromAppointment(props.appointment);
  const appointmentStatus = selectStatusFromAppointment(props.appointment);
  const appointmentVersion = selectVersionFromAppointment(props.appointment);
  const clinicId = selectClinicIdFromAppointment(props.appointment);

  const patchAppointmentMutation = usePatchAppointmentMutation({
    appointmentId,
    appointmentVersion,
    clinicId,
  });

  return useMutation<Appointment, Error, SetAppointmentStatusMutationVariables>(
    (variables) => {
      const {status} = variables;

      const input = createPatch({status: appointmentStatus}, {status});

      return patchAppointmentMutation.mutateAsync({
        input,
      });
    },
    {
      onSuccess(patchedAppointment) {
        const {id: slotId} = patchedAppointment.slot;

        queryClient.setQueriesData<Appointment[]>(
          createAppointmentsListKey(clinicId),
          (cachedAppointments) => {
            if (Array.isArray(cachedAppointments)) {
              return cachedAppointments.map((cachedAppointment) => {
                return cachedAppointment.id === patchedAppointment.id
                  ? patchedAppointment
                  : cachedAppointment;
              });
            }

            return [];
          }
        );

        void queryClient.invalidateQueries(createAppointmentsListKey(clinicId));
        void queryClient.invalidateQueries(
          createSlotDetailsKey(clinicId, slotId)
        );
      },
    }
  );
}

function selectClinicIdFromAppointment(appointment: Appointment) {
  return appointment.clinic.id;
}

function selectIdFromAppointment(appointment: Appointment) {
  return appointment.id;
}

function selectStatusFromAppointment(appointment: Appointment) {
  return appointment.status;
}

function selectVersionFromAppointment(appointment: Appointment) {
  return appointment.version;
}
