import React, {Ref} from "react";
import {
  Button as OriginalButton,
  ButtonProps as OriginalButtonProps,
} from "react-bootstrap";
import {classNames} from "../../../utils";
import {LoadingIcon} from "../../icon";
import styles from "./button.module.scss";

type ButtonProps = OriginalButtonProps & {
  isLoading?: boolean;
  ref?: Ref<HTMLButtonElement>;
};

export const Button = (props: ButtonProps) => {
  const {
    children,
    className,
    disabled,
    isLoading = false,
    ...otherProps
  } = props;
  const classes = classNames(styles.root, className, {
    [styles.isLoading]: isLoading,
  });

  return (
    <OriginalButton
      {...otherProps}
      className={classes}
      disabled={disabled || isLoading}
    >
      {children}
      {/* Always render the loading icon to be able to animate */}
      <LoadingIcon className={styles.spinner} spin />
    </OriginalButton>
  );
};
