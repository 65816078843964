const EXTERNAL = "EXTERNAL";
const INTERNAL = "INTERNAL";

export const AppointmentOrigin = {
  EXTERNAL,
  INTERNAL,
} as const;

/* eslint-disable-next-line @typescript-eslint/no-redeclare -- We know what we are doing here */
export type AppointmentOrigin =
  typeof AppointmentOrigin[keyof typeof AppointmentOrigin];
