import {isNonEmptyString} from "../../../utils";

const BOOKED = "BOOKED";
const CANCELLED = "CANCELLED";
const FULFILLED = "FULFILLED";
const NO_SHOW = "NOSHOW";

export const AppointmentStatus = {
  BOOKED,
  CANCELLED,
  FULFILLED,
  NO_SHOW,
} as const;

/* eslint-disable-next-line @typescript-eslint/no-redeclare -- We know what we are doing here */
export type AppointmentStatus =
  typeof AppointmentStatus[keyof typeof AppointmentStatus];

export function isAppointmentStatus(
  value: unknown
): value is AppointmentStatus {
  return (
    isNonEmptyString(value) &&
    Object.values(AppointmentStatus).some(
      (appointmentStatus) => appointmentStatus === value
    )
  );
}
