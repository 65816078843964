import {useCallback, useMemo} from "react";
import {useQueryClient, UseQueryOptions} from "react-query";
import {Clinic, useApiClient} from "../../../time-book-scheduling-api";
import {
  createClinicDetailsKey,
  createClinicsListKey,
} from "../query-key-creators";

export default useClinicQueryCreators;

export interface GetClinicsQueryVariables {}

export type GetClinicsQueryOptions = UseQueryOptions<Clinic[], Error>;

export interface GetClinicQueryVariables {
  clinicId: string;
}

export type GetClinicQueryOptions = UseQueryOptions<Clinic, Error>;

export function useClinicQueryCreators() {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const createGetClinicQuery = useCallback(
    (
      variables: GetClinicQueryVariables,
      options: GetClinicQueryOptions = {}
    ) => {
      const {clinicId} = variables;

      return {
        initialData(): Clinic | undefined {
          return queryClient
            .getQueryData<Clinic[]>(createClinicsListKey())
            ?.find((clinic) => clinic.id === clinicId);
        },
        ...options,
        queryKey: createClinicDetailsKey(clinicId),
        queryFn() {
          return apiClient
            .getClinic({clinicId})
            .then((clinicJSON) => Clinic.fromJSON(clinicJSON));
        },
      };
    },
    [apiClient, queryClient]
  );

  const createGetClinicsQuery = useCallback(
    (
      variables?: GetClinicsQueryVariables,
      options: GetClinicsQueryOptions = {}
    ) => {
      return {
        ...options,
        queryKey: createClinicsListKey(),
        queryFn() {
          return apiClient
            .getClinics()
            .then(({data}) => data.map((clinic) => Clinic.fromJSON(clinic)));
        },
      };
    },
    [apiClient]
  );

  return useMemo(
    () => ({
      createGetClinicQuery,
      createGetClinicsQuery,
    }),
    [createGetClinicQuery, createGetClinicsQuery]
  );
}
