import {Filter as ApiSearchFilter} from "../search-query";

const ROOT = "slots";

export const createSlotsAllKey = () => [ROOT];

export const createSlotDetailsKey = (clinicId: string, slotId: string) => [
  ROOT,
  "detail",
  {clinicId, slotId},
];

export const createCountSlotsKey = (
  clinicId: string,
  filter: ApiSearchFilter | null
) => [ROOT, "count", {clinicId, filter}];

export const createSlotsListKey = (clinicId: string) => [
  ROOT,
  "list",
  {clinicId},
];

export const createSlotsSearchKey = (
  clinicId: string,
  filter: ApiSearchFilter | null
) => [...createSlotsListKey(clinicId), {filter}];
