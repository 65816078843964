import {UseQueryResult} from "react-query";
import getCompoundError from "./get-compound-error";
import getCompoundStatus from "./get-compound-status";
import getDerivedBooleans from "./get-derived-booleans";

export default createCompoundQueryResult;

type CompoundQueryResult<TData> = Pick<
  UseQueryResult,
  | "dataUpdatedAt"
  | "error"
  | "errorUpdatedAt"
  | "isError"
  | "isFetched"
  | "isFetching"
  | "isIdle"
  | "isLoading"
  | "isLoadingError"
  | "isRefetchError"
  | "isRefetching"
  | "isSuccess"
  | "status"
> & {
  data: TData;
};

function createCompoundQueryResult<TData extends any[]>(
  queryResults: readonly [...{[I in keyof TData]: UseQueryResult<TData[I]>}]
): CompoundQueryResult<TData> {
  const status = getCompoundStatus(queryResults);

  const {isError, isIdle, isLoading, isSuccess} = getDerivedBooleans(status);
  const isFetched = queryResults.every((queryResult) => queryResult.isFetched);
  const isFetching = queryResults.some((queryResult) => queryResult.isFetching);
  const isLoadingError = queryResults.some(
    (queryResult) => queryResult.isLoadingError
  );
  const isRefetching = queryResults.some(
    (queryResult) => queryResult.isRefetching
  );
  const isRefetchError = queryResults.some(
    (queryResult) => queryResult.isRefetchError
  );

  const data = queryResults.map((queryResult) => queryResult.data) as TData;
  const dataUpdatedAt = getDataUpdatedAt(queryResults);

  const error = isError ? getCompoundError(queryResults) : null;
  const errorUpdatedAt = getErrorUpdatedAt(queryResults);

  return {
    data,
    dataUpdatedAt,
    error,
    errorUpdatedAt,
    isError,
    isFetched,
    isFetching,
    isIdle,
    isLoading,
    isLoadingError,
    isRefetching,
    isRefetchError,
    isSuccess,
    status,
  };
}

function getDataUpdatedAt(queryResults: readonly UseQueryResult[]): number {
  return getMostRecentUpdatedAt(
    queryResults.map((queryResult) => queryResult.dataUpdatedAt)
  );
}

function getErrorUpdatedAt(queryResults: readonly UseQueryResult[]): number {
  return getMostRecentUpdatedAt(
    queryResults.map((queryResult) => queryResult.errorUpdatedAt)
  );
}

function getMostRecentUpdatedAt(timestamps: number[]): number {
  return Math.max(...timestamps);
}
