import React, {ReactNode} from "react";
import classnames from "classnames";
import {Alert, AlertHeading} from "../bootstrap";
import styles from "./loading-error.module.scss";

type LoadingErrorProps = {
  children?: ReactNode;
  className?: string;
  error: Error | null | undefined;
  title?: string;
};

export const LoadingError = (props: LoadingErrorProps) => {
  const {children, className, error, title} = props;
  const classNames = classnames(styles.root, className);

  if (!error) {
    return null;
  }

  return (
    <Alert className={classNames} variant="danger">
      {title && <AlertHeading>{title}</AlertHeading>}
      <div>{error.message}</div>
      {children ? children : null}
    </Alert>
  );
};
