import React, {useCallback, useState} from "react";
import {Redirect} from "react-router-dom";
import {useClinicId} from "../../hooks";
import {getViewResidentRoute} from "../../services/routing";
import {isNonEmptyString} from "../../utils";
import {SearchResident, SearchResidentResult} from "./search-resident";
import {SearchResidentNoList} from "./search-resident-no-list";

type SearchResidentWithRedirectionProps = {
  autoFocus?: boolean;
};

export const SearchResidentWithRedirection = (
  props: SearchResidentWithRedirectionProps
) => {
  const {autoFocus} = props;
  const [clinicId] = useClinicId();
  const [residentId, setResidentId] = useState<string>();
  const onSuccess = useCallback(
    (result: SearchResidentResult) => setResidentId(result?.id),
    []
  );

  if (!clinicId) {
    return null;
  }
  if (residentId) {
    return (
      <SearchResidentNoList
        autoFocus={autoFocus}
        clinicId={clinicId}
        onSuccess={onSuccess}
      >
        {isNonEmptyString(residentId) && (
          <Redirect to={getViewResidentRoute({clinicId, residentId})} />
        )}
      </SearchResidentNoList>
    );
  } else {
    return (
      <SearchResident
        autoFocus={autoFocus}
        clinicId={clinicId}
        onSuccess={onSuccess}
      >
        {isNonEmptyString(residentId) && (
          <Redirect to={getViewResidentRoute({clinicId, residentId})} />
        )}
      </SearchResident>
    );
  }
};
