import {useLocale} from "../../services/i18n";

type WeekdayStringProps = {
  className?: string;
  date: Date | string;
};

export const WeekdayString = (props: WeekdayStringProps) => {
  const {className, date} = props;
  const {currentLocale} = useLocale();
  const weekday = new Date(date).toLocaleDateString(currentLocale.tag, {
    weekday: "long",
  });

  return <span className={className}>{weekday}</span>;
};
