import {HeadersFactoryOrObject} from "../http/types";

const mergeHeaders = (
  defaults: HeadersFactoryOrObject = {},
  overrides: HeadersFactoryOrObject = {}
) => {
  const defaultsObject = typeof defaults === "function" ? defaults() : defaults;
  const overridesObject =
    typeof overrides === "function" ? overrides(defaultsObject) : overrides;

  return Object.assign({}, defaultsObject, overridesObject);
};

export default mergeHeaders;
