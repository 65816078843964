import {useMutation, useQueryClient} from "react-query";
import {
  ApiError,
  SlotType,
  UpdateSlotTypeInput,
} from "../../../time-book-scheduling-api";
import useApi from "../../use-api";
import {
  createSlotTypesListKey,
  createSlotTypeDetailsKey,
} from "../query-key-creators";

export default useUpdateSlotTypeMutation;

interface UpdateSlotTypeMutationProps {
  clinicId: string;
  slotTypeId: string;
}

interface UpdateSlotTypeMutationVariables {
  input: UpdateSlotTypeInput;
}

function useUpdateSlotTypeMutation(props: UpdateSlotTypeMutationProps) {
  const api = useApi();
  const queryClient = useQueryClient();

  const {clinicId, slotTypeId} = props;

  return useMutation<SlotType, ApiError, UpdateSlotTypeMutationVariables>(
    (variables) => {
      const {input} = variables;

      return api
        .updateSlotType({clinicId, slotTypeId}, input)
        .then((slotTypeData) => SlotType.fromJSON(slotTypeData));
    },
    {
      onSuccess(/*slotType*/) {
        void queryClient.invalidateQueries(createSlotTypesListKey(clinicId));
        void queryClient.invalidateQueries(
          createSlotTypeDetailsKey(clinicId, slotTypeId)
        );
      },
    }
  );
}
