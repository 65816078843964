import {useMemo} from "react";
import {TimeDescriptor, setLocalTime} from "../date-time-set-time";
import {toLocalIsoDateString} from "../local-date";

/**
 * This hook returns today's date. Use optional argument to set time of date.
 *
 * It guarantees the same Date instance for each render until a new day, then a
 * new Date instance is returned.
 *
 * Keep in mind that React Hooks are initialized when the component mounts, so
 * each individual component mount will get it's own individual Date instance.
 */
export const useToday = (time: TimeDescriptor = {}) => {
  const dateString = toLocalIsoDateString(new Date());
  const today = useMemo(
    () => setLocalTime(new Date(), time),
    // When the ISO Date string is a different string, a new date is generated
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateString]
  );

  return today;
};
