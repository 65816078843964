import React, {useCallback, useEffect, useMemo} from "react";
import {Button} from "react-bootstrap";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "../../../../services/i18n";
import {printWindow, toLocalIsoDateString} from "../../../../utils";
import DatePaginator from "../../../calendar/date-paginator";
import {Card} from "../../../card";
import {DateText} from "../../../date-time";
import {LoadingIcon, PrintIcon} from "../../../icon";
import {Loading} from "../../../loading";
import {TodayButton} from "../../../today-button";
import {
  useGeneratePath,
  useRouteDate,
  useSelectedSlotIds,
  useSlotId,
} from "../../hooks";
import {useFilterPredicate} from "../../hooks/filter";
import BookingAppAside from "../booking-app-aside";
import BookingAppBatchActions from "../booking-app-batch-actions";
import BookingAppBookingsTable from "../booking-app-bookings-table";
import BookingAppButtonPanel from "../booking-app-filter/booking-app-button-panel";
import BookingAppFilterPanel from "../booking-app-filter/booking-app-filter-panel";
import styles from "./booking-app-main.module.scss";
import useBookingAppMainQuery from "./use-booking-app-main-query";

export default BookingAppMain;

interface BookingAppMainProps {
  clinicId: string;
  date: Date;
}

function BookingAppMain(props: BookingAppMainProps) {
  const {clinicId, date} = props;
  const filter = useFilterPredicate();
  const generatePath = useGeneratePath();
  const history = useHistory();
  const location = useLocation();
  const [routeDate] = useRouteDate();
  const [selectedSlotIds, setSelectedSlotIds] = useSelectedSlotIds();
  const [slotId] = useSlotId();

  const bookingAppMainQuery = useBookingAppMainQuery({
    clinicId,
    date,
  });

  const bookingsTableData = useMemo(() => {
    return bookingAppMainQuery.data?.filter((booking) => filter(booking)) ?? [];
  }, [filter, bookingAppMainQuery.data]);

  const navigateToDate = useCallback(
    (date: Date) => {
      const pathname = generatePath("/:date/:slotId?", {
        date: toLocalIsoDateString(date),
        slotId,
      });

      history.push({
        pathname,
        search: location.search,
      });
    },
    [generatePath, history, location.search, slotId]
  );

  const onDateChange = useCallback(
    (nextDate: Date) => {
      navigateToDate(nextDate);
    },
    [navigateToDate]
  );

  const onTodayButtonClick = useCallback(() => {
    const nextDate = new Date();
    navigateToDate(nextDate);
  }, [navigateToDate]);

  useEffect(() => {
    /*
     * Reset selected slots when:
     * - the date changes
     * - filter is changed
     */
    setSelectedSlotIds([]);
  }, [filter, routeDate, setSelectedSlotIds]);

  const printButtonTitleText = useTranslation("calendar-filters-print");
  const onPrintButtonClick = useCallback(() => {
    printWindow();
  }, []);

  return (
    <div className={styles.root}>
      <main className={styles.main}>
        <Card className={styles.card}>
          <div className={styles.document}>
            <div className={styles.header}>
              <div className={styles.headingAndControls}>
                <h5 className={styles.heading}>
                  <DateText date={date} dateStyle="full" />{" "}
                  {bookingAppMainQuery.isFetching &&
                  !bookingAppMainQuery.isLoading ? (
                    <LoadingIcon spin />
                  ) : null}
                </h5>
                <div className={styles.dateControls}>
                  <TodayButton
                    className={styles.todayButton}
                    date={date}
                    data-testid="calendar-navigation-today-btn"
                    setDate={onTodayButtonClick}
                  />
                  <DatePaginator date={date} onDateChange={onDateChange} />
                </div>
              </div>
              <div className={styles.filtersAndControls}>
                <div className={styles.buttons}>
                  <BookingAppButtonPanel />
                </div>
                <div className={styles.filters}>
                  <BookingAppFilterPanel
                    data={bookingAppMainQuery.data}
                    loading={bookingAppMainQuery.isFetching}
                  />

                  <div className={styles.printButtonContainer}>
                    <Button
                      data-testid="calendar-event-filters-print-icon"
                      onClick={onPrintButtonClick}
                      title={printButtonTitleText}
                      variant="light"
                    >
                      <PrintIcon />
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.body}>
              {bookingAppMainQuery.isLoading ? (
                <div className={styles.spinner}>
                  <Loading />
                </div>
              ) : null}
              {bookingAppMainQuery.isSuccess ? (
                <BookingAppBookingsTable data={bookingsTableData} />
              ) : null}
            </div>
            {selectedSlotIds.length > 0 ? (
              <div className={styles.footer}>
                <BookingAppBatchActions />
              </div>
            ) : null}
          </div>
        </Card>
      </main>

      <aside className={styles.aside}>
        <Card className={styles.card}>
          <BookingAppAside />
        </Card>
      </aside>
    </div>
  );
}
