import React, {ReactNode} from "react";
import {
  ColorName,
  COLOR_GRASS,
  COLOR_PLUM,
  COLOR_SKY,
  COLOR_STONE,
  COLOR_SUN,
} from "../../styles/color-name";
import {classNames} from "../../utils";
import styles from "./tag.module.scss";

type TagProps = {
  children: ReactNode;
  className?: string;
  variant?: ColorName;
};

export const Tag = (props: TagProps) => {
  const {children, className, variant = COLOR_STONE} = props;

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.grass]: variant === COLOR_GRASS,
        [styles.plum]: variant === COLOR_PLUM,
        [styles.sky]: variant === COLOR_SKY,
        [styles.stone]: variant === COLOR_STONE,
        [styles.sun]: variant === COLOR_SUN,
      })}
    >
      {children}
    </div>
  );
};
