import {classNames, isNonEmptyString, splitAtIndices} from "../../utils";
import styles from "./personal-identification-number.module.scss";

export {PersonalIdentificationNumber};

interface PersonalIdentificationNumberProps {
  className?: string;
  value?: string;
}

/**
 * The reason for this component is so we can display PINs in a bit more
 * readable form with some added margin between segments (year, month, day &
 * digits).
 */
function PersonalIdentificationNumber(
  props: PersonalIdentificationNumberProps
) {
  const {className, value} = props;
  const classes = classNames(styles.root, className);
  const values = splitPersonalIdentificationNumber(value);

  if (!Array.isArray(values)) {
    return <span className={classes}>{value}</span>;
  }
  const [year, month, day, nnnn] = values;

  return (
    <span className={classes}>
      <span className={styles.yyyy}>{year}</span>
      <span className={styles.mm}>{month}</span>
      <span className={styles.dd}>{day}</span>
      <span className={styles.nnnn}>{nnnn}</span>
    </span>
  );
}

function splitPersonalIdentificationNumber(value?: string) {
  if (isNonEmptyString(value)) {
    // 'YYYYMMDDNNNN' -> ['YYYY', 'MM', 'DD', 'NNNN']
    if (value.length === 12) {
      return splitAtIndices(value, 4, 6, 8);
    }
    // 'YYMMDDNNNN' -> ['YY', 'MM', 'DD', 'NNNN']
    if (value.length === 10) {
      return splitAtIndices(value, 2, 4, 6);
    }
  }
  return null;
}
