import {generatePath} from "react-router-dom";
import {isNonEmptyString, joinPaths} from "../../utils";
import {isIsoDateString} from "../../utils/date-time";

const BASE_URL = "";

// Actions
const EDIT = "edit";
const NEW = "new";
const SEARCH = "search";

// Root
export const getRootRoute = () => joinPaths(BASE_URL, "/");

// Appointments
const APPOINTMENTS = "appointments";

export const getListAppointmentsRoute = () => joinPaths(BASE_URL, APPOINTMENTS);

// Bookings
export const getClinicsRoute = () => joinPaths(BASE_URL, "/clinics");

export const getClinicRoute = ({clinicId = ":clinicId"} = {}) =>
  joinPaths(BASE_URL, `/clinics/${clinicId}`);

export const getBookingsRoute = ({clinicId = ":clinicId"} = {}) =>
  joinPaths(getClinicRoute({clinicId}), `/booking`);

export const getBookingsDateRoute = ({
  clinicId = ":clinicId",
  date = ":date",
} = {}) => joinPaths(getBookingsRoute({clinicId}), `/${date}`);

export const getBookingListDetailsRoute = ({
  clinicId = ":clinicId",
  date = ":date",
  slotId = ":slotId",
} = {}) => joinPaths(getBookingsDateRoute({clinicId, date}), `/${slotId}`);

export const getBookingListDetailsRescheduleRoute = ({
  clinicId = ":clinicId",
  date = ":date",
  slotId = ":slotId",
} = {}) =>
  joinPaths(
    getBookingListDetailsRoute({clinicId, date, slotId}),
    `/reschedule`
  );

// Residents
const RESIDENTS = "residents";

export const getListResidentsRoute = () => joinPaths(BASE_URL, RESIDENTS);
export const getNewResidentRoute = () => joinPaths(BASE_URL, RESIDENTS, NEW);
export const getViewResidentRoute = ({
  clinicId = ":clinicId",
  residentId = ":residentId",
}) => joinPaths(getClinicRoute({clinicId}), RESIDENTS, residentId);
export const getEditResidentRoute = ({
  clinicId = ":clinicId",
  residentId = ":residentId",
}) => joinPaths(getClinicRoute({clinicId}), RESIDENTS, residentId, EDIT);
export const getSearchResidentsRoute = () =>
  joinPaths(BASE_URL, RESIDENTS, SEARCH);

// Slot Types
const SLOT_TYPES = "slot-types";

export const getListSlotTypesRoute = ({clinicId = ":clinicId"} = {}) =>
  joinPaths(getClinicRoute({clinicId}), SLOT_TYPES);
export const getNewSlotTypeRoute = ({clinicId = ":clinicId"} = {}) =>
  joinPaths(getClinicRoute({clinicId}), SLOT_TYPES, NEW);
export const getViewSlotTypeRoute = ({
  clinicId = ":clinicId",
  slotTypeId = ":slotTypeId",
}) => joinPaths(getClinicRoute({clinicId}), SLOT_TYPES, slotTypeId);
export const getEditSlotTypeRoute = ({
  clinicId = ":clinicId",
  slotTypeId = ":slotTypeId",
}) => joinPaths(getClinicRoute({clinicId}), SLOT_TYPES, slotTypeId, EDIT);

// Slots
const SLOTS = "slots";

export const getListSlotsRoute = () => joinPaths(BASE_URL, SLOTS);

// Scheduling
const SCHEDULING = "scheduling";

export const getSchedulingRoute = ({clinicId = ":clinicId"} = {}) =>
  joinPaths(getClinicRoute({clinicId}), SCHEDULING);

// Admin
const ADMIN = "admin";

export const getAdminRoute = () => joinPaths(BASE_URL, ADMIN);

// Admin Clinics
const CLINICS = "clinics";

export {
  generatePathToBooking,
  generatePathToClinic,
  generatePathToClinicMutation,
  generatePathToScheduling,
  generatePathToSlotTypes,
  generatePathToSlotType,
};

export const RoutePath = {
  BOOKING: `/${CLINICS}/:clinicId/booking`,
  CLINICS: `/${CLINICS}`,
  CLINIC: `/${CLINICS}/:clinicId`,
  RESIDENTS: `/${CLINICS}/:clinicId/residents`,
  SCHEDULING: `/${CLINICS}/:clinicId/scheduling`,
} as const;

export interface BookingRouteParams extends Record<string, string | undefined> {
  clinicId: string;
  date?: string;
  slotId?: string;
}

function generatePathToBooking(params: BookingRouteParams) {
  const {clinicId, date, slotId} = params;

  const path = `${RoutePath.BOOKING}/:date?/:slotId?`;
  const routeParams: BookingRouteParams = {
    clinicId,
  };

  if (isIsoDateString(date)) {
    routeParams.date = date;

    if (isNonEmptyString(slotId)) {
      routeParams.slotId = slotId;
    }
  }
  return generatePath(path, routeParams);
}

export const ClinicView = {
  BUSINESS_HOURS: "business-hours",
  RESOURCES: "resources",
  SLOT_TYPES: "slot-types",
} as const;
/* eslint-disable-next-line @typescript-eslint/no-redeclare -- We know what we are doing here */
type ClinicView = typeof ClinicView[keyof typeof ClinicView];

export const RouteOperation = {
  EDIT: "_edit",
  NEW: "_new",
} as const;
/* eslint-disable-next-line @typescript-eslint/no-redeclare -- We know what we are doing here */
export type RouteOperation = typeof RouteOperation[keyof typeof RouteOperation];

export interface ClinicRouteParams {
  clinicId: string;
  view?: ClinicView;
}

function generatePathToClinic(params: ClinicRouteParams) {
  const {clinicId, view} = params;

  return generatePath(
    `${RoutePath.CLINIC}/:view(business-hours|resources|slot-types)?`,
    {
      clinicId,
      ...(isNonEmptyString(view) ? {view} : null),
    }
  );
}

interface ClinicMutationRouteParams {
  clinicId: string;
  operation: RouteOperation;
}

function generatePathToClinicMutation(params: ClinicMutationRouteParams) {
  const {clinicId, operation} = params;

  return generatePath(`${RoutePath.CLINIC}/:operation`, {
    clinicId,
    operation,
  });
}

interface ResidentsRouteParams {
  clinicId: string;
}

export const generatePathToResidents = (params: ResidentsRouteParams) => {
  const {clinicId} = params;

  return generatePath(`${RoutePath.RESIDENTS}`, {clinicId});
};

interface SchedulingRouteParams {
  clinicId: string;
}

function generatePathToScheduling(params: SchedulingRouteParams) {
  const {clinicId} = params;

  return generatePath(`${RoutePath.SCHEDULING}`, {clinicId});
}

export interface SlotTypesRouteParams {
  clinicId: string;
  operation?: RouteOperation;
}

function generatePathToSlotTypes(params: SlotTypesRouteParams) {
  const {clinicId, operation} = params;

  return generatePath(`${RoutePath.CLINIC}/slot-types/:operation(_new)?`, {
    clinicId,
    ...(operation != null ? {operation} : null),
  });
}

export interface SlotTypeRouteParams {
  clinicId: string;
  slotTypeId: string;
  operation?: RouteOperation;
}

function generatePathToSlotType(params: SlotTypeRouteParams) {
  const {clinicId, slotTypeId, operation} = params;

  return generatePath(
    `${RoutePath.CLINIC}/slot-types/:slotTypeId/:operation(_edit)?`,
    {
      clinicId,
      slotTypeId,
      ...(operation != null ? {operation} : null),
    }
  );
}
