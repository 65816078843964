import {MouseEvent, useCallback, useState} from "react";
import classNames from "classnames";
import {
  useGetAppointmentsQuery,
  usePatchAppointmentsMutation,
} from "../../../../services/api/appointments";
import {
  useGetSlotsQuery,
  usePatchSlotsMutation,
} from "../../../../services/api/slots";
import {Translation} from "../../../../services/i18n";
import {
  AppointmentStatus,
  Slot,
  SlotStatus,
} from "../../../../services/time-book-scheduling-api";
import {isNonEmptyArray} from "../../../../utils";
import {createReplaceOperation} from "../../../../utils/json-patch";
import ActionButton, {ActionButtonProps} from "../../../form/action-button";
import {useClinicId, useSelectedSlotIds} from "../../hooks";
import styles from "./booking-app-batch-actions.module.scss";

export default BookingAppBatchActions;

function BookingAppBatchActions() {
  const [clinicId] = useClinicId();
  const [selectedSlotIds, setSelectedSlotIds] = useSelectedSlotIds();

  const getSlotsQuery = useGetSlotsQuery(
    {
      clinicId,
      slotIds: selectedSlotIds,
    },
    {
      keepPreviousData: true,
    }
  );

  const appointmentIds = selectAppointmentIdsFromSlots(getSlotsQuery.data);
  const getAppointmentsQuery = useGetAppointmentsQuery(
    {
      clinicId,
      appointmentIds,
    },
    {
      enabled: getSlotsQuery.isSuccess && isNonEmptyArray(appointmentIds),
    }
  );

  const patchSlotsMutation = usePatchSlotsMutation({
    slots: selectSlotsWithNoAppointment(getSlotsQuery.data),
  });

  const patchAppointmentsMutation = usePatchAppointmentsMutation({
    appointments: getAppointmentsQuery.data,
  });

  const [nextSlotStatus, setNextSlotStatus] = useState<
    SlotStatus | undefined
  >();
  const [nextAppointmentStatus, setNextAppointmentStatus] = useState<
    AppointmentStatus | undefined
  >();

  const onSlotsPatchButtonClick = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      if (patchSlotsMutation.isLoading) {
        return;
      }

      const nextSlotStatus = event.currentTarget.value as SlotStatus;

      setNextSlotStatus(nextSlotStatus);

      const input = [createReplaceOperation("status", nextSlotStatus)];
      await patchSlotsMutation.mutateAsync({input});

      setNextSlotStatus(undefined);
    },
    [patchSlotsMutation]
  );

  const onAppointmentsPatchButtonClick = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      if (patchAppointmentsMutation.isLoading) {
        return;
      }

      const nextAppointmentStatus = event.currentTarget
        .value as AppointmentStatus;

      setNextAppointmentStatus(nextAppointmentStatus);

      const input = [createReplaceOperation("status", nextAppointmentStatus)];
      await patchAppointmentsMutation.mutateAsync({input});

      setNextAppointmentStatus(undefined);
    },
    [patchAppointmentsMutation]
  );

  const isFetching = () =>
    getSlotsQuery.isFetching || getAppointmentsQuery.isFetching;
  const isMutating = () =>
    patchSlotsMutation.isLoading || patchAppointmentsMutation.isLoading;
  const isFetchingOrMutating = isFetching() || isMutating();

  const onCloseButtonClick = useCallback(() => {
    if (isFetchingOrMutating) {
      return;
    }

    setSelectedSlotIds([]);
  }, [isFetchingOrMutating, setSelectedSlotIds]);

  const getSlotsActionButtonProps = (slotStatus: SlotStatus) => {
    const props: ActionButtonProps = {};

    if (isFetchingOrMutating && nextSlotStatus === slotStatus) {
      props.loading = true;
    } else if (isFetchingOrMutating) {
      props.disabled = true;
    }

    return props;
  };

  const getAppointmentsActionButtonProps = (
    appointmentsStatus: AppointmentStatus
  ) => {
    const props: ActionButtonProps = {};

    if (isFetchingOrMutating && nextAppointmentStatus === appointmentsStatus) {
      props.loading = true;
    } else if (isFetchingOrMutating) {
      props.disabled = true;
    }

    return props;
  };

  const rootClassNames = classNames(styles.root, {
    [styles.isFetching]:
      getSlotsQuery.isFetching || getAppointmentsQuery.isFetching,
  });

  return (
    <div className={rootClassNames}>
      <div>
        <h6 data-testid="booking-app-batch-actions-info-text-available-appointments">
          <Translation
            tKey="batch-actions-slots-count"
            tValues={{
              count: count(selectSlotsWithNoAppointment(getSlotsQuery.data)),
            }}
          />
        </h6>
        <div
          className={styles.buttons}
          data-testid="booking-app-batch-actions-block-buttons"
        >
          <ActionButton
            {...getSlotsActionButtonProps(SlotStatus.BLOCKED)}
            onClick={onSlotsPatchButtonClick}
            value={SlotStatus.BLOCKED}
          >
            <Translation tKey="batch-actions-block" />
          </ActionButton>
          <ActionButton
            {...getSlotsActionButtonProps(SlotStatus.CREATED)}
            onClick={onSlotsPatchButtonClick}
            value={SlotStatus.CREATED}
          >
            <Translation tKey="batch-actions-unblock" />
          </ActionButton>
        </div>
      </div>

      <div>
        <h6 data-testid="booking-app-batch-actions-info-text-booked-appointments">
          <Translation
            tKey="batch-actions-appointments-count"
            tValues={{
              count: count(selectSlotsWithAppointment(getSlotsQuery.data)),
            }}
          />
        </h6>
        <div
          className={styles.buttons}
          data-testid="booking-app-batch-actions-status-buttons"
        >
          <ActionButton
            {...getAppointmentsActionButtonProps(AppointmentStatus.BOOKED)}
            onClick={onAppointmentsPatchButtonClick}
            value={AppointmentStatus.BOOKED}
          >
            <Translation tKey="batch-actions-set-booked" />
          </ActionButton>
          <ActionButton
            {...getAppointmentsActionButtonProps(AppointmentStatus.FULFILLED)}
            onClick={onAppointmentsPatchButtonClick}
            value={AppointmentStatus.FULFILLED}
          >
            <Translation tKey="batch-actions-set-fulfilled" />
          </ActionButton>
          <ActionButton
            {...getAppointmentsActionButtonProps(AppointmentStatus.NO_SHOW)}
            onClick={onAppointmentsPatchButtonClick}
            value={AppointmentStatus.NO_SHOW}
          >
            <Translation tKey="batch-actions-set-no-show" />
          </ActionButton>

          <ActionButton
            className={styles.closeButton}
            disabled={isFetchingOrMutating}
            onClick={onCloseButtonClick}
          >
            <Translation tKey="batch-actions-close" />
          </ActionButton>
        </div>
      </div>
    </div>
  );
}

function count(items: unknown[] = []) {
  return items.length;
}

function selectAppointmentIdsFromSlots(slots: Slot[] = []) {
  return selectSlotsWithAppointment(slots).map((slot) =>
    selectAppointmentIdFromSlot(slot)
  );
}

function selectAppointmentIdFromSlot(slot: Slot) {
  return slot.appointment.id;
}

function selectSlotsWithAppointment(slots: Slot[] = []) {
  return slots.filter((slot) => slot.appointment != null);
}

function selectSlotsWithNoAppointment(slots: Slot[] = []) {
  return slots.filter((slot) => slot.appointment == null);
}
