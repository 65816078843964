const BLOCKED = "BLOCKED";
const CREATED = "CREATED";

export const SlotStatus = {
  BLOCKED,
  CREATED,
} as const;

/* eslint-disable-next-line @typescript-eslint/no-redeclare -- We know what we are doing here */
export type SlotStatus = typeof SlotStatus[keyof typeof SlotStatus];
