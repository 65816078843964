import type {FormDataEntry, FormDataEntryName} from "./types";

export {createFormDataObject, getFormDataEntryValue};

function createFormDataObject(formDataEntries: FormDataEntry[]) {
  const formData = new FormData();

  for (const formDataEntry of formDataEntries) {
    const [name, value] = formDataEntry;

    formData.append(name, value);
  }

  return formData;
}

function getFormDataEntryValue<T extends FormDataEntryValue = string>(
  formData: FormData,
  formDataEntryName: FormDataEntryName
) {
  return formData.get(formDataEntryName) as T;
}
