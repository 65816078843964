import {useQuery} from "react-query";
import {Resident} from "../../../time-book-scheduling-api";
import useResidentQueryCreators, {
  SearchResidentsQueryOptions,
  SearchResidentsQueryVariables,
} from "./use-resident-query-creators";

export default useSearchResidentsQuery;

function useSearchResidentsQuery<TData = Resident[]>(
  variables: SearchResidentsQueryVariables,
  options?: SearchResidentsQueryOptions<TData>
) {
  const {createSearchResidentsQuery} = useResidentQueryCreators();

  return useQuery(createSearchResidentsQuery(variables, options));
}
