import React, {ComponentType, FunctionComponent} from "react";
import {create, act, ReactTestRenderer} from "react-test-renderer";
import {I18nProvider, I18nSource} from "../services/i18n";
import {Locale} from "../services/i18n/locale";
import {enGB} from "../services/i18n/locales";

export type I18nMock = {
  [languageCode: string]: I18nSource;
};

export type I18nMockProviderProps = {
  locale?: Locale;
  mock?: I18nMock;
};

export const I18nMockProvider: FunctionComponent<I18nMockProviderProps> = (
  props
) => {
  const {children, locale = enGB, mock = {en: {}}} = props;
  const resolver = async (locale: Locale): Promise<I18nSource> =>
    mock[locale.languageCode];

  const mockConfig = {
    availableLocales: [locale],
    defaultLocale: locale,
    fallbackLocale: locale,
    resolver,
  };

  return <I18nProvider config={mockConfig}>{children}</I18nProvider>;
};

export const withI18nMockProvider = async (
  locale: Locale,
  data: I18nMock,
  Component: ComponentType
): Promise<ReactTestRenderer | undefined> => {
  let testRenderer: ReactTestRenderer | undefined;

  await act(async () => {
    testRenderer = create(
      <I18nMockProvider locale={locale} mock={data}>
        <Component />
      </I18nMockProvider>
    );
  });
  return testRenderer;
};
