import {useCallback} from "react";
import {
  setRouteDateAction,
  useBookingAppState,
} from "../services/booking-app-state";

export default useRouteDate;

function useRouteDate() {
  const [state, dispatch] = useBookingAppState();

  const setRouteDate = useCallback(
    (routeDate: string) => {
      dispatch(setRouteDateAction(routeDate));
    },
    [dispatch]
  );

  return [state.routeDate, setRouteDate] as const;
}
