export type Action<
  Payload extends unknown = unknown,
  Type extends string = string
> = {
  payload: Payload;
  type: Type;
};

export const createActionCreator = <A extends Action>(type: string) => {
  return (payload: A["payload"]): A => ({type, payload} as A);
};
