import {useMutation, useQueryClient} from "react-query";
import {createAppointmentsAllKey} from "../../services/api/appointments";
import {createSlotsAllKey} from "../../services/api/slots";
import {
  Appointment,
  Slot,
  useApiClient,
} from "../../services/time-book-scheduling-api";
import {AppointmentStatus} from "../../services/time-book-scheduling-api/entities/appointment-status";
import {Patch} from "../../utils/json-patch";

type RescheduleBookingMutationVariables = {
  clinicNotes: string;
  existingAppointment: Appointment;
  nextSlot: Slot;
};

/**
 * This is a compound mutation that will A) copy an existing appointment onto
 * a new slot and then B) delete the appointment from the old slot.
 */
export const useRescheduleBookingMutation = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<Appointment, Error, RescheduleBookingMutationVariables>(
    (variables) => {
      const {clinicNotes, existingAppointment, nextSlot} = variables;
      const {
        clinic,
        id: appointmentId,
        resident,
        version: appointmentVersion,
      } = existingAppointment;

      const {id: clinicId} = clinic;
      const clinicIdentifier = {
        clinicId,
        residentId: resident.id,
      };
      const createAppointmentInput = {
        clinic,
        clinicNotes,
        resident,
        slot: nextSlot,
      };

      return api
        .createResidentAppointment(clinicIdentifier, createAppointmentInput)
        .then(async (nextAppointmentJson) => {
          const identifier = {
            clinicId,
            appointmentId,
            appointmentVersion,
            residentId: resident.id,
          };
          const patchInput: Patch = [
            {
              op: "replace",
              path: "status",
              value: AppointmentStatus.CANCELLED,
            },
          ];

          await api.patchResidentAppointment(identifier, patchInput);

          queryClient.invalidateQueries(createAppointmentsAllKey());
          queryClient.invalidateQueries(createSlotsAllKey());
          return Appointment.fromJSON(nextAppointmentJson);
        });
    }
  );
};
