import React, {ReactNode} from "react";
import {classNames} from "../../utils";
import style from "./card.module.scss";

type CardProps = {
  children: ReactNode;
  className?: string;
};

export const Card = ({className, ...otherProps}: CardProps) => (
  <div {...otherProps} className={classNames(style.root, className)} />
);
