import {
  ComparisonOperator,
  createFilter,
  Filter,
} from "../../../../services/api";
import {useGetBookingsQuery} from "../../../../services/api/bookings";
import {AppointmentStatus} from "../../../../services/time-book-scheduling-api";
import {
  createEndOfDayDate,
  createStartOfDayDate,
} from "../../../../utils/date-time";
import {toMilliseconds} from "../../../../utils/time/duration";

export default useBookingAppMainQuery;

interface BookingAppMainQueryVariables {
  clinicId: string;
  date: Date;
}

function useBookingAppMainQuery(variables: BookingAppMainQueryVariables) {
  const {clinicId, date} = variables;

  const start = createStartOfDayDate(date);
  const end = createEndOfDayDate(date);

  const appointmentsFilter = createDateRangeFilter(
    ["slot.start", start],
    ["slot.end", end]
  );
  appointmentsFilter.conditions.push({
    operator: ComparisonOperator.NOT_EQUAL,
    property: "status",
    value: AppointmentStatus.CANCELLED,
  });
  const slotsFilter = createDateRangeFilter(["start", start], ["end", end]);

  return useGetBookingsQuery(
    {clinicId, appointmentsFilter, slotsFilter},
    {
      refetchInterval: toMilliseconds({minutes: 4}),
      refetchOnWindowFocus: true,
    }
  );
}

function createDateRangeFilter(
  start: [property: string, value: Date],
  end: [property: string, value: Date]
): Filter {
  const [startProperty, startValue] = start;
  const [endProperty, endValue] = end;

  return createFilter([
    {
      operator: ComparisonOperator.GREATER_THAN_OR_EQUAL,
      property: startProperty,
      value: startValue.toISOString(),
    },
    {
      operator: ComparisonOperator.LESS_THAN_OR_EQUAL,
      property: endProperty,
      value: endValue.toISOString(),
    },
  ]);
}
