import {useMutation, useQueryClient} from "react-query";
import {
  ScheduleDefinition,
  CreateScheduleDefinitionInput,
  ApiError,
} from "../../../time-book-scheduling-api";
import useApi from "../../use-api";
import {createScheduleDefinitionsListKey} from "../query-key-creators";

export default useCreateScheduleDefinitionMutation;

interface CreateScheduleDefinitionMutationProps {
  clinicId: string;
}

interface CreateScheduleDefinitionMutationVariables {
  input: CreateScheduleDefinitionInput;
}

function useCreateScheduleDefinitionMutation(
  props: CreateScheduleDefinitionMutationProps
) {
  const api = useApi();
  const queryClient = useQueryClient();

  const {clinicId} = props;

  return useMutation<
    ScheduleDefinition,
    ApiError,
    CreateScheduleDefinitionMutationVariables
  >(
    (variables) => {
      const {input} = variables;

      return api
        .createScheduleDefinition({clinicId}, input)
        .then((data) => ScheduleDefinition.fromJSON(data));
    },
    {
      onSuccess(/*scheduleDefinition*/) {
        void queryClient.invalidateQueries(
          createScheduleDefinitionsListKey(clinicId)
        );
      },
    }
  );
}
