import {useCallback} from "react";
import {useAppState, setClinicIdAction} from "../services/app-state";

export default useClinicId;

function useClinicId() {
  const [appState, dispatch] = useAppState();

  const setClinicId = useCallback(
    (clinicId: string) => {
      dispatch(setClinicIdAction(clinicId));
    },
    [dispatch]
  );

  return [appState.clinicId, setClinicId] as const;
}
