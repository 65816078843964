import {useContext} from "react";
import SessionClientContext from "./session-client-context";

export default useSessionClient;

function useSessionClient() {
  const sessionClient = useContext(SessionClientContext);

  if (sessionClient === null) {
    throw new Error(
      "useSessionClient must be used within a SessionClientProvider"
    );
  }

  return sessionClient;
}
