import React, {Fragment} from "react";
import {toAddressString} from "../services/api/utils";

export {Address};

interface AddressProps {
  inline?: boolean;
  value: string | string[];
}

function Address(props: AddressProps) {
  const {inline = false, value} = props;

  return (
    <Fragment>
      {Array.isArray(value) ? renderAddressArray(value, inline) : value}
    </Fragment>
  );
}

function renderAddressArray(value: string[], inline = false) {
  return inline
    ? toAddressString(value)
    : value.map((line, index) => (
        <Fragment key={line}>
          {index === 0 ? null : <br />}
          {line}
        </Fragment>
      ));
}
