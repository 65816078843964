import {Modal, ModalBody, ModalFooter, ModalTitle} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {useTranslation} from "../../../services/i18n";
import {Button} from "../../bootstrap";

export {ConfirmDialog};

export interface ConfirmDialogProps {
  cancelLabel?: string;
  message: string;
  okLabel?: string;
  onCancelClick: () => void;
  onOkClick: () => void;
  show: boolean;
  title?: string;
}

function ConfirmDialog(props: ConfirmDialogProps) {
  const defaultAbortLabel = useTranslation("call-to-action-abort");
  const defaultContinueLabel = useTranslation("call-to-action-ok");
  const defaultTitle = useTranslation("confirm-dialog-default-title");
  const {
    cancelLabel = defaultAbortLabel,
    message,
    okLabel = defaultContinueLabel,
    onCancelClick,
    onOkClick,
    show = false,
    title = defaultTitle,
  } = props;

  return (
    <Modal
      aria-labelledby="confirm-modal-title"
      backdrop="static"
      keyboard={false}
      show={show}
    >
      <ModalHeader>
        <ModalTitle id="confirm-modal-title">{title}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <p>{message}</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onOkClick} variant="primary">
          {okLabel}
        </Button>
        <Button onClick={onCancelClick} variant="secondary">
          {cancelLabel}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
