interface ClinicInit {
  id?: string;
  hsaId?: string;
  address: string[];
  name: string;
  phone: string;
  externalBookingStartRestriction: string;
  version?: string;
  enableSelectSlotType: boolean;
}

export interface ClinicJSON {
  id: string;
  hsa_id: string;
  address: string[];
  name: string;
  phone: null | string;
  external_booking_start_restriction: string;
  version: null | string;
  enable_select_slot_type: boolean;
}

export default class Clinic {
  id: string;
  hsaId: string;
  address: string[];
  name: string;
  phone: string;
  externalBookingStartRestriction: string;
  version: string;
  enableSelectSlotType: boolean;

  constructor({
    id,
    hsaId,
    name,
    address,
    phone,
    externalBookingStartRestriction,
    version,
    enableSelectSlotType,
  }: ClinicInit) {
    Object.assign(this, {
      id,
      hsaId,
      name,
      address,
      phone,
      externalBookingStartRestriction,
      version,
      enableSelectSlotType,
    });
  }

  static fromJSON(json: ClinicJSON) {
    const {
      id,
      hsa_id: hsaId,
      address,
      name,
      phone,
      external_booking_start_restriction: externalBookingStartRestriction,
      version,
      enable_select_slot_type: enableSelectSlotType,
    } = json;

    return new Clinic({
      id,
      hsaId,
      address,
      name,
      phone: phone ?? "",
      externalBookingStartRestriction,
      version: version ?? "",
      enableSelectSlotType,
    });
  }

  toJSON() {
    const {
      id,
      hsaId: hsa_id,
      name,
      address,
      phone,
      externalBookingStartRestriction: external_booking_start_restriction,
      version,
      enableSelectSlotType: enable_select_slot_type,
    } = this;

    return {
      id,
      hsa_id,
      name,
      address,
      phone,
      external_booking_start_restriction,
      version,
      enable_select_slot_type,
    };
  }
}
