import React, {Fragment, useCallback, useEffect, useState} from "react";
import {Location} from "history";
import {
  Route,
  Switch,
  Redirect,
  generatePath,
  useParams,
  useRouteMatch,
  Prompt,
} from "react-router-dom";
import {useCreateScheduleDefinitionMutation} from "../../services/api/schedule-definition/hooks";
import {useGetSlotTypesQuery} from "../../services/api/slot-types/hooks";
import {useTranslation} from "../../services/i18n";
import {useRouting} from "../../services/routing";
import {
  addDays,
  classNames,
  createArray,
  isFiniteNumber,
  isUndefined,
  isValidDate,
  resetLocalTime,
} from "../../utils";
import {useRefState} from "../../utils/react";
import {Alert} from "../bootstrap";
import {Card} from "../card";
import {Loading} from "../loading";
import {LoadingError} from "../loading-error";
import {
  SchedulingWizardMutationError,
  SchedulingWizardMutationLoading,
  SchedulingWizardMutationSuccess,
  SchedulingWizardNavigation,
  SchedulingWizardStep1,
  SchedulingWizardStep2,
  SchedulingWizardStep3,
} from "./components";
import styles from "./scheduling-wizard.module.scss";
import {
  WizardCalendarEvent,
  clampCalendarEvents,
} from "./wizard-calendar-event";

export default SchedulingWizard;

type SchedulingWizardParams = {
  step: string;
};

type SchedulingWizardProps = {
  clinicId: string;
  className?: string;
};

const RESOURCES_MAX = 15;
const RESOURCES_MIN = 1;

function SchedulingWizard(props: SchedulingWizardProps) {
  const {className, clinicId} = props;
  const params = useParams<SchedulingWizardParams>();
  const routeMatch = useRouteMatch();
  const {redirect} = useRouting();

  const getSlotTypesQuery = useGetSlotTypesQuery({clinicId});
  const createScheduleDefinitionMutation = useCreateScheduleDefinitionMutation({
    clinicId,
  });

  const [isInProgress, setIsInProgress] = useState(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [numberOfResources, setNumberOfResources] = useState<number>();
  const [viewDateRef, setViewDate] = useRefState<Date>(new Date());
  const [calendarEventsRef, setCalendarEvents] = useRefState<
    WizardCalendarEvent[]
  >([]);
  const [validationError, setValidationError] = useState<Error | null>(null);

  const tChooseStartDateError = useTranslation(
    "scheduling-wizard-validation-start-date"
  );
  const tChooseEndDateError = useTranslation(
    "scheduling-wizard-validation-end-date"
  );
  const tChooseNoOfResourcesError = useTranslation(
    "scheduling-wizard-validation-no-resources"
  );
  const tNoOfResourcesRangeError = useTranslation(
    "scheduling-wizard-validation-resources-range",
    {max: RESOURCES_MAX, min: RESOURCES_MIN}
  );
  const tNoEventsError = useTranslation(
    "scheduling-wizard-validation-no-events"
  );

  const validateDateRange = useCallback(
    (start?: Date, end?: Date) => {
      if (!isValidDate(start)) {
        return new Error(tChooseStartDateError);
      }
      if (!isValidDate(end)) {
        return new Error(tChooseEndDateError);
      }
      return null;
    },
    [tChooseStartDateError, tChooseEndDateError]
  );

  const validateNoOfResources = useCallback(
    (resources?: number) => {
      if (!isFiniteNumber(resources)) {
        return new Error(tChooseNoOfResourcesError);
      }
      if (resources < RESOURCES_MIN || resources > RESOURCES_MAX) {
        return new Error(tNoOfResourcesRangeError);
      }
      return null;
    },
    [tChooseNoOfResourcesError, tNoOfResourcesRangeError]
  );

  const validateCalendarEvents = useCallback(
    (calendarEvents: WizardCalendarEvent[]) => {
      if (calendarEvents.length === 0) {
        return new Error(tNoEventsError);
      }
      return null;
    },
    [tNoEventsError]
  );

  const saveSchedule = useCallback(
    (calendarEvents: WizardCalendarEvent[]) => {
      const slotRanges = calendarEvents.map((calendarEvent) => {
        const {start, end, resource, slotType} = calendarEvent;

        return {
          start,
          end,
          slotType,
          resourceIndex: Number(resource.id),
        };
      });

      return createScheduleDefinitionMutation.mutateAsync({
        input: {
          slotRanges,
        },
      });
    },
    [createScheduleDefinitionMutation]
  );

  const resetState = useCallback(() => {
    setStartDate(undefined);
    setEndDate(undefined);
    setNumberOfResources(undefined);
    setCalendarEvents([]);
  }, [setStartDate, setEndDate, setNumberOfResources, setCalendarEvents]);

  const onSubmit = useCallback(() => {
    return saveSchedule(calendarEventsRef.current).then((data) => {
      setIsInProgress(false);
      resetState();
      return data;
    });
  }, [saveSchedule, calendarEventsRef, resetState]);

  const onCalendarEventsChange = useCallback(
    (calendarEvents: WizardCalendarEvent[]) => {
      setCalendarEvents(calendarEvents);
    },
    [setCalendarEvents]
  );

  const onViewDateChange = useCallback(
    (viewDate: Date) => {
      setViewDate(viewDate);
    },
    [setViewDate]
  );

  const step = Number(params.step);

  const onPrevClick = useCallback(() => {
    const prevStepLocation = generatePath(routeMatch.path, {
      clinicId,
      step: getPrevStep(step),
    });

    redirect(prevStepLocation);
  }, [clinicId, step, routeMatch.path, redirect]);

  const onNextClick = useCallback(() => {
    let error: Error | null = null;

    setIsInProgress(true);
    if (step === 1) {
      error =
        validateNoOfResources(numberOfResources) ||
        validateDateRange(startDate, endDate);

      // Make sure there are no slots that falls outside current date range.
      // Eg. if the user navigates to step 2, adds slots then navigates back to
      // step 1 and trims the date range.
      if (isValidDate(startDate) && isValidDate(endDate)) {
        const events = calendarEventsRef.current;
        const min = resetLocalTime(startDate);
        const max = addDays(resetLocalTime(endDate), 1);
        const clamped = clampCalendarEvents(events, min, max);

        if (events.length !== clamped.length) {
          setCalendarEvents(clamped);
        }
      }
    }
    if (step === 2) {
      error = validateCalendarEvents(calendarEventsRef.current);
    }

    setValidationError(error);
    if (!error) {
      const params = {clinicId, step: getNextStep(step)};
      const nextStepLocation = generatePath(routeMatch.path, params);

      redirect(nextStepLocation);
    }
  }, [
    calendarEventsRef,
    clinicId,
    endDate,
    numberOfResources,
    redirect,
    routeMatch.path,
    setCalendarEvents,
    startDate,
    step,
    validateCalendarEvents,
    validateDateRange,
    validateNoOfResources,
  ]);

  const tLeaveWhileInProgress = useTranslation(
    "scheduling-wizard-leave-while-in-progress"
  );
  const promptOnNavigation = useCallback(
    (nextLocation: Location) => {
      const wizardPath = generatePath(routeMatch.path, {clinicId});
      const isWizardPath = nextLocation.pathname.startsWith(wizardPath);

      // Awkward API...
      // return true: pass through
      // return string: display prompt with message
      if (isInProgress && !isWizardPath) {
        return tLeaveWhileInProgress;
      }
      return true;
    },
    [clinicId, isInProgress, routeMatch.path, tLeaveWhileInProgress]
  );

  useEffect(() => {
    const resourceIds = createArray(numberOfResources ?? 0, String);

    setCalendarEvents((prevCalendarEvents) => {
      const nextCalendarEvents = prevCalendarEvents.filter((calendarEvent) => {
        return resourceIds.includes(calendarEvent.resource.id);
      });

      return nextCalendarEvents;
    });
  }, [numberOfResources, setCalendarEvents]);

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    // If we include createScheduleDefinitionMutation in the dependency array
    // we end up in a loop.
    if (step !== 3) {
      createScheduleDefinitionMutation.reset();
    }
  }, [step]);

  // Validate on routing
  const step2Errors = validateCalendarEvents(calendarEventsRef.current);

  if (step === 3 && step2Errors !== null) {
    return <Redirect to={generatePath(routeMatch.path, {clinicId, step: 2})} />;
  }
  const step1Errors =
    validateNoOfResources(numberOfResources) ||
    validateDateRange(startDate, endDate);

  if ((step === 2 && step1Errors !== null) || isUndefined(params.step)) {
    return <Redirect to={generatePath(routeMatch.path, {clinicId, step: 1})} />;
  }

  return (
    <Fragment>
      <Prompt message={promptOnNavigation} />
      {getSlotTypesQuery.isLoading ? <Loading /> : null}
      {getSlotTypesQuery.isError ? (
        <LoadingError error={getSlotTypesQuery.error} />
      ) : null}
      {getSlotTypesQuery.isSuccess ? (
        <div className={classNames(styles.root, className)}>
          <div className={styles.header}>
            <SchedulingWizardNavigation
              disabled={!isInProgress && step === 3}
              step={step}
              onPrevClick={onPrevClick}
              onNextClick={onNextClick}
            />
          </div>
          <div className={styles.body}>
            {validationError ? (
              <Alert variant="danger">{validationError.message}</Alert>
            ) : null}
            {createScheduleDefinitionMutation.isLoading ? (
              <SchedulingWizardMutationLoading />
            ) : null}
            {createScheduleDefinitionMutation.isSuccess ? (
              <SchedulingWizardMutationSuccess />
            ) : null}
            {createScheduleDefinitionMutation.isError ? (
              <SchedulingWizardMutationError />
            ) : null}
            {createScheduleDefinitionMutation.isIdle ? (
              <Switch>
                <Route
                  path={generatePath(routeMatch.path, {clinicId, step: 1})}
                >
                  <Card>
                    <SchedulingWizardStep1
                      clinicId={clinicId}
                      startDate={startDate}
                      onStartDateChange={setStartDate}
                      endDate={endDate}
                      onEndDateChange={setEndDate}
                      numberOfResources={numberOfResources}
                      onNumberOfResourcesChange={setNumberOfResources}
                      resourcesMax={RESOURCES_MAX}
                      resourcesMin={RESOURCES_MIN}
                    />
                  </Card>
                </Route>
                <Route
                  path={generatePath(routeMatch.path, {clinicId, step: 2})}
                >
                  <SchedulingWizardStep2
                    startDate={startDate}
                    endDate={endDate}
                    numberOfResources={numberOfResources!}
                    initialCalendarEvents={calendarEventsRef.current}
                    initialViewDate={viewDateRef.current}
                    onCalendarEventsChange={onCalendarEventsChange}
                    onViewDateChange={onViewDateChange}
                    slotTypes={getSlotTypesQuery.data}
                  />
                </Route>
                <Route
                  path={generatePath(routeMatch.path, {clinicId, step: 3})}
                >
                  <Card>
                    <SchedulingWizardStep3
                      startDate={startDate!}
                      endDate={endDate!}
                      onSubmit={onSubmit}
                      calendarEvents={calendarEventsRef.current}
                      slotTypes={getSlotTypesQuery.data}
                    />
                  </Card>
                </Route>
              </Switch>
            ) : null}
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}

function getPrevStep(step: number) {
  return step === 1 ? step : step - 1;
}

function getNextStep(step: number) {
  return step === 3 ? step : step + 1;
}
