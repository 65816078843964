import {Component, createContext, ErrorInfo, ReactChild} from "react";

const ErrorBoundaryContext = createContext<Error | null>(null);
ErrorBoundaryContext.displayName = "ErrorBoundaryContext";
export {ErrorBoundaryContext};

interface ErrorBoundaryProps {
  fallback?: ReactChild;
  name: string;
}

interface ErrorBoundaryState {
  error: Error | null;
  hasError: boolean;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {error: null, hasError: false};
  }

  static getDerivedStateFromError(error: Error) {
    return {error, hasError: true};
  }

  componentDidCatch = (error: Error, errorInfo: ErrorInfo) => {
    const {name} = this.props;

    console.error(`ErrorBoundary '${name}' caught an error`, error, errorInfo);
    // TODO: Report to remote error service
  };

  render() {
    const {children, fallback = <div>Ooops! &#x1F625;</div>} = this.props;
    const {error, hasError} = this.state;

    return (
      <ErrorBoundaryContext.Provider value={error}>
        {hasError ? fallback : children}
      </ErrorBoundaryContext.Provider>
    );
  }
}
