import EntityReference from "./entity-reference";
import SlotRange, {SlotRangeJSON} from "./slot-range";

interface ScheduleDefinitionInit {
  id?: string;
  slotRanges: SlotRange[];
  clinic?: EntityReference;
  version?: string;
}

export interface ScheduleDefinitionJSON {
  id: string;
  slot_ranges: SlotRangeJSON[];
  clinic: EntityReference;
  version: null | string;
}

export default class ScheduleDefinition {
  id: string;
  slotRanges: SlotRange[];
  clinic: EntityReference;
  version: string;

  constructor({id, clinic, slotRanges, version}: ScheduleDefinitionInit) {
    Object.assign(this, {id, clinic, slotRanges, version});
  }

  static fromJSON(json: ScheduleDefinitionJSON) {
    const {id, clinic, slot_ranges: slotRangesJson, version} = json;

    const slotRanges = slotRangesJson.map((slotRangeJson) => {
      return SlotRange.fromJSON(slotRangeJson);
    });

    return new ScheduleDefinition({
      id,
      clinic,
      slotRanges,
      version: version ?? "",
    });
  }

  toJSON() {
    const {id, clinic, slotRanges: slot_ranges, version} = this;

    return {id, clinic, slot_ranges, version};
  }
}
