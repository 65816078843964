import React, {ReactNode} from "react";
import {Translation} from "../../../services/i18n";
import {classNames} from "../../../utils";
import {Button} from "../../bootstrap";
import styles from "./scheduling-wizard-navigation.module.scss";

export default SchedulingWizardNavigation;

type SchedulingWizardNavigationProps = {
  disabled?: boolean;
  step: number;
  onPrevClick: () => void;
  onNextClick: () => void;
};

function SchedulingWizardNavigation(props: SchedulingWizardNavigationProps) {
  const {disabled = false, step, onPrevClick, onNextClick} = props;

  const isFirstStep = step === 1;
  const isLastStep = step === 3;

  return (
    <div className={styles.root}>
      <div className={styles.buttonContainer}>
        {!disabled && !isFirstStep && (
          <Button onClick={onPrevClick} variant="outline-primary">
            <Translation tKey="scheduling-wizard-back-button" />
          </Button>
        )}
      </div>

      <StepIndicator active={step === 1} step={1}>
        <Translation tKey="scheduling-wizard-step-1" />
      </StepIndicator>

      <div className={styles.connector} />

      <StepIndicator active={step === 2} step={2}>
        <Translation tKey="scheduling-wizard-step-2" />
      </StepIndicator>

      <div className={styles.connector} />

      <StepIndicator active={step === 3} step={3}>
        <Translation tKey="scheduling-wizard-step-3" />
      </StepIndicator>

      <div className={styles.buttonContainer}>
        {!disabled && !isLastStep && (
          <Button onClick={onNextClick} variant="outline-primary">
            <Translation tKey="scheduling-wizard-next-button" />
          </Button>
        )}
      </div>
    </div>
  );
}

interface StepIndicatorProps {
  active: boolean;
  children: ReactNode;
  step: number;
}

function StepIndicator(props: StepIndicatorProps) {
  const {active, children, step} = props;
  const classes = classNames(styles.stepIndicator, {[styles.active]: active});

  return (
    <div className={classes}>
      <div className={styles.stepIndicatorNumber}>{step}</div>
      <div className={styles.stepIndicatorLabel}>{children}</div>
    </div>
  );
}
