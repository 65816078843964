import {useQuery} from "react-query";
import useSlotQueryCreators, {
  SearchSlotsQueryOptions,
  SearchSlotsQueryVariables,
} from "./use-slot-query-creators";

export default useSearchSlotsQuery;

function useSearchSlotsQuery(
  variables: SearchSlotsQueryVariables,
  options?: SearchSlotsQueryOptions
) {
  const {createSearchSlotsQuery} = useSlotQueryCreators();

  return useQuery(createSearchSlotsQuery(variables, options));
}
