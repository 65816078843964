import {createContext} from "react";
import {InterpolationTemplate} from "../../utils";
import type {Locale, LocaleTag} from "./locale";

export type I18nState = {
  availableLocales: Locale[];
  currentLocale: Locale;
  setLocale: (locale: Locale | LocaleTag) => void;
  source: I18nSource;
};

export type I18nSource = {
  [i18nKey: string]: InterpolationTemplate;
};

export const I18nContext = createContext<I18nState | null>(null);

export class I18nContextOutOfBoundsError extends Error {
  constructor() {
    super("You are trying to access I18n data outside the I18nProvider");
  }
}
