import React, {ForwardedRef, ReactNode} from "react";

interface FullCalendarEventWrapperProps {
  children: ReactNode;
  style: Record<string, string>;
}

const FullCalendarEventWrapper = React.forwardRef(
  (props: FullCalendarEventWrapperProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {children, style} = props;

    return (
      <div className="fc-timegrid-event" ref={ref} style={style}>
        {children}
      </div>
    );
  }
);

export default FullCalendarEventWrapper;
