import {Fragment, memo, useEffect, useState} from "react";
import {toMilliseconds} from "../../utils/time/duration";
import {DateStyle, DateText, TimeStyle, TimeText} from "../date-time";

export default memo(SessionInfoTimeAndDate);

function SessionInfoTimeAndDate() {
  const [date, setDate] = useState<Date>(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDate(new Date());
    }, toMilliseconds({minutes: 1}));

    return () => {
      clearInterval(intervalId);
    };
  }, [setDate]);

  return (
    <Fragment>
      <TimeText date={date} timeStyle={TimeStyle.SHORT} />,{" "}
      <DateText date={date} dateStyle={DateStyle.FULL} />
      <br />
    </Fragment>
  );
}
