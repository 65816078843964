import {useContext} from "react";
import SingleChoiceContext, {
  SingleChoiceContextValue,
} from "./single-choice-context";

export default useSingleChoice;

export type UseSingleChoiceValue<T> = Readonly<SingleChoiceContextValue<T>>;

function useSingleChoice<T>() {
  const singleChoiceContextValue = useContext<UseSingleChoiceValue<T> | null>(
    SingleChoiceContext
  );

  if (singleChoiceContextValue === null) {
    throw new Error(
      "useSingleChoice must be used under a SingleChoiceContext.Provider"
    );
  }

  return singleChoiceContextValue;
}
