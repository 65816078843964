import React, {Fragment} from "react";
import ResidentLink from "../../routes/residents/components/resident-link";
import {Translation} from "../../services/i18n";
import {Resident} from "../../services/time-book-scheduling-api";
import {PersonalIdentificationNumber} from "../personal-identification-number";
import {ResidentBookings} from "./components";
import styles from "./search-results.module.scss";

interface SearchResultsProps {
  clinicId: string;
  resident: Resident;
}

export const SearchResults = (props: SearchResultsProps) => {
  const {clinicId, resident} = props;

  return (
    <Fragment>
      <div className={styles.details}>
        <span>{resident.name}</span>
        <PersonalIdentificationNumber
          className={styles.pin}
          value={resident.personalIdentityNumber}
        />
        <ResidentLink clinicId={clinicId} residentId={resident.id}>
          <Translation tKey="site-search-navigate-to-resident" />
        </ResidentLink>
      </div>
      <ResidentBookings clinicId={clinicId} resident={resident} />
    </Fragment>
  );
};
