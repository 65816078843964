import {useCallback, useMemo} from "react";
import {LocationDescriptor} from "history";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {toQueryStringObject} from "../../utils";

export const useRouting = <Params extends object>() => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const params = useParams<Params>();

  const back = useCallback(() => {
    history.goBack();
  }, [history]);

  const forward = useCallback(() => {
    history.goForward();
  }, [history]);

  const redirect = useCallback(
    (location: LocationDescriptor | string) => {
      history.push(location);
    },
    [history]
  );

  return useMemo(() => {
    const query = toQueryStringObject(location.search);

    return {
      back,
      forward,
      location,
      match,
      params,
      query,
      redirect,
    };
  }, [back, forward, location, match, params, redirect]);
};
