import React, {Fragment} from "react";
import {InterpolationValues} from "../../utils";
import {useTranslation} from "./use-translation";

export type TranslationProps = {
  fallback?: string;
  tKey: string;
  tValues?: InterpolationValues;
};

export const Translation = (props: TranslationProps) => {
  const {fallback, tKey, tValues} = props;
  const phrase = useTranslation(tKey, tValues, fallback);

  return <Fragment>{phrase}</Fragment>;
};
