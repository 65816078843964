import React, {ReactNode} from "react";
import {Link as ReactRouterLink} from "react-router-dom";

type LinkProps = {
  children: ReactNode;
  className?: string;
  href: string;
};

export const Link = ({href, ...otherProps}: LinkProps) => (
  <ReactRouterLink to={href} {...otherProps} />
);
