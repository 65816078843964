import {ReactNode} from "react";
import {useMainNavigationCollapsed} from "../../hooks/use-main-navigation-collapsed";
import {Translation} from "../../services/i18n";
import {getRootRoute} from "../../services/routing";
import {classNames} from "../../utils";
import {Button} from "../bootstrap";
import {ArrowCircleLeftIcon, ArrowCircleRightIcon} from "../icon";
import {Link} from "../link";
import {Logo} from "../logo";
import {MainNavigation} from "../main-navigation";
import SessionInfo from "../session-info";
import styles from "./default-layout.module.scss";

export {DefaultLayout};

interface DefaultLayoutProps {
  children: ReactNode;
  header?: ReactNode;
}

function DefaultLayout(props: DefaultLayoutProps) {
  const {children, header} = props;

  const [isNavContainerCollapsed] = useMainNavigationCollapsed();
  const navContainerClasses = classNames(styles.navContainer, {
    [styles.navContainerCollapsed]: isNavContainerCollapsed,
  });

  return (
    <div className={styles.root}>
      <div className={navContainerClasses}>
        <AppTitle />
        <div className={styles.navMain}>
          <MainNavigation />
          <CollapseMainNavigationButton />
        </div>
      </div>

      <div className={styles.mainContainer}>
        <div className={styles.mainHeader}>
          <div className={styles.midHeaderInner}>
            <div className={styles.search}>{header}</div>
            <div className={styles.user}>
              <SessionInfo />
            </div>
          </div>
        </div>

        <div className={styles.mainContentContainer}>
          <div className={styles.mainContent}>{children}</div>
        </div>
      </div>
    </div>
  );
}

function AppTitle() {
  return (
    <Link className={styles.navHeader} href={getRootRoute()}>
      <Logo className={styles.navLogo} />
      <span className="nav-item-text">
        <Translation tKey="app-title" />
      </span>
    </Link>
  );
}

function CollapseMainNavigationButton() {
  const [isCollapsed, setIsCollapsed] = useMainNavigationCollapsed();

  return (
    <Button
      className={styles.navCollapseButton}
      onClick={() => setIsCollapsed(!isCollapsed)}
      variant="outline-secondary"
    >
      {isCollapsed ? <ArrowCircleRightIcon /> : <ArrowCircleLeftIcon />}
    </Button>
  );
}
