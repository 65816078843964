import React from "react";
import {useParams} from "react-router-dom";
import {Card} from "../../components/card";
import {DefaultLayout} from "../../components/layouts";
import {Loading} from "../../components/loading";
import {LoadingError} from "../../components/loading-error";
import {ResidentBookingsTable} from "../../components/resident-bookings-table";
import {SearchResidentWithRedirection} from "../../components/search-resident";
import useGetResidentBookingsQuery from "../../components/search-resident/components/use-get-resident-bookings-query";
import {useClinicId} from "../../hooks";
import {useGetResidentQuery} from "../../services/api/residents/hooks";
import {isNonEmptyString} from "../../utils";
import {ResidentDetails} from "./components";

type ViewResidentRouteParams = {
  residentId: string;
};

const ViewResidentRoute = () => {
  const {residentId} = useParams<ViewResidentRouteParams>();
  const [clinicId] = useClinicId();

  if (!isNonEmptyString(clinicId)) {
    throw new TypeError("clinicId missing");
  }
  const getResidentQuery = useGetResidentQuery(
    {residentId},
    {keepPreviousData: true}
  );
  const getResidentBookingsQueryResult = useGetResidentBookingsQuery(
    {
      clinicId,
      residentId,
    },
    {keepPreviousData: true}
  );

  const headerElement = <SearchResidentWithRedirection />;

  return (
    <DefaultLayout header={headerElement}>
      <Card>
        {getResidentQuery.isError ? (
          <LoadingError error={getResidentQuery.error} />
        ) : null}

        {getResidentQuery.isLoading ? <Loading /> : null}

        {getResidentQuery.isSuccess ? (
          <ResidentDetails resident={getResidentQuery.data} />
        ) : null}
      </Card>
      <Card>
        {getResidentBookingsQueryResult.isError ? (
          <LoadingError error={getResidentBookingsQueryResult.error} />
        ) : null}

        {getResidentBookingsQueryResult.isLoading ||
        getResidentQuery.isLoading ? (
          <Loading />
        ) : null}

        {getResidentQuery.isSuccess &&
        getResidentBookingsQueryResult.isSuccess ? (
          <ResidentBookingsTable
            bookings={getResidentBookingsQueryResult.data}
            resident={getResidentQuery.data}
          />
        ) : null}
      </Card>
    </DefaultLayout>
  );
};

export {ViewResidentRoute};
