import {useCallback} from "react";
import {generatePath} from "react-router-dom";
import useRouteUrl from "./use-route-url";

export default useGeneratePath;

function useGeneratePath() {
  const [routeUrl] = useRouteUrl();

  return useCallback<typeof generatePath>(
    (path, params) => {
      return generatePath(routeUrl + path, params);
    },
    [routeUrl]
  );
}
