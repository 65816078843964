import {getWindowLocalStorage} from "./globals";
import {isString} from "./is-string";
import {JsonValue} from "./types";

const internalRead = <T extends JsonValue>(key: string): T | null => {
  const value = getWindowLocalStorage().getItem(key);

  if (isString(value)) {
    return JSON.parse(value) as T;
  }
  return null;
};

const internalWrite = <T extends JsonValue>(key: string, value: T) => {
  getWindowLocalStorage().setItem(key, JSON.stringify(value));
};

export const createLocalStorageItem = <T extends JsonValue>(key: string) => ({
  read: () => internalRead<T>(key),
  wite: (value: T) => internalWrite<T>(key, value),
});
