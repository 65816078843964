import React, {ReactNode} from "react";
import {LocationDescriptor} from "history";
import {NavLink as ReactRouterNavLink} from "react-router-dom";

interface NavLinkProps {
  children: ReactNode;
  className?: string;
  exact?: boolean;
  to: LocationDescriptor;
}

export const NavLink = (props: NavLinkProps) => {
  const {children, className, exact, to} = props;

  return (
    <ReactRouterNavLink className={className} exact={exact} to={to}>
      {children}
    </ReactRouterNavLink>
  );
};
