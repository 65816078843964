import {useMemo} from "react";
import {UseQueryOptions} from "react-query";
import {isNonEmptyArray} from "../../../../utils";
import {Slot} from "../../../time-book-scheduling-api";
import {createSearchEntitiesByIdFilter as createSearchSlotsByIdFilter} from "../../search-query";
import useSearchSlotsQuery from "./use-search-slots-query";

export default useGetSlotsQuery;

interface GetSlotsQueryVariables {
  clinicId: string;
  slotIds: string[];
}

type GetSlotsQueryOptions = UseQueryOptions<Slot[], Error>;

function useGetSlotsQuery(
  variables: GetSlotsQueryVariables,
  options?: GetSlotsQueryOptions
) {
  const {clinicId, slotIds} = variables;

  const filter = useMemo(() => {
    return isNonEmptyArray(slotIds)
      ? createSearchSlotsByIdFilter(slotIds)
      : null;
  }, [slotIds]);

  return useSearchSlotsQuery({clinicId, filter}, options);
}
