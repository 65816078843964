import {useGetSlotTypesQuery} from "../../../../services/api/slot-types";
import {useTranslation} from "../../../../services/i18n";
import {useClinicId} from "../../hooks";
import {createOption} from "./utils";

export default useSlotTypeOptions;

function useSlotTypeOptions() {
  const [clinicId] = useClinicId();
  const getSlotTypesQuery = useGetSlotTypesQuery({clinicId});
  const slotTypes = getSlotTypesQuery.data ?? [];

  const tAll = useTranslation("calendar-slot-type-option-all");

  return [
    createOption(tAll, ""),
    ...slotTypes.map((slotType) => createOption(slotType.name, slotType.id)),
  ];
}
