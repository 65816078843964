import React, {useCallback} from "react";
import {Button} from "react-bootstrap";
import {useCurrentClinic, useCurrentUser} from "../../hooks";
import {useSessionClient} from "../../services/session";
import {LogOutIcon} from "../icon";
import SessionInfoTimeAndDate from "./session-info-time-and-date";
import styles from "./session-info.module.scss";

export default SessionInfo;

function SessionInfo() {
  const sessionClient = useSessionClient();
  const currentClinic = useCurrentClinic();
  const currentUser = useCurrentUser();

  const onLogOutClick = useCallback(() => {
    sessionClient.logout();
  }, [sessionClient]);

  const name = `${currentUser.firstName} ${currentUser.lastName}`;

  return (
    <div className={styles.root}>
      <div className={styles.info}>
        <div className={styles.name} data-testid="session-info-name">
          {name}, {currentClinic?.name}
        </div>
        <div className={styles.time} data-testid="session-info-time">
          <SessionInfoTimeAndDate />
        </div>
      </div>
      <div className={styles.button}>
        <Button
          onClick={onLogOutClick}
          data-testid="session-info-logout"
          variant="light"
        >
          <LogOutIcon />
        </Button>
      </div>
    </div>
  );
}
