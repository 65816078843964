import {SlotType} from "../../services/time-book-scheduling-api";
import SlotTypeAndDuration from "../slot-type-and-duration";
import SlotTypePlaque from "../slot-type-plaque";
import styles from "./slot-type-nameplate.module.scss";

export default SlotTypeNameplate;

interface SlotTypeNameplateProps {
  active?: boolean;
  slotType: SlotType;
}

function SlotTypeNameplate(props: SlotTypeNameplateProps) {
  const {active = false, slotType} = props;

  return (
    <SlotTypePlaque active={active} className={styles.root} slotType={slotType}>
      <SlotTypeAndDuration slotType={slotType} />
    </SlotTypePlaque>
  );
}
