import {UseQueryOptions} from "react-query";
import {useDerivedQuery} from "../../../../utils/react-query";
import {SlotType} from "../../../time-book-scheduling-api";
import {useSearchAppointmentsQuery} from "../../appointments";
import {useGetResidentsQuery} from "../../residents";
import {Filter} from "../../search-query";
import {useGetSlotTypesQuery} from "../../slot-types";
import {useSearchSlotsQuery} from "../../slots";
import {
  selectAppointmentBySlotId,
  selectResidentById,
  selectResidentIdsFromAppointments,
  selectSlotTypeById,
} from "../../utils/selectors";
import {Booking} from "../types";

export default useGetBookingsQuery;

interface GetBookingsQueryVariables {
  clinicId: string;
  appointmentsFilter: Filter;
  slotsFilter: Filter;
}

type GetBookingsQueryOptions = Pick<
  UseQueryOptions,
  | "cacheTime"
  | "keepPreviousData"
  | "refetchInterval"
  | "refetchOnWindowFocus"
  | "staleTime"
>;

function useGetBookingsQuery(
  variables: GetBookingsQueryVariables,
  options: GetBookingsQueryOptions = {}
) {
  const {clinicId, appointmentsFilter, slotsFilter} = variables;
  const {
    cacheTime,
    keepPreviousData,
    refetchInterval,
    refetchOnWindowFocus,
    staleTime,
  } = options;

  const getSlotTypesQuery = useGetSlotTypesQuery({clinicId});

  const searchSlotsQuery = useSearchSlotsQuery(
    {
      clinicId,
      filter: slotsFilter,
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus,
      refetchInterval,
    }
  );

  const searchAppointmentsQuery = useSearchAppointmentsQuery(
    {
      clinicId,
      filter: appointmentsFilter,
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus,
      refetchInterval,
    }
  );

  const residentIds = selectResidentIdsFromAppointments(
    searchAppointmentsQuery.data
  );
  const getResidentsQuery = useGetResidentsQuery(
    {residentIds},
    {
      enabled: searchAppointmentsQuery.isSuccess,
      keepPreviousData: true,
    }
  );

  return useDerivedQuery(
    ["bookings", variables],
    [
      searchSlotsQuery,
      searchAppointmentsQuery,
      getResidentsQuery,
      getSlotTypesQuery,
    ],
    (data) => {
      const [slots, appointments, residents, slotTypes] = data;

      const bookings = slots.map((slot) => {
        const appointment = selectAppointmentBySlotId(appointments, slot.id);
        const resident = selectResidentById(
          residents,
          appointment?.resident.id
        );
        const slotType = selectSlotTypeById(
          slotTypes,
          slot.slotType.id
        ) as SlotType;

        return {
          appointment,
          resident,
          slot,
          slotType,
        } as Booking;
      });

      return bookings;
    },
    {
      cacheTime,
      keepPreviousData,
      staleTime,
    }
  );
}
