import {memoize} from "../memoize";

type DateTimeFormatOptionsMonth = "long" | "narrow" | "short";

const getMonthNames = memoize(
  (option: DateTimeFormatOptionsMonth) => (locales: string | string[] = []) => {
    const formatter = new Intl.DateTimeFormat(locales, {month: option});

    return [
      formatter.format(new Date(2021, 0, 1)),
      formatter.format(new Date(2021, 1, 1)),
      formatter.format(new Date(2021, 2, 1)),
      formatter.format(new Date(2021, 3, 1)),
      formatter.format(new Date(2021, 4, 1)),
      formatter.format(new Date(2021, 5, 1)),
      formatter.format(new Date(2021, 6, 1)),
      formatter.format(new Date(2021, 7, 1)),
      formatter.format(new Date(2021, 8, 1)),
      formatter.format(new Date(2021, 9, 1)),
      formatter.format(new Date(2021, 10, 1)),
      formatter.format(new Date(2021, 11, 1)),
    ];
  }
);

/**
 * Returns a list of month names, the long version.
 * @param locales - As default, the browser's default locale will be used
 */
export const getLongMonthNames = getMonthNames("long");

/**
 * Returns a list of month names, the narrow version.
 * @param locales - As default, the browser's default locale will be used
 */
export const getNarrowMonthNames = getMonthNames("narrow");

/**
 * Returns a list of month names, the short version.
 * @param locales - As default, the browser's default locale will be used
 */
export const getShortMonthNames = getMonthNames("short");
