import {ReactNode, useEffect, useState} from "react";
import {toLower, getWindowNavigator, isObject} from "../../utils";
import {I18nContext, I18nSource} from "./i18n-context";
import type {Locale, LocaleTag} from "./locale";

export {I18nProvider};

const {Provider} = I18nContext;

type I18nResolver = (locale: Locale) => Promise<I18nSource>;

interface I18nConfig {
  availableLocales: Locale[];
  defaultLocale?: Locale;
  fallbackLocale: Locale;
  resolver: I18nResolver;
}

interface I18nProviderProps {
  children: ReactNode;
  config: I18nConfig;
}

function I18nProvider(props: I18nProviderProps) {
  const {children, config} = props;
  const {availableLocales, defaultLocale, fallbackLocale, resolver} = config;

  const locale = resolveLocale(availableLocales, fallbackLocale, defaultLocale);
  const [currentLocale, setLocaleInternal] = useState<Locale>(locale);
  const [source, setSource] = useState<I18nSource>({});
  const setLocale = (locale: Locale | LocaleTag) => {
    const tag = isObject(locale) ? locale.tag : locale;
    const foundLocale = availableLocales.find((locale) => locale.tag === tag);

    if (!foundLocale) {
      throw new Error(`"${tag}" is not a supported locale`);
    }
    setLocaleInternal(foundLocale);
  };
  const state = {availableLocales, currentLocale, setLocale, source};

  useEffect(() => {
    resolver(currentLocale).then(setSource);
  }, [currentLocale, resolver]);
  return <Provider value={state}>{children}</Provider>;
}

function resolveLocale(
  locales: Locale[],
  fallback: Locale,
  override?: Locale
): Locale {
  // 1. Ignore browser setting
  if (override) {
    return override;
  }
  // 2. Use browser's language setting
  // This can absolutely be made smarter (eg. look in navigator.languages and
  // check against locale.language)
  const browserLanguage = toLower(getWindowNavigator().language);
  const locale = locales.find(({tag}) => toLower(tag) === browserLanguage);

  // 3. Fallback
  return locale ? locale : fallback;
}
