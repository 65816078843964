import React from "react";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {useClinicId} from "../../hooks";
import {isUndefined} from "../../utils";
import {SearchResidentRoute} from "./search-residents-route";
import {ViewResidentRoute} from "./view-resident-route";

const ResidentsRoute = () => {
  const routeMatch = useRouteMatch();
  const [clinicId] = useClinicId();

  if (isUndefined(clinicId)) {
    return null;
  }
  return (
    <Switch>
      <Route path={`${routeMatch.path}/:residentId`} exact>
        <ViewResidentRoute />
      </Route>
      <Route path={`${routeMatch.path}`} exact>
        <SearchResidentRoute />
      </Route>
    </Switch>
  );
};

export {ResidentsRoute};
