import React, {
  RefObject,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import {Translation, useTranslation} from "../../../services/i18n";
import {SlotType} from "../../../services/time-book-scheduling-api";
import {
  getNextDay,
  getPreviousDay,
  isFunction,
  isSameDate,
  isValidDate,
  setLocalTime,
} from "../../../utils";
import {
  getDateDifferenceInDays,
  getDateDifferenceInMinutes,
} from "../../../utils/date-time-difference";
import {useRefState} from "../../../utils/react";
import {
  toDurationObject,
  toMilliseconds,
  toMinutes,
} from "../../../utils/time/duration";
import {Button, ButtonGroup} from "../../bootstrap";
import DatePaginator from "../../calendar/date-paginator";
import {Card} from "../../card";
import {ConditionalTooltip} from "../../conditional-tooltip";
import {DateStyle, DateText} from "../../date-time";
import {useConfirm} from "../../dialogs";
import FullCalendar, {
  DatesSetArg,
  EventApi,
  EventContentArg,
  EventResizeDoneArg,
  FullCalendarDraggableEvent,
  FullCalendarEventWrapper,
  FullCalendarRefObject,
} from "../../full-calendar";
import {CopyIcon, PasteIcon, TrashIcon} from "../../icon";
import Triptych from "../../layouts/triptych";
import {SlotTypeList} from "../../slot-type-list";
import {WizardCalendarEvent} from "../wizard-calendar-event";
import styles from "./scheduling-wizard-step-2.module.scss";
import SlotRangeEventContent from "./slot-range-event-content";
import SlotTypeEventContent from "./slot-type-event-content";

export default SchedulingWizardStep2;

type SchedulingWizardStep2Props = {
  initialCalendarEvents: WizardCalendarEvent[];
  initialViewDate: Date;
  startDate?: Date;
  endDate?: Date;
  numberOfResources: number;
  onCalendarEventsChange: (calendarEvents: WizardCalendarEvent[]) => void;
  onViewDateChange: (viewDate: Date) => void;
  slotTypes: SlotType[];
};

function SchedulingWizardStep2(props: SchedulingWizardStep2Props) {
  const {
    initialCalendarEvents,
    initialViewDate: initialDate,
    startDate,
    endDate,
    numberOfResources,
    onCalendarEventsChange,
    onViewDateChange,
    slotTypes,
  } = props;
  const confirm = useConfirm();

  if (!startDate || !endDate) {
    throw new Error();
  }
  const fullCalendarRef: FullCalendarRefObject = useRef(null);

  const tTooltipCopy = useTranslation("schedule-wizard-step-2-copy");
  const tTooltipPaste = useTranslation("schedule-wizard-step-2-paste");
  const tTooltipCalendar = useTranslation("schedule-wizard-step-2-pick-day");
  const tTooltipNext = useTranslation("schedule-wizard-step-2-next-day");
  const tTooltipPrevious = useTranslation("schedule-wizard-step-2-prev-day");
  const tResourceName = useTranslation("schedule-wizard-step-2-resource");
  const tTooltipClear = useTranslation("schedule-wizard-step-2-clear");
  const tClearConfirmMessage = useTranslation(
    "schedule-wizard-step-2-clear-confirm"
  );

  const initialScrollTime = "07:30:00";
  const [date, setDate] = useState(initialDate);

  const eventContent = useCallback((eventContentArg: EventContentArg) => {
    const {event, timeText} = eventContentArg;
    const slotType = event.extendedProps.slotType as SlotType;

    const slotRangeDurationInMinutes = getDateDifferenceInMinutes(
      event.start!,
      event.end!
    );
    const slotTypeDurationInMinutes = toMinutes(slotType.duration);
    const slotCount = Math.floor(
      slotRangeDurationInMinutes / slotTypeDurationInMinutes
    );

    return (
      <SlotRangeEventContent event={event}>
        <Translation
          tKey="schedule-wizard-step-2-event-content"
          tValues={{count: slotCount, time: timeText}}
        />
      </SlotRangeEventContent>
    );
  }, []);

  const initialResources = useMemo(() => {
    return Array.from({length: numberOfResources}, (_, index) => ({
      id: `${index}`,
      title: `${tResourceName} ${index + 1}`,
      index,
    }));
  }, [numberOfResources, tResourceName]);

  const initialEvents = useMemo(() => {
    return initialCalendarEvents.map((calendarEvent) => {
      const {start, end, resource, slotType: slotTypeRef} = calendarEvent;
      const resourceId = resource.id;

      const slotTypeProps = slotTypes.find(
        (slotType) => slotType.id === slotTypeRef.id
      );

      return {
        start,
        end,
        resourceId,
        backgroundColor: slotTypeProps?.color,
        resource,
        slotType: slotTypeRef,
      };
    });
  }, [initialCalendarEvents, slotTypes]);

  const onEventResize = useCallback(
    (eventResizeDoneArg: EventResizeDoneArg) => {
      const {event, startDelta, endDelta} = eventResizeDoneArg;

      if (!isValidDate(event.start) || !isValidDate(event.end)) {
        throw new Error();
      }
      const msStartDate = event.start.getTime();
      const msEndDate = event.end.getTime();
      const msRangeDuration = msEndDate - msStartDate;

      const slotType = event.extendedProps.slotType as SlotType;
      const msSlotTypeDuration = toMilliseconds(slotType.duration);
      const slotCount = Math.floor(msRangeDuration / msSlotTypeDuration) || 1;
      const msNextRangeDuration = slotCount * msSlotTypeDuration;

      const isStartChanged = toMilliseconds(startDelta) !== 0;

      if (isStartChanged) {
        const nextStartDate = new Date(msEndDate - msNextRangeDuration);

        event.setStart(nextStartDate);
        return;
      }

      const isEndChanged = toMilliseconds(endDelta) !== 0;

      if (isEndChanged) {
        const nextEndDate = new Date(msStartDate + msNextRangeDuration);

        event.setEnd(nextEndDate);
        return;
      }
    },
    []
  );

  const onEventsChange = useCallback(
    (fcEvents: EventApi[]) => {
      const events = fcEvents.map((fcEvent) => toCalendarEvent(fcEvent));

      onCalendarEventsChange(events);
    },
    [onCalendarEventsChange]
  );

  const onDatesChange = useCallback(
    (datesSetArg: DatesSetArg) => {
      const {start} = datesSetArg;

      setDate(start);

      if (isFunction(onViewDateChange)) {
        onViewDateChange(start);
      }
    },
    [onViewDateChange, setDate]
  );

  const validRange = useMemo(() => {
    return {start: startDate, end: endDate};
  }, [startDate, endDate]);

  const onCalendarNavigationDateChange = useCallback((nextDate: Date) => {
    if (fullCalendarRef.current) {
      const fullCalendar = fullCalendarRef.current.getApi();

      fullCalendar.gotoDate(nextDate);
    }
  }, []);

  const [clipboardRef, setClipboard] = useRefState<Record<string, any>[]>([]);

  const onCopy = useCallback(() => {
    if (fullCalendarRef.current) {
      const viewDate = date;
      const fullCalendar = fullCalendarRef.current.getApi();
      const fcAllEvents = fullCalendar.getEvents();

      const fcEventsToBeCopied = fcAllEvents
        .filter((fcEvent) => {
          return isSameDate(viewDate, fcEvent.start!);
        })
        .map((fcEvent) => {
          const resourceId = getResourceIdFromEvent(fcEvent);

          return {
            ...fcEvent.toPlainObject(),
            resourceId,
          };
        });

      setClipboard(fcEventsToBeCopied);
    }
  }, [date, setClipboard]);

  const onPaste = useCallback(() => {
    if (fullCalendarRef.current) {
      const viewDate = date;
      const fullCalendar = fullCalendarRef.current.getApi();
      const fcAllEvents = fullCalendar.getEvents();

      // Do not allow pasting on days that are not empty
      const isViewDateDirty = fcAllEvents.some((fcEvent) => {
        return isSameDate(viewDate, fcEvent.start!);
      });

      if (isViewDateDirty) {
        return;
      }

      const fcCopiedEvents = clipboardRef.current;

      fcCopiedEvents.forEach((event) => {
        const fcEvent = fullCalendar.addEvent(event);

        if (fcEvent) {
          const startTime = new Date(fcEvent.start!);

          startTime.setFullYear(viewDate.getFullYear());
          startTime.setMonth(viewDate.getMonth());
          startTime.setDate(viewDate.getDate());

          fcEvent.setStart(startTime, {maintainDuration: true});
        }
      });
    }
  }, [date, clipboardRef]);

  const onClear = useCallback(async () => {
    if (await confirm(tClearConfirmMessage)) {
      if (fullCalendarRef.current) {
        const viewDate = date;
        const fullCalendar = fullCalendarRef.current.getApi();
        const fcAllEvents = fullCalendar.getEvents();

        for (const fcEvent of fcAllEvents) {
          if (isSameDate(viewDate, fcEvent.start!)) {
            fcEvent.remove();
          }
        }
      }
    }
  }, [confirm, date, tClearConfirmMessage]);

  const dateAtNoon = setLocalTime(date, {hours: 12});
  const currentProgress = getDateDifferenceInDays(startDate, dateAtNoon);
  const totalProgress = getDateDifferenceInDays(startDate, endDate);

  // Keyboard shortcuts
  useEffect(() => {
    const shortcut = {
      copy: "c",
      paste: "v",
      prevDate: "ArrowLeft",
      nextDate: "ArrowRight",
    };

    const eventName = "keydown";
    const eventHandler = (event: globalThis.KeyboardEvent) => {
      const {key} = event;

      if (key === shortcut.copy) {
        onCopy();
      }
      if (key === shortcut.paste) {
        onPaste();
      }
      if (key === shortcut.prevDate) {
        onCalendarNavigationDateChange(getPreviousDay(dateAtNoon));
      }
      if (key === shortcut.nextDate) {
        onCalendarNavigationDateChange(getNextDay(dateAtNoon));
      }
    };

    document.addEventListener(eventName, eventHandler);
    return () => {
      document.removeEventListener(eventName, eventHandler);
    };
  }, [dateAtNoon, onCalendarNavigationDateChange, onCopy, onPaste]);

  return (
    <div className={styles.root}>
      <main className={styles.main}>
        <Card>
          <div className={styles.mainCardContentLayout}>
            <div>
              <Triptych>
                <h5>
                  <DateText date={date} dateStyle={DateStyle.FULL} />
                </h5>
                <div className={styles.triptychCenterPanelContentLayout}>
                  <div>
                    <ButtonGroup>
                      <ConditionalTooltip
                        id="tooltip-copy"
                        tooltip={tTooltipCopy}
                      >
                        <Button onClick={onCopy}>
                          <CopyIcon />
                        </Button>
                      </ConditionalTooltip>
                      <ConditionalTooltip
                        id="tooltip-paste"
                        tooltip={tTooltipPaste}
                      >
                        <Button onClick={onPaste}>
                          <PasteIcon />
                        </Button>
                      </ConditionalTooltip>
                    </ButtonGroup>
                  </div>
                  <div>
                    <ConditionalTooltip
                      id="tooltip-clear"
                      tooltip={tTooltipClear}
                    >
                      <Button onClick={onClear}>
                        <TrashIcon />
                      </Button>
                    </ConditionalTooltip>
                  </div>
                </div>
                <div>
                  <span className={styles.progress}>
                    <Translation
                      tKey="schedule-wizard-step-2-progress"
                      tValues={{
                        // +1 due to zero based indexing
                        current: currentProgress + 1,
                        total: totalProgress + 1,
                      }}
                    />
                  </span>
                  <DatePaginator
                    date={dateAtNoon}
                    maxDate={endDate}
                    minDate={startDate}
                    onDateChange={onCalendarNavigationDateChange}
                    tooltipCalendar={tTooltipCalendar}
                    tooltipNext={tTooltipNext}
                    tooltipPrevious={tTooltipPrevious}
                  />
                </div>
              </Triptych>
            </div>
            <div>
              <FullCalendar
                initialDate={initialDate}
                initialEvents={initialEvents}
                initialResources={initialResources}
                initialScrollTime={initialScrollTime}
                initialView="resourceTimeGridDay"
                allDaySlot={false}
                eventOverlap={false}
                eventResizableFromStart={true}
                eventContent={eventContent}
                headerToolbar={false}
                footerToolbar={false}
                onEventResize={onEventResize}
                onDatesChange={onDatesChange}
                onEventsChange={onEventsChange}
                ref={fullCalendarRef}
                resourceOrder="index"
                snapDuration="00:05"
                validRange={validRange}
              />
            </div>
          </div>
        </Card>
      </main>
      <aside>
        <Card>
          <SlotTypeList>
            {slotTypes.map((slotType) => {
              const eventData = {
                backgroundColor: slotType.color,
                duration: toDurationObject(slotType.duration),
                title: slotType.name,
                slotType,
              };

              return (
                <FullCalendarDraggableEvent
                  key={`SlotTypeEvent#${slotType.id}`}
                  eventData={eventData}
                >
                  {(ref) => (
                    <FullCalendarEventWrapper
                      ref={ref as RefObject<HTMLDivElement>}
                      style={{backgroundColor: slotType.color}}
                    >
                      <SlotTypeEventContent slotType={slotType} />
                    </FullCalendarEventWrapper>
                  )}
                </FullCalendarDraggableEvent>
              );
            })}
          </SlotTypeList>
        </Card>
      </aside>
    </div>
  );
}

function toCalendarEvent(fcEvent: EventApi): WizardCalendarEvent {
  const {start, end, title, extendedProps} = fcEvent;

  const {slotType} = extendedProps;
  const resource = {id: getResourceIdFromEvent(fcEvent)};

  return {
    start: start as Date,
    end: end as Date,
    title,
    resource,
    slotType,
  };
}

function getResourceIdFromEvent(fcEvent: EventApi) {
  const resources = fcEvent.getResources();

  return resources[0]?.id;
}
