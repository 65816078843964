import classNames from "classnames";
import {useLocale} from "../../services/i18n";
import styles from "./date-text.module.scss";
import {DateStyle} from "./date-time-style";
import type {DateTextProps} from "./types";

export default DateText;

function DateText(props: DateTextProps) {
  const {currentLocale} = useLocale();
  const {
    className,
    date,
    dateStyle,
    locales = currentLocale.tag,
    timeZone,
  } = props;

  const rootClassNames = classNames(
    styles.root,
    {
      [styles.short]: dateStyle === DateStyle.SHORT,
    },
    className
  );

  const dateString = date.toLocaleDateString(locales, {
    dateStyle,
    timeZone,
  });

  return <span className={rootClassNames}>{dateString}</span>;
}
