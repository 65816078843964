import React, {useCallback, useState} from "react";
import {useClinicId} from "../../hooks";
import {SearchResident, SearchResidentResult} from "./search-resident";
import {SearchResults} from "./search-results";

export const SearchResidentWithResults = () => {
  const [clinicId] = useClinicId();
  const [resident, setResident] = useState<SearchResidentResult>();
  const onSuccess = useCallback(
    (result: SearchResidentResult) => setResident(result),
    []
  );

  if (!clinicId) {
    return null;
  }
  return (
    <SearchResident clinicId={clinicId} onSuccess={onSuccess}>
      {resident && <SearchResults clinicId={clinicId} resident={resident} />}
    </SearchResident>
  );
};
