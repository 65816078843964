import {RefObject, useEffect, useRef, ReactElement} from "react";
import {Draggable} from "@fullcalendar/interaction";
import {DragMetaInput} from "@fullcalendar/react";
import {isNullOrUndefined} from "../../utils";

export default FullCalendarDraggableEvent;

interface FullCalendarDraggableEventProps {
  children: (ref: RefObject<HTMLElement>) => ReactElement;
  eventData: DragMetaInput | ((el: HTMLElement) => DragMetaInput);
}

function FullCalendarDraggableEvent(props: FullCalendarDraggableEventProps) {
  const {children, eventData} = props;
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (ref.current === null) {
      return;
    }

    const draggable = new Draggable(ref.current, {eventData});

    return () => {
      if (isNullOrUndefined(draggable)) {
        return;
      }

      draggable.destroy();
    };
  }, [eventData]);

  return children(ref);
}
