import {useCallback} from "react";
import {
  setSelectedSlotIdsAction,
  useBookingAppState,
} from "../services/booking-app-state";

export default useSelectedSlotIds;

function useSelectedSlotIds() {
  const [state, dispatch] = useBookingAppState();

  const setSelectedSlotIds = useCallback(
    (slotIds: string[]) => {
      dispatch(setSelectedSlotIdsAction(slotIds));
    },
    [dispatch]
  );

  return [state.selectedSlotIds, setSelectedSlotIds] as const;
}
