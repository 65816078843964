import React, {FormEvent, useCallback} from "react";
import {FormCheck} from "react-bootstrap";
import {
  formatPhoneNumber,
  toAddressArray,
  toAddressString,
} from "../../services/api/utils";
import {Translation, useTranslation} from "../../services/i18n";
import {Clinic} from "../../services/time-book-scheduling-api";
import {isFunction} from "../../utils";
import {toDurationString, toMinutes} from "../../utils/time/duration";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  FormText,
} from "../bootstrap";

const HSA_ID_INPUT = "clinic-form-hsa-id";
const NAME_INPUT = "clinic-form-name";
const ADDRESS_INPUT = "clinic-form-address";
const PHONE_INPUT = "clinic-form-phone";
const TIME_BUFFER_INPUT = "clinic-form-external-booking-time-buffer";
const ENABLE_SELECT_SLOT_TYPE_INPUT = "clinic-form-enable-select-slot-type";

const THIRTY_DAYS_IN_MINUTES = 43200;

export type ClinicFormValue = Pick<
  Clinic,
  | "address"
  | "externalBookingStartRestriction"
  | "phone"
  | "enableSelectSlotType"
>;

interface ClinicFormProps {
  clinic: Clinic;
  onClinicSubmit: (clinic: ClinicFormValue) => void;
  onCancel?: () => void;
}

export const ClinicForm = (props: ClinicFormProps) => {
  const {clinic, onCancel, onClinicSubmit} = props;

  const [enableSelectSlotType, setEnableSelectSlotType] = React.useState(
    clinic.enableSelectSlotType
  );

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const data = new FormData(event.currentTarget);

      const commaSeparatedAddress = (data.get(ADDRESS_INPUT) as string) || "";
      const phone = (data.get(PHONE_INPUT) as string) || "";
      const timeBufferInMinutes = (data.get(TIME_BUFFER_INPUT) as string) || "";

      const address = toAddressArray(commaSeparatedAddress);
      const externalBookingStartRestriction = toDurationString({
        minutes: Number(timeBufferInMinutes) || 0,
      });

      if (onClinicSubmit) {
        onClinicSubmit({
          address,
          externalBookingStartRestriction,
          phone,
          enableSelectSlotType,
        });
      }
    },
    [onClinicSubmit, enableSelectSlotType]
  );

  const tHsaLabel = useTranslation("clinic-form-input-label-hsa");
  const tNameLabel = useTranslation("clinic-form-input-label-name");
  const tAddressLabel = useTranslation("clinic-form-input-label-address");
  const tPhoneLabel = useTranslation("clinic-form-input-label-phone");
  const tStartRestrictionLabel = useTranslation(
    "clinic-form-input-label-time-buffer"
  );
  const tEnableSelectSlotTypeLabel = useTranslation(
    "clinic-form-input-label-select-slottype-flag"
  );

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup controlId={HSA_ID_INPUT}>
        <FormLabel>{tHsaLabel}</FormLabel>
        <FormControl
          defaultValue={clinic.hsaId}
          name={HSA_ID_INPUT}
          placeholder={tHsaLabel}
          readOnly
          type="input"
        />
      </FormGroup>

      <FormGroup controlId={NAME_INPUT}>
        <FormLabel>{tNameLabel}</FormLabel>
        <FormControl
          defaultValue={clinic.name}
          name={NAME_INPUT}
          placeholder={tNameLabel}
          readOnly
          type="input"
        />
      </FormGroup>

      <FormGroup controlId={ADDRESS_INPUT}>
        <FormLabel>{tAddressLabel}</FormLabel>
        <FormControl
          defaultValue={toAddressString(clinic.address)}
          name={ADDRESS_INPUT}
          placeholder={tAddressLabel}
          type="input"
        />
      </FormGroup>

      <FormGroup controlId={PHONE_INPUT}>
        <FormLabel>{tPhoneLabel}</FormLabel>
        <FormControl
          defaultValue={formatPhoneNumber(clinic.phone)}
          name={PHONE_INPUT}
          placeholder={tPhoneLabel}
          type="input"
        />
      </FormGroup>

      <FormGroup controlId={TIME_BUFFER_INPUT}>
        <FormLabel>{tStartRestrictionLabel}</FormLabel>
        <FormControl
          defaultValue={
            clinic ? toMinutes(clinic.externalBookingStartRestriction) : ""
          }
          max={THIRTY_DAYS_IN_MINUTES}
          min="0"
          name={TIME_BUFFER_INPUT}
          placeholder={tStartRestrictionLabel}
          step="1"
          type="number"
        />
        <FormText className="text-muted">
          <Translation tKey="clinic-form-input-label-time-buffer-help" />
        </FormText>
      </FormGroup>

      <FormGroup controlId={ENABLE_SELECT_SLOT_TYPE_INPUT}>
        <FormCheck>
          <FormCheck.Input
            checked={enableSelectSlotType}
            onChange={() => {
              setEnableSelectSlotType(!enableSelectSlotType);
            }}
            type="checkbox"
          />
          <FormCheck.Label>{tEnableSelectSlotTypeLabel}</FormCheck.Label>
        </FormCheck>
        <FormText className="text-muted">
          <Translation tKey="clinic-form-input-label-select-slottype-flag-help" />
        </FormText>
      </FormGroup>

      <Button type="submit">
        <Translation tKey="clinic-form-button-save" />
      </Button>
      {isFunction(onCancel) ? (
        <Button onClick={onCancel} variant="link">
          <Translation tKey="clinic-form-button-cancel" />
        </Button>
      ) : null}
    </Form>
  );
};
