import {useQuery} from "react-query";
import {
  useResidentAppointmentQueryCreators,
  SearchResidentAppointmentsQueryVariables,
  SearchResidentAppointmentsQueryOptions,
} from "./use-resident-appointment-query-creators";

export const useSearchResidentAppointmentsQuery = (
  variables: SearchResidentAppointmentsQueryVariables,
  options?: SearchResidentAppointmentsQueryOptions
) => {
  const {createSearchResidentAppointmentsQuery} =
    useResidentAppointmentQueryCreators();

  return useQuery(createSearchResidentAppointmentsQuery(variables, options));
};
