import React, {ReactNode, useCallback} from "react";
import {DayModifiers} from "react-day-picker";
import {classNames, isDate} from "../../utils";
import {useToday} from "../../utils/react";
import {DayPicker} from "../day-picker";
import styles from "./day-range-picker.module.scss";

export type DateRange = {
  from?: Date;
  to?: Date;
};

type DateRangePickerProps = {
  className?: string;
  initialMonth?: Date;
  numberOfMonths?: number;
  onMonthChange?: (date: Date) => void;
  onSelectedRangeChange: (value: DateRange) => void;
  renderDay?: (date: Date, modifiers: DayModifiers) => ReactNode;
  selectableRange?: DateRange;
  selectedRange: DateRange;
};

export const DayRangePicker = (props: DateRangePickerProps) => {
  const {
    className,
    initialMonth,
    numberOfMonths,
    onMonthChange,
    onSelectedRangeChange,
    renderDay,
    selectableRange = {},
    selectedRange,
  } = props;
  const todayAtNoon = useToday({hours: 12});
  const disabledDays = [];

  if (selectableRange.from) {
    disabledDays.push({before: selectableRange.from});
  }
  if (selectableRange.to) {
    disabledDays.push({after: selectableRange.to});
  }

  const onDayClick = useCallback(
    (date: Date) => {
      // Initially, both dates are `undefined`.
      // 1. Set the `from` date.
      // 2. Set the `to` date, making sure the `to` date is the lager one, if
      //    not, we swap the dates.
      // 3. Third click (both `from` & `to` are dates), set the `from` date
      //    and reset the `to` date to undefined.
      const {from, to} = selectedRange;

      if (!isDate(from) || (isDate(from) && isDate(to))) {
        onSelectedRangeChange({from: date, to: undefined});
      } else {
        const newFrom = date < from ? date : from;
        const newTo = date > from ? date : from;

        onSelectedRangeChange({from: newFrom, to: newTo});
      }
    },
    [onSelectedRangeChange, selectedRange]
  );
  const {from, to} = selectedRange;

  return (
    <DayPicker
      className={classNames(styles.root, className)}
      disabledDays={disabledDays}
      initialMonth={initialMonth || todayAtNoon}
      modifiers={{start: from, end: to}}
      numberOfMonths={numberOfMonths}
      onDayClick={onDayClick}
      onMonthChange={onMonthChange}
      renderDay={renderDay}
      selectedDays={[from, {from, to}]}
      showWeekDays
      showWeekNumbers
    />
  );
};
