import {createContext} from "react";

export interface SingleChoiceContextValue<T> {
  disabled: boolean;
  name: string;
  value: T;
  isOptionDisabled(optionDisabled?: boolean): boolean;
  isOptionSelected(optionValue: T): boolean;
}

const SingleChoiceContext = createContext<SingleChoiceContextValue<any> | null>(
  null
);
SingleChoiceContext.displayName = "SingleChoiceContext";

export default SingleChoiceContext;
