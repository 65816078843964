/**
 * Create an array and fill it with whatever the map function produces.
 *
 * @param length The length of the new array
 * @param map A function that produces a list item
 *
 * @example
 * ```
 * const ordinals = createArray(3, (index) => toOrdinal(index + 1));
 * console.log(ordinals); // ['1st', '2nd', '3rd']
 * ```
 */
export const createArray = <T>(length: number, map: (index: number) => T) =>
  Array.from({length}, (_, index) => map(index));
