import {useCallback, useEffect, useMemo, useState} from "react";
import type {
  ColumnDef,
  RowSelectionState,
  OnChangeFn,
} from "@tanstack/react-table";
import classNames from "classnames";
import {Booking} from "../../services/api/bookings";
import {Translation} from "../../services/i18n";
import {
  Appointment,
  AppointmentStatus,
  Slot,
  SlotStatus,
} from "../../services/time-book-scheduling-api";
import {
  clearInterval,
  isFunction,
  isNonEmptyString,
  setInterval,
} from "../../utils";
import {toMilliseconds} from "../../utils/time/duration";
import AppointmentOrigin from "../appointment-origin";
import {AppointmentStatusQuickMutator} from "../appointment-status";
import {ClinicNote} from "../calendar/clinic-note";
import {SlotResource} from "../calendar/slot-resource";
import {DateText, TimeText} from "../date-time";
import {BooleanChoice} from "../form";
import NonBreakingText from "../non-breaking-text";
import {PersonalIdentificationNumber} from "../personal-identification-number";
import ReactTable from "../react-table";
import type {ReactTableRowPropsFactory} from "../react-table/types";
import SlotTypeNameplate from "../slot-type-nameplate";
import EventTrap, {EventType} from "../util/event-trap";
import styles from "./bookings-table.module.scss";
import {
  ALL,
  APPOINTMENT_CLINIC_NOTES,
  APPOINTMENT_ORIGIN,
  APPOINTMENT_STATUS,
  RESIDENT_NAME,
  RESIDENT_PERSONAL_IDENTITY_NUMBER,
  RESOURCE_INDEX,
  ROW_ACTIONS,
  ROW_NUMBER,
  ROW_SELECT,
  SLOT_TYPE,
  START_DATE,
  START_TIME,
} from "./column-ids";
import {AppointmentActions, RowNumber} from "./components";
import type {BookingsTableProps} from "./types";
import {
  createColumnVisibilityState,
  createRowSelectionState,
  getColumnIdKey,
} from "./utils";

export default BookingsTable;

const CLOSE_TO_NOW_LIMIT = toMilliseconds({minutes: 5});

function BookingsTable(props: BookingsTableProps) {
  const {
    activeSlotId,
    data,
    onRowClick,
    onSelectedSlotIdsChange,
    selectedSlotIds = [],
    visibleColumns = ALL,
  } = props;

  const columns: Array<ColumnDef<Booking>> = useMemo(
    () => [
      {
        id: getColumnIdKey(ROW_NUMBER),
        header: () => null,
        cell: (cellContext) => {
          const {row} = cellContext;

          return <RowNumber value={row.index + 1} />;
        },
      },
      {
        id: getColumnIdKey(ROW_SELECT),
        header: (headerContext) => {
          const {table} = headerContext;

          const indeterminate = table.getIsSomeRowsSelected();
          const value = table.getIsAllRowsSelected();
          const onValueChange = (value: boolean) => {
            table.toggleAllRowsSelected(value);
          };

          return (
            <BooleanChoice
              indeterminate={indeterminate}
              value={value}
              onValueChange={onValueChange}
            />
          );
        },
        cell: (cellContext) => {
          const {row} = cellContext;

          const value = row.getIsSelected();
          const onValueChange = (value: boolean) => {
            row.toggleSelected(value);
          };

          return (
            <EventTrap eventTypes={[EventType.CLICK]}>
              <BooleanChoice value={value} onValueChange={onValueChange} />
            </EventTrap>
          );
        },
      },
      {
        id: getColumnIdKey(START_DATE),
        accessorFn: (booking) => {
          return booking.slot.start;
        },
        header: () => <Translation tKey="bookings-table-header-date" />,
        cell: (cellContext) => {
          const {cell} = cellContext;

          const value = cell.getValue<string>();
          const date = new Date(value);

          return <DateText date={date} dateStyle="short" />;
        },
      },
      {
        id: getColumnIdKey(START_TIME),
        accessorFn: (booking) => {
          return booking.slot.start;
        },
        header: () => <Translation tKey="bookings-table-header-time" />,
        cell: (cellContext) => {
          const {cell} = cellContext;

          const value = cell.getValue<string>();
          const date = new Date(value);

          return <TimeText date={date} timeStyle="short" />;
        },
      },
      {
        id: getColumnIdKey(SLOT_TYPE),
        accessorFn: (booking) => {
          return booking.slot.slotType.id;
        },
        header: () => <Translation tKey="bookings-table-header-slot-type" />,
        cell: (cellContext) => {
          const {row} = cellContext;

          const {slot, slotType} = row.original;

          return (
            <SlotTypeNameplate
              active={hasAppointment(slot)}
              slotType={slotType}
            />
          );
        },
      },
      {
        id: getColumnIdKey(RESIDENT_PERSONAL_IDENTITY_NUMBER),
        accessorFn: (booking) => {
          return booking.resident?.personalIdentityNumber;
        },
        header: () => (
          <Translation tKey="bookings-table-header-personal-identity-number" />
        ),
        cell: (cellContext) => {
          const {cell} = cellContext;

          const personalIdentityNumber = cell.getValue<string | undefined>();

          return isNonEmptyString(personalIdentityNumber) ? (
            <PersonalIdentificationNumber value={personalIdentityNumber} />
          ) : null;
        },
      },
      {
        id: getColumnIdKey(RESIDENT_NAME),
        accessorFn: (booking) => {
          return booking.resident?.name;
        },
        header: () => (
          <Translation tKey="bookings-table-header-resident-name" />
        ),
      },
      {
        id: getColumnIdKey(RESOURCE_INDEX),
        accessorFn: (booking) => {
          return booking.slot.resourceIndex;
        },
        header: () => <Translation tKey="bookings-table-header-resource" />,
        cell: (cellContext) => {
          const {cell} = cellContext;
          const index = cell.getValue<number>();

          return (
            <NonBreakingText>
              <SlotResource index={index} />
            </NonBreakingText>
          );
        },
      },
      {
        id: getColumnIdKey(APPOINTMENT_ORIGIN),
        accessorFn: (booking) => {
          return booking.appointment?.origin;
        },
        header: () => (
          <Translation tKey="bookings-table-header-appointment-origin" />
        ),
        cell: (cellContext) => {
          const {row} = cellContext;

          const {appointment} = row.original;

          return appointment != null ? (
            <AppointmentOrigin appointment={appointment} />
          ) : null;
        },
      },
      {
        id: getColumnIdKey(APPOINTMENT_CLINIC_NOTES),
        accessorFn: (booking) => {
          return booking.appointment?.clinicNotes;
        },
        header: () => (
          <Translation tKey="bookings-table-header-appointment-clinic-note" />
        ),
        cell: (cellContext) => {
          const {cell, row} = cellContext;

          const clinicNotes = cell.getValue<string | undefined>();
          const {appointment} = row.original;

          return isNonEmptyString(clinicNotes) && appointment != null ? (
            <ClinicNote id={appointment.id} note={clinicNotes} />
          ) : null;
        },
      },
      {
        id: getColumnIdKey(APPOINTMENT_STATUS),
        accessorFn: (booking) => {
          return booking.appointment?.status;
        },
        header: () => (
          <Translation tKey="bookings-table-header-appointment-status" />
        ),
        cell: (cellContext) => {
          const {row} = cellContext;

          const {appointment} = row.original;

          return appointment != null ? (
            <EventTrap eventTypes={[EventType.CLICK]}>
              <AppointmentStatusQuickMutator appointment={appointment} />
            </EventTrap>
          ) : null;
        },
      },
      {
        id: getColumnIdKey(ROW_ACTIONS),
        header: () => null,
        cell: (cellContext) => {
          const {row} = cellContext;

          const {appointment} = row.original;

          return appointment != null && isBooked(appointment) ? (
            <EventTrap eventTypes={[EventType.CLICK]}>
              <AppointmentActions appointment={appointment} />
            </EventTrap>
          ) : null;
        },
      },
    ],
    []
  );

  const isActive = useCallback(
    (slot: Slot) => {
      return slot.id === activeSlotId;
    },
    [activeSlotId]
  );

  const dateNow = useDateNow();

  const isCloseToNow = useCallback(
    (slot: Slot) => {
      const startTime = new Date(slot.start).valueOf();

      return Math.abs(dateNow - startTime) < CLOSE_TO_NOW_LIMIT;
    },
    [dateNow]
  );

  const isSelected = useCallback(
    (slot: Slot) => {
      return selectedSlotIds.includes(slot.id);
    },
    [selectedSlotIds]
  );

  const createRowProps: ReactTableRowPropsFactory<Booking> = useCallback(
    (row) => {
      const {slot} = row.original;

      const className = classNames(styles.bodyRow, {
        [styles.isActive]: isActive(slot),
        [styles.isBlocked]: isBlocked(slot),
        [styles.isCloseToNow]: isCloseToNow(slot),
        [styles.isSelected]: isSelected(slot),
      });

      const onClick = () => {
        onRowClick?.(row);
      };

      return {
        className,
        onClick,
      };
    },
    [isActive, isCloseToNow, isSelected, onRowClick]
  );

  const getRowId = useCallback((originalRow: Booking) => {
    return originalRow.slot.id;
  }, []);

  const columnVisibility = useMemo(
    () => createColumnVisibilityState(visibleColumns),
    [visibleColumns]
  );
  const rowSelection = useMemo(
    () => createRowSelectionState(selectedSlotIds),
    [selectedSlotIds]
  );

  const onRowSelectionChange: OnChangeFn<RowSelectionState> = useCallback(
    (getRowSelection) => {
      if (isFunction(onSelectedSlotIdsChange) && isFunction(getRowSelection)) {
        const newRowSelection = getRowSelection(rowSelection);

        onSelectedSlotIdsChange(Object.keys(newRowSelection));
      }
    },
    [onSelectedSlotIdsChange, rowSelection]
  );

  return (
    <ReactTable<Booking>
      className={styles.root}
      options={{
        columns,
        data,
        getRowId,
        initialState: {columnVisibility},
        onRowSelectionChange,
        state: {rowSelection},
      }}
      rowProps={createRowProps}
    />
  );
}

function hasAppointment(slot: Slot) {
  return slot.appointment != null;
}

function isBlocked(slot: Slot) {
  return slot.status === SlotStatus.BLOCKED;
}

function isBooked(appointment: Appointment) {
  return appointment.status === AppointmentStatus.BOOKED;
}

function useDateNow(updateInterval: number = toMilliseconds({minutes: 5})) {
  const [dateNow, setDateNow] = useState(Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDateNow(Date.now());
    }, updateInterval);

    return () => {
      clearInterval(intervalId);
    };
  }, [updateInterval]);

  return dateNow;
}
