import React, {useCallback, useState} from "react";
import {Translation} from "../../services/i18n";
import {Appointment, Resident} from "../../services/time-book-scheduling-api";
import {trim} from "../../utils";
import {Button} from "../bootstrap";
import {CalendarData} from "../calendar";
import {BookingModal} from "./booking-modal";
import {BookingModalTitle} from "./booking-modal-title";
import {useScheduleBookingMutation} from "./use-schedule-booking-mutation";

type ScheduleBookingModalProps = {
  clinicId: string;
  onFailure?: (error: Error) => void;
  onSuccess?: (appointment: Appointment) => void;
  onHide: () => void;
  resident: Resident;
  show: boolean;
};

export const ScheduleBookingModal = (props: ScheduleBookingModalProps) => {
  const {clinicId, onFailure, onSuccess, onHide, resident, show} = props;
  const scheduleMutation = useScheduleBookingMutation();
  const [clinicNotes, setClinicNotes] = useState("");

  const schedule = useCallback(
    (calendarData: CalendarData) => {
      const {slot} = calendarData;

      scheduleMutation
        .mutateAsync({clinicId, clinicNotes: trim(clinicNotes), resident, slot})
        .then(
          (appointment) => {
            onSuccess?.(appointment);
            setClinicNotes("");
          },
          (error) => {
            onFailure?.(error);
          }
        );
    },
    [clinicId, clinicNotes, onFailure, onSuccess, resident, scheduleMutation]
  );

  const title = (
    <BookingModalTitle resident={resident}>
      <Translation tKey="booking-modal-schedule-title" />
    </BookingModalTitle>
  );

  const renderSlotTableActions = useCallback(
    (calendarData: CalendarData) => (
      <Button onClick={() => schedule(calendarData)}>
        <Translation tKey="booking-modal-schedule-cta" />
      </Button>
    ),
    [schedule]
  );

  return (
    <BookingModal
      clinicId={clinicId}
      clinicNotes={clinicNotes}
      isLoading={scheduleMutation.isLoading}
      onClinicNotesChange={setClinicNotes}
      onHide={onHide}
      renderSlotTableActions={renderSlotTableActions}
      show={show}
      title={title}
    />
  );
};
