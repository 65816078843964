import {Action, createActionCreator} from "../../utils/action";

export const CLINIC_ID_SET = "CLINIC_ID_SET";
export const BOOKING_DATE_SET = "BOOKING_DATE_SET";
export const MAIN_NAVIGATION_COLLAPSED = "MAIN_NAVIGATION_COLLAPSED";

export type BookingDateAction = Action<Date, typeof BOOKING_DATE_SET>;
export type ClinicIdAction = Action<string, typeof CLINIC_ID_SET>;
export type MainNavigationCollapsedAction = Action<
  boolean,
  typeof MAIN_NAVIGATION_COLLAPSED
>;

export const setBookingDateAction =
  createActionCreator<BookingDateAction>(BOOKING_DATE_SET);

export const setClinicIdAction =
  createActionCreator<ClinicIdAction>(CLINIC_ID_SET);

export const setMainNavigationCollapsedAction =
  createActionCreator<MainNavigationCollapsedAction>(MAIN_NAVIGATION_COLLAPSED);
