import {useMutation, useQueryClient} from "react-query";
import {Clinic, UpdateClinicInput} from "../../../time-book-scheduling-api";
import useApi from "../../use-api";
import {
  createClinicDetailsKey,
  createClinicsListKey,
} from "../query-key-creators";

export default useUpdateClinicMutation;

interface UpdateClinicMutationProps {
  clinicId: string;
  clinicVersion: string;
}

interface UpdateClinicMutationVariables {
  input: UpdateClinicInput;
}

function useUpdateClinicMutation(props: UpdateClinicMutationProps) {
  const api = useApi();
  const queryClient = useQueryClient();

  const {clinicId, clinicVersion} = props;

  return useMutation<Clinic, Error, UpdateClinicMutationVariables>(
    (variables) => {
      const {input} = variables;

      return api
        .updateClinic({clinicId, clinicVersion}, input)
        .then((clinicData) => Clinic.fromJSON(clinicData));
    },
    {
      onSuccess(/*clinic*/) {
        void queryClient.invalidateQueries(createClinicsListKey());
        void queryClient.invalidateQueries(createClinicDetailsKey(clinicId));
      },
    }
  );
}
