import {I18nProvider, I18nSource} from "../services/i18n";
import {Locale} from "../services/i18n/locale";
import {enGB, svSE} from "../services/i18n/locales";
import {fetchTranslations} from "../services/i18n/translations";
import {InternalProviderProps} from "./types/internal-provider";

export {InternalI18nProvider};

const config = {
  availableLocales: [enGB, svSE],
  defaultLocale: svSE,
  fallbackLocale: svSE,
  resolver: async (locale: Locale): Promise<I18nSource> => {
    return fetchTranslations(locale.languageCode);
  },
};

function InternalI18nProvider(props: InternalProviderProps) {
  return <I18nProvider config={config}>{props.children}</I18nProvider>;
}
