import React, {forwardRef, useCallback, useEffect, useRef} from "react";
import {isFunction} from "../../../utils";
import {useCombinedRefs} from "../../../utils/react";

interface BooleanChoiceProps {
  className?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  name?: string;
  value?: boolean;
  onValueChange?: (value: boolean) => void;
}

const BooleanChoice = forwardRef<HTMLInputElement, BooleanChoiceProps>(
  (props, outerRef) => {
    const {
      className,
      disabled,
      indeterminate = false,
      name,
      value = false,
      onValueChange,
    } = props;

    const innerRef = useRef<HTMLInputElement>(null);
    const ref = useCombinedRefs<HTMLInputElement>(outerRef, innerRef);

    useEffect(() => {
      if (innerRef.current) {
        innerRef.current.indeterminate = indeterminate;
      }
    }, [indeterminate]);

    const onChange = useCallback(
      (event) => {
        if (isFunction(onValueChange)) {
          onValueChange(event.target.checked);
        }
      },
      [onValueChange]
    );

    return (
      <input
        checked={value}
        className={className}
        disabled={disabled}
        name={name}
        onChange={onChange}
        ref={ref}
        type="checkbox"
        value={String(value)}
      />
    );
  }
);

export default BooleanChoice;
