import React from "react";
import {Nav, NavItem, NavLink} from "../../components/bootstrap";
import {Card} from "../../components/card";
import {Loading} from "../../components/loading";
import {LoadingError} from "../../components/loading-error";
import {useGetSlotTypesQuery} from "../../services/api/slot-types";
import {Translation} from "../../services/i18n";
import {generatePathToSlotTypes, RouteOperation} from "../../services/routing";
import {SlotTypesTable} from "./components/slot-types-table";
import styles from "./list-slot-types-route.module.scss";

interface ListSlotTypesRouteProps {
  clinicId: string;
}

export const ListSlotTypesRoute = (props: ListSlotTypesRouteProps) => {
  const {clinicId} = props;

  const getSlotTypesQuery = useGetSlotTypesQuery({clinicId});

  return (
    <Card className={styles.card}>
      <Nav
        className={styles.nav}
        defaultActiveKey={generatePathToSlotTypes({
          clinicId,
          operation: RouteOperation.NEW,
        })}
        variant="pills"
      >
        <NavItem>
          <NavLink
            to={generatePathToSlotTypes({
              clinicId,
              operation: RouteOperation.NEW,
            })}
          >
            <Translation tKey="list-slot-types-create-new" />
          </NavLink>
        </NavItem>
      </Nav>
      {getSlotTypesQuery.isLoading ? <Loading /> : null}
      {getSlotTypesQuery.isError ? (
        <LoadingError error={getSlotTypesQuery.error} />
      ) : null}
      {getSlotTypesQuery.isSuccess ? (
        <SlotTypesTable slotTypes={getSlotTypesQuery.data} />
      ) : null}
    </Card>
  );
};
