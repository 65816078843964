import {FormEvent, useCallback} from "react";
import {
  Button,
  Form,
  FormCheckbox,
  FormControl,
  FormGroup,
  FormLabel,
  Select,
} from "../../../components/bootstrap";
import {Translation, useTranslation} from "../../../services/i18n";
import {
  SlotType,
  SlotTypeVisibility,
} from "../../../services/time-book-scheduling-api";
import {isFunction, isNonEmptyString} from "../../../utils";
import {toDurationString, toMinutes} from "../../../utils/time/duration";
import {ColorSelector} from "./color-selector";
import styles from "./slot-type-form.module.scss";

const colors = [
  "#41586c",
  "#577590",
  "#43aa8b",
  "#90be3d",
  "#f9c74f",
  "#f8961e",
  "#f3722c",
  "#f94144",
  "#bb3133",
  "#9d9d9d",
];

const NAME_INPUT = "slot-type-form-name";
const DURATION_INPUT = "slot-type-form-duration";
const COLOR_INPUT = "slot-type-form-color";
const VISIBILITY_INPUT = "slot-type-form-visibility";

export type SlotTypeFormValue = Pick<
  SlotType,
  "color" | "duration" | "name" | "visibility"
>;

interface SlotTypeFormProps {
  slotType?: SlotTypeFormValue;
  onSlotTypeSubmit: (slotTypeFormValue: SlotTypeFormValue) => void;
  onCancel?: () => void;
}

export const SlotTypeForm = (props: SlotTypeFormProps) => {
  const {slotType, onSlotTypeSubmit, onCancel} = props;

  const tSlotTypeVisibilityOptionLabelResident = useTranslation(
    "slot-type-form-visibility-option-resident"
  );
  const tSlotTypeVisibilityOptionLabelExternal = useTranslation(
    "slot-type-form-visibility-option-external"
  );

  // prettier-ignore
  const durationOptions = [
    {label: useTranslation("slot-type-form-duration-option", {minutes: 5}), value: "5"},
    {label: useTranslation("slot-type-form-duration-option", {minutes: 10}), value: "10"},
    {label: useTranslation("slot-type-form-duration-option", {minutes: 15}), value: "15"},
    {label: useTranslation("slot-type-form-duration-option", {minutes: 20}), value: "20"},
    {label: useTranslation("slot-type-form-duration-option", {minutes: 25}), value: "25"},
    {label: useTranslation("slot-type-form-duration-option", {minutes: 30}), value: "30"},
    {label: useTranslation("slot-type-form-duration-option", {minutes: 35}), value: "35"},
    {label: useTranslation("slot-type-form-duration-option", {minutes: 40}), value: "40"},
    {label: useTranslation("slot-type-form-duration-option", {minutes: 45}), value: "45"},
    {label: useTranslation("slot-type-form-duration-option", {minutes: 50}), value: "50"},
    {label: useTranslation("slot-type-form-duration-option", {minutes: 55}), value: "55"},
  ];

  const selectedColor = slotType?.color ?? colors[0];
  const durationInMinutes = isNonEmptyString(slotType?.duration)
    ? toMinutes(slotType!.duration)
    : undefined;
  const visibility = slotType?.visibility ?? SlotTypeVisibility.NONE;

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const formData = new FormData(event.target as HTMLFormElement);

      const name = formData.get(NAME_INPUT) as string;
      const durationValue = formData.get(DURATION_INPUT) as string;
      const color = formData.get(COLOR_INPUT) as string;
      const visibilityValue = formData.getAll(VISIBILITY_INPUT);

      const visibility = visibilityValue.reduce(
        (previousValue, currentValue) => {
          return previousValue + Number(currentValue);
        },
        SlotTypeVisibility.INTERNAL_CLINIC
      );
      const durationInMinutes = Number.parseInt(durationValue);
      const duration = Number.isFinite(durationInMinutes)
        ? toDurationString({minutes: durationInMinutes})
        : "";

      onSlotTypeSubmit({
        name,
        duration,
        color,
        visibility,
      });
    },
    [onSlotTypeSubmit]
  );

  const isChecked = useCallback(
    (value: SlotTypeVisibility) => {
      return (visibility & value) !== 0;
    },
    [visibility]
  );

  return (
    <div className={styles.root}>
      <Form onSubmit={onSubmit}>
        <FormGroup controlId={NAME_INPUT}>
          <FormLabel>
            <Translation tKey="slot-type-form-input-label-name" />
          </FormLabel>
          <FormControl
            autoFocus
            defaultValue={slotType?.name}
            name={NAME_INPUT}
            required
            type="input"
          />
        </FormGroup>

        <FormGroup
          controlId={DURATION_INPUT}
          data-testid="slot-type-form-duration"
        >
          <FormLabel>
            <Translation tKey="slot-type-form-input-label-duration" />
          </FormLabel>
          <Select
            defaultValue={durationInMinutes}
            name={DURATION_INPUT}
            options={durationOptions}
          />
        </FormGroup>

        <FormGroup
          controlId={COLOR_INPUT}
          data-testid="slot-type-form-color-selector"
        >
          <FormLabel>
            <Translation tKey="slot-type-form-input-label-color" />
          </FormLabel>
          <ColorSelector
            colors={colors}
            defaultColor={selectedColor}
            name={COLOR_INPUT}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>
            <Translation tKey="slot-type-form-input-label-visibility" />
          </FormLabel>
          <FormCheckbox
            data-testid="slot-type-form-resident-visible"
            defaultChecked={isChecked(SlotTypeVisibility.RESIDENT_PORTAL)}
            id={`${VISIBILITY_INPUT}-${SlotTypeVisibility.RESIDENT_PORTAL}`}
            label={tSlotTypeVisibilityOptionLabelResident}
            name={VISIBILITY_INPUT}
            value={SlotTypeVisibility.RESIDENT_PORTAL}
          />
          <FormCheckbox
            data-testid="slot-type-form-external-clinic-visible"
            defaultChecked={isChecked(SlotTypeVisibility.EXTERNAL_CLINIC)}
            id={`${VISIBILITY_INPUT}-${SlotTypeVisibility.EXTERNAL_CLINIC}`}
            label={tSlotTypeVisibilityOptionLabelExternal}
            name={VISIBILITY_INPUT}
            value={SlotTypeVisibility.EXTERNAL_CLINIC}
          />
        </FormGroup>

        <Button type="submit">
          <Translation tKey="slot-type-form-button-save" />
        </Button>
        {isFunction(onCancel) ? (
          <Button onClick={onCancel} variant="link">
            <Translation tKey="clinic-form-button-cancel" />
          </Button>
        ) : null}
      </Form>
    </div>
  );
};
