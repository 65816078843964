import React, {ComponentType} from "react";
import classNames from "classnames";
import {LocationDescriptor} from "history";
import {Nav, NavItem, NavLink} from "../bootstrap";
import {IconProps} from "../icon";
import styles from "./navigation.module.scss";

interface NavigationProps {
  items: NavigationItemProps[];
}

const Navigation = (props: NavigationProps) => {
  const {items} = props;
  const classes = classNames("flex-column", styles.root);

  return (
    <Nav className={classes}>
      {items.map((navigationItemProps) => {
        return (
          <NavigationItem
            key={navigationItemProps.text}
            {...navigationItemProps}
          />
        );
      })}
    </Nav>
  );
};

export {Navigation, NavigationItem};

export interface NavigationItemProps {
  children?: NavigationItemProps[];
  exact?: boolean;
  Icon?: ComponentType<IconProps>;
  text: string;
  to?: LocationDescriptor;
}

function NavigationItem(props: NavigationItemProps) {
  const {children, exact, Icon, text, to} = props;

  if (to !== undefined) {
    return (
      <NavItem key={text}>
        <NavLink className={styles.link} exact={exact} to={to}>
          {Icon && <Icon fixedWidth size="lg" />}
          <span className="nav-item-text" data-testid="navigation">
            {text}{" "}
          </span>
        </NavLink>
        {children ? <Navigation items={children} /> : null}
      </NavItem>
    );
  }

  return (
    <NavItem className={styles.heading} key={text}>
      {Icon && <Icon fixedWidth size="lg" />}
      <span className="nav-item-text">{text}</span>
    </NavItem>
  );
}
