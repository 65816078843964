import {useContext} from "react";
import AppStateContext from "./app-state-context";

export default useAppState;

class UseAppStateContextTypeError extends TypeError {
  constructor() {
    super("Cannot access AppState context outside AppStateProvider");
  }
}

function useAppState() {
  const context = useContext(AppStateContext);

  if (context == null) {
    throw new UseAppStateContextTypeError();
  }

  return context;
}
