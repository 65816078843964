export type ScrollBehavior = "auto" | "smooth";

export type ScrollToOptions = {
  behavior?: ScrollBehavior;
  left?: number;
  top?: number;
};

export type TimerHandler = () => void;

export const clearInterval = (id: number) => window.clearInterval(id);
export const clearTimeout = (id: number) => window.clearTimeout(id);
export const getWindowLocalStorage = () => window.localStorage;
export const getWindowSessionStorage = () => window.sessionStorage;
export const getWindowCrypto = () => window.crypto;
export const getWindowNavigator = () => window.navigator;
export const matchMedia = (query: string) => window.matchMedia(query);
export const printWindow = () => window.print();
export const scrollTo = (options: ScrollToOptions) => window.scrollTo(options);
export const setInterval = (handler: TimerHandler, interval = 0) =>
  window.setInterval(handler, interval);
export const setTimeout = (handler: TimerHandler, delay = 0) =>
  window.setTimeout(handler, delay);
export const reloadWindow = () => window.location.reload();
