import {useCallback} from "react";
import {
  useAppState,
  setMainNavigationCollapsedAction,
} from "../services/app-state";

export const useMainNavigationCollapsed = () => {
  const [appState, dispatch] = useAppState();
  const setMainNavigationCollapsed = useCallback(
    (isMainNavigationCollapsed: boolean) => {
      dispatch(setMainNavigationCollapsedAction(isMainNavigationCollapsed));
    },
    [dispatch]
  );

  return [
    appState.isMainNavigationCollapsed,
    setMainNavigationCollapsed,
  ] as const;
};
