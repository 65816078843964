import {ReactNode} from "react";
import styles from "./non-breaking-text.module.scss";

export default NonBreakingText;

interface NonBreakingTextProps {
  children: ReactNode;
}

function NonBreakingText(props: NonBreakingTextProps) {
  const {children} = props;

  return <span className={styles.root}>{children}</span>;
}
