import {UseQueryResult} from "react-query";

export default getCompoundError;

function getCompoundError(queryResults: readonly UseQueryResult[]) {
  const queryResultWithError = queryResults.find(
    (queryResult) => queryResult.error !== null
  );

  return queryResultWithError?.error;
}
