import {useEffect} from "react";
import invariant from "invariant";
import {
  Redirect,
  Route,
  Switch,
  generatePath,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {isNonEmptyString, isValidDate, toLocalDate} from "../../utils";
import {isValidISODateString} from "../../utils/date-time";
import BookingAppMain from "./components/booking-app-main";
import {useBookingDate, useClinicId, useRouteDate} from "./hooks";

export default BookingAppRoot;

function BookingAppRoot() {
  const routeMatch = useRouteMatch();

  return (
    <Switch>
      <Route path={`${routeMatch.path}/:date`}>
        <BookingAppMainRoute />
      </Route>
      <Route>
        <RedirectToBookingAppMainRoute />
      </Route>
    </Switch>
  );
}

function RedirectToBookingAppMainRoute() {
  const routeMatch = useRouteMatch();

  const date = new Date().toLocaleDateString("sv-SE");
  const locationPath = generatePath(`${routeMatch.url}/:date`, {
    date,
  });

  return <Redirect to={locationPath} />;
}

interface BookingAppMainRouteParams {
  date: string;
}

function BookingAppMainRoute() {
  const params = useParams<BookingAppMainRouteParams>();
  const [bookingDate, setBookingDate] = useBookingDate();
  const [clinicId] = useClinicId();
  const [, setRouteDate] = useRouteDate();

  useEffect(() => {
    setBookingDate(toLocalDate(params.date));
    setRouteDate(params.date);
  }, [params.date, setBookingDate, setRouteDate]);

  invariant(
    isNonEmptyString(params.date),
    "Required date param missing in BookingAppMainRoute."
  );
  invariant(
    isValidISODateString(params.date),
    `${params.date} is not a valid ISO date in BookingAppMainRoute.`
  );
  invariant(
    isValidDate(bookingDate),
    "Created date in state is invalid in BookingAppMainRoute."
  );

  return <BookingAppMain clinicId={clinicId} date={bookingDate} />;
}
