import {useCallback, useMemo} from "react";
import {Permission} from "./permission";
import {getPermissionsForClaims} from "./utils";

export default useAuthService;

function useAuthService(claims: string[]) {
  const currentPermissions = useMemo(
    () => getPermissionsForClaims(claims),
    [claims]
  );

  const isAuthorizedForPermission = useCallback(
    (requiredPermission: Permission) => {
      return currentPermissions.includes(requiredPermission);
    },
    [currentPermissions]
  );

  return useMemo(
    () => ({isAuthorizedForPermission}),
    [isAuthorizedForPermission]
  );
}
