import React, {FormEvent, useCallback} from "react";
import classNames from "classnames";
import {Booking} from "../../../../services/api/bookings";
import {Translation} from "../../../../services/i18n";
import {isAppointmentStatus} from "../../../../services/time-book-scheduling-api";
import {Select, Button} from "../../../bootstrap";
import {useFilter} from "../../hooks/filter";
import {
  BookingAppFilter,
  isSlotStatus,
} from "../../services/booking-app-filter";
import styles from "./booking-app-filter-control-panel.module.scss";
import useAppointmentStatusOptions from "./use-appointment-status-options";
import useResourceOptions from "./use-resource-options";
import useSlotStatusOptions from "./use-slot-status-options";
import useSlotTypeOptions from "./use-slot-type-options";

export default BookingAppFilterPanel;

interface BookingAppFilterPanelProps {
  data?: Booking[];
  loading?: boolean;
}

function BookingAppFilterPanel(props: BookingAppFilterPanelProps) {
  const {data = [], loading} = props;

  const [filter, filterActions] = useFilter();
  const {resetFilter, setFilterValue} = filterActions;

  const appointmentStatusOptions = useAppointmentStatusOptions();
  const resourceOptions = useResourceOptions(data);
  const slotTypeOptions = useSlotTypeOptions();
  const slotStatusOptions = useSlotStatusOptions();

  const onReset = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      resetFilter();
    },
    [resetFilter]
  );

  const onSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  }, []);

  const onAppointmentStatusChange = useCallback(
    (appointmentStatus: string) => {
      if (isAppointmentStatus(appointmentStatus)) {
        setFilterValue("appointmentStatus", appointmentStatus);
      }

      if (appointmentStatus === "") {
        setFilterValue("appointmentStatus", undefined);
      }
    },
    [setFilterValue]
  );

  const onResourceChange = useCallback(
    (resource: string) => {
      setFilterValue("resource", resource);
    },
    [setFilterValue]
  );

  const onSlotTypeChange = useCallback(
    (slotType: string) => {
      setFilterValue("slotType", slotType);
    },
    [setFilterValue]
  );

  const onSlotStatusChange = useCallback(
    (slotStatus: string) => {
      if (isSlotStatus(slotStatus)) {
        setFilterValue("slotStatus", slotStatus);
      }

      if (slotStatus === "") {
        setFilterValue("slotStatus", undefined);
      }
    },
    [setFilterValue]
  );

  const {
    appointmentStatus = "",
    resource = "",
    slotType = "",
    slotStatus = "",
  } = filter;

  const rootClassName = classNames(styles.root, {[styles.loading]: loading});

  return (
    <form className={rootClassName} onReset={onReset} onSubmit={onSubmit}>
      <div>
        <label>
          <Translation tKey={"calendar-slot-type-option"} />
        </label>
        <Select
          className={styles.select}
          dataTestid="calendar-event-filters-slot-type"
          onValueChange={onSlotTypeChange}
          options={slotTypeOptions}
          value={slotType}
        />
      </div>

      <div>
        <label>
          <Translation tKey={"calendar-resource-filter-option"} />
        </label>
        <Select
          className={styles.select}
          dataTestid="calendar-event-filters-resource"
          onValueChange={onResourceChange}
          options={resourceOptions}
          value={resource}
        />
      </div>

      <div>
        <label>
          <Translation tKey={"calendar-appointment-status-option"} />
        </label>
        <Select
          className={styles.select}
          dataTestid="calendar-event-filters-appointment-status"
          onValueChange={onAppointmentStatusChange}
          options={appointmentStatusOptions}
          value={appointmentStatus}
        />
      </div>

      <div>
        <label>
          <Translation tKey={"calendar-slot-status-option"} />
        </label>
        <Select
          className={styles.select}
          dataTestid="calendar-event-filters-slot-status"
          onValueChange={onSlotStatusChange}
          options={slotStatusOptions}
          value={slotStatus}
        />
      </div>

      <div className={styles.resetButton}>
        <Button
          className={styles.resetButton}
          data-testid="calendar-event-filters-reset-btn"
          disabled={!isFilterResettable(filter)}
          type="reset"
          variant="link"
        >
          <Translation tKey="calendar-filters-reset" />
        </Button>
      </div>
    </form>
  );
}

function isFilterResettable(filter: BookingAppFilter) {
  return Object.values(filter).some((value) => value != null);
}
