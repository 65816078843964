import {useCallback, useMemo} from "react";
import {AppointmentStatus} from "../../../../services/time-book-scheduling-api";
import {
  BookingAppFilter,
  BookingAppFilterInit,
  BookingType,
  resetFilterAction,
  setAppointmentStatusAction,
  setBookingTypeAction,
  setFilterAction,
  setResourceAction,
  setSlotStatusAction,
  setSlotTypeAction,
  useBookingAppFilter,
} from "../../services/booking-app-filter";

export default useFilter;

type SetFilterValueArgs =
  | [
      keyof Pick<BookingAppFilter, "appointmentStatus">,
      AppointmentStatus | undefined
    ]
  | [keyof Pick<BookingAppFilter, "bookingType">, BookingType | undefined]
  | [
      keyof Omit<BookingAppFilter, "appointmentStatus" | "bookingType">,
      string | undefined
    ];

function useFilter() {
  const [state, dispatch] = useBookingAppFilter();

  const resetFilter = useCallback(
    (filter?: BookingAppFilterInit) => {
      dispatch(resetFilterAction(filter));
    },
    [dispatch]
  );

  const setFilter = useCallback(
    (filter: BookingAppFilter) => {
      dispatch(setFilterAction(filter));
    },
    [dispatch]
  );

  const setFilterValue = useCallback(
    (...args: SetFilterValueArgs) => {
      // TODO: Revisit once TS is upgraded to 4.6 that supports destructuring
      switch (args[0]) {
        case "appointmentStatus":
          dispatch(setAppointmentStatusAction(args[1]));
          break;
        case "bookingType":
          dispatch(setBookingTypeAction(args[1]));
          break;
        case "resource":
          dispatch(setResourceAction(args[1]));
          break;
        case "slotType":
          dispatch(setSlotTypeAction(args[1]));
          break;
        case "slotStatus":
          dispatch(setSlotStatusAction(args[1]));
          break;
        default:
          throw new TypeError(`Unknown filter key: ${args[0]}`);
      }
    },
    [dispatch]
  );

  const actions = useMemo(
    () => ({
      resetFilter,
      setFilter,
      setFilterValue,
    }),
    [resetFilter, setFilter, setFilterValue]
  );

  return [state, actions] as const;
}
