import {useCallback} from "react";
import {useAppState, setBookingDateAction} from "../services/app-state";

export default useBookingDate;

function useBookingDate() {
  const [appState, dispatch] = useAppState();

  const setBookingDate = useCallback(
    (bookingDate: Date) => {
      dispatch(setBookingDateAction(bookingDate));
    },
    [dispatch]
  );

  return [appState.bookingDate, setBookingDate] as const;
}
