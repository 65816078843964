import styles from "./row-number.module.scss";

export default RowNumber;

interface RowNumberProps {
  value: number;
}

function RowNumber(props: RowNumberProps) {
  return <span className={styles.root}>{props.value}</span>;
}
