import {useCallback} from "react";
import {useHistory} from "react-router-dom";
import {Permission} from "../../services/auth";
import {Translation} from "../../services/i18n";
import {
  generatePathToClinicMutation,
  RouteOperation,
} from "../../services/routing";
import {Clinic} from "../../services/time-book-scheduling-api";
import {toMinutes} from "../../utils/time/duration";
import {Address} from "../address";
import {Authorization} from "../authorization";
import {Button} from "../bootstrap";
import {Card} from "../card";
import PhoneNumber from "../phone-number";

export default ClinicDetails;

interface ClinicDetailsProps {
  className?: string;
  clinic: Clinic;
}

function ClinicDetails(props: ClinicDetailsProps) {
  const {className, clinic} = props;
  const history = useHistory();

  const onEditButtonClick = useCallback(() => {
    history.push(
      generatePathToClinicMutation({
        clinicId: clinic.id,
        operation: RouteOperation.EDIT,
      })
    );
  }, [clinic.id, history]);

  return (
    <Card>
      <div className={className}>
        <dl>
          <dt>
            <Translation tKey="clinic-form-input-label-hsa" />
          </dt>
          <dd>{clinic.hsaId}</dd>
          <dt>
            <Translation tKey="clinic-form-input-label-name" />
          </dt>
          <dd>{clinic.name}</dd>
          <dt>
            <Translation tKey="clinic-form-input-label-address" />
          </dt>
          <dd>
            <Address value={clinic.address} />
          </dd>
          <dt>
            <Translation tKey="clinic-form-input-label-phone" />
          </dt>
          <dd>
            <PhoneNumber value={clinic.phone} />
          </dd>
          <dt>
            <Translation tKey="clinic-form-input-label-time-buffer" />
          </dt>
          <dd>{toMinutes(clinic.externalBookingStartRestriction)}</dd>
          <dt>
            <Translation tKey="clinic-form-input-label-select-slottype-flag" />
          </dt>
          <dd>
            {clinic.enableSelectSlotType ? (
              <Translation tKey="slot-type-visible-for-residents-true" />
            ) : (
              <Translation tKey="slot-type-visible-for-residents-false" />
            )}
          </dd>
        </dl>

        <Authorization requiredPermission={Permission.ADMINISTRATE}>
          <Button onClick={onEditButtonClick} variant="link">
            <Translation tKey="clinic-details-button-edit" />
          </Button>
        </Authorization>
      </div>
    </Card>
  );
}
