import {Locale} from "./locale";

export const svSE: Locale = Object.freeze({
  languageCode: "sv",
  languageName: "Svenska",
  languageNameInEnglish: "Swedish",
  regionCode: "SE",
  regionName: "Sverige",
  regionNameInEnglish: "Sweden",
  tag: "sv-SE",
});

export const enGB: Locale = Object.freeze({
  languageCode: "en",
  languageName: "English",
  languageNameInEnglish: "English",
  regionCode: "GB",
  regionName: "Great Britain",
  regionNameInEnglish: "Great Britain",
  tag: "en-GB",
});
