import React, {ReactNode} from "react";
import {Card} from "../card";
import styles from "./centered-card-layout.module.scss";

export type CenteredCardLayoutProps = {
  children: ReactNode;
};

export const CenteredCardLayout = (props: CenteredCardLayoutProps) => {
  const {children} = props;

  return (
    <div className={styles.root}>
      <Card className={styles.card}>{children}</Card>
    </div>
  );
};
