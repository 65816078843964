/* eslint-disable import/order */
import React, {forwardRef, RefObject, useCallback} from "react";
import ReactFullCalendar, {
  CalendarApi,
  CalendarOptions,
  CustomContentGenerator,
  DatesSetArg,
  Duration,
  DurationInput,
  EventApi,
  EventClickArg,
  EventContentArg,
} from "@fullcalendar/react";
import svLocale from "@fullcalendar/core/locales/sv";
import interactionPlugin, {EventResizeDoneArg} from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import timeGridPlugin from "@fullcalendar/timegrid";
import {isFunction} from "../../utils";

export type {
  CalendarApi,
  CustomContentGenerator,
  DatesSetArg,
  EventApi,
  EventClickArg,
  EventContentArg,
  EventResizeDoneArg,
};

export type FullCalendarRefObject = RefObject<ReactFullCalendar>;

// Remove renamed props
type CustomCalendarOptions = Omit<
  CalendarOptions,
  "enabled" | "eventClick" | "eventsSet" | "scrollTime"
>;

interface FullCalendarProps extends CustomCalendarOptions {
  initialScrollTime?: DurationInput | Duration | null;
  disabled?: boolean;
  onDatesChange?: (arg: DatesSetArg) => void;
  onEventClick?: (arg: EventClickArg) => void;
  onEventResize?: (arg: EventResizeDoneArg) => void;
  onEventsChange?: (events: EventApi[]) => void;
}

export const FullCalendar = forwardRef<ReactFullCalendar, FullCalendarProps>(
  function CustomFullCalendar(props, ref) {
    const {
      initialDate = new Date(),
      initialEvents,
      initialResources,
      initialScrollTime,
      initialView = "timeGridWeek",
      datesAboveResources = true,
      disabled = false,
      onDatesChange,
      onEventClick,
      onEventResize,
      onEventsChange,
      ...otherProps
    } = props;

    const onDatesSet = useCallback(
      (datesSetArg: DatesSetArg) => {
        if (isFunction(onDatesChange)) {
          onDatesChange(datesSetArg);
        }
      },
      [onDatesChange]
    );

    const onEventsSet = useCallback(
      (events: EventApi[]) => {
        if (isFunction(onEventsChange)) {
          onEventsChange(events);
        }
      },
      [onEventsChange]
    );

    return (
      <ReactFullCalendar
        //Valid 2023-09-14 - 2024-09-13
        schedulerLicenseKey="0725669990-fcs-1694605529"
        // Can't be changed after mount
        initialDate={initialDate}
        initialEvents={initialEvents}
        initialResources={initialResources}
        initialView={initialView}
        scrollTime={initialScrollTime}
        datesAboveResources={datesAboveResources}
        editable={!disabled}
        locale={svLocale}
        datesSet={onDatesSet}
        eventClick={onEventClick}
        eventResize={onEventResize}
        eventsSet={onEventsSet}
        slotDuration={{minutes: 10}}
        slotLabelInterval={{minutes: 60}}
        // How the left most column should show time.
        // Current config produces 06:00, 14:30 etc.
        slotLabelFormat={{
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
          omitZeroMinute: false,
        }}
        // props that can be set
        weekends={true}
        businessHours={false}
        {...otherProps}
        plugins={[
          interactionPlugin,
          timeGridPlugin,
          resourceTimeGridPlugin,
          resourceTimelinePlugin,
        ]}
        ref={ref}
      />
    );
  }
);

export default FullCalendar;

/**
 * FullCalendar Event Object
 * @see https://fullcalendar.io/docs/event-object
 */
export type EventObject<ExtendedProps extends object> = {
  id: string;
  end: Date;
  extendedProps: ExtendedProps;
  start: Date;
  title: string;
};
