import {Fragment, ReactNode, useMemo, useState} from "react";
import {useGetSlotTypesQuery} from "../../services/api/slot-types/hooks";
import {Translation} from "../../services/i18n";
import {
  addDays,
  classNames,
  isNonEmptyString,
  isUndefined,
  resetLocalTime,
} from "../../utils";
import {useToday} from "../../utils/react";
import {Required} from "../../utils/types";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalProps,
  Option,
  Select,
  Table,
} from "../bootstrap";
import {BookingData, CalendarData} from "../calendar";
import {ClinicNotesInput} from "../calendar/calendar-event-details/clinic-notes-input";
import {SlotInfoDetails} from "../calendar/calendar-event-details/slot-info-details";
import {DatePaginator} from "../calendar/date-paginator";
import {SlotResource} from "../calendar/slot-resource";
import {CALENDAR_EVENT_TYPE_FILTER_OPTION_ALL} from "../calendar/use-calendar-event-type-filter-options";
import {
  SLOT_TYPE_FILTER_OPTION_ALL,
  useSlotTypeFilterOptions,
} from "../calendar/use-slot-type-filter-options";
import {DateStyle, DateText, TimeStyle, TimeText} from "../date-time";
import {Loading} from "../loading";
import {LoadingError} from "../loading-error";
import SlotTypeNameplate from "../slot-type-nameplate";
import {TodayButton} from "../today-button";
import styles from "./booking-modal.module.scss";
import {useBookAppointmentQuery} from "./use-book-appointment-query";

export {BookingModal};

type SlotTableActionsRenderer = (calendarData: CalendarData) => ReactNode;

type BookingModalProps = Required<ModalProps, "onHide" | "show"> & {
  clinicId: string;
  clinicNotes?: string;
  existingBooking?: BookingData;
  isLoading?: boolean;
  onClinicNotesChange: (value: string) => void;
  renderSlotTableActions: SlotTableActionsRenderer;
  title: ReactNode;
};

function BookingModal(props: BookingModalProps) {
  const {
    className,
    clinicId,
    clinicNotes = "",
    existingBooking,
    isLoading = false,
    onClinicNotesChange,
    onHide,
    renderSlotTableActions,
    show,
    title,
    ...otherModalProps
  } = props;

  const [selectedDate, setSelectedDate] = useState(
    existingBooking ? new Date(existingBooking?.slot.start) : new Date()
  );
  const [slotTypeFilter, setSlotTypeFilter] = useState(
    CALENDAR_EVENT_TYPE_FILTER_OPTION_ALL
  );

  const [start, end] = useMemo(() => {
    const now = new Date();
    const date = resetLocalTime(selectedDate);

    // You cannot reschedule to a passed date time.
    if (date < now) {
      return [now, resetLocalTime(addDays(now, 1))];
    }
    return [date, addDays(date, 1)];
  }, [selectedDate]);

  const slotTypesQuery = useGetSlotTypesQuery({clinicId});
  const calendarDataQuery = useBookAppointmentQuery({clinicId, start, end});
  const slotTypeOptions = useSlotTypeFilterOptions(slotTypesQuery.data ?? []);

  const filteredCalendarData = useMemo(
    () => filterCalendarData(calendarDataQuery.data ?? [], {slotTypeFilter}),
    [calendarDataQuery.data, slotTypeFilter]
  );

  const isShowNoSlotsMessage =
    !calendarDataQuery.isLoading && filteredCalendarData.length === 0;
  const isShowSlotsTable =
    !calendarDataQuery.isLoading && filteredCalendarData.length > 0;

  return show ? (
    <Modal
      backdrop="static"
      centered
      className={classNames(styles.root, className)}
      keyboard={false}
      onHide={onHide}
      show={true}
      size="lg"
      {...otherModalProps}
    >
      <ModalHeader closeButton>{title}</ModalHeader>
      <ModalBody>
        {!isUndefined(existingBooking) && (
          <SlotInfoDetails calendarData={existingBooking} />
        )}

        <ClinicNotesInput
          disabled={isLoading}
          name="CLINIC_NOTES"
          onValueChange={onClinicNotesChange}
          value={clinicNotes}
        />

        <div className={styles.container}>
          <OverlayLoading isLoading={isLoading} />

          <SlotTableControls
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setSlotTypeFilter={setSlotTypeFilter}
            slotTypeFilter={slotTypeFilter}
            slotTypeOptions={slotTypeOptions}
          />

          <div className={styles.tableWrapper}>
            <LoadingError error={calendarDataQuery.error} />
            {calendarDataQuery.isLoading && <Loading />}

            {isShowNoSlotsMessage && (
              <NoSlotsMessage slotTypeFilter={slotTypeFilter} />
            )}
            {isShowSlotsTable && (
              <SlotsTable
                calendarData={filteredCalendarData}
                className={styles.slotsTable}
                renderActions={renderSlotTableActions}
              />
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  ) : null;
}

interface SlotsTableProps {
  calendarData: CalendarData[];
  className?: string;
  renderActions: SlotTableActionsRenderer;
}

function SlotsTable(props: SlotsTableProps) {
  const {calendarData, className, renderActions} = props;

  return (
    <Table className={classNames(styles.table, className)}>
      <tbody>
        {calendarData.map((item, index) => {
          const {slot, slotType} = item;
          const startTime = new Date(slot.start);

          return (
            <tr key={slot.id}>
              <td className={styles.index}>{index + 1}</td>
              <td className={styles.time}>
                <TimeText date={startTime} timeStyle={TimeStyle.SHORT} />
              </td>
              <td>
                <SlotTypeNameplate slotType={slotType} />
              </td>
              <td>
                <SlotResource index={slot.resourceIndex} />
              </td>
              <td className={styles.actions}>{renderActions(item)}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

interface NoSlotsMessageProps {
  slotTypeFilter: string;
}

function NoSlotsMessage(props: NoSlotsMessageProps) {
  const {slotTypeFilter} = props;

  return (
    <div className={styles.noSlots}>
      <Translation tKey="booking-modal-no-slots" />
      {isSlotTypeFilterSet(slotTypeFilter) && (
        <div className={styles.isSlotTypeFilterSet}>
          <Translation tKey="booking-modal-filter-set" />
        </div>
      )}
    </div>
  );
}

interface SlotTableControlsProps {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  setSlotTypeFilter: (slotTypeId: string) => void;
  slotTypeFilter: string;
  slotTypeOptions: Option<string>[];
}

function SlotTableControls(props: SlotTableControlsProps) {
  const {
    selectedDate,
    setSelectedDate,
    setSlotTypeFilter,
    slotTypeFilter,
    slotTypeOptions,
  } = props;
  const todayAtNoon = useToday({hours: 12});

  return (
    <div className={styles.tableNavigation}>
      <h5 className={styles.selectedDate}>
        <DateText date={selectedDate} dateStyle={DateStyle.FULL} />
      </h5>
      <div>
        <Select
          options={slotTypeOptions}
          onValueChange={setSlotTypeFilter}
          value={slotTypeFilter}
        />
      </div>
      <TodayButton date={selectedDate} setDate={setSelectedDate} />
      <DatePaginator
        date={selectedDate}
        minDate={todayAtNoon}
        onDateChange={setSelectedDate}
      />
    </div>
  );
}

interface OverlayLoadingProps {
  isLoading: boolean;
}

function OverlayLoading(props: OverlayLoadingProps) {
  const {isLoading} = props;

  if (!isLoading) {
    return null;
  }

  return (
    <Fragment>
      <div className={styles.overlay} />
      <div className={styles.loading}>
        <Loading />
      </div>
    </Fragment>
  );
}

interface CalendarEventFilterValues {
  slotTypeFilter?: string;
}

function filterCalendarData(
  calendarDataItems: CalendarData[],
  filter: CalendarEventFilterValues
) {
  const {slotTypeFilter} = filter;

  if (!isSlotTypeFilterSet(slotTypeFilter)) {
    return calendarDataItems;
  }

  return calendarDataItems.filter(
    ({slotType}) => slotType.id === slotTypeFilter
  );
}

function isSlotTypeFilterSet(slotTypeFilter?: string) {
  return (
    isNonEmptyString(slotTypeFilter) &&
    slotTypeFilter !== SLOT_TYPE_FILTER_OPTION_ALL
  );
}
