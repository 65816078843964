import type {AppointmentOrigin} from "./appointment-origin";
import type {AppointmentStatus} from "./appointment-status";
import EntityReference from "./entity-reference";

interface AppointmentInit {
  id?: string;
  clinic: EntityReference;
  clinicNotes?: string;
  resident: EntityReference;
  slot: EntityReference;
  status?: AppointmentStatus;
  origin?: AppointmentOrigin;
  version?: string;
}

export interface AppointmentJSON {
  id: string;
  clinic: EntityReference;
  clinic_notes: null | string;
  resident: EntityReference;
  slot: EntityReference;
  status: AppointmentStatus;
  origin: AppointmentOrigin;
  version: null | string;
}

export default class Appointment {
  id: string;
  clinic: EntityReference;
  clinicNotes: string;
  resident: EntityReference;
  slot: EntityReference;
  status: AppointmentStatus;
  origin: AppointmentOrigin;
  version: string;

  constructor({
    id,
    clinic,
    clinicNotes = "",
    resident,
    slot,
    status,
    origin,
    version,
  }: AppointmentInit) {
    Object.assign(this, {
      id,
      clinic,
      clinicNotes,
      resident,
      slot,
      status,
      origin,
      version,
    });
  }

  static fromJSON(json: AppointmentJSON) {
    const {
      id,
      clinic,
      clinic_notes: clinicNotes,
      resident,
      slot,
      status,
      origin,
      version,
    } = json;

    return new Appointment({
      id,
      clinic,
      clinicNotes: clinicNotes ?? "",
      resident,
      slot,
      status,
      origin,
      version: version ?? "",
    });
  }

  toJSON() {
    const {
      id,
      clinic,
      clinicNotes: clinic_notes,
      resident,
      slot,
      status,
      origin,
      version,
    } = this;

    return {id, clinic, clinic_notes, resident, slot, status, origin, version};
  }
}
