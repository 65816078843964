import {Booking} from "../../../../services/api/bookings";
import {useTranslation} from "../../../../services/i18n";
import {Slot} from "../../../../services/time-book-scheduling-api";
import {interpolate, unique} from "../../../../utils";
import {createOption} from "./utils";

export default useResourceOptions;

function useResourceOptions(bookings: Booking[] = []) {
  const tAll = useTranslation("calendar-resource-filter-option-all");
  const tResource = useTranslation("slot-resource");

  const slots = selectSlotsFromBookings(bookings);
  const resources = selectUniqueResourcesFromSlots(slots);

  return [
    createOption(tAll, ""),
    ...resources.map((resource) =>
      createOption(
        interpolate(tResource, {number: Number(resource) + 1}),
        resource
      )
    ),
  ];
}

function selectSlotsFromBookings(bookings: Booking[]) {
  return bookings.map((booking) => booking.slot);
}

function selectUniqueResourcesFromSlots(slots: Slot[] = []) {
  const allIndices = slots.map((slot) => slot.resourceIndex);

  return unique(allIndices)
    .sort()
    .map((resourceIndex) => String(resourceIndex));
}
