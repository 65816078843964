import {useCallback} from "react";
import {usePatchAppointmentMutation} from "../../../services/api/appointments";
import {Translation, useTranslation} from "../../../services/i18n";
import {
  Appointment,
  //AppointmentStatus,
} from "../../../services/time-book-scheduling-api";
//import {createReplaceOperation} from "../../../utils/json-patch";
import {useConfirm} from "../../dialogs";
import ActionButton from "../../form/action-button";
import styles from "./appointment-actions.module.scss";

export default AppointmentActions;

interface AppointmentActionsProps {
  appointment: Appointment;
}

function AppointmentActions(props: AppointmentActionsProps) {
  const {appointment} = props;

  const confirm = useConfirm();
  const confirmMessage = useTranslation("generic-cancel-appointment-confirm");

  const patchAppointmentMutation = usePatchAppointmentMutation({
    appointmentId: appointment.id,
    appointmentVersion: appointment.version,
    clinicId: appointment.clinic.id,
  });

  const onCancelAppointmentClick = useCallback(async () => {
    if (await confirm(confirmMessage)) {
      //const patch = [createReplaceOperation("/status", AppointmentStatus.CANCELLED)];
      //console.log("patchAppointmentMutation.mutate:", patch);
      //patchAppointmentMutation.mutate({input: patch})
    }
  }, [confirm, confirmMessage]);

  const onRescheduleAppointmentClick = useCallback(async () => {
    //console.log("Implement Reschedule appointment");
  }, []);

  const isMutating = patchAppointmentMutation.isLoading;

  return (
    <div className={styles.root}>
      <ActionButton
        data-testid="reschedule-appointment-button"
        disabled={isMutating}
        loading={false}
        onClick={onRescheduleAppointmentClick}
        size="xs"
        variant="primary"
      >
        <Translation tKey="call-to-action-reschedule-appointment" />
      </ActionButton>
      <ActionButton
        data-testid="cancel-appointment-button"
        disabled={isMutating}
        loading={false}
        onClick={onCancelAppointmentClick}
        size="xs"
        variant="danger"
      >
        <Translation tKey="call-to-action-cancel-appointment" />
      </ActionButton>
    </div>
  );
}
