import {useMemo} from "react";
import {UseQueryOptions} from "react-query";
import {Appointment} from "../../../time-book-scheduling-api";
import {
  Filter as ApiSearchFilter,
  ComparisonOperator,
  LogicalOperator,
} from "../../search-query";
import {useSearchResidentAppointmentsQuery} from "../resident-appointments/use-search-resident-appointments-query";

export default useGetResidentAppointmentsQuery;

interface GetResidentAppointmentsQueryVariables {
  clinicId: string;
  residentId: string;
}

type GetResidentAppointmentsQueryOptions = UseQueryOptions<
  Appointment[],
  Error
>;

function useGetResidentAppointmentsQuery(
  variables: GetResidentAppointmentsQueryVariables,
  options?: GetResidentAppointmentsQueryOptions
) {
  const {clinicId, residentId} = variables;
  const filter = useMemo(() => {
    return createGetResidentAppointmentsQueryFilter(residentId);
  }, [residentId]);

  return useSearchResidentAppointmentsQuery(
    {clinicId, residentId, filter},
    options
  );
}

function createGetResidentAppointmentsQueryFilter(
  residentId: string
): ApiSearchFilter {
  return {
    conditions: [
      {
        operator: ComparisonOperator.EQUAL,
        property: "resident.id",
        value: residentId,
      },
    ],
    operator: LogicalOperator.AND,
  };
}
