import {useCallback} from "react";
import {
  setRouteUrlAction,
  useBookingAppState,
} from "../services/booking-app-state";

export default useRouteUrl;

function useRouteUrl() {
  const [state, dispatch] = useBookingAppState();

  const setRouteUrl = useCallback(
    (routeUrl: string) => {
      dispatch(setRouteUrlAction(routeUrl));
    },
    [dispatch]
  );

  return [state.routeUrl, setRouteUrl] as const;
}
