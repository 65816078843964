import React from "react";
import {Button, ButtonGroup} from "../../components/bootstrap";
import {Clinic} from "../../services/time-book-scheduling-api";

export default ClinicSelector;

interface ClinicSelectorProps {
  clinics: Clinic[];
  onClinicClick: (clinic: Clinic) => void;
}

function ClinicSelector(props: ClinicSelectorProps) {
  const {clinics, onClinicClick} = props;

  return (
    <ButtonGroup vertical>
      {clinics.map((clinic) => {
        return (
          <Button
            block
            key={clinic.id}
            onClick={() => onClinicClick(clinic)}
            variant="primary"
          >
            {clinic.name}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}
