import {
  DetailedHTMLProps,
  TdHTMLAttributes,
  useCallback,
  useState,
} from "react";
import {Address} from "../../../components/address";
import {ScheduleBookingModal} from "../../../components/book-appointment-modal";
import {Button, Table} from "../../../components/bootstrap";
import {Email} from "../../../components/email";
import {PersonalIdentificationNumber} from "../../../components/personal-identification-number";
import PhoneNumber from "../../../components/phone-number";
import {useAppState} from "../../../services/app-state";
import {Translation} from "../../../services/i18n";
import {Resident} from "../../../services/time-book-scheduling-api";
import {classNames} from "../../../utils";
import styles from "./resident-details.module.scss";

export default ResidentDetails;

interface ResidentDetailsProps {
  className?: string;
  resident: Resident;
}

function ResidentDetails(props: ResidentDetailsProps) {
  const {className, resident} = props;
  const [isSchedule, setIsSchedule] = useState(false);
  const [appState] = useAppState();

  const onScheduleOpen = useCallback(() => {
    setIsSchedule(true);
  }, []);

  const onScheduleClose = useCallback(() => {
    setIsSchedule(false);
  }, []);

  if (!appState.clinicId) {
    throw new Error("ResidentDetails requires clinicId");
  }
  return (
    <div className={classNames(styles.root, className)}>
      <Table borderless className={styles.table} hover={false} size="sm">
        <tbody>
          {/* PIN | Name */}
          <tr>
            <th>
              <Translation tKey="resident-form-input-label-pin" />
            </th>
            <th>
              <Translation tKey="resident-form-input-label-name" />
            </th>
          </tr>
          <tr>
            <Td>
              <PersonalIdentificationNumber
                value={resident.personalIdentityNumber}
              />
            </Td>
            <Td>{resident.name}</Td>
          </tr>

          {/* Phone | Email */}
          <tr>
            <th>
              <Translation tKey="resident-form-input-label-phone" />
            </th>
            <th>
              <Translation tKey="resident-form-input-label-email" />
            </th>
          </tr>
          <tr>
            <Td>
              <PhoneNumber value={resident.phone} />
            </Td>
            <Td className={styles.empty}>
              <Email value={resident.email} />
            </Td>
          </tr>

          {/* Address */}
          <tr>
            <th colSpan={2}>
              <Translation tKey="resident-form-input-label-address" />
            </th>
          </tr>
          <tr>
            <Td colSpan={2}>
              <Address value={resident.address} />
            </Td>
          </tr>
        </tbody>
      </Table>

      <div className={styles.actions}>
        <Button onClick={onScheduleOpen}>
          <Translation tKey="resident-details-new-booking" />
        </Button>

        {isSchedule ? (
          <ScheduleBookingModal
            clinicId={appState.clinicId}
            onHide={onScheduleClose}
            onSuccess={onScheduleClose}
            resident={resident}
            show={isSchedule}
          />
        ) : null}
      </div>
    </div>
  );
}

type TdProps = DetailedHTMLProps<
  TdHTMLAttributes<HTMLTableCellElement>,
  HTMLTableCellElement
>;

function Td(props: TdProps) {
  const {children, ...otherProps} = props;

  return children ? (
    <td {...otherProps}>{children}</td>
  ) : (
    <td {...otherProps}>&nbsp;</td>
  );
}
