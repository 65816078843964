import {ChangeEvent, useCallback} from "react";
import {Form as OriginalForm, FormProps} from "react-bootstrap";
import {isFunction} from "../../../utils";

export {Form, Select};

function Form(props: FormProps) {
  return <OriginalForm autoComplete="off" {...props} />;
}

export interface Option<Value extends string = string> {
  label: string;
  value: Value | undefined;
}

const FormControl = OriginalForm.Control;

interface SelectProps {
  className?: string;
  defaultValue?: any;
  id?: string;
  name?: string;
  options: Option[];
  onValueChange?: (value: string) => void;
  value?: string;
  dataTestid?: string;
}

function Select(props: SelectProps) {
  const {
    className,
    defaultValue,
    id,
    name,
    onValueChange,
    options,
    value,
    dataTestid,
  } = props;

  const onChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const {value} = event.target;

      if (isFunction(onValueChange)) {
        onValueChange(value);
      }
    },
    [onValueChange]
  );

  const isControlled = isFunction(onValueChange);

  const formControlProps = isControlled ? {onChange, value} : {defaultValue};

  return (
    <FormControl
      as="select"
      className={className}
      custom
      id={id}
      name={name}
      data-testid={dataTestid}
      {...formControlProps}
    >
      {options.map(({label, value}) => (
        <option key={`${label}_${value}`} value={value}>
          {label}
        </option>
      ))}
    </FormControl>
  );
}
