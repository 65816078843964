interface SessionUserInit {
  hsaId: string;
  firstName: string;
  lastName: string;
  email: string;
}

export default class SessionUser {
  hsaId: string;
  firstName: string;
  lastName: string;
  email: string;

  constructor({hsaId, firstName, lastName, email}: SessionUserInit) {
    Object.assign(this, {hsaId, firstName, lastName, email});
  }
}
