import {useCallback, useMemo} from "react";
import {UseQueryOptions} from "react-query";
import {isNull} from "../../../../utils";
import {Resident} from "../../../time-book-scheduling-api";
import {Filter as ApiSearchFilter} from "../../search-query";
import useApi from "../../use-api";
import {
  createResidentDetailsKey,
  createResidentsSearchKey,
} from "../query-key-creators";

export default useResidentQueryCreators;

export interface GetResidentQueryVariables {
  residentId: string;
}

export type GetResidentQueryOptions = UseQueryOptions<Resident, Error>;

export interface SearchResidentsQueryVariables {
  filter: ApiSearchFilter | null;
}

export type SearchResidentsQueryOptions<TData = Resident[]> = UseQueryOptions<
  Resident[],
  Error,
  TData
>;

function useResidentQueryCreators() {
  const api = useApi();

  const createGetResidentQuery = useCallback(
    (
      variables: GetResidentQueryVariables,
      options: GetResidentQueryOptions = {}
    ) => {
      const {residentId} = variables;
      const {queryKey = createResidentDetailsKey(residentId), ...otherOptions} =
        options;

      return {
        ...otherOptions,
        queryKey,
        queryFn() {
          return api
            .getResident({residentId})
            .then((residentData) => Resident.fromJSON(residentData));
        },
      };
    },
    [api]
  );

  const createSearchResidentsQuery = useCallback(
    <TData>(
      variables: SearchResidentsQueryVariables,
      options: SearchResidentsQueryOptions<TData> = {}
    ) => {
      const {filter} = variables;
      const {queryKey = createResidentsSearchKey(filter), ...otherOptions} =
        options;

      return {
        ...otherOptions,
        queryKey,
        queryFn() {
          return isNull(filter)
            ? Promise.reject(new TypeError("Provided `filter` is `null`"))
            : api
                .searchResidents({filter})
                .then(({data}) => data.map((item) => Resident.fromJSON(item)));
        },
      };
    },
    [api]
  );

  return useMemo(
    () => ({
      createGetResidentQuery,
      createSearchResidentsQuery,
    }),
    [createGetResidentQuery, createSearchResidentsQuery]
  );
}
