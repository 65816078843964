import {HTTPStatusCode} from "../http/types";

export default class HTTPError extends Error {
  status: HTTPStatusCode;

  constructor(message: string, status: HTTPStatusCode) {
    super(message);

    this.status = status;

    // Restore prototype chain
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
