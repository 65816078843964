import {isNonEmptyString, splitAtIndices} from "../../../utils";

export {formatPhoneNumber};

const INTERNATIONAL_PREFIX = "00";
const COUNTRY_CODE = "46";
const NATIONAL_PREFIX = "0";

/**
 * Formats Swedish phone numbers
 */
function formatPhoneNumber(value: string | null | undefined) {
  if (!isNonEmptyString(value)) {
    return "";
  }
  // Remove any non-digit characters
  let number = value.replace(/\D/g, "");

  // International prefix is 2 digits long (00) -- but not required!
  // Country code is 2 digits long (46) -- but not required!
  // Destination code is 2-4 digits long incl national prefix (ig. 08, 040, 0141)
  // Subscriber number is 5-8 digits long
  // So minimum length is 7 and maximum length is 16
  const MIN_LENGTH = 7;
  const MAX_LENGTH = 16;
  const isTooShort = number.length < MIN_LENGTH;
  const isTooLong = number.length > MAX_LENGTH;

  if (isTooShort || isTooLong) {
    return value;
  }

  // Figure out if prefixed with international prefix and country code.
  // Store the prefix until later and the remove it.
  let prefix = "";

  if (number.startsWith(COUNTRY_CODE)) {
    prefix = `+${COUNTRY_CODE}`;
    number = number.substring(COUNTRY_CODE.length);
  }

  const ZERO_ZERO_FORTY_SIX = INTERNATIONAL_PREFIX + COUNTRY_CODE;

  if (number.startsWith(ZERO_ZERO_FORTY_SIX)) {
    prefix = ZERO_ZERO_FORTY_SIX;
    number = number.substring(ZERO_ZERO_FORTY_SIX.length);
  }

  // Normalize number to support both '073123456789' and '+4673123456789' (the
  // national prefix, '0' is variable).
  if (number.startsWith(NATIONAL_PREFIX)) {
    number = number.substring(1);
  }

  // Split into destination code and subscriber number
  const index = resolveDestinationCodeLength(number);
  const [destinationCode, subscriberNumber] = splitAtIndices(number, index);

  // Format
  const formattedSubscriberNumber = formatSubscriberNumber(subscriberNumber);
  const formattedPhoneNumber = `${destinationCode}-${formattedSubscriberNumber}`;

  // Add prefix (if any) otherwise just the '0'
  return prefix
    ? `${prefix} ${formattedPhoneNumber}`
    : `${NATIONAL_PREFIX}${formattedPhoneNumber}`;
}

// If the subscriber numbers is even length, return number in groups of two.
// If odd, make the first group three and the rest in groups of two.
// Max length of a subscriber number is 8.
function formatSubscriberNumber(value: string) {
  const isEven = value.length % 2 === 0;

  return isEven
    ? splitAtIndices(value, 2, 4, 6).join(" ")
    : splitAtIndices(value, 3, 5).join(" ");
}

// Without leading national prefix '0'
function hasTwoDigitDestinationCode(value: string) {
  return /^8/.test(value);
}

// Without leading national prefix '0'
function hasThreeDigitDestinationCode(value: string) {
  return /^(?:10|11|13|16|18|19|20|21|23|26|31|33|35|36|40|42|44|46|54|60|63|70|72|73|74|76|77|78|79|90)/.test(
    value
  );
}

// Without leading national prefix '0'
function resolveDestinationCodeLength(value: string) {
  if (hasTwoDigitDestinationCode(value)) {
    return 1;
  }
  if (hasThreeDigitDestinationCode(value)) {
    return 2;
  }
  return 3;
}
