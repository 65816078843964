import React, {useCallback} from "react";
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {Translation} from "../../../../services/i18n";
import {useFilter} from "../../hooks/filter";
import {isBookingType} from "../../services/booking-app-filter";
import styles from "./booking-app-filter-control-panel.module.scss";

export default BookingAppButtonPanel;

const BOOKING_TYPE_INPUT = "BOOKING_TYPE_INPUT";

function BookingAppButtonPanel() {
  const [filter, filterActions] = useFilter();
  const {setFilterValue} = filterActions;

  const onBookingTypeChange = useCallback(
    (bookingType: string) => {
      if (isBookingType(bookingType)) {
        setFilterValue("bookingType", bookingType);
      }

      if (bookingType === "") {
        setFilterValue("bookingType", undefined);
      }
    },
    [setFilterValue]
  );

  const {bookingType = ""} = filter;

  return (
    <div>
      <ToggleButtonGroup
        name={BOOKING_TYPE_INPUT}
        onChange={onBookingTypeChange}
        type="radio"
        value={bookingType}
      >
        <ToggleButton className={styles.toggleButton} value="">
          <Translation tKey="calendar-event-type-option-all" />
        </ToggleButton>
        <ToggleButton className={styles.toggleButton} value="AVEC_APPOINTMENT">
          <Translation tKey="calendar-event-type-option-appointment" />
        </ToggleButton>
        <ToggleButton className={styles.toggleButton} value="SANS_APPOINTMENT">
          <Translation tKey="calendar-event-type-option-slot" />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
