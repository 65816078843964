import {isFiniteNumber} from "./is-number";
import {isString} from "./is-string";

export type InterpolationTemplate = string;

export type InterpolationValues = {
  [key: string]: number | string | undefined;
};

export const interpolate = (
  template: InterpolationTemplate,
  values?: InterpolationValues
) => {
  if (!template || !values) {
    return template;
  }
  // Replace eg. `Hello {name}!` with value in `values['name']`
  return template.replace(/\{\s*(\w+)\s*\}/g, (match, key) => {
    const value = values[key];

    if (isFiniteNumber(value) || isString(value)) {
      return String(value);
    }
    return match;
  });
};
