import {useContext} from "react";
import ApiClientContext from "./api-client-context";

export default useApiClient;

function useApiClient() {
  const apiClient = useContext(ApiClientContext);

  if (apiClient === null) {
    throw new Error("useApiClient must be used within a ApiClientProvider");
  }

  return apiClient;
}
