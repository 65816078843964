import {useContext} from "react";
import BookingAppFilterContext from "./booking-app-filter-context";

export default useBookingAppFilter;

class UseBookingAppFilterContextTypeError extends TypeError {
  constructor() {
    super(
      "Cannot access BookingAppFilter context outside BookingAppFilterProvider"
    );
  }
}

function useBookingAppFilter() {
  const context = useContext(BookingAppFilterContext);

  if (context === null) {
    throw new UseBookingAppFilterContextTypeError();
  }

  return context;
}
