import {useQuery} from "react-query";
import useClinicQueryCreators, {
  GetClinicsQueryOptions,
  GetClinicsQueryVariables,
} from "./use-clinic-query-creators";

export default useGetClinicsQuery;

function useGetClinicsQuery(
  variables?: GetClinicsQueryVariables,
  options?: GetClinicsQueryOptions
) {
  const {createGetClinicsQuery} = useClinicQueryCreators();

  return useQuery(createGetClinicsQuery(variables, options));
}
