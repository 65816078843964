import {ReactNode} from "react";
import {BookingAppFilterProvider} from "./services/booking-app-filter";
import {
  BookingAppStateInit,
  BookingAppStateProvider,
} from "./services/booking-app-state";

export default BookingAppShell;

interface BookingAppShellProps {
  children: ReactNode;
  initialState: BookingAppStateInit;
}

function BookingAppShell(props: BookingAppShellProps) {
  const {children, initialState} = props;

  return (
    <BookingAppStateProvider initialState={initialState}>
      <BookingAppFilterProvider>{children}</BookingAppFilterProvider>
    </BookingAppStateProvider>
  );
}
