import {useMutation, useQueryClient} from "react-query";
import {Queue} from "../../../../utils";
import {
  ApiError,
  Appointment,
  PatchAppointmentInput,
} from "../../../time-book-scheduling-api";
import {useApi} from "../../use-api";
import {
  createAppointmentDetailsKey,
  createAppointmentsListKey,
} from "../query-key-creators";

export default usePatchAppointmentsMutation;

interface PatchAppointmentsMutationProps {
  appointments?: Appointment[];
}

interface PatchAppointmentsMutationVariables {
  input: PatchAppointmentInput;
}

function usePatchAppointmentsMutation(props: PatchAppointmentsMutationProps) {
  const {appointments = []} = props;

  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<
    Appointment[],
    ApiError,
    PatchAppointmentsMutationVariables
  >(
    (variables) => {
      const {input} = variables;

      const tasks = appointments.map((appointment) => {
        const identifier = {
          clinicId: appointment.clinic.id,
          appointmentId: appointment.id,
          appointmentVersion: appointment.version,
        };

        return () => {
          return api.patchAppointment(identifier, input).then((json) => {
            return Appointment.fromJSON(json);
          });
        };
      });

      return new Queue({concurrency: 4}).addAll(tasks);
    },
    {
      onSuccess() {
        for (const clinicId of selectUniqueClinicIdsFromAppointments(
          appointments
        )) {
          void queryClient.invalidateQueries(
            createAppointmentsListKey(clinicId)
          );
        }

        for (const appointment of appointments) {
          const clinicId = appointment.clinic.id;
          const appointmentId = appointment.id;

          void queryClient.invalidateQueries(
            createAppointmentDetailsKey(clinicId, appointmentId)
          );
        }
      },
    }
  );
}

function selectUniqueClinicIdsFromAppointments(
  appointments: Appointment[] = []
) {
  const allClinicIds = appointments.map((appointment) => appointment.clinic.id);

  return [...new Set(allClinicIds)];
}
