import {isValidDate} from "../is-date";
import {isNonEmptyString} from "../is-non-empty-string";
import type {IsoDate} from "./types";

export {
  cloneDate,
  createEndOfDayDate,
  createMiddayDate,
  createStartOfDayDate,
  isIsoDateString,
  isValidISODateString,
  toISOTimeString,
  toISOString,
  toISODateString,
};
export type {IsoDate};

function createStartOfDayDate(date: Date) {
  const copy = cloneDate(date);
  copy.setHours(0, 0, 0, 0);
  return copy;
}

function createMiddayDate(date: Date) {
  const copy = cloneDate(date);
  copy.setHours(12, 0, 0, 0);
  return copy;
}

function createEndOfDayDate(date: Date) {
  const copy = cloneDate(date);
  copy.setHours(23, 59, 59, 999);
  return copy;
}

function cloneDate(date: Date) {
  return new Date(date);
}

function isIsoDateString(value: unknown): value is IsoDate {
  return isNonEmptyString(value) && /^\d\d\d\d-\d\d-\d\d$/.test(value);
}

const isoDatePattern = /^\d\d\d\d-\d\d-\d\d$/;

function isValidISODateString(value: string): value is IsoDate {
  if (isoDatePattern.test(value)) {
    const date = new Date(value);

    return isValidDate(date) && toISODateString(date) === value;
  }

  return false;
}

function toISODateString(date: Date) {
  const isoDataAndTimeParts = date.toISOString().split("T");
  return isoDataAndTimeParts[0];
}

function toISOTimeString(date: Date) {
  const isoDataAndTimeParts = date.toISOString().split("T");
  return isoDataAndTimeParts[1];
}

function toISOString(date: Date) {
  return date.toISOString();
}
