import {ReactNode} from "react";
import classnames from "classnames";
import {useTranslation} from "../../../services/i18n";
import {Alert, AlertHeading} from "../../bootstrap";
import styles from "./authorization-error.module.scss";

export default AuthorizationError;

interface AuthorizationErrorProps {
  children?: ReactNode;
  className?: string;
  title?: string;
}

function AuthorizationError(props: AuthorizationErrorProps) {
  const tAuthorizationErrorTitle = useTranslation("authorization-error-title");
  const tAuthorizationErrorMessage = useTranslation(
    "authorization-error-message"
  );
  const {
    children = tAuthorizationErrorMessage,
    className,
    title = tAuthorizationErrorTitle,
  } = props;
  const classNames = classnames(styles.root, className);

  return (
    <Alert className={classNames} variant="danger">
      {title ? <AlertHeading>{title}</AlertHeading> : null}
      {children ? children : null}
    </Alert>
  );
}
