import {createContext, Dispatch, ReducerAction} from "react";
import bookingAppStateReducer from "./booking-app-state-reducer";

export interface BookingAppState {
  bookingDate: Date;
  clinicId: string;
  routeDate: string;
  routePath: string;
  routeUrl: string;
  selectedSlotIds: string[];
  slotId: string | undefined;
}

export interface BookingAppStateInit {
  bookingDate?: Date;
  clinicId: string;
  routePath: string;
  routeUrl: string;
  slotId?: string;
}

export function initializeBookingAppState(
  initialState: BookingAppStateInit
): BookingAppState {
  const today = new Date();

  const {
    bookingDate = today,
    clinicId,
    routePath,
    routeUrl,
    slotId,
  } = initialState;

  const routeDate = today.toLocaleDateString("sv-SE");
  const selectedSlotIds = [] as string[];

  return {
    bookingDate,
    clinicId,
    routeDate,
    routePath,
    routeUrl,
    selectedSlotIds,
    slotId,
  };
}

const BookingAppStateContext = createContext<
  | [BookingAppState, Dispatch<ReducerAction<typeof bookingAppStateReducer>>]
  | null
>(null);
BookingAppStateContext.displayName = "BookingAppStateContext";

export default BookingAppStateContext;
