import React from "react";
import ReactTooltip from "react-tooltip";
import {isNonEmptyString} from "../../utils";
import {CommentIcon} from "../icon";
import styles from "./clinic-note.module.scss";

const NO_OF_CHARS_BEFORE_ELLIPSIS = 30;

// TODO: replace note with children
type ClinicNoteProps = {
  id?: string;
  note?: string;
};

export const ClinicNote = (props: ClinicNoteProps) => {
  const {id, note} = props;

  if (!isNonEmptyString(note)) {
    return null;
  }
  const noteId = `note-${id}`;
  const isLongNote = note.length > NO_OF_CHARS_BEFORE_ELLIPSIS;

  if (!isLongNote) {
    return (
      <div>
        <CommentIcon /> {note}
      </div>
    );
  }
  return (
    <div className={styles.root}>
      <div data-tip data-for={noteId}>
        <CommentIcon /> {note.substring(0, NO_OF_CHARS_BEFORE_ELLIPSIS)}…
      </div>
      <ReactTooltip
        arrowColor={"#ccc"}
        border
        borderColor={"#ccc"}
        className={styles.tooltip}
        id={noteId}
        place="bottom"
        delayShow={200}
        type="light"
      >
        {note}
      </ReactTooltip>
    </div>
  );
};
