import {ReactNode} from "react";
import classNames from "classnames";
import styles from "./single-choice-option.module.scss";
import useSingleChoice from "./use-single-choice";

export default SingleChoiceOption;

interface SingleChoiceOptionProps {
  children?: ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
  value: string;
}

function SingleChoiceOption(props: SingleChoiceOptionProps) {
  const {children, disabled, readOnly, value} = props;

  const controlProps = useSingleChoice();
  const {name: controlName, isOptionDisabled, isOptionSelected} = controlProps;

  const labelClassNames = classNames(styles.root, {
    [styles.isDisabled]: disabled,
    [styles.isReadOnly]: readOnly,
  });

  return (
    <label className={labelClassNames}>
      <input
        checked={isOptionSelected(value)}
        disabled={isOptionDisabled(disabled)}
        name={controlName}
        onChange={noop}
        readOnly={readOnly}
        type="radio"
        value={value}
      />
      {children != null ? (
        <div className={styles.content}>{children}</div>
      ) : null}
    </label>
  );
}

function noop() {}
