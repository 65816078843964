import {useCallback, useMemo} from "react";
import {UseQueryOptions} from "react-query";
import {isNull} from "../../../../utils";
import {Appointment} from "../../../time-book-scheduling-api";
import {Filter as ApiSearchFilter} from "../../search-query";
import useApi from "../../use-api";
import {
  createAppointmentDetailsKey,
  createAppointmentsSearchKey,
} from "../query-key-creators";

export default useAppointmentQueryCreators;

export type GetAppointmentQueryVariables = {
  appointmentId: string;
  clinicId: string;
};

export type GetAppointmentQueryOptions = UseQueryOptions<Appointment, Error>;

export type SearchAppointmentsQueryVariables = {
  clinicId: string;
  filter: ApiSearchFilter | null;
};

export type SearchAppointmentsQueryOptions = UseQueryOptions<
  Appointment[],
  Error
>;

function useAppointmentQueryCreators() {
  const api = useApi();

  const createGetAppointmentQuery = useCallback(
    (
      variables: GetAppointmentQueryVariables,
      options: GetAppointmentQueryOptions = {}
    ) => {
      const {clinicId, appointmentId} = variables;
      const {
        queryKey = createAppointmentDetailsKey(clinicId, appointmentId),
        ...otherOptions
      } = options;

      return {
        ...otherOptions,
        queryKey,
        queryFn() {
          return api
            .getAppointment({clinicId, appointmentId})
            .then((appointmentData) => Appointment.fromJSON(appointmentData));
        },
      };
    },
    [api]
  );

  const createSearchAppointmentsQuery = useCallback(
    (
      variables: SearchAppointmentsQueryVariables,
      options: SearchAppointmentsQueryOptions = {}
    ) => {
      const {clinicId, filter} = variables;
      const {
        queryKey = createAppointmentsSearchKey(clinicId, filter),
        ...otherOptions
      } = options;

      return {
        ...otherOptions,
        queryKey,
        queryFn() {
          return isNull(filter)
            ? Promise.reject(new TypeError("Provided `filter` is `null"))
            : api
                .searchAppointments({clinicId}, {filter})
                .then(({data}) =>
                  data.map((appointmentData) =>
                    Appointment.fromJSON(appointmentData)
                  )
                );
        },
      };
    },
    [api]
  );

  return useMemo(
    () => ({
      createGetAppointmentQuery,
      createSearchAppointmentsQuery,
    }),
    [createGetAppointmentQuery, createSearchAppointmentsQuery]
  );
}
