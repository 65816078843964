import EntityReference from "./entity-reference";

interface SlotRangeInit {
  start: Date;
  end: Date;
  slotType: EntityReference;
  resourceIndex: number;
}

export interface SlotRangeJSON {
  start: string | Date;
  end: string | Date;
  slot_type: EntityReference;
  resource_index: number;
}

export default class SlotRange {
  start: Date;
  end: Date;
  slotType: EntityReference;
  resourceIndex: number;

  constructor({start, end, slotType, resourceIndex}: SlotRangeInit) {
    Object.assign(this, {start, end, slotType, resourceIndex});
  }

  static fromJSON(json: SlotRangeJSON) {
    const {
      start,
      end,
      slot_type: slotType,
      resource_index: resourceIndex,
    } = json;

    return new SlotRange({
      start: new Date(start),
      end: new Date(end),
      slotType,
      resourceIndex,
    });
  }

  toJSON(): SlotRangeJSON {
    const {
      start,
      end,
      slotType: slot_type,
      resourceIndex: resource_index,
    } = this;

    return {start, end, slot_type, resource_index};
  }
}
