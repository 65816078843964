import {ForwardedRef, forwardRef} from "react";
import classNames from "classnames";
import {
  Button as ReactBootstrapButton,
  ButtonProps as ReactBootstrapButtonProps,
} from "react-bootstrap";
import styles from "./button.module.scss";

export default forwardRef<HTMLButtonElement, ButtonProps>(Button);

// @ts-ignore: TS2430: Type '"xs"' is not assignable to type '"lg" | "sm" | undefined'.
export interface ButtonProps extends ReactBootstrapButtonProps {
  form?: string;
  size?: "xs" | "sm" | "lg";
  value?: string;
}

function Button(props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) {
  const {children, className, ...otherProps} = props;

  const rootClassNames = classNames(styles.root, className);

  return (
    // @ts-ignore: TS2322: Type '"xs"' is not assignable to type '"sm" | "lg" | undefined'.
    <ReactBootstrapButton {...otherProps} className={rootClassNames} ref={ref}>
      {children}
    </ReactBootstrapButton>
  );
}
