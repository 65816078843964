import {SlotType} from "../../../services/time-book-scheduling-api";
import {
  getMondayOfWeek,
  getSundayOfWeek,
  getWeekNumber,
  toLocalDate,
  toLocalIsoDateString,
} from "../../../utils";
import {toMilliseconds} from "../../../utils/time/duration";
import {WizardCalendarEvent} from "../wizard-calendar-event";
import {
  DayFragment,
  DaySummary,
  DaySummaryMap,
  SchedulingSummary,
  WeekSummary,
  WeekSummaryMap,
} from "./scheduling-overview-types";

export {
  createDayFragments,
  createDaySummary,
  createWeekSummary,
  createSchedulingSummary,
  getUsedSlotTypes,
};

/**
 * For each CalendarEvent;
 * 1) Get the day (eg. 2021-02-03),
 * 2) Count the number of slots that fit into the event by looking at the SlotType data, and
 * 3) Keep the SlotType id for reference.
 */
function createDayFragments(
  calendarEvents: WizardCalendarEvent[],
  slotTypesById: Record<string, SlotType>
): DayFragment[] {
  return calendarEvents.map((calendarEvent) => {
    const {start, end} = calendarEvent;
    const msCalendarEventDuration = end.getTime() - start.getTime();

    const slotTypeId = calendarEvent.slotType.id; // id only
    const slotType = slotTypesById[slotTypeId]; // full SlotType
    const msSlotTypeDuration = toMilliseconds(slotType.duration);

    const slotCount = Math.floor(msCalendarEventDuration / msSlotTypeDuration);
    const day = toLocalIsoDateString(start);

    return {day, slotCount, slotTypeId};
  });
}

/**
 * For each DayFragment, group the fragments of the same day into a single
 * DaySummary object and sum up the slotCount for each SlotType. Also keep a
 * grand total for the day.
 *
 * From:
 * [
 *   {day: '2021-02-03', slotCount: 5, slotTypeId: '111'},
 *   {day: '2021-02-03', slotCount: 5, slotTypeId: '111'},
 *   {day: '2021-02-03', slotCount: 3, slotTypeId: '222'}
 * ]
 *
 * Into:
 * {
 *   '2021-02-03': {
 *     day: '2021-02-03',
 *     slotTypeSummary: {
 *       '111': 10,
 *       '222': 3,
 *     },
 *     dayTotal: 13,
 *   }
 * }
 */
function createDaySummary(dayFragments: DayFragment[]): DaySummaryMap {
  return dayFragments.reduce<DaySummaryMap>((accumulator, fragment) => {
    const {day, slotTypeId} = fragment;

    if (!accumulator[day]) {
      accumulator[day] = {
        day,
        slotTypeSummary: {},
        dayTotal: 0,
      };
    }
    const daySummary = accumulator[day];
    const slotCountDayTotal = daySummary.slotTypeSummary[slotTypeId] ?? 0;
    const nextSlotCountDayTotal = fragment.slotCount + slotCountDayTotal;
    const nextDayTotal = fragment.slotCount + daySummary.dayTotal;

    const nextDaySummary: DaySummary = {
      day,
      slotTypeSummary: {
        ...daySummary.slotTypeSummary,
        [slotTypeId]: nextSlotCountDayTotal,
      },
      dayTotal: nextDayTotal,
    };

    return {
      ...accumulator,
      [day]: nextDaySummary,
    };
  }, {});
}

/**
 * Similar to `createDaySummary`, but by week instead of by day. The key here
 * is the week number. Sore it by the week number.
 */
function createWeekSummary(daySummaryMap: DaySummaryMap): WeekSummaryMap {
  return Object.values(daySummaryMap).reduce<WeekSummaryMap>(
    (accumulator, daySummary) => {
      const dayAsDate = toLocalDate(daySummary.day);
      const firstDayOfWeek = getMondayOfWeek(dayAsDate);
      const lastDayOfWeek = getSundayOfWeek(dayAsDate);
      const weekNumber = getWeekNumber(dayAsDate);

      if (!accumulator[weekNumber]) {
        accumulator[weekNumber] = {
          days: {},
          firstDayOfWeek,
          lastDayOfWeek,
          slotTypeSummary: {},
          weekNumber: 0,
          weekTotal: 0,
        };
      }
      const weekSummary = accumulator[weekNumber];

      const nextDays: DaySummaryMap = {
        ...weekSummary.days,
        [daySummary.day]: daySummary,
      };

      const nextSlotTypeSummary = sumMapValues(
        weekSummary.slotTypeSummary,
        daySummary.slotTypeSummary
      );
      const nextWeekTotal = weekSummary.weekTotal + daySummary.dayTotal;

      const nextWeekSummary: WeekSummary = {
        days: nextDays,
        firstDayOfWeek,
        lastDayOfWeek,
        slotTypeSummary: nextSlotTypeSummary,
        weekNumber,
        weekTotal: nextWeekTotal,
      };

      return {
        ...accumulator,
        [weekNumber]: nextWeekSummary,
      };
    },
    {}
  );
}

/**
 * Sum up everything
 */
function createSchedulingSummary(
  weekSummaryMap: WeekSummaryMap
): SchedulingSummary {
  const weekSummaries = Object.values(weekSummaryMap);
  const schedulingTotal = weekSummaries.reduce<number>(
    (accumulator, {weekTotal}) => accumulator + weekTotal,
    0
  );
  const weekNumbers = weekSummaries.map(({weekNumber}) => weekNumber).sort();

  return {
    schedulingTotal,
    weekNumbers,
    weeks: weekSummaryMap,
  };
}

function getUsedSlotTypes(
  slotTypesById: Record<string, SlotType>,
  schedulingSummary: SchedulingSummary
): SlotType[] {
  const usedSlotTypes: Record<string, SlotType> = {};

  schedulingSummary.weekNumbers.forEach((weekNumber) => {
    const week = schedulingSummary.weeks[weekNumber];

    Object.keys(week.slotTypeSummary).forEach((slotTypeId) => {
      usedSlotTypes[slotTypeId] = slotTypesById[slotTypeId];
    });
  });

  return Object.values(usedSlotTypes);
}

/**
 * Merges two objects. If a key exists in both objects, the values will be
 * added together.
 *
 * @example
 * sumMapValues({a: 1}, {a: 2, b: 2}) => {a: 2, b: 2}
 */
function sumMapValues(
  a: Record<string, number>,
  b: Record<string, number>
): Record<string, number> {
  return Object.entries(b).reduce((accumulator, [key, bValue]) => {
    const aValue = accumulator[key] ?? 0;

    return {
      ...accumulator,
      [key]: aValue + bValue,
    };
  }, Object.assign({}, a));
}
