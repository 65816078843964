import React from "react";
import {Translation, useLocale} from "../../../services/i18n";
import {SlotType} from "../../../services/time-book-scheduling-api";
import {
  classNames,
  isSameMonth,
  isSameYear,
  isWeekend,
  keyBy,
  toLocalDate,
} from "../../../utils";
import {Table} from "../../bootstrap";
import {SlotTypeLabel} from "../../calendar";
import {WeekdayString} from "../../dates";
import {Tag} from "../../tag";
import {WizardCalendarEvent} from "../wizard-calendar-event";
import {
  createDayFragments,
  createDaySummary,
  createSchedulingSummary,
  createWeekSummary,
  getUsedSlotTypes,
} from "./scheduling-overview-utils";
import styles from "./scheduling-overview.module.scss";

export {SchedulingOverview};

interface SchedulingOverviewProps {
  className?: string;
  calendarEvents: WizardCalendarEvent[];
  slotTypes: SlotType[];
}

function SchedulingOverview(props: SchedulingOverviewProps) {
  const {className, calendarEvents, slotTypes} = props;

  const slotTypesById = keyBy("id", slotTypes);
  const dayFragment = createDayFragments(calendarEvents, slotTypesById);
  const daySummary = createDaySummary(dayFragment);
  const weekSummary = createWeekSummary(daySummary);
  const schedulingSummary = createSchedulingSummary(weekSummary);
  const usedSlotTypes = getUsedSlotTypes(slotTypesById, schedulingSummary);

  return (
    <div className={classNames(styles.root, className)}>
      <Table className={styles.table}>
        <thead>
          <tr>
            <th />
            {usedSlotTypes.map((slotType) => (
              <th className={styles.dataHeading} key={`heading_${slotType.id}`}>
                <SlotTypeLabel slotType={slotType} />
              </th>
            ))}
            <th className={styles.dataHeading}>
              <Translation tKey="scheduling-overview-total" />
            </th>
          </tr>
        </thead>

        {schedulingSummary.weekNumbers.map((weekNumber) => {
          const week = schedulingSummary.weeks[weekNumber];
          const weekKey = `week_${weekNumber}`;

          return (
            <tbody key={weekKey}>
              {/* Week summary row */}
              <tr className={styles.weekSummary}>
                <th>
                  <Tag className={styles.weekNumber} variant="sun">
                    <Translation
                      tKey="scheduling-overview-week-number"
                      tValues={{weekNumber}}
                    />
                  </Tag>
                  <DateSpanString
                    from={week.firstDayOfWeek}
                    to={week.lastDayOfWeek}
                  />
                </th>
                {usedSlotTypes.map((slotType) => {
                  const weekSlotTypeKey = `${weekKey}_${slotType.id}`;

                  return (
                    <td className={styles.data} key={weekSlotTypeKey}>
                      {week.slotTypeSummary[slotType.id] ?? 0}
                    </td>
                  );
                })}
                <td className={styles.dataTotal}>{week.weekTotal ?? 0}</td>
              </tr>

              {/* Day summary rows */}
              {Object.values(week.days).map((daySummary) => {
                const day = toLocalDate(daySummary.day);
                const dayKey = `day_${daySummary.day}`;
                const classes = classNames(styles.daySummary, {
                  [styles.isWeekend]: isWeekend(day),
                });

                return (
                  <tr className={classes} key={dayKey}>
                    <th className={styles.daySummaryHeading}>
                      <WeekdayString date={day} />
                    </th>
                    {usedSlotTypes.map((slotType) => {
                      const daySlotTypeKey = `${dayKey}_${slotType.id}`;

                      return (
                        <td className={styles.data} key={daySlotTypeKey}>
                          {daySummary.slotTypeSummary[slotType.id] ?? 0}
                        </td>
                      );
                    })}
                    <td className={styles.dataTotal}>{daySummary.dayTotal}</td>
                  </tr>
                );
              })}
            </tbody>
          );
        })}
      </Table>
    </div>
  );
}

interface DateSpanStringProps {
  from: Date;
  to: Date;
}

function DateSpanString(props: DateSpanStringProps) {
  const {from, to} = props;
  const {currentLocale} = useLocale();

  const fromOptions: Intl.DateTimeFormatOptions = {
    day: "numeric",
  };
  const toOptions: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  if (!isSameMonth(from, to)) {
    fromOptions.month = "short";
  }
  if (!isSameYear(from, to)) {
    fromOptions.year = "numeric";
  }
  return (
    <Translation
      tKey="scheduling-overview-date-span-string"
      tValues={{
        from: from.toLocaleString(currentLocale.tag, fromOptions),
        to: to.toLocaleString(currentLocale.tag, toOptions),
      }}
    />
  );
}
