import React, {
  ChangeEventHandler,
  FormEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import {useSearchResidentQuery} from "../../services/api/residents/hooks";
import {getPersonalIdentityNumberValidity} from "../../services/api/utils/personal-identity-number";
import {Translation, useTranslation} from "../../services/i18n";
import {Resident} from "../../services/time-book-scheduling-api";
import {classNames, isNonEmptyString, isUndefined} from "../../utils";
import {Form, FormControl} from "../bootstrap";
import {LoadingIcon} from "../icon";
import {LoadingError} from "../loading-error";
import {NotFound} from "../not-found";
import styles from "./search-resident.module.scss";

export type SearchResidentNoListResult = Resident | undefined;

type SearchResidentProps = {
  autoFocus?: boolean;
  children?: ReactNode;
  className?: string;
  clinicId: string;
  onSuccess: (result: SearchResidentNoListResult) => void;
};

export const SearchResidentNoList = (props: SearchResidentProps) => {
  const {autoFocus = false, children, className, clinicId, onSuccess} = props;
  const searchPlaceholder = useTranslation("header-search-placeholder");
  const [isInvalidSearchInput, setIsInvalidSearchInput] = useState(false);
  const [personalIdentityNumber, setPersonalIdentityNumber] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const pinValidity = getPersonalIdentityNumberValidity(personalIdentityNumber);
  const searchQuery = useSearchResidentQuery(
    {personalIdentityNumber},
    {enabled: pinValidity.valid}
  );

  const onSearchInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const searchInput = event.target.value;

      setSearchInput(searchInput);
    },
    [setSearchInput]
  );

  const checkValidity = useCallback(
    (searchInput: string) => {
      const validity = getPersonalIdentityNumberValidity(searchInput);

      if (!validity.valid) {
        setIsInvalidSearchInput(true);
      }
      return validity;
    },
    [setIsInvalidSearchInput]
  );

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      const isValid = checkValidity(searchInput);

      if (isValid) {
        setPersonalIdentityNumber(searchInput);
      }
    },
    [checkValidity, searchInput, setPersonalIdentityNumber]
  );

  // Reset the view if the user changes the input after a search
  useEffect(() => {
    setIsInvalidSearchInput(false);
    setPersonalIdentityNumber("");
  }, [searchInput, setIsInvalidSearchInput]);

  useEffect(() => {
    onSuccess(searchQuery.data);
  }, [onSuccess, searchQuery.data]);

  const searchInputValidity = getPersonalIdentityNumberValidity(searchInput);
  const isSearchInputValid = searchInputValidity.valid;
  const showSearchResultPanel =
    isSearchInputValid && !searchQuery.isIdle && !searchQuery.isLoading;

  return (
    <Form className={classNames(styles.root, className)} onSubmit={onSubmit}>
      <FormControl
        autoFocus={autoFocus}
        disabled={!isNonEmptyString(clinicId)}
        isInvalid={isInvalidSearchInput}
        isValid={isSearchInputValid}
        onChange={onSearchInputChange}
        placeholder={searchPlaceholder}
        type="search"
        value={searchInput}
      />
      {searchQuery.isLoading && (
        <LoadingIcon className={styles.inlineLoading} spin />
      )}
      <div className={styles.searchResult}>
        {showSearchResultPanel &&
          ((searchQuery.isError || searchQuery.isLoadingError) && (
            <LoadingError error={searchQuery.error} />
          ),
          searchQuery.isSuccess && isUndefined(searchQuery.data) && (
            <NotFound>
              <Translation
                tKey="site-search-resident-not-found"
                tValues={{query: searchInput}}
              />
            </NotFound>
          ))}
      </div>
      {children}
    </Form>
  );
};
