import {useGetSlotQuery} from "../../../../services/api/slots";
import {Loading} from "../../../loading";
import {LoadingError} from "../../../loading-error";
import SlotInfo from "../../../slot-info";
import BookingAppAsideAppointment from "../booking-app-aside-appointment";
import BookingAppAsideAvailableSlot from "../booking-app-aside-available-slot";
import styles from "./booking-app-aside-slot.module.scss";

export default BookingAppAsideSlot;

interface BookingAppAsideSlotProps {
  clinicId: string;
  slotId: string;
}

function BookingAppAsideSlot(props: BookingAppAsideSlotProps) {
  const {clinicId, slotId} = props;

  const getSlotQuery = useGetSlotQuery({clinicId, slotId});

  if (getSlotQuery.isLoading) {
    return <Loading />;
  }

  if (getSlotQuery.isError) {
    return <LoadingError error={getSlotQuery.error} />;
  }

  if (getSlotQuery.isSuccess) {
    const slot = getSlotQuery.data;

    return (
      <div className={styles.root}>
        <SlotInfo slot={slot} />

        {slot.appointment != null ? (
          <BookingAppAsideAppointment
            slot={slot}
            appointmentId={slot.appointment.id}
            clinicId={slot.clinic.id}
            slotStart={slot.start}
          />
        ) : (
          <BookingAppAsideAvailableSlot slot={slot} />
        )}
      </div>
    );
  }

  return null;
}
