import {useCallback, useMemo} from "react";
import {UseQueryOptions} from "react-query";
import SlotType from "../../../time-book-scheduling-api/entities/slot-type";
import useApi from "../../use-api";
import {
  createSlotTypeDetailsKey,
  createSlotTypesListKey,
} from "../query-key-creators";

export default useSlotTypeQueryCreators;

export interface GetSlotTypesQueryVariables {
  clinicId: string;
}

export type GetSlotTypesQueryOptions = UseQueryOptions<SlotType[], Error>;

export interface GetSlotTypeQueryVariables {
  clinicId: string;
  slotTypeId: string;
}

export type GetSlotTypeQueryOptions = UseQueryOptions<SlotType, Error>;

function useSlotTypeQueryCreators() {
  const api = useApi();

  const createGetSlotTypesQuery = useCallback(
    (
      variables: GetSlotTypesQueryVariables,
      options: GetSlotTypesQueryOptions = {}
    ) => {
      const {clinicId} = variables;

      return {
        ...options,
        queryKey: createSlotTypesListKey(clinicId),
        queryFn() {
          return api
            .getSlotTypes({clinicId})
            .then(({data}) =>
              data.map((slotTypeJSON) => SlotType.fromJSON(slotTypeJSON))
            );
        },
      };
    },
    [api]
  );

  const createGetSlotTypeQuery = useCallback(
    (
      variables: GetSlotTypeQueryVariables,
      options: GetSlotTypeQueryOptions = {}
    ) => {
      const {clinicId, slotTypeId} = variables;

      return {
        ...options,
        queryKey: createSlotTypeDetailsKey(clinicId, slotTypeId),
        queryFn: () => {
          return api
            .getSlotType({clinicId, slotTypeId})
            .then((data) => SlotType.fromJSON(data));
        },
      };
    },
    [api]
  );

  return useMemo(
    () => ({createGetSlotTypesQuery, createGetSlotTypeQuery}),
    [createGetSlotTypesQuery, createGetSlotTypeQuery]
  );
}
