import {AppointmentStatus} from "../../../services/time-book-scheduling-api";
import SingleChoice, {SingleChoiceProps} from "../../form/single-choice";
import SingleChoiceOption from "../../form/single-choice/single-choice-option";
import AppointmentStatusText from "../appointment-status-text";
import styles from "./appointment-status-input.module.scss";

export default AppointmentStatusInput;

const statuses: AppointmentStatus[] = [
  AppointmentStatus.BOOKED,
  AppointmentStatus.FULFILLED,
  AppointmentStatus.NO_SHOW,
];

type SansChildren<T> = Omit<T, "children">;

interface AppointmentStatusInputProps
  extends SansChildren<SingleChoiceProps<AppointmentStatus>> {}

function AppointmentStatusInput(props: AppointmentStatusInputProps) {
  const {name} = props;

  return (
    <SingleChoice {...props} className={styles.root}>
      {statuses.map((status) => {
        return (
          <SingleChoiceOption key={`${name}-${status}`} value={status}>
            <AppointmentStatusText status={status} />
          </SingleChoiceOption>
        );
      })}
    </SingleChoice>
  );
}
