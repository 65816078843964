import React, {useCallback, useMemo} from "react";
import {useQuery} from "react-query";
import {Button} from "../components/bootstrap";
import {CenteredCardLayout} from "../components/layouts";
import {Loading} from "../components/loading";
import {LoadingError} from "../components/loading-error";
import {
  fetchSession,
  SessionClient,
  SessionClientProvider,
  SessionJSON,
  SessionUser,
} from "../services/session";
import styles from "./session-client-provider.module.scss";
import {InternalProviderProps} from "./types/internal-provider";

export default InternalSessionClientProvider;

function InternalSessionClientProvider(props: InternalProviderProps) {
  const getSessionQuery = useGetSessionQuery();

  const sessionClient = useMemo(() => {
    if (getSessionQuery.isSuccess) {
      const {claims, hsaid, firstname, lastname, email} = getSessionQuery.data;

      const user = new SessionUser({
        hsaId: hsaid,
        firstName: firstname,
        lastName: lastname,
        email,
      });

      return new SessionClient({claims, user});
    }

    return null;
  }, [getSessionQuery.isSuccess, getSessionQuery.data]);

  if (getSessionQuery.isLoading) {
    return (
      <CenteredCardLayout>
        <Loading />
      </CenteredCardLayout>
    );
  }

  if (getSessionQuery.isLoadingError || getSessionQuery.isRefetchError) {
    return (
      <CenteredCardLayout>
        <LoadingError error={getSessionQuery.error} title="Session Error">
          {process.env.NODE_ENV === "development" ? (
            <div className={styles.buttonContainer}>
              <SignInButton />
            </div>
          ) : null}
        </LoadingError>
      </CenteredCardLayout>
    );
  }

  if (getSessionQuery.isSuccess && sessionClient !== null) {
    return (
      <SessionClientProvider client={sessionClient}>
        {props.children}
      </SessionClientProvider>
    );
  }

  return null;
}

function useGetSessionQuery() {
  return useQuery<SessionJSON, Error>(["session"], fetchSession, {
    refetchOnWindowFocus: true,
  });
}

function SignInButton() {
  const onSignInButtonClick = useCallback(() => {
    SessionClient.login();
  }, []);

  return (
    <Button block variant="info" onClick={onSignInButtonClick}>
      Sign In
    </Button>
  );
}
