import React, {useState} from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {getEnvironmentConfiguration} from "../services/config";
import {InternalProviderProps} from "./types/internal-provider";

export const InternalQueryClientProvider = (props: InternalProviderProps) => {
  const {REACT_QUERY_REFETCH_ON_WINDOW_FOCUS} = getEnvironmentConfiguration();

  const [client] = useState(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: REACT_QUERY_REFETCH_ON_WINDOW_FOCUS,
          retry: false,
        },
      },
    });
  });

  return (
    <QueryClientProvider client={client}>{props.children}</QueryClientProvider>
  );
};
