import {Filter as ApiSearchFilter} from "../search-query";

const ROOT = "residents";

export const createResidentsAllKey = () => [ROOT];

export const createResidentDetailsKey = (residentId: string) => [
  ROOT,
  "detail",
  {residentId},
];

export const createResidentsListKey = () => [ROOT, "list"];

export const createResidentsSearchKey = (filter: ApiSearchFilter | null) => [
  ...createResidentsListKey(),
  {filter},
];
