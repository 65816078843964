import React from "react";
import {Card} from "../../components/card";
import {DefaultLayout} from "../../components/layouts";
import {SearchResidentWithRedirection} from "../../components/search-resident";
import {SearchResidentInstruction} from "./search-resident-instruction";
import styles from "./search-residents-route.module.scss";

export const SearchResidentRoute = () => {
  const headerElement = <SearchResidentWithRedirection autoFocus />;

  return (
    <DefaultLayout header={headerElement}>
      <Card className={styles.root}>
        <SearchResidentInstruction className={styles.instruction} />
      </Card>
    </DefaultLayout>
  );
};
