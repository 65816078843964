import {createContext, Dispatch, ReducerAction} from "react";
import {appStateReducer} from "./app-state-reducer";

export type AppState = {
  bookingDate?: Date;
  clinicId?: string;
  isMainNavigationCollapsed: boolean;
};

export const getDefaultInitialState = (): AppState => ({
  isMainNavigationCollapsed: false,
});

const AppStateContext = createContext<
  [AppState, Dispatch<ReducerAction<typeof appStateReducer>>] | null
>(null);
AppStateContext.displayName = "AppStateContext";

export default AppStateContext;
