// TODO: Supercede this file.
/**
 * DateTime format option object values
 * https://tc39.es/ecma402/#datetimeformat-objects
 */
export enum Era {
  Narrow = "narrow",
  Short = "short",
  Long = "long",
}

export enum Year {
  TwoDigit = "2-digit",
  Numeric = "numeric",
}

export enum Month {
  TwoDigit = "2-digit",
  Numeric = "numeric",
  Narrow = "narrow",
  Short = "short",
  Long = "long",
}

export enum Day {
  TwoDigit = "2-digit",
  Numeric = "numeric",
}

export enum Weekday {
  Narrow = "narrow",
  Short = "short",
  Long = "long",
}

export enum Hour {
  TwoDigit = "2-digit",
  Numeric = "numeric",
}

export enum Minute {
  TwoDigit = "2-digit",
  Numeric = "numeric",
}

export enum Second {
  TwoDigit = "2-digit",
  Numeric = "numeric",
}

export enum TimeZoneName {
  Short = "short",
  Long = "long",
}
