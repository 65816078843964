import {ReactNode} from "react";
import {
  InternalApiClientProvider,
  InternalAppStateProvider,
  InternalBrowserRouterProvider,
  InternalConfirmProvider,
  InternalI18nProvider,
  InternalQueryClientProvider,
  InternalSessionClientProvider,
} from "./providers";

export default MainAppShell;

interface MainAppShellProps {
  children: ReactNode;
}

function MainAppShell(props: MainAppShellProps) {
  const {children} = props;

  return (
    <InternalBrowserRouterProvider>
      <InternalApiClientProvider>
        <InternalQueryClientProvider>
          <InternalI18nProvider>
            <InternalSessionClientProvider>
              <InternalAppStateProvider>
                <InternalConfirmProvider>{children}</InternalConfirmProvider>
              </InternalAppStateProvider>
            </InternalSessionClientProvider>
          </InternalI18nProvider>
        </InternalQueryClientProvider>
      </InternalApiClientProvider>
    </InternalBrowserRouterProvider>
  );
}
