import {useReducer, useEffect, ReactNode} from "react";
import {createLocalStorageItem} from "../../utils/local-storage";
import AppStateContext, {
  AppState,
  getDefaultInitialState,
} from "./app-state-context";
import appStateReducer from "./app-state-reducer";

type PersistedAppState = Pick<AppState, "isMainNavigationCollapsed">;

const appStateStorage = createLocalStorageItem<PersistedAppState>("appState");

export default AppStateProvider;

type AppStateProviderProps = {
  children: ReactNode;
  initialState?: AppState;
};

function AppStateProvider(props: AppStateProviderProps) {
  const {children, initialState = getDefaultInitialState()} = props;
  const [state, dispatch] = useReducer(appStateReducer, initialState, init);

  useEffect(() => {
    appStateStorage.wite({
      // Be V E R Y selective with what goes into Local Storage!
      isMainNavigationCollapsed: state.isMainNavigationCollapsed,
    });
  }, [state.isMainNavigationCollapsed]);

  return (
    <AppStateContext.Provider value={[state, dispatch]}>
      {children}
    </AppStateContext.Provider>
  );
}

function init(initialArg: AppState): AppState {
  const storedState = appStateStorage.read();

  return {
    ...initialArg,
    ...storedState,
  };
}
