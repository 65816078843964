import React, {ReactElement} from "react";
import {isUndefined} from "../utils";
import {OverlayTrigger, Tooltip} from "./bootstrap/unchanged";

type ConditionalTooltipProps = {
  children: ReactElement;
  id: string;
  tooltip?: ReactElement | string;
};

export const ConditionalTooltip = (props: ConditionalTooltipProps) => {
  const {children, id, tooltip} = props;

  if (isUndefined(tooltip)) {
    return children;
  }
  const overlay = <Tooltip id={id}>{tooltip}</Tooltip>;

  return <OverlayTrigger overlay={overlay}>{children}</OverlayTrigger>;
};
