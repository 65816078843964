import {Fragment, useCallback} from "react";
import {usePatchSlotMutation} from "../../../services/api/slots";
import {Translation} from "../../../services/i18n";
import {Slot, SlotStatus} from "../../../services/time-book-scheduling-api";
import {createPatch} from "../../../utils/json-patch";
import ActionButton from "../../form/action-button";
import {LoadingError} from "../../loading-error";

export default BlockSlotButton;

interface BlockSlotButtonProps {
  slot: Slot;
}

function BlockSlotButton(props: BlockSlotButtonProps) {
  const {slot} = props;

  const clinicId = slot.clinic.id;
  const slotId = slot.id;
  const slotVersion = slot.version;

  const patchSlotMutation = usePatchSlotMutation({
    clinicId,
    slotId,
    slotVersion,
  });

  const onClick = useCallback(() => {
    const prevStatus = slot.version;
    const nextStatus = isBlocked(slot)
      ? SlotStatus.CREATED
      : SlotStatus.BLOCKED;

    const operation = createPatch({status: prevStatus}, {status: nextStatus});

    patchSlotMutation.mutate({input: operation});
  }, [slot, patchSlotMutation]);

  return (
    <Fragment>
      {patchSlotMutation.isError ? (
        <LoadingError error={patchSlotMutation.error!} />
      ) : null}
      <ActionButton
        block
        loading={patchSlotMutation.isLoading}
        onClick={onClick}
        variant="outline-primary"
      >
        {isBlocked(slot) ? (
          <Translation tKey="calendar-event-details-unblock" />
        ) : (
          <Translation tKey="calendar-event-details-block" />
        )}
      </ActionButton>
    </Fragment>
  );
}

function isBlocked(slot: Slot) {
  return slot.status === SlotStatus.BLOCKED;
}
