import {ChangeEvent, FormEvent, useCallback, useState} from "react";
import {Translation, useTranslation} from "../../services/i18n";
import {Form, FormControl, FormText, InputGroup} from "../bootstrap";
import {ActionButton} from "../form";

const PIN = "PIN";

const FormDataEntryName = {PIN} as const;

export {SearchResidentForm as default, FormDataEntryName};

interface SearchResidentFormProps {
  loading?: boolean;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  isBookable: boolean;
}

function SearchResidentForm(props: SearchResidentFormProps) {
  const {loading, onSubmit: dispatchSubmitEvent, isBookable} = props;

  const [validated, setValidated] = useState(false);
  const onFormSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setValidated(true);

      if (event.currentTarget.checkValidity()) {
        dispatchSubmitEvent(event);
      }
    },
    [dispatchSubmitEvent, setValidated]
  );

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValidated(false);

      if (event.currentTarget.checkValidity()) {
        setValidated(true);
      }
    },
    [setValidated]
  );

  const tPlaceholder = useTranslation("calendar-event-details-pin");

  return (
    <Form
      // @ts-ignore - noValidate DO exist. STFU TypeScript
      noValidate
      onSubmit={onFormSubmit}
      validated={validated}
    >
      <InputGroup>
        <FormControl
          defaultValue=""
          name={PIN}
          onChange={onInputChange}
          pattern="^(\d{6}|\d{8})[0-9a-z]{4}$"
          placeholder={tPlaceholder}
          readOnly={loading}
          required
          type="search"
          disabled={!isBookable}
        />
        <InputGroup.Append>
          <ActionButton loading={loading} type="submit" disabled={!isBookable}>
            <Translation tKey="calendar-event-details-search" />
          </ActionButton>
        </InputGroup.Append>
      </InputGroup>

      {!isBookable ? (
        <FormText className="text-muted">
          <Translation tKey="calendar-event-details-disabled-info" />
        </FormText>
      ) : null}
    </Form>
  );
}
