import {useMutation, useQueryClient} from "react-query";
import {
  ApiError,
  CreateSlotTypeInput,
  SlotType,
} from "../../../time-book-scheduling-api";
import useApi from "../../use-api";
import {createSlotTypesListKey} from "../query-key-creators";

export default useCreateSlotTypeMutation;

interface CreateSlotTypeMutationProps {
  clinicId: string;
}

interface CreateSlotTypeMutationVariables {
  input: CreateSlotTypeInput;
}

function useCreateSlotTypeMutation(props: CreateSlotTypeMutationProps) {
  const api = useApi();
  const queryClient = useQueryClient();

  const {clinicId} = props;

  return useMutation<SlotType, ApiError, CreateSlotTypeMutationVariables>(
    (variables) => {
      const {input} = variables;

      return api
        .createSlotType({clinicId}, input)
        .then((slotTypeData) => SlotType.fromJSON(slotTypeData));
    },
    {
      onSuccess(/*slotType*/) {
        void queryClient.invalidateQueries(createSlotTypesListKey(clinicId));
      },
    }
  );
}
