const BOOK = "BOOK";
const BOOK_EXTERNAL = "BOOK_EXTERNAL";
const SCHEDULE = "SCHEDULE";
const ADMINISTRATE = "ADMINISTRATE";

export const Permission = {
  BOOK,
  BOOK_EXTERNAL,
  SCHEDULE,
  ADMINISTRATE,
} as const;

/* eslint-disable-next-line @typescript-eslint/no-redeclare -- We know what we are doing here */
export type Permission = typeof Permission[keyof typeof Permission];
