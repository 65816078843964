import React from "react";
import {isNonEmptyString} from "../utils";

type EmailProps = {
  value: string;
};

export const Email = (props: EmailProps) => {
  const {value} = props;

  if (!isNonEmptyString(value)) {
    return null;
  }
  return <a href={`mailto:${value}`}>{value}</a>;
};
