import {QueryStatus, UseQueryResult} from "react-query";

export default getCompoundStatus;

function getCompoundStatus(
  queryResults: readonly Pick<UseQueryResult, "status">[]
): QueryStatus {
  // Order of the returns is important here.

  // A compound query is successful ONLY if ALL queries are successful
  if (queryResults.every((queryResult) => queryResult.status === "success")) {
    return "success";
  }

  // A compound query is considered to be loading if ONE query is loading
  if (queryResults.some((queryResult) => queryResult.status === "loading")) {
    return "loading";
  }

  // A compound query is considered to be a failure if ONE query is failing
  if (queryResults.some((queryResult) => queryResult.status === "error")) {
    return "error";
  }

  return "idle";
}
