import {useLocale} from "../../services/i18n";
import type {TimeTextProps} from "./types";

export default TimeText;

function TimeText(props: TimeTextProps) {
  const {currentLocale} = useLocale();
  const {
    className,
    date,
    timeStyle,
    locales = currentLocale.tag,
    timeZone,
  } = props;

  const timeString = date.toLocaleTimeString(locales, {
    timeStyle,
    timeZone,
  });

  return <span className={className}>{timeString}</span>;
}
