import {ChangeEventHandler, useCallback, useMemo, useState} from "react";
import {
  FormControl,
  FormGroup,
  FormLabel,
  FormText,
} from "../../../components/bootstrap";
import {createDateRangeFilter} from "../../../services/api";
import {useCountSlotsQuery} from "../../../services/api/slots";
import {Translation, useTranslation} from "../../../services/i18n";
import {CountByDate} from "../../../services/time-book-scheduling-api";
import {
  addDays,
  addMonths,
  getFirstDateOfMonth,
  getLastDateOfMonth,
  inDaysUtc,
  keyBy,
} from "../../../utils";
import {toISODateString} from "../../../utils/date-time";
import {OverlayTrigger, Tooltip} from "../../bootstrap";
import {DateRange, DayRangePicker} from "../../day-range-picker";
import {Loading} from "../../loading";
import SchedulingWizardContentConstrainer from "./scheduling-wizard-content-constrainer";
import styles from "./scheduling-wizard-step-1.module.scss";

export default SchedulingWizardStep1;

const NUMBER_OF_MONTHS = 2;

interface SchedulingWizardStep1Props {
  clinicId: string;
  endDate?: Date;
  numberOfResources?: number;
  resourcesMax: number;
  resourcesMin: number;
  onEndDateChange: (endDate?: Date) => void;
  onNumberOfResourcesChange: (numberOfResources?: number) => void;
  onStartDateChange: (startDate?: Date) => void;
  startDate?: Date;
}

function SchedulingWizardStep1(props: SchedulingWizardStep1Props) {
  const {
    clinicId,
    onEndDateChange,
    onNumberOfResourcesChange,
    onStartDateChange,
    numberOfResources,
    resourcesMax,
    resourcesMin,
    startDate,
    endDate,
  } = props;
  const now = new Date();
  const [dayPickerDate, setDayPickerDate] = useState(new Date());

  const dateMin = now;
  const dateMax = addDays(now, 365);
  const selectedDaysCount =
    startDate && endDate ? inDaysUtc(endDate) - inDaysUtc(startDate) + 1 : 0;

  const tResources = useTranslation("schedule-wizard-step-1-resources");
  const tResourcesHelp = useTranslation(
    "schedule-wizard-step-1-resources-help",
    {max: resourcesMax, min: resourcesMin}
  );

  const onSelectedRangeChange = ({from, to}: DateRange) => {
    onStartDateChange(from);
    onEndDateChange(to);
  };

  const [firstDay, lastDay] = getDateRange(dayPickerDate, NUMBER_OF_MONTHS);

  const filter = useMemo(
    () => createDateRangeFilter(firstDay, lastDay),
    [firstDay, lastDay]
  );
  const countSlotsQuery = useCountSlotsQuery({clinicId, filter});

  const stats = useMemo(() => {
    const results: Record<string, CountByDate> = {};

    if (!countSlotsQuery.data) {
      return results;
    }
    return keyBy("date", countSlotsQuery.data);
  }, [countSlotsQuery.data]);

  const renderDayPickerDay = useCallback(
    (date: Date /*, modifiers: DayModifiers*/) => {
      const dateKey = toISODateString(date);
      const dayOfMonth = date.getDate();
      const existingSlotsCount = stats[dateKey]?.count || 0;

      if (existingSlotsCount === 0) {
        return <div className={styles.day}>{dayOfMonth}</div>;
      }
      const overlay = (
        <Tooltip id={`tooltip-${dateKey}`}>
          <Translation
            tKey="schedule-wizard-step-1-slot-count-tooltip"
            tValues={{count: existingSlotsCount}}
          />
        </Tooltip>
      );

      return (
        <OverlayTrigger overlay={overlay} placement="top">
          <div className={styles.dayWithSlots}>{dayOfMonth}</div>
        </OverlayTrigger>
      );
    },
    [stats]
  );

  const onMonthChange = useCallback(
    (date: Date) => setDayPickerDate(date),
    [setDayPickerDate]
  );

  return (
    <SchedulingWizardContentConstrainer>
      <div>
        <NumberInput
          helpText={tResourcesHelp}
          label={tResources}
          max={resourcesMax}
          min={resourcesMin}
          name="resources"
          onNumberChange={onNumberOfResourcesChange}
          value={numberOfResources}
        />
      </div>
      <div className={styles.dayRangePickerContainer}>
        <DayRangePicker
          className={styles.dayRangePicker}
          initialMonth={now}
          numberOfMonths={NUMBER_OF_MONTHS}
          onMonthChange={onMonthChange}
          onSelectedRangeChange={onSelectedRangeChange}
          renderDay={renderDayPickerDay}
          selectableRange={{from: dateMin, to: dateMax}}
          selectedRange={{from: startDate, to: endDate}}
        />
      </div>
      {countSlotsQuery.isLoading && (
        <Loading>
          <Translation tKey="schedule-wizard-step-1-calendar-loading" />
        </Loading>
      )}
      {selectedDaysCount > 0 ? (
        <div>
          <Translation
            tKey="schedule-wizard-step-1-no-of-days"
            tValues={{days: selectedDaysCount}}
          />
        </div>
      ) : null}
    </SchedulingWizardContentConstrainer>
  );
}

interface NumberInputProps {
  helpText?: string;
  label: string;
  max: number;
  min: number;
  name: string;
  onNumberChange: (value?: number) => void;
  value?: number;
}

function NumberInput(props: NumberInputProps) {
  const {helpText, label, max, min, name, onNumberChange, value} = props;

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onNumberChange(event.target.valueAsNumber || undefined);
    },
    [onNumberChange]
  );

  return (
    <FormGroup controlId={name}>
      <FormLabel>{label}</FormLabel>
      <FormControl
        autoFocus
        onChange={onChange}
        max={max}
        min={min}
        required
        step="1"
        type="number"
        value={value ?? ""}
      />
      {helpText && <FormText>{helpText}</FormText>}
    </FormGroup>
  );
}

function getDateRange(initialDate: Date, months = 1) {
  const first = getFirstDateOfMonth(initialDate);
  const otherMonth = addMonths(initialDate, months - 1);
  const last = getLastDateOfMonth(otherMonth);

  return [first, last];
}
