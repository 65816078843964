import React, {ReactNode} from "react";
import classNames from "classnames";
import styles from "./triptych.module.scss";

export default Triptych;

interface TriptychProps {
  children: ReactNode;
  className?: string;
}

function Triptych(props: TriptychProps) {
  const {children, className} = props;

  if (React.Children.count(children) !== 3) {
    throw new RangeError("Always 3 there should be.");
  }

  const [startPanelContent, centerPanelContent, endPanelContent] =
    React.Children.toArray(children);

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.startPanel}>{startPanelContent}</div>
      <div className={styles.centerPanel}>{centerPanelContent}</div>
      <div className={styles.endPanel}>{endPanelContent}</div>
    </div>
  );
}
