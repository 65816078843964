import {useQuery} from "react-query";
import useAppointmentQueryCreators, {
  SearchAppointmentsQueryVariables,
  SearchAppointmentsQueryOptions,
} from "./use-appointment-query-creators";

export default useSearchAppointmentsQuery;

function useSearchAppointmentsQuery(
  variables: SearchAppointmentsQueryVariables,
  options?: SearchAppointmentsQueryOptions
) {
  const {createSearchAppointmentsQuery} = useAppointmentQueryCreators();

  return useQuery(createSearchAppointmentsQuery(variables, options));
}
