import React, {ReactNode} from "react";
import {classNames} from "../../../utils";
import styles from "./button-container.module.scss";

type ButtonContainerProps = {
  children: ReactNode;
  className?: string;
};

export const ButtonContainer = (props: ButtonContainerProps) => (
  <div className={classNames(styles.root, props.className)}>
    {props.children}
  </div>
);
