import invariant from "invariant";
import {useMutation, useQueryClient} from "react-query";
import {isUndefined} from "../../../../utils";
import {
  ApiError,
  PatchSlotInput,
  Slot,
} from "../../../time-book-scheduling-api";
import {StrictSlotIdentifier} from "../../../time-book-scheduling-api/api-client";
import useApi from "../../use-api";
import {createSlotDetailsKey, createSlotsListKey} from "../query-key-creators";

export default usePatchSlotMutation;

interface PatchSlotMutationVariables {
  identifier?: StrictSlotIdentifier;
  input: PatchSlotInput;
}

function usePatchSlotMutation(initIdentifier?: StrictSlotIdentifier) {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<Slot, ApiError, PatchSlotMutationVariables>(
    (variables) => {
      const {identifier, input} = variables;

      invariant(
        !isUndefined(initIdentifier) || !isUndefined(identifier),
        "A slot identifier is required"
      );

      const {clinicId, slotId, slotVersion} = Object.assign(
        {},
        initIdentifier,
        identifier
      );

      return api
        .patchSlot({clinicId, slotId, slotVersion}, input)
        .then((slotData) => Slot.fromJSON(slotData));
    },
    {
      onSuccess(nextSlot) {
        const {clinic} = nextSlot;

        const slotDetailsQueryKey = createSlotDetailsKey(
          clinic.id,
          nextSlot.id
        );
        const slotListQueryKey = createSlotsListKey(clinic.id);

        // Update cache entry for single slot
        queryClient.setQueryData(slotDetailsQueryKey, nextSlot);

        // Update cache entry for slot in lists
        queryClient.setQueriesData<Slot[]>(
          {
            queryKey: slotListQueryKey,
            predicate(query) {
              return (
                query.state.status === "success" &&
                (query.state.data as Slot[]).some((slot) => {
                  return slot.id === nextSlot.id;
                })
              );
            },
          },
          (slots = [] as Slot[]) => {
            for (const slot of slots) {
              if (slot.id === nextSlot.id) {
                Object.assign(slot, nextSlot);

                // break loop once we found the appointment
                break;
              }
            }

            return [...slots];
          }
        );

        // Invalidate all queries
        void queryClient.invalidateQueries(slotDetailsQueryKey);
        void queryClient.invalidateQueries(slotListQueryKey);
      },
    }
  );
}
