import SessionUser from "./session-user";

interface SessionClientInit {
  claims: string[];
  user: SessionUser;
}

export default class SessionClient {
  claims: string[];
  user: SessionUser;

  constructor({claims, user}: SessionClientInit) {
    Object.assign(this, {claims, user});
  }

  static login() {
    globalThis.location.assign("/auth/login");
  }

  /* eslint-disable-next-line class-methods-use-this */
  logout() {
    globalThis.location.assign("/auth/logout");
  }
}
