// TODO: Supercede this file
import {Hour, Minute} from "../../utils";
import {DateTimeStyleFormat} from "../../utils/date-time-style-format";

export {toLongDateString, toTimeString};

/** For now, let's use same (Swedish) date/time format everywhere */
const defaultLocale = "sv-SE";

/**
 * Formats a Date as: `"14:15"`.
 * If date is undefined an empty string is returned.
 */
function toTimeString(date?: Date, locale = defaultLocale) {
  const options: Intl.DateTimeFormatOptions = {
    hour: Hour.TwoDigit,
    minute: Minute.TwoDigit,
  };

  return date?.toLocaleTimeString(locale, options) || "";
}

function toLongDateString(date?: Date, locale = defaultLocale) {
  const options: Intl.DateTimeFormatOptions = {
    // @ts-ignore
    dateStyle: DateTimeStyleFormat.Full,
  };

  return date?.toLocaleString(locale, options) || "";
}
