import {Fragment} from "react";
import classNames from "classnames";
import {useLocale} from "../../services/i18n";
import styles from "./time-interval.module.scss";
import TimeText from "./time-text";
import type {TimeIntervalProps} from "./types";

export default TimeInterval;

function TimeInterval(props: TimeIntervalProps) {
  const {currentLocale} = useLocale();
  const {
    className,
    end,
    locales = currentLocale.tag,
    start,
    timeStyle,
    timeZone,
  } = props;

  const rootClassNames = classNames(styles.root, className);
  const timeTextProps = {locales, timeStyle, timeZone};

  return (
    <span className={rootClassNames}>
      <TimeText {...timeTextProps} date={start} />
      <EnDash />
      <TimeText {...timeTextProps} date={end} />
    </span>
  );
}

function EnDash() {
  return <Fragment>–</Fragment>;
}
