import React from "react";
import {
  toLongDateString,
  toTimeString,
  Translation,
} from "../../../services/i18n";
import {isUndefined} from "../../../utils";
import {CalendarData} from "../create-calendar-events";
import {SlotResource} from "../slot-resource";
import styles from "./slot-info-details.module.scss";

type SlotInfoDetailsProps = {
  calendarData: CalendarData;
};

export const SlotInfoDetails = (props: SlotInfoDetailsProps) => {
  const {calendarData} = props;
  const {appointment, slot, slotType} = calendarData;
  const start = new Date(slot.start);
  const end = new Date(slot.end);
  const duration = Math.floor((end.getTime() - start.getTime()) / 60000);
  const isAppointment = !isUndefined(appointment);
  const colorStyles = {
    backgroundColor: isAppointment ? slotType.color : "transparent",
    borderColor: slotType.color,
  };

  return (
    <div className={styles.root}>
      <div className={styles.color} style={colorStyles} />
      <div className={styles.startAndEnd}>
        {toTimeString(start)} - {toTimeString(end)}
      </div>
      <div className={styles.date}>{toLongDateString(start)}</div>
      <div className={styles.resource}>
        <SlotResource index={slot.resourceIndex} />
      </div>
      <div className={styles.name}>
        {slotType.name}{" "}
        <span className={styles.duration}>
          (
          <Translation
            tKey="calendar-events-list-duration"
            tValues={{duration}}
          />
          )
        </span>
      </div>
    </div>
  );
};
