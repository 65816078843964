import React from "react";
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {AppointmentStatus} from "../../../services/time-book-scheduling-api";
import AppointmentStatusText from "../appointment-status-text";

export default AppointmentStatusToggleButtonGroup;

const statuses: AppointmentStatus[] = [
  AppointmentStatus.BOOKED,
  AppointmentStatus.FULFILLED,
  AppointmentStatus.NO_SHOW,
];

interface AppointmentStatusToggleButtonGroupProps {
  name: string;
  onValueChange: (value: AppointmentStatus) => void;
  size?: "xs" | "sm" | "lg";
  value?: AppointmentStatus;
}

function AppointmentStatusToggleButtonGroup(
  props: AppointmentStatusToggleButtonGroupProps
) {
  const {name, onValueChange, size, value} = props;

  return (
    <ToggleButtonGroup
      name={name}
      onChange={onValueChange}
      // @ts-ignore: TS2322: Type '"xs"' is not assignable to type '"sm" | "lg" | undefined'.
      size={size}
      type="radio"
      value={value}
    >
      {statuses.map((status) => {
        return (
          <ToggleButton value={status} key={`${name}-${status}`}>
            <AppointmentStatusText status={status} />
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
