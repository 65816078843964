import {ACCEPT, APPLICATION_JSON, CONTENT_TYPE} from "./headers";
import {
  HttpRequesterConfig,
  HttpRequestConfig,
  httpRequesterFactory,
  RequestBody,
} from "./http";

export {getHttpClient};

type HttpClientMethod = <R>(
  path: string,
  body?: RequestBody,
  requestConfig?: HttpRequestConfig
) => Promise<R>;

type HttpClientDelete = HttpClientMethod;
type HttpClientGet = HttpClientMethod;
type HttpClientPatch = HttpClientMethod;
type HttpClientPost = HttpClientMethod;
type HttpClientPut = HttpClientMethod;

interface HttpClient {
  delete: HttpClientDelete;
  get: HttpClientGet;
  patch: HttpClientPatch;
  post: HttpClientPost;
  put: HttpClientPut;
}

let client: HttpClient;

function getHttpClient() {
  if (!client) {
    client = createHttpClient({defaultHeaders: createDefaultHeaders});
  }
  return client;
}

function createDefaultHeaders() {
  return {
    [ACCEPT]: APPLICATION_JSON,
    [CONTENT_TYPE]: APPLICATION_JSON,
  };
}

function createHttpClient(config: HttpRequesterConfig = {}): HttpClient {
  return {
    delete: httpRequesterFactory("DELETE", config),
    get: httpRequesterFactory("GET", config),
    patch: httpRequesterFactory("PATCH", config),
    post: httpRequesterFactory("POST", config),
    put: httpRequesterFactory("PUT", config),
  };
}
