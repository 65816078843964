import {createAction} from "@reduxjs/toolkit";

const SET_BOOKING_DATE = "SET_BOOKING_DATE";
const SET_CLINIC_ID = "SET_CLINIC_ID";
const SET_ROUTE_DATE = "SET_ROUTE_DATE";
const SET_ROUTE_PATH = "SET_ROUTE_PATH";
const SET_ROUTE_URL = "SET_ROUTE_URL";
const SET_SELECTED_SLOT_IDS = "SET_SELECTED_SLOT_IDS";
const SET_SLOT_ID = "SET_SLOT_ID";

const setBookingDateAction = createAction<Date>(SET_BOOKING_DATE);
const setClinicIdAction = createAction<string>(SET_CLINIC_ID);
const setRouteDateAction = createAction<string>(SET_ROUTE_DATE);
const setRoutePathAction = createAction<string>(SET_ROUTE_PATH);
const setRouteUrlAction = createAction<string>(SET_ROUTE_URL);
const setSelectedSlotIdsAction = createAction<string[]>(SET_SELECTED_SLOT_IDS);
const setSlotIdAction = createAction<string | undefined>(SET_SLOT_ID);

export {
  setBookingDateAction,
  setClinicIdAction,
  setRouteDateAction,
  setRoutePathAction,
  setRouteUrlAction,
  setSelectedSlotIdsAction,
  setSlotIdAction,
};
