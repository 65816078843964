import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {Loading} from "../../components/loading";
import {LoadingError} from "../../components/loading-error";
import {PageMissingMessage} from "../../components/page-missing-message";
import {useGetSlotTypeQuery} from "../../services/api/slot-types";
import type {SlotTypeRouteParams} from "../../services/routing";
import {UpdateSlotType} from "./update-slot-type";

export default SlotTypeRoute;

function SlotTypeRoute() {
  const {clinicId, slotTypeId} = useParams<SlotTypeRouteParams>();
  const routeMatch = useRouteMatch();

  const getSlotTypeQuery = useGetSlotTypeQuery({clinicId, slotTypeId});

  if (getSlotTypeQuery.isLoading) {
    return <Loading />;
  }

  if (getSlotTypeQuery.isError) {
    return <LoadingError error={getSlotTypeQuery.error} />;
  }

  if (getSlotTypeQuery.isSuccess) {
    return (
      <Switch>
        <Route path={`${routeMatch.path}`} exact>
          <Redirect to={`${routeMatch.url}/_edit`} />
        </Route>
        <Route path={`${routeMatch.path}/_edit`} exact>
          <UpdateSlotType slotType={getSlotTypeQuery.data} />
        </Route>
        <Route>
          <PageMissingMessage />
        </Route>
      </Switch>
    );
  }

  return null;
}
