import {useReducer, ReactNode} from "react";
import BookingAppFilterContext from "./booking-app-filter-context";
import bookingAppFilterReducer, {
  BookingAppFilterInit,
  initializeBookingAppFilter,
} from "./booking-app-filter-reducer";

export default BookingAppFilterProvider;

interface BookingAppFilterProviderProps {
  children: ReactNode;
  initialState?: BookingAppFilterInit;
}

function BookingAppFilterProvider(props: BookingAppFilterProviderProps) {
  const {children, initialState} = props;
  const [state, dispatch] = useReducer(
    bookingAppFilterReducer,
    initialState,
    initializeBookingAppFilter
  );

  return (
    <BookingAppFilterContext.Provider value={[state, dispatch]}>
      {children}
    </BookingAppFilterContext.Provider>
  );
}
