import {useMutation, useQueryClient} from "react-query";
import {createAppointmentsAllKey} from "../../services/api/appointments";
import {createSlotsAllKey} from "../../services/api/slots";
import {
  Appointment,
  Resident,
  Slot,
  useApiClient,
} from "../../services/time-book-scheduling-api";

type RescheduleBookingMutationVariables = {
  clinicId: string;
  clinicNotes: string;
  resident: Resident;
  slot: Slot;
};

export const useScheduleBookingMutation = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<Appointment, Error, RescheduleBookingMutationVariables>(
    (variables) => {
      const {clinicId, clinicNotes, resident, slot} = variables;

      const identifier = {
        clinicId,
        residentId: resident.id,
      };
      const input = {
        clinic: {id: clinicId},
        clinicNotes,
        resident,
        slot,
      };

      return api.createResidentAppointment(identifier, input).then((json) => {
        queryClient.invalidateQueries(createAppointmentsAllKey());
        queryClient.invalidateQueries(createSlotsAllKey());
        return Appointment.fromJSON(json);
      });
    }
  );
};
