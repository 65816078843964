import {ChangeEvent, ReactNode, useCallback, useMemo, useState} from "react";
import {hasOwn} from "../../../utils/lang";
import SingleChoiceContext from "./single-choice-context";

export default SingleChoice;

const FormControlMode = {
  CONTROLLED: "CONTROLLED",
  UNCONTROLLED: "UNCONTROLLED",
} as const;

export interface SingleChoiceProps<T> {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  name: string;
  onValueChange?: (value: T) => void;
  readOnly?: boolean;
  value?: T;
}

function SingleChoice<T extends unknown>(props: SingleChoiceProps<T>) {
  const {
    children,
    className,
    disabled = false,
    name,
    onValueChange,
    readOnly = false,
    value,
  } = props;
  const mode = getFormControlMode<T>(props);
  const [internalValue, setInternalValue] = useState<T>();

  const onChange = useCallback(
    (event: ChangeEvent<HTMLDivElement>) => {
      event.stopPropagation();

      const nextValue = (event.target as HTMLInputElement).value as T;

      if (mode === FormControlMode.UNCONTROLLED) {
        setInternalValue(nextValue);
      }

      if (typeof onValueChange === "function") {
        onValueChange(nextValue);
      }
    },
    [mode, onValueChange]
  );

  const contextValue = useMemo(() => {
    const usedValue =
      mode === FormControlMode.UNCONTROLLED ? internalValue : value;

    return {
      disabled,
      name,
      value: usedValue,
      isOptionDisabled(optionDisabled?: boolean) {
        return disabled || Boolean(optionDisabled);
      },
      isOptionReadOnly(optionReadOnly?: boolean) {
        return readOnly || Boolean(optionReadOnly);
      },
      isOptionSelected(optionValue: T) {
        return optionValue === usedValue;
      },
    };
  }, [disabled, internalValue, mode, name, readOnly, value]);

  return (
    <div onChange={onChange} className={className}>
      <SingleChoiceContext.Provider value={contextValue}>
        {children}
      </SingleChoiceContext.Provider>
    </div>
  );
}

function getFormControlMode<T>(props: SingleChoiceProps<T>) {
  if (hasOwn(props, "value")) {
    return FormControlMode.CONTROLLED;
  }

  return FormControlMode.UNCONTROLLED;
}
