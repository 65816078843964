import React, {ReactNode} from "react";
import {Resident} from "../../services/time-book-scheduling-api";
import {classNames} from "../../utils";
import {PersonalIdentificationNumber} from "../personal-identification-number";
import styles from "./booking-modal-title.module.scss";

type BookingModalTitleProps = {
  children: ReactNode;
  className?: string;
  resident: Resident;
};

export const BookingModalTitle = (props: BookingModalTitleProps) => {
  const {children, className, resident} = props;

  return (
    <h4 className={classNames(styles.root, className)}>
      {children}
      {": "}
      <PersonalIdentificationNumber value={resident.personalIdentityNumber} />
      {", "}
      {resident.name}
    </h4>
  );
};
