import React from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import {DefaultLayout} from "../../components/layouts";
import SchedulingWizard from "../../components/scheduling-wizard";

export default SchedulingRoute;

interface SchedulingRouteProps {
  clinicId: string;
}

function SchedulingRoute(props: SchedulingRouteProps) {
  const {clinicId} = props;
  const routeMatch = useRouteMatch();

  return (
    <Switch>
      <Route path={`${routeMatch.path}/wizard/:step?`}>
        <DefaultLayout>
          <SchedulingWizard clinicId={clinicId} />
        </DefaultLayout>
      </Route>
      <Route>
        <Redirect to={`${routeMatch.url}/wizard`} />
      </Route>
    </Switch>
  );
}
