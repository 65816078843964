import React, {ReactNode} from "react";
import {useTranslation} from "../../services/i18n";
import {classNames} from "../../utils";
import {LoadingIcon} from "../icon";
import styles from "./loading.module.scss";

type LoadingProps = {
  children?: ReactNode;
  className?: string;
};

export const Loading = (props: LoadingProps) => {
  const defaultMessage = useTranslation("generic-loading-message");
  const {children = defaultMessage, className} = props;

  return (
    <div className={classNames(styles.root, className)}>
      <LoadingIcon className={styles.spinner} spin />
      <div>{children}</div>
    </div>
  );
};
