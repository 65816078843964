import {UNAUTHORIZED} from "../http/http-status-code";
import HTTPError from "./http-error";

const defaultMessage = `${UNAUTHORIZED} Unauthorized`;

export default class UnauthorizedError extends HTTPError {
  constructor(message: string = defaultMessage) {
    super(message, UNAUTHORIZED);

    // Restore prototype chain
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
