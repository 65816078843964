import {isFunction} from "./is-function";

/**
 * Takes an array of objects and reduces them into an object by the name.
 * @example
 * const collection = [{id: 'id1'}];
 *
 * // Second argument can be either the property name of an item:
 * keyBy('id', collection); // {'id1': {id: 'id1'}}
 *
 * // ...or a function that returns any string:
 * const getId = (item) => item.id;
 * keyBy(getId, collection); // {'id1': {id: 'id1'}}
 */
export const keyBy = <T extends {}, P extends keyof T>(
  name: P | ((item: T) => string),
  items: T[]
) => {
  const getKey = isFunction(name) ? name : (item: T) => String(item[name]);

  return items.reduce<Record<string, T>>((accumulator, item) => {
    accumulator[getKey(item)] = item;
    return accumulator;
  }, {});
};
