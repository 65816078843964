import {useQuery} from "react-query";
import {getHttpClient} from "../transport/http";

export type TranslationsSource = {
  [key: string]: string;
};

const BASE_PATH = "/translations";

const getPath = (languageCode: string) => `${BASE_PATH}/${languageCode}.json`;

export const fetchTranslations = (languageCode: string) =>
  getHttpClient().get<TranslationsSource>(getPath(languageCode));

export const useTranslationsQuery = (languageCode: string) =>
  useQuery<TranslationsSource, Error>("GetTranslations", () =>
    fetchTranslations(languageCode)
  );
