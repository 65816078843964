import {Filter as ApiSearchFilter} from "../search-query";

const ROOT = "appointments";

export const createAppointmentsAllKey = () => [ROOT];

export const createAppointmentDetailsKey = (
  clinicId: string,
  appointmentId: string
) => [ROOT, "detail", {clinicId, appointmentId}];

export const createAppointmentsListKey = (clinicId: string) => [
  ROOT,
  "list",
  {clinicId},
];

export const createAppointmentsSearchKey = (
  clinicId: string,
  filter: ApiSearchFilter | null
) => [...createAppointmentsListKey(clinicId), {filter}];
