import {Translation} from "../../services/i18n";
import {isFiniteNumber} from "../../utils";

type SlotResourceProps = {
  className?: string;
  index?: number;
};

export const SlotResource = (props: SlotResourceProps) => {
  const {className, index} = props;

  if (!isFiniteNumber(index)) {
    return null;
  }
  const number = index + 1;

  return (
    <span className={className}>
      <Translation tKey="slot-resource" tValues={{number}} />
    </span>
  );
};
