import {
  AddOperation,
  CopyOperation,
  RemoveOperation,
  ReplaceOperation,
  TestOperation,
} from "rfc6902/diff";

export type {
  AddOperation,
  CopyOperation,
  RemoveOperation,
  ReplaceOperation,
  TestOperation,
};

export const createAddOperation = (path: string, value: any): AddOperation => ({
  op: "add",
  path,
  value,
});

export const createCopyOperation = (
  from: string,
  path: string
): CopyOperation => ({op: "copy", path, from});

export const createRemoveOperation = (path: string): RemoveOperation => ({
  op: "remove",
  path,
});

export const createReplaceOperation = (
  path: string,
  value: any
): ReplaceOperation => ({op: "replace", path, value});

export const createTestOperation = (
  path: string,
  value: any
): TestOperation => ({op: "test", path, value});
