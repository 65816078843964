import React, {useCallback} from "react";
import {useHistory} from "react-router-dom";
import {Alert, Button} from "../../components/bootstrap";
import {Card} from "../../components/card";
import {Loading} from "../../components/loading";
import {LoadingError} from "../../components/loading-error";
import {LoadingSuccess} from "../../components/loading-success";
import {useCreateSlotTypeMutation} from "../../services/api/slot-types/hooks";
import {Translation} from "../../services/i18n";
import {SlotTypeForm, SlotTypeFormValue} from "./components/slot-type-form";
import styles from "./new-slot-type-route.module.scss";

interface CreateSlotTypeProps {
  clinicId: string;
}

export const CreateSlotType = (props: CreateSlotTypeProps) => {
  const {clinicId} = props;
  const history = useHistory();

  const createSlotTypeMutation = useCreateSlotTypeMutation({clinicId});

  const onCancel = useCallback(() => history.goBack(), [history]);

  const onSlotTypeSubmit = useCallback(
    (slotTypeFormValue: SlotTypeFormValue) => {
      const input = {
        ...slotTypeFormValue,
      };

      createSlotTypeMutation.mutate({input});
    },
    [createSlotTypeMutation]
  );

  return (
    <Card className={styles.root}>
      {createSlotTypeMutation.isLoading ? (
        <Alert variant="info">
          <Loading>
            <Translation tKey="slot-type-create-loading" />
          </Loading>
        </Alert>
      ) : null}
      {createSlotTypeMutation.isError ? (
        <LoadingError error={createSlotTypeMutation.error} />
      ) : null}
      {createSlotTypeMutation.isSuccess ? (
        <div>
          <Button className={styles.backLink} onClick={onCancel} variant="link">
            <Translation tKey="slot-type-form-button-back" />
          </Button>
          <LoadingSuccess>
            <Translation tKey="slot-type-create-success" />
          </LoadingSuccess>
        </div>
      ) : null}
      <SlotTypeForm onSlotTypeSubmit={onSlotTypeSubmit} onCancel={onCancel} />
    </Card>
  );
};
