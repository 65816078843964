import {useCallback} from "react";
import {
  setBookingDateAction,
  useBookingAppState,
} from "../services/booking-app-state";

export default useBookingDate;

function useBookingDate() {
  const [state, dispatch] = useBookingAppState();

  const setBookingDate = useCallback(
    (bookingDate: Date) => {
      dispatch(setBookingDateAction(bookingDate));
    },
    [dispatch]
  );

  return [state.bookingDate, setBookingDate] as const;
}
