import {useEffect} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {NotFound} from "./components/not-found";
import {useClinicId} from "./hooks";
import {ClinicsRoute} from "./routes/clinics";
import {useTranslation, useLocale} from "./services/i18n";
import {generatePathToBooking, RoutePath} from "./services/routing";

export default MainAppRoot;

function MainAppRoot() {
  const {currentLocale} = useLocale();
  const title = useTranslation("document-title");

  useEffect(() => {
    setDocumentTitle(title);
    setHtmlLangAttribute(currentLocale.languageCode);
  });

  return (
    <Switch>
      <Route path="/" exact>
        <RedirectToSuitableRoute />
      </Route>

      <Route path={RoutePath.CLINICS}>
        <ClinicsRoute />
      </Route>

      {/* If no match - Render not found */}
      <Route component={NotFound} />
    </Switch>
  );
}

function RedirectToSuitableRoute() {
  const [clinicId] = useClinicId();

  if (clinicId) {
    return <Redirect to={generatePathToBooking({clinicId})} />;
  }

  // No user selected clinic found, redirect to clinic selector
  return <Redirect to={RoutePath.CLINICS} />;
}

function setDocumentTitle(title: string) {
  document.title = title;
}

function setHtmlLangAttribute(lang: string) {
  document.getElementsByTagName("html")[0].lang = lang;
}
