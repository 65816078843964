import {useCallback} from "react";
import {
  setSlotIdAction,
  useBookingAppState,
} from "../services/booking-app-state";

export default useSlotId;

function useSlotId() {
  const [state, dispatch] = useBookingAppState();

  const setSlotId = useCallback(
    (slotId?: string) => {
      dispatch(setSlotIdAction(slotId));
    },
    [dispatch]
  );

  return [state.slotId, setSlotId] as const;
}
