export interface ResidentInit {
  id?: string;
  address: string[];
  email: string;
  name: string;
  personalIdentityNumber: string;
  phone: string;
  version?: string;
}

export interface ResidentJSON {
  id: string;
  address: string[];
  email: null | string;
  name: string;
  personal_identity_number: string;
  phone: null | string;
  version: null | string;
}

export default class Resident {
  id: string;
  address: string[];
  email: string;
  name: string;
  personalIdentityNumber: string;
  phone: string;
  version: string;

  constructor({
    id,
    address,
    email,
    name,
    personalIdentityNumber,
    phone,
    version,
  }: ResidentInit) {
    Object.assign(this, {
      id,
      address,
      email,
      name,
      personalIdentityNumber,
      phone,
      version,
    });
  }

  static fromJSON(json: ResidentJSON) {
    const {
      id,
      address,
      email,
      name,
      personal_identity_number: personalIdentityNumber,
      phone,
      version,
    } = json;

    return new Resident({
      id,
      address,
      email: email ?? "",
      name,
      personalIdentityNumber,
      phone: phone ?? "",
      version: version ?? "",
    });
  }

  toJSON() {
    const {
      id,
      address,
      email,
      name,
      personalIdentityNumber: personal_identity_number,
      phone,
      version,
    } = this;

    return {id, address, email, name, personal_identity_number, phone, version};
  }
}
