type AnyFunction = (...args: any[]) => any;

type MapLike = {
  has: (key: any) => boolean;
  get: (key: any) => any;
  set: (key: any, value: any) => MapLike;
};

type CacheKeyFunction<ProducerFunctionArgs extends unknown[], Key = any> = (
  ...args: ProducerFunctionArgs
) => Key;

const stringifyArgs = <ProducerFunctionArgs extends unknown[]>(
  ...args: ProducerFunctionArgs
) => JSON.stringify(args);

export const memoize = <ProducerFunction extends AnyFunction>(
  producer: ProducerFunction,
  cacheKey: CacheKeyFunction<Parameters<ProducerFunction>> = stringifyArgs,
  cache: MapLike = new Map()
) => {
  return (
    ...args: Parameters<ProducerFunction>
  ): ReturnType<ProducerFunction> => {
    const key = cacheKey(...args);

    if (cache.has(key)) {
      cache.get(key);
    }
    const result = producer(...args);

    cache.set(key, result);
    return result;
  };
};
