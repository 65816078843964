import React, {useCallback} from "react";
import {useHistory} from "react-router-dom";
import {useUpdateClinicMutation} from "../../services/api/clinics";
import {Translation} from "../../services/i18n";
import {Clinic} from "../../services/time-book-scheduling-api";
import {Alert} from "../bootstrap";
import {Card} from "../card";
import {Loading} from "../loading";
import {LoadingError} from "../loading-error";
import {LoadingSuccess} from "../loading-success";
import {ClinicForm, ClinicFormValue} from "./clinic-form";

export default EditClinic;

interface EditClinicProps {
  clinic: Clinic;
}

function EditClinic(props: EditClinicProps) {
  const {clinic} = props;
  const history = useHistory();

  const updateClinicMutation = useUpdateClinicMutation({
    clinicId: clinic.id,
    clinicVersion: clinic.version,
  });

  const onCancel = useCallback(() => history.goBack(), [history]);

  const onClinicSubmit = useCallback(
    (clinicData: ClinicFormValue) => {
      const {
        address,
        externalBookingStartRestriction,
        phone,
        enableSelectSlotType,
      } = clinicData;

      updateClinicMutation.mutate({
        input: {
          ...clinic,
          address,
          externalBookingStartRestriction,
          phone,
          enableSelectSlotType,
        },
      });
    },
    [clinic, updateClinicMutation]
  );

  return (
    <Card>
      {updateClinicMutation.isLoading ? (
        <Alert variant="info">
          <Loading>
            <Translation tKey="clinic-form-update-loading" />
          </Loading>
        </Alert>
      ) : null}
      {updateClinicMutation.isError ? (
        <LoadingError error={updateClinicMutation.error} />
      ) : null}

      {updateClinicMutation.isSuccess ? (
        <LoadingSuccess>
          <Translation tKey="clinic-form-update-success" />
        </LoadingSuccess>
      ) : null}
      <ClinicForm
        clinic={clinic}
        onCancel={onCancel}
        onClinicSubmit={onClinicSubmit}
      />
    </Card>
  );
}
