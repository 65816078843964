import React, {useMemo} from "react";
import {
  ApiClient,
  ApiClientProvider,
} from "../services/time-book-scheduling-api";
import {InternalProviderProps} from "./types/internal-provider";

export default InternalApiClientProvider;

function InternalApiClientProvider(props: InternalProviderProps) {
  const apiClient = useMemo(() => {
    return new ApiClient({serviceRootUrl: "/api"});
  }, []);

  return (
    <ApiClientProvider client={apiClient}>{props.children}</ApiClientProvider>
  );
}
