import {useClinicId} from "../../hooks";
import ResidentLink from "../../routes/residents/components/resident-link";
import {Resident} from "../../services/time-book-scheduling-api";
import {isNonEmptyString} from "../../utils";
import {Address} from "../address";
import {Email} from "../email";
import {PersonalIdentificationNumber} from "../personal-identification-number";
import PhoneNumber from "../phone-number";

export default ResidentInfo;

interface Props {
  className?: string;
  resident: Resident;
}

function ResidentInfo(props: Props) {
  const {className, resident} = props;
  const [clinicId] = useClinicId();

  if (!resident) {
    return null;
  }
  return (
    <div className={className}>
      <div>
        <PersonalIdentificationNumber value={resident.personalIdentityNumber} />
      </div>
      <div>
        {isNonEmptyString(clinicId) ? (
          <ResidentLink clinicId={clinicId} residentId={resident.id}>
            {resident.name}
          </ResidentLink>
        ) : (
          resident.name
        )}
      </div>
      <div>
        <Address value={resident.address} />
      </div>
      <div>
        <PhoneNumber value={resident.phone} />
      </div>
      <div>
        <Email value={resident.email} />
      </div>
    </div>
  );
}
