import classNames from "classnames";
import {Translation} from "../../services/i18n";
import {
  Appointment,
  AppointmentOrigin as AppointmentOriginEnum,
} from "../../services/time-book-scheduling-api";
import {GlobeIcon} from "../icon";
import styles from "./appointment-origin.module.scss";

export default AppointmentOrigin;

interface AppointmentOriginProps {
  appointment: Appointment;
  className?: string;
}

function AppointmentOrigin(props: AppointmentOriginProps) {
  const {appointment, className} = props;

  if (isBookedExternally(appointment)) {
    return (
      <div className={classNames(styles.root, className)}>
        <GlobeIcon className={styles.icon} />
        <Translation tKey="appointment-origin-external" />
      </div>
    );
  }
  return null;
}

function isBookedExternally(appointment: Appointment) {
  return appointment.origin === AppointmentOriginEnum.EXTERNAL;
}
