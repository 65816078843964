import {useQuery, UseQueryOptions} from "react-query";
import {Resident} from "../../../time-book-scheduling-api";
import {createSearchEntitiesByIdFilter as createSearchResidentsByIdFilter} from "../../search-query";
import useResidentQueryCreators from "./use-resident-query-creators";

export default useGetResidentsQuery;

interface GetResidentsQueryVariables {
  residentIds: string[];
}

type GetResidentsQueryOptions = UseQueryOptions<Resident[], Error>;

function useGetResidentsQuery(
  variables: GetResidentsQueryVariables,
  options: GetResidentsQueryOptions = {}
) {
  const {createSearchResidentsQuery} = useResidentQueryCreators();
  const {residentIds} = variables;

  const useGetResidentsOptions =
    residentIds.length === 0
      ? createEmptyListQuery<Resident[], Error>(options)
      : createSearchResidentsQuery(
          {filter: createSearchResidentsByIdFilter(residentIds)},
          options
        );
  return useQuery(useGetResidentsOptions);
}

function createEmptyListQuery<TQueryFnData, TError>(
  options: UseQueryOptions<TQueryFnData, TError>
): UseQueryOptions<TQueryFnData, TError> {
  return {
    ...options,
    queryFn() {
      return Promise.resolve([] as unknown as TQueryFnData);
    },
    queryKey: ["empty", "list"],
  };
}
