import {Switch, Route, useRouteMatch} from "react-router-dom";
import {Permission} from "../../services/auth";
import {Clinic} from "../../services/time-book-scheduling-api";
import {Authorization, AuthorizationError} from "../authorization";
import {DefaultLayout} from "../layouts";
import {PageMissingMessage} from "../page-missing-message";
import ClinicDetails from "./clinic-details";
import EditClinic from "./edit-clinic";

export default ClinicApp;

interface ClinicAppProps {
  clinic: Clinic;
}

function ClinicApp(props: ClinicAppProps) {
  const {clinic} = props;
  const routeMatch = useRouteMatch();

  return (
    <DefaultLayout>
      <Switch>
        <Route path={routeMatch.path} exact>
          <ClinicDetails clinic={clinic} />
        </Route>
        <Route path={`${routeMatch.path}/_edit`} exact>
          <Authorization
            requiredPermission={Permission.ADMINISTRATE}
            unauthorized={<AuthorizationError />}
          >
            <EditClinic clinic={clinic} />
          </Authorization>
        </Route>
        <Route>
          <PageMissingMessage />
        </Route>
      </Switch>
    </DefaultLayout>
  );
}
