import {useReducer, ReactNode} from "react";
import BookingAppStateContext, {
  BookingAppStateInit,
  initializeBookingAppState,
} from "./booking-app-state-context";
import bookingAppStateReducer from "./booking-app-state-reducer";

export default BookingAppStateProvider;

interface BookingAppStateProviderProps {
  children: ReactNode;
  initialState: BookingAppStateInit;
}

function BookingAppStateProvider(props: BookingAppStateProviderProps) {
  const {children, initialState} = props;
  const [state, dispatch] = useReducer(
    bookingAppStateReducer,
    initialState,
    initializeBookingAppState
  );

  return (
    <BookingAppStateContext.Provider value={[state, dispatch]}>
      {children}
    </BookingAppStateContext.Provider>
  );
}
