import invariant from "invariant";
import {useMutation, useQueryClient} from "react-query";
import {isUndefined} from "../../../../utils";
import {
  ApiError,
  Appointment,
  PatchAppointmentInput,
  StrictAppointmentIdentifier,
} from "../../../time-book-scheduling-api";
import {useApi} from "../../use-api";
import {
  createAppointmentDetailsKey,
  createAppointmentsListKey,
} from "../query-key-creators";

export default usePatchAppointmentMutation;

interface PatchAppointmentMutationVariables {
  identifier?: StrictAppointmentIdentifier;
  input: PatchAppointmentInput;
}

function usePatchAppointmentMutation(
  initIdentifier?: StrictAppointmentIdentifier
) {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<Appointment, ApiError, PatchAppointmentMutationVariables>(
    (variables) => {
      const {identifier, input} = variables;

      invariant(
        !isUndefined(initIdentifier) || !isUndefined(identifier),
        "An appointment identifier is required"
      );

      const {appointmentId, appointmentVersion, clinicId} = Object.assign(
        {},
        initIdentifier,
        identifier
      );

      return api
        .patchAppointment({clinicId, appointmentId, appointmentVersion}, input)
        .then((appointmentData) => Appointment.fromJSON(appointmentData));
    },
    {
      onSuccess(nextAppointment) {
        const {clinic} = nextAppointment;

        const appointmentDetailsQueryKey = createAppointmentDetailsKey(
          clinic.id,
          nextAppointment.id
        );
        const appointmentListQueryKey = createAppointmentsListKey(clinic.id);

        // Update cache entry for single appointment
        queryClient.setQueryData(appointmentDetailsQueryKey, nextAppointment);

        // Update cache entry for appointment in lists
        queryClient.setQueriesData<Appointment[]>(
          {
            queryKey: appointmentListQueryKey,
            predicate(query) {
              return (
                query.state.status === "success" &&
                (query.state.data as Appointment[]).some((appointment) => {
                  return appointment.id === nextAppointment.id;
                })
              );
            },
          },
          (appointments = [] as Appointment[]) => {
            for (const appointment of appointments) {
              if (appointment.id === nextAppointment.id) {
                Object.assign(appointment, nextAppointment);

                // break loop once we found the appointment
                break;
              }
            }

            return [...appointments];
          }
        );

        // Invalidate all queries
        void queryClient.invalidateQueries(appointmentDetailsQueryKey);
        void queryClient.invalidateQueries(appointmentListQueryKey);
      },
    }
  );
}
