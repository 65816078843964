import {useMutation, useQueryClient} from "react-query";
import {useApi} from "../../../../services/api";
import {
  ApiError,
  PatchSlotInput,
  Slot,
} from "../../../../services/time-book-scheduling-api";
import {Queue} from "../../../../utils";
import {createSlotDetailsKey, createSlotsListKey} from "../query-key-creators";

export default usePatchSlotsMutation;

interface PatchSlotsMutationProps {
  slots?: Slot[];
}

interface PatchSlotsMutationVariables {
  input: PatchSlotInput;
}

function usePatchSlotsMutation(props: PatchSlotsMutationProps) {
  const {slots = []} = props;

  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<Slot[], ApiError, PatchSlotsMutationVariables>(
    (variables) => {
      const {input} = variables;

      const tasks = slots.map((slot) => {
        const identifier = {
          clinicId: slot.clinic.id,
          slotId: slot.id,
          slotVersion: slot.version,
        };

        return () => {
          return api.patchSlot(identifier, input).then((json) => {
            return Slot.fromJSON(json);
          });
        };
      });

      return new Queue({concurrency: 4}).addAll(tasks);
    },
    {
      onSuccess() {
        for (const clinicId of selectUniqueClinicIdsFromSlots(slots)) {
          void queryClient.invalidateQueries(createSlotsListKey(clinicId));
        }

        for (const slot of slots) {
          const clinicId = slot.clinic.id;
          const slotId = slot.id;

          void queryClient.invalidateQueries(
            createSlotDetailsKey(clinicId, slotId)
          );
        }
      },
    }
  );
}

function selectUniqueClinicIdsFromSlots(slots: Slot[] = []) {
  const allClinicIds = slots.map((slot) => slot.clinic.id);

  return [...new Set(allClinicIds)];
}
