export {joinPaths};

function joinPaths(base: string, ...segments: string[]) {
  const prepared = [base, ...segments].map((segment, index) => {
    const trimmed = segment.trim();

    // Quick pass
    if (trimmed === "" || trimmed === "/") {
      return trimmed;
    }
    // Do not mess with leading slashes on the base segment because we want
    // to support protocol-relative URLs.
    return index === 0
      ? removeTrailingSlash(trimmed)
      : ensureSingleLeadingSlash(removeTrailingSlash(trimmed));
  });
  const joined = prepared.join("");
  const isSlashesOnly = /^\/+$/.test(joined);

  return isSlashesOnly ? "/" : joined;
}

function ensureSingleLeadingSlash(value: string) {
  return `/${value}`.replace(/^\/+/, "/");
}

function removeTrailingSlash(value: string) {
  return value.replace(/\/+$/, "");
}
