import React from "react";
import {classNames} from "../../utils";
import styles from "./logo.module.scss";
import {ReactComponent as SvgSkane} from "./skane.svg";

type LogoProps = {
  className?: string;
  height?: number | string;
  width?: number | string;
};

export const Logo = (props: LogoProps) => {
  const {className, ...otherProps} = props;
  const classes = classNames(styles.root, className);

  return <SvgSkane {...otherProps} className={classes} />;
};
