import {useEffect} from "react";
import invariant from "invariant";
import {Route, Switch, useParams, useRouteMatch} from "react-router-dom";
import {Translation} from "../../../../services/i18n";
import {isNonEmptyString} from "../../../../utils";
import {useClinicId, useSlotId} from "../../hooks";
import BookingAppAsideSlot from "../booking-app-aside-slot";
import styles from "./booking-app-aside.module.scss";

export default BookingAppAside;

function BookingAppAside() {
  const routeMatch = useRouteMatch();

  return (
    <div className={styles.root}>
      <Switch>
        <Route path={`${routeMatch.path}/:slotId`}>
          <BookingAppAsideSlotRoute />
        </Route>
        <Route>
          <BookingAppAsideNoSlot />
        </Route>
      </Switch>
    </div>
  );
}

interface BookingAppAsideSlotRouteParams {
  slotId: string;
}

function BookingAppAsideSlotRoute() {
  const params = useParams<BookingAppAsideSlotRouteParams>();
  const [clinicId] = useClinicId();
  const [slotId, setSlotId] = useSlotId();

  invariant(
    isNonEmptyString(params.slotId),
    "Required slotId param missing in BookingAppSlotRoute."
  );

  useEffect(() => {
    setSlotId(params.slotId);
  }, [params.slotId, setSlotId]);

  if (isNonEmptyString(slotId)) {
    return (
      <BookingAppAsideSlot clinicId={clinicId} slotId={slotId} key={slotId} />
    );
  }

  return null;
}

function BookingAppAsideNoSlot() {
  const [, setSlotId] = useSlotId();

  useEffect(() => {
    setSlotId(undefined);
  }, [setSlotId]);

  return <Translation tKey="calendar-event-details-no-details" />;
}
