import {SlotType} from "../../services/time-book-scheduling-api";
import {classNames, isNonEmptyString} from "../../utils";
import {SlotTypeColor} from "./slot-type-color";
import styles from "./slot-type-label.module.scss";

type SlotTypeLabelProps = {
  className?: string;
  slotType?: SlotType;
};

export const SlotTypeLabel = (props: SlotTypeLabelProps) => {
  const {className, slotType} = props;
  const classes = classNames(styles.root, className);

  if (!slotType || !isNonEmptyString(slotType.name)) {
    return null;
  }
  return (
    <span className={classes}>
      <SlotTypeColor slotType={slotType} />
      {slotType.name}
    </span>
  );
};
