import EntityReference from "./entity-reference";
import {SlotTypeVisibility, toArray, toBitmask} from "./slot-type-visibility";

interface SlotTypeInit {
  id?: string;
  name: string;
  color: string;
  duration: string;
  visibility: SlotTypeVisibility;
  clinic?: EntityReference;
  version?: string;
}

export interface SlotTypeJSON {
  id: string;
  name: string;
  color: null | string;
  duration: string;
  is_visible_for_residents: boolean;
  visibility: SlotTypeVisibility | string;
  clinic: EntityReference;
  version: null | string;
}

export default class SlotType {
  id: string;
  name: string;
  color: string;
  duration: string;
  visibility: SlotTypeVisibility;
  clinic: EntityReference;
  version: string;

  constructor({
    id,
    name,
    color,
    duration,
    visibility,
    clinic,
    version,
  }: SlotTypeInit) {
    Object.assign(this, {
      id,
      name,
      color,
      duration,
      visibility,
      clinic,
      version,
    });
  }

  static fromJSON(json: SlotTypeJSON) {
    const {id, name, color, duration, visibility, clinic, version} = json;

    return new SlotType({
      id,
      name,
      color: color ?? "",
      duration,
      visibility:
        typeof visibility === "number"
          ? visibility
          : toBitmask(toArray(visibility)),
      clinic,
      version: version ?? "",
    });
  }

  toJSON() {
    const {id, name, color, duration, visibility, clinic, version} = this;

    return {
      id,
      name,
      color,
      duration,
      visibility,
      clinic,
      version,
    };
  }
}
