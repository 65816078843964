import {useContext} from "react";
import {I18nContext, I18nContextOutOfBoundsError} from "./i18n-context";

export const useLocale = () => {
  const context = useContext(I18nContext);

  if (context === null) {
    throw new I18nContextOutOfBoundsError();
  }
  const {availableLocales, currentLocale, setLocale} = context;

  return {availableLocales, currentLocale, setLocale};
};
