import {Table} from "../../../components/bootstrap";
import {SlotTypeColor} from "../../../components/calendar/slot-type-color";
import {Translation} from "../../../services/i18n";
import {SlotType} from "../../../services/time-book-scheduling-api/entities";
import SlotTypeDuration from "./slot-type-duration";
import SlotTypeLink from "./slot-type-link";
import SlotTypeVisibleForResidents from "./slot-type-visible-for-residents";

type SlotTypesTableProps = {
  slotTypes?: SlotType[];
};

export const SlotTypesTable = (props: SlotTypesTableProps) => {
  const {slotTypes = []} = props;

  return (
    <Table striped bordered>
      <thead>
        <tr>
          <th>#</th>
          <th>
            <Translation tKey="slot-type-list-table-heading-name" />
          </th>
          <th>
            <Translation tKey="slot-type-list-table-heading-color" />
          </th>
          <th>
            <Translation tKey="slot-type-list-table-heading-duration" />
          </th>
          <th>
            <Translation tKey="slot-type-list-table-heading-resident-visible" />
          </th>
          <th>
            <Translation tKey="slot-type-list-table-heading-edit" />
          </th>
        </tr>
      </thead>
      <tbody>
        {slotTypes.map((slotType, index) => (
          <tr key={slotType.id}>
            <td>{index + 1}</td>
            <td>{slotType.name}</td>
            <td>
              <SlotTypeColor slotType={slotType} />
            </td>
            <td>
              <SlotTypeDuration slotType={slotType} />
            </td>
            <td>
              <SlotTypeVisibleForResidents slotType={slotType} />
            </td>
            <td>
              <SlotTypeLink
                clinicId={slotType.clinic.id}
                slotTypeId={slotType.id}
                edit
              >
                <Translation tKey="slot-type-list-table-heading-edit" />
              </SlotTypeLink>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
