import {memoize} from "../memoize";

type Locales = string | string[];
type DateTimeFormatOptionsWeekday = "long" | "narrow" | "short";

const getWeekdayNames = memoize(
  (locales: Locales, option: DateTimeFormatOptionsWeekday) => {
    const formatter = new Intl.DateTimeFormat(locales, {weekday: option});

    return [
      formatter.format(new Date(2021, 0, 3)),
      formatter.format(new Date(2021, 0, 4)),
      formatter.format(new Date(2021, 0, 5)),
      formatter.format(new Date(2021, 0, 6)),
      formatter.format(new Date(2021, 0, 7)),
      formatter.format(new Date(2021, 0, 8)),
      formatter.format(new Date(2021, 0, 9)),
    ];
  }
);

/**
 * Returns a list of weekday names, the long version.
 * @param locales - As default, the browser's default locale will be used
 */
export const getLongWeekdayNames = (locales: Locales = []) =>
  getWeekdayNames(locales, "long");

/**
 * Returns a list of weekday names, the narrow version.
 * @param locales - As default, the browser's default locale will be used
 */
export const getNarrowWeekdayNames = (locales: Locales = []) =>
  getWeekdayNames(locales, "narrow");

/**
 * Returns a list of weekday names, the short version.
 * @param locales - As default, the browser's default locale will be used
 */
export const getShortWeekdayNames = (locales: Locales = []) =>
  getWeekdayNames(locales, "short");
