import {Route, Switch, useRouteMatch} from "react-router-dom";
import ClinicRoute from "./clinic-route";
import SelectClinicRoute from "./select-clinic-route";

export default ClinicsRoute;

function ClinicsRoute() {
  const routeMatch = useRouteMatch();

  return (
    <Switch>
      <Route path={routeMatch.path} exact>
        <SelectClinicRoute />
      </Route>
      <Route path={`${routeMatch.path}/:clinicId`}>
        <ClinicRoute />
      </Route>
    </Switch>
  );
}
