import ReactDayPicker, {DayPickerProps} from "react-day-picker";
import "react-day-picker/lib/style.css";
import {useLocale} from "../../services/i18n";
import {localeSettings} from "./day-picker-i18n";

export {DayPicker};

function DayPicker(props: DayPickerProps) {
  const {currentLocale} = useLocale();

  return (
    <ReactDayPicker
      locale={currentLocale.languageCode}
      localeUtils={localeSettings}
      {...props}
    />
  );
}
