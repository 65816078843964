import React from "react";
import {formatPhoneNumber} from "../services/api/utils";
import {isNonEmptyString} from "../utils";

export default PhoneNumber;

type PhoneNumberProps = {
  value: string;
};

function PhoneNumber(props: PhoneNumberProps) {
  const {value} = props;

  if (!isNonEmptyString(value)) {
    return null;
  }
  const formatted = formatPhoneNumber(value);

  return <a href={`tel:${formatted}`}>{formatted}</a>;
}
