import React from "react";
import {classNames} from "../../../utils";
import styles from "./color-selector.module.scss";

interface ColorSelectorProps {
  className?: string;
  colors: string[];
  defaultColor?: string;
  name: string;
}

export const ColorSelector = (props: ColorSelectorProps) => {
  const {className, colors, defaultColor, name} = props;

  return (
    <div className={classNames(styles.root, className)}>
      <ul className={styles.items}>
        {colors.map((color) => {
          const id = `${name}_${color}`;

          return (
            <li className={styles.item} key={id}>
              <input
                className={styles.itemInput}
                defaultChecked={defaultColor === color}
                id={id}
                name={name}
                type="radio"
                value={color}
              />
              <label
                className={styles.itemLabel}
                htmlFor={id}
                style={{backgroundColor: color}}
              >
                &#x25cf;
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
