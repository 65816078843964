import {ErrorBoundary} from "./components/error-boundary";
import MainAppRoot from "./main-app-root";
import MainAppShell from "./main-app-shell";

export default MainApp;

function MainApp() {
  return (
    <ErrorBoundary name="MainApp">
      <MainAppShell>
        <MainAppRoot />
      </MainAppShell>
    </ErrorBoundary>
  );
}
