import {useMemo} from "react";
import {useQuery} from "react-query";
import {createFilter} from "../../services/api";
import {useGetSlotTypesQuery} from "../../services/api/slot-types/hooks";
import {useSearchSlotsQuery} from "../../services/api/slots/hooks";
import {ApiError, SlotStatus} from "../../services/time-book-scheduling-api";
import {keyBy} from "../../utils";
import {getLastDataUpdatedAt} from "../../utils/react-query";
import {CalendarData} from "../calendar";

export {useBookAppointmentQuery};

interface UseBookAppointmentQueryVariables {
  clinicId: string;
  start: Date;
  end: Date;
}

function useBookAppointmentQuery(variables: UseBookAppointmentQueryVariables) {
  const {clinicId, start, end} = variables;

  // SlotTypes
  const slotTypesQuery = useGetSlotTypesQuery({clinicId});

  // Slots
  const filter = useMemo(
    () => createSearchSlotsFilter(start, end),
    [start, end]
  );
  const slotsQuery = useSearchSlotsQuery({clinicId, filter});

  const queries = [slotTypesQuery, slotsQuery].filter(
    (queryResult) => !queryResult.isIdle
  );
  const dataUpdatedAt = getLastDataUpdatedAt(queries);
  const error = queries.find((result) => result.error !== null)?.error ?? null;
  const isError = queries.some((result) => result.isError);
  const isFetching = queries.some((result) => result.isFetching);
  const isLoading = queries.some((result) => result.isLoading);
  const isLoadingError = queries.some((result) => result.isLoadingError);
  const isRefetchError = queries.some((result) => result.isRefetchError);
  const isSuccess = queries.every((result) => result.isSuccess);

  const slotTypesMap = useMemo(
    () => keyBy("id", slotTypesQuery.data ?? []),
    [slotTypesQuery.data]
  );

  const queryKey = useMemo(() => {
    if (isSuccess) {
      return [
        "useBookAppointmentQuery",
        {
          clinicId,
          start,
          end,
          dataUpdatedAt,
        },
      ];
    }
    return ["useBookAppointmentQuery"];
  }, [isSuccess, clinicId, start, end, dataUpdatedAt]);

  const calendarDataResult = useQuery<CalendarData[] | undefined, ApiError>(
    queryKey,
    () => {
      if (slotsQuery.data) {
        return slotsQuery.data.map((slot) => {
          const slotType = slotTypesMap[slot.slotType.id];

          return {slot, slotType};
        });
      }
    },
    // Do not cache compound queries
    {cacheTime: 0}
  );

  return {
    ...calendarDataResult,
    error: error || calendarDataResult.error,
    isError: isError || calendarDataResult.isError,
    isFetching: isFetching || calendarDataResult.isFetching,
    isLoading: isLoading || calendarDataResult.isLoading,
    isLoadingError: isLoadingError || calendarDataResult.isLoadingError,
    isRefetchError: isRefetchError || calendarDataResult.isRefetchError,
    isSuccess: isSuccess || calendarDataResult.isSuccess,
  };
}

function createSearchSlotsFilter(start: Date, end: Date) {
  return createFilter([
    {
      operator: "gt",
      property: "start",
      value: start.toISOString(),
    },
    {
      operator: "lt",
      property: "end",
      value: end.toISOString(),
    },
    {
      operator: "eq",
      property: "appointment",
      value: null,
    },
    {
      operator: "eq",
      property: "status",
      value: SlotStatus.CREATED,
    },
  ]);
}
