import {HTTPError, NetworkError, UnauthorizedError} from "./errors";
import {ACCEPT} from "./http/http-header";
import {GET} from "./http/http-method";
import {APPLICATION_JSON} from "./http/media-type";
import {HTTPStatusCode} from "./http/types";
import {SessionJSON} from "./types";

export default fetchSession;

function fetchSession(): Promise<SessionJSON> {
  return fetch("/auth/session", {
    method: GET,
    cache: "no-store",
    headers: {
      [ACCEPT]: APPLICATION_JSON,
    },
    redirect: "error",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        }

        if (response.status === 401) {
          throw new UnauthorizedError();
        } else {
          throw new HTTPError(
            `${response.status} ${response.statusText}`,
            response.status as HTTPStatusCode
          );
        }
      },
      () => {
        throw new NetworkError();
      }
    )
    .then((response) => {
      const contentType = response.headers.get("Content-Type");

      if (contentType?.includes("application/json")) {
        return response.json();
      }
    })
    .catch((error: Error) => {
      if (process.env.NODE_ENV === "development") {
        console.error(error);
      }

      throw error;
    });
}
