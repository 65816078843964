import {QueryStatus} from "react-query";

export default getDerivedBooleans;

interface DerivedBooleans {
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}

function getDerivedBooleans(status: QueryStatus): DerivedBooleans {
  const derivedBooleans = {
    isError: false,
    isIdle: false,
    isLoading: false,
    isSuccess: false,
  };

  switch (status) {
    case "error":
      derivedBooleans.isError = true;
      break;
    case "idle":
      derivedBooleans.isIdle = true;
      break;
    case "loading":
      derivedBooleans.isLoading = true;
      break;
    case "success":
      derivedBooleans.isSuccess = true;
      break;
    default:
      throw new TypeError(`Unknown status: ${status}.`);
  }

  return derivedBooleans;
}
