const ROOT = "clinics";

export const createClinicsAllKey = () => [ROOT];

export const createClinicDetailsKey = (clinicId: string) => [
  ROOT,
  "detail",
  {clinicId},
];

export const createClinicsListKey = () => [ROOT, "list"];
