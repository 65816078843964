import {useCallback} from "react";
import {useHistory, useLocation} from "react-router-dom";
import BookingsTable from "../../../bookings-table";
import {ALL, ROW_ACTIONS, START_DATE} from "../../../bookings-table/column-ids";
import type {
  BookingsTableProps,
  BookingsTableRow,
} from "../../../bookings-table/types";
import {
  useGeneratePath,
  useRouteDate,
  useSelectedSlotIds,
  useSlotId,
} from "../../hooks";

export default BookingAppBookingsTable;

// Select ALL columns then exclude START_DATE and ROW_ACTIONS
const visibleColumns: number = ALL ^ START_DATE ^ ROW_ACTIONS;

type BookingAppBookingsTableProps = Pick<BookingsTableProps, "data">;

function BookingAppBookingsTable(props: BookingAppBookingsTableProps) {
  const {data} = props;

  const generatePath = useGeneratePath();
  const history = useHistory();
  const location = useLocation();
  const [routeDate] = useRouteDate();

  const [selectedSlotIds, setSelectedSlotIds] = useSelectedSlotIds();
  const [slotId] = useSlotId();

  const navigateToSlot = useCallback(
    (slotId: string) => {
      const pathname = generatePath("/:date/:slotId", {
        date: routeDate,
        slotId,
      });

      history.push({
        pathname,
        search: location.search,
      });
    },
    [generatePath, history, location.search, routeDate]
  );

  const onRowClick = useCallback(
    (row: BookingsTableRow) => {
      const {slot} = row.original;

      navigateToSlot(slot.id);
    },
    [navigateToSlot]
  );

  const onSelectedSlotIdsChange = useCallback(
    (selectedSlotIds: string[]) => {
      setSelectedSlotIds(selectedSlotIds);
    },
    [setSelectedSlotIds]
  );

  return (
    <BookingsTable
      activeSlotId={slotId}
      data={data}
      onRowClick={onRowClick}
      onSelectedSlotIdsChange={onSelectedSlotIdsChange}
      selectedSlotIds={selectedSlotIds}
      visibleColumns={visibleColumns}
    />
  );
}
