import {createContext, Dispatch, ReducerAction} from "react";
import bookingAppFilterReducer, {
  BookingAppFilter,
} from "./booking-app-filter-reducer";

const BookingAppFilterContext = createContext<
  | [BookingAppFilter, Dispatch<ReducerAction<typeof bookingAppFilterReducer>>]
  | null
>(null);
BookingAppFilterContext.displayName = "BookingAppFilterStateContext";

export default BookingAppFilterContext;
