import {useQuery} from "react-query";
import {toMilliseconds} from "../../../../utils/time/duration";
import useSlotTypeQueryCreators, {
  GetSlotTypesQueryOptions,
  GetSlotTypesQueryVariables,
} from "./use-slot-type-query-creators";

export default useGetSlotTypesQuery;

function useGetSlotTypesQuery(
  variables: GetSlotTypesQueryVariables,
  options?: GetSlotTypesQueryOptions
) {
  const {createGetSlotTypesQuery} = useSlotTypeQueryCreators();

  return useQuery(
    createGetSlotTypesQuery(variables, {
      // slot types rarely changes and if they do
      // the mutator should invalidate this query
      cacheTime: toMilliseconds({minutes: 30}),
      staleTime: Infinity,
      ...options,
    })
  );
}
