import {createAction} from "@reduxjs/toolkit";
import {AppointmentStatus} from "../../../../services/time-book-scheduling-api";
import {
  BookingAppFilter,
  BookingAppFilterInit,
} from "./booking-app-filter-reducer";
import {BookingType} from "./booking-app-filter-types";

const RESET_FILTER = "RESET_FILTER";
const SET_FILTER = "SET_FILTER";
const SET_APPOINTMENT_STATUS = "SET_APPOINTMENT_STATUS";
const SET_BOOKING_TYPE = "SET_BOOKING_TYPE";
const SET_RESOURCE = "SET_RESOURCE";
const SET_SLOT_TYPE = "SET_SLOT_TYPE";
const SET_SLOT_STATUS = "SET_SLOT_STATUS";

const resetFilterAction = createAction<BookingAppFilterInit | undefined>(
  RESET_FILTER
);
const setFilterAction = createAction<BookingAppFilter>(SET_FILTER);
const setAppointmentStatusAction = createAction<AppointmentStatus | undefined>(
  SET_APPOINTMENT_STATUS
);
const setBookingTypeAction = createAction<BookingType | undefined>(
  SET_BOOKING_TYPE
);
const setResourceAction = createAction<string | undefined>(SET_RESOURCE);
const setSlotTypeAction = createAction<string | undefined>(SET_SLOT_TYPE);

const setSlotStatusAction = createAction<string | undefined>(SET_SLOT_STATUS);

export {
  resetFilterAction,
  setFilterAction,
  setAppointmentStatusAction,
  setBookingTypeAction,
  setResourceAction,
  setSlotTypeAction,
  setSlotStatusAction,
};
