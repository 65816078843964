/**
 * DateTime style format values
 * https://tc39.es/ecma402/#sec-date-time-style-format
 */
export enum DateTimeStyleFormat {
  Full = "full",
  Long = "long",
  Medium = "medium",
  Short = "short",
}
