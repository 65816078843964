const ROOT = "slot-types";

export const createSlotTypesAllKey = () => [ROOT];

export const createSlotTypeDetailsKey = (
  clinicId: string,
  slotTypeId: string
) => [ROOT, "detail", {clinicId, slotTypeId}];

export const createSlotTypesListKey = (clinicId: string) => [
  ROOT,
  "list",
  {clinicId},
];
