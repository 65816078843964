import React, {ReactNode, useCallback} from "react";
import {EventApi} from "@fullcalendar/react";
import {RemoveSlotRangeIcon} from "../../icon";
import styles from "./slot-range-event-content.module.scss";

export default SlotRangeEventContent;

interface SlotRangeEventContentProps {
  children: ReactNode;
  event: EventApi;
}

function SlotRangeEventContent(props: SlotRangeEventContentProps) {
  const {children, event} = props;

  const onRemoveIconClick = useCallback(() => {
    event.remove();
  }, [event]);

  const heading = event.title;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h6 className={styles.heading}>{heading}</h6>
        <div className={styles.action}>
          <RemoveSlotRangeIcon onClick={onRemoveIconClick} />
        </div>
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  );
}
