import React, {MouseEvent, useCallback} from "react";
import {Translation} from "../../services/i18n";
import {isToday} from "../../utils";
import {useToday} from "../../utils/react";
import {Button, ButtonProps} from "../bootstrap";

type TodayButtonProps = ButtonProps & {
  date: Date;
  setDate: (nextDate: Date) => void;
};

export const TodayButton = (props: TodayButtonProps) => {
  const {date, disabled, onClick, setDate, ...otherProps} = props;
  const todayAtNoon = useToday({hours: 12});

  const isDisabled = disabled === true || isToday(date);

  const onClickProxy = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setDate(todayAtNoon);
      onClick?.(event);
    },
    [onClick, setDate, todayAtNoon]
  );

  return (
    <Button {...otherProps} disabled={isDisabled} onClick={onClickProxy}>
      <Translation tKey="today-button-text" />
    </Button>
  );
};
