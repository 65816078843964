import {Action} from "@reduxjs/toolkit";
import {
  AppointmentStatus,
  isAppointmentStatus,
  SlotStatus,
} from "../../../../services/time-book-scheduling-api";
import {isNonEmptyString} from "../../../../utils";
import {
  resetFilterAction,
  setAppointmentStatusAction,
  setBookingTypeAction,
  setFilterAction,
  setResourceAction,
  setSlotStatusAction,
  setSlotTypeAction,
} from "./booking-app-filter-actions";
import {BookingType} from "./booking-app-filter-types";

export default bookingAppFilterReducer;

export interface BookingAppFilter {
  appointmentStatus: AppointmentStatus | undefined;
  bookingType: BookingType | undefined;
  resource: string | undefined;
  slotType: string | undefined;
  slotStatus: SlotStatus | undefined;
}

export interface BookingAppFilterInit {
  appointmentStatus?: AppointmentStatus;
  bookingType?: BookingType;
  resource?: string;
  slotType?: string;
  slotStatus?: SlotStatus;
}

export function initializeBookingAppFilter(
  initialState: BookingAppFilterInit = {}
): BookingAppFilter {
  const {appointmentStatus, bookingType, resource, slotType, slotStatus} =
    initialState;

  return {
    appointmentStatus,
    bookingType,
    resource,
    slotType,
    slotStatus,
  };
}

function bookingAppFilterReducer(
  state: BookingAppFilter,
  action: Action
): BookingAppFilter {
  if (resetFilterAction.match(action)) {
    return initializeBookingAppFilter(action.payload);
  }

  if (setAppointmentStatusAction.match(action)) {
    return {
      ...state,
      appointmentStatus: action.payload,
      ...(isAppointmentStatus(action.payload)
        ? {bookingType: BookingType.AVEC_APPOINTMENT}
        : null),
    };
  }

  if (setBookingTypeAction.match(action)) {
    return {
      ...state,
      bookingType: action.payload,
      ...(action.payload !== BookingType.AVEC_APPOINTMENT
        ? {appointmentStatus: undefined}
        : null),
    };
  }

  if (setFilterAction.match(action)) {
    return action.payload;
  }

  if (setResourceAction.match(action)) {
    return {
      ...state,
      resource: isNonEmptyString(action.payload) ? action.payload : undefined,
    };
  }

  if (setSlotTypeAction.match(action)) {
    return {
      ...state,
      slotType: isNonEmptyString(action.payload) ? action.payload : undefined,
    };
  }

  if (setSlotStatusAction.match(action)) {
    const slotStatus = action.payload as SlotStatus | undefined;
    return {
      ...state,
      slotStatus,
      ...(slotStatus !== SlotStatus.CREATED
        ? {appointmentStatus: undefined}
        : null),
    };
  }

  return state;
}
