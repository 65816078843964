import {hasOwnProperty} from "./has-own-property";
import {isNonEmptyString} from "./is-non-empty-string";
import {isString} from "./is-string";

export type URLQueryObject = Record<string, string | string[]>;

/**
 * Takes a URL search param string (eg. document.location.search) and turns it
 * into an object.
 *
 * @example
 * const q = toQueryStringObject('?a=1&b=2&c=%20&a=duplicate');
 * q.a[0] // '1'
 * q.a[1] // 'duplicate'
 * q.b // '2'
 * q.c // ' '
 * q.d // undefined
 */
export const toQueryStringObject = (search: string): URLQueryObject => {
  if (!isNonEmptyString(search)) {
    return {};
  }
  const params = new URLSearchParams(search);
  const entries = Array.from(params);

  return entries.reduce<URLQueryObject>((query, [key, value]) => {
    if (hasOwnProperty(query, key)) {
      const existing = query[key];

      if (Array.isArray(existing)) {
        query[key] = [...existing, value];
      } else if (isString(existing)) {
        query[key] = [existing, value];
      }
    } else {
      query[key] = value;
    }
    return query;
  }, {});
};

/**
 * Takes a URLQueryObject and turns it into it's string format -- excluding the
 * leading question mark!
 *
 * @example
 * toQueryString({a: ['1', 'duplicate'], b: '2', c: ' '});
 * // returns: 'a=1&a=duplicate&b=2&c=%20'
 */
export const toQueryString = (query: URLQueryObject): string => {
  const params = new URLSearchParams();

  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        params.append(key, item);
      });
    }
    if (isString(value)) {
      params.append(key, value);
    }
  }, []);
  return params.toString();
};
