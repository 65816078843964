import {useQuery} from "react-query";
import useClinicQueryCreators, {
  GetClinicQueryOptions,
  GetClinicQueryVariables,
} from "./use-clinic-query-creators";

export {useGetClinicQuery as default, useGetClinicQuery};

function useGetClinicQuery(
  variables: GetClinicQueryVariables,
  options?: GetClinicQueryOptions
) {
  const {createGetClinicQuery} = useClinicQueryCreators();

  return useQuery(createGetClinicQuery(variables, options));
}
