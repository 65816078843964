const DateTimeStyle = {
  FULL: "full",
  LONG: "long",
  MEDIUM: "medium",
  SHORT: "short",
} as const;

const DateStyle = DateTimeStyle;
const TimeStyle = DateTimeStyle;

export {DateTimeStyle as default, DateStyle, TimeStyle};
