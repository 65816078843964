import EntityReference from "./entity-reference";
import {SlotStatus} from "./slot-status";

interface SlotInit {
  id?: string;
  start: string;
  end: string;
  appointment: EntityReference;
  clinic: EntityReference;
  slotType: EntityReference;
  resourceIndex: number;
  status: SlotStatus;
  version?: string;
}

export interface SlotJSON {
  id: string;
  start: string;
  end: string;
  appointment: EntityReference;
  clinic: EntityReference;
  slot_type: EntityReference;
  resource_index: number;
  status: SlotStatus;
  version: null | string;
}

export default class Slot {
  id: string;
  start: string;
  end: string;
  appointment: EntityReference;
  clinic: EntityReference;
  slotType: EntityReference;
  resourceIndex: number;
  status: SlotStatus;
  version: string;

  constructor({
    id,
    start,
    end,
    appointment,
    clinic,
    slotType,
    resourceIndex,
    status,
    version,
  }: SlotInit) {
    Object.assign(this, {
      id,
      start,
      end,
      appointment,
      clinic,
      slotType,
      resourceIndex,
      status,
      version,
    });
  }

  static fromJSON(json: SlotJSON) {
    const {
      id,
      start,
      end,
      appointment,
      clinic,
      slot_type: slotType,
      resource_index: resourceIndex,
      status,
      version,
    } = json;

    return new Slot({
      id,
      start,
      end,
      appointment,
      clinic,
      slotType,
      resourceIndex,
      status,
      version: version ?? "",
    });
  }

  toJSON() {
    const {
      id,
      start,
      end,
      appointment,
      clinic,
      slotType: slot_type,
      resourceIndex: resource_index,
      status,
      version,
    } = this;

    return {
      id,
      start,
      end,
      appointment,
      clinic,
      slot_type,
      resource_index,
      status,
      version,
    };
  }
}
