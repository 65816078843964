import React, {ReactNode} from "react";
import {useTranslation} from "../../../services/i18n";
import {Alert} from "../../bootstrap";
import SchedulingWizardContentConstrainer from "./scheduling-wizard-content-constrainer";

export default SchedulingWizardMutationError;

type SchedulingWizardMutationErrorProps = {
  children?: ReactNode;
};

function SchedulingWizardMutationError(
  props: SchedulingWizardMutationErrorProps
) {
  const tDefaultMessage = useTranslation("scheduling-wizard-mutation-error");
  const {children = tDefaultMessage} = props;

  return (
    <SchedulingWizardContentConstrainer>
      <Alert variant={"danger"}>{children}</Alert>
    </SchedulingWizardContentConstrainer>
  );
}
