import {
  FormEvent,
  ReactChild,
  ReactFragment,
  useCallback,
  useState,
} from "react";
import {Translation} from "../../../../services/i18n";
import {Resident, Slot} from "../../../../services/time-book-scheduling-api";
import {Form} from "../../../bootstrap";
import {ButtonContainer} from "../../../calendar/calendar-event-details/button-container";
import {ClinicNotesInput} from "../../../calendar/calendar-event-details/clinic-notes-input";
import {ActionButton} from "../../../form";
import styles from "./new-appointment-form.module.scss";

const CLINIC_ID = "CLINIC_ID";
const CLINIC_NOTES = "CLINIC_NOTES";
const RESIDENT_ID = "RESIDENT_ID";
const SLOT_ID = "SLOT_ID";

const FormDataEntryName = {
  CLINIC_ID,
  CLINIC_NOTES,
  RESIDENT_ID,
  SLOT_ID,
} as const;

export {NewAppointmentForm as default, FormDataEntryName};

interface NewAppointmentFormProps {
  error?: ReactChild | ReactFragment | null;
  loading?: boolean;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  resident: Resident;
  slot: Slot;
}

function NewAppointmentForm(props: NewAppointmentFormProps) {
  const {error, loading, onSubmit: dispatchSubmitEvent, resident, slot} = props;

  const [clinicNotes, setClinicNotes] = useState("");

  const [validated, setValidated] = useState(false);
  const onFormSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setValidated(true);

      if (event.currentTarget.checkValidity()) {
        dispatchSubmitEvent(event);
      }
    },
    [dispatchSubmitEvent, setValidated]
  );

  return (
    <Form
      className={styles.root}
      // @ts-ignore - noValidate DO exist. STFU TypeScript!
      noValidate
      onSubmit={onFormSubmit}
      validated={validated}
    >
      <input type="hidden" name={CLINIC_ID} value={slot.clinic.id} />
      <input type="hidden" name={RESIDENT_ID} value={resident.id} />
      <input type="hidden" name={SLOT_ID} value={slot.id} />

      <ClinicNotesInput
        name={CLINIC_NOTES}
        onValueChange={setClinicNotes}
        value={clinicNotes}
      />

      {error != null ? error : null}

      <ButtonContainer>
        <ActionButton block loading={loading} type="submit">
          <Translation tKey="calendar-event-details-book" />
        </ActionButton>
      </ButtonContainer>
    </Form>
  );
}
