import {isNonEmptyString} from "./is-non-empty-string";

/**
 * Split a string at given indices.
 * @example
 * splitAtIndices('Hullabaloo', 4, 5, 7); -> ['Hull', 'a', 'ba', 'loo']
 */
export const splitAtIndices = (value: string, ...indices: number[]) => {
  const result: string[] = [];

  if (!isNonEmptyString(value)) {
    return result;
  }
  const endOfValue = value.length;

  let i = 0;
  let startOfSegment = 0;
  let endOfSegment = indices[i];

  while (startOfSegment < endOfSegment) {
    result.push(value.substring(startOfSegment, endOfSegment));
    startOfSegment = endOfSegment;
    endOfSegment = indices[++i] || endOfValue;
  }
  return result;
};
