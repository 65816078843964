import {useMemo} from "react";
import {useQuery, UseQueryOptions, UseQueryResult} from "react-query";
import {useGetResidentAppointmentsQuery} from "../../../services/api/appointments";
import {Booking} from "../../../services/api/bookings";
import {useGetSlotTypesQuery} from "../../../services/api/slot-types";
import {useGetSlotsQuery} from "../../../services/api/slots";
import {Appointment} from "../../../services/time-book-scheduling-api";
import {isNonEmptyArray, keyBy} from "../../../utils";
import {createCompoundQueryResult} from "../../../utils/react-query";

export default useGetResidentBookingsQuery;

interface GetResidentBookingsQueryVariables {
  clinicId: string;
  residentId: string;
}

type GetResidentBookingsQueryOptions = UseQueryOptions<Booking[], Error>;

function useGetResidentBookingsQuery(
  variables: GetResidentBookingsQueryVariables,
  options?: GetResidentBookingsQueryOptions
) {
  const {clinicId, residentId} = variables;

  const getSlotTypesQueryResult = useGetSlotTypesQuery({clinicId});

  const getResidentAppointmentsQueryResult = useGetResidentAppointmentsQuery({
    clinicId,
    residentId,
  });

  const slotIds = useMemo(() => {
    return getResidentAppointmentsQueryResult.data
      ? getSlotIdsFromAppointments(getResidentAppointmentsQueryResult.data)
      : [];
  }, [getResidentAppointmentsQueryResult.data]);
  const getSlotsQueryResult = useGetSlotsQuery(
    {clinicId, slotIds},
    {
      enabled: isNonEmptyArray(slotIds),
    }
  );

  const {
    dataUpdatedAt,
    error,
    isError,
    isFetching,
    isIdle,
    isLoading,
    isLoadingError,
    isRefetchError,
    isSuccess,
  } = createCompoundQueryResult([
    getResidentAppointmentsQueryResult,
    getSlotsQueryResult,
    getSlotTypesQueryResult,
  ]);

  const slotTypesMap = useMemo(
    () => keyBy("id", getSlotTypesQueryResult.data ?? []),
    [getSlotTypesQueryResult.data]
  );

  const slotsMap = useMemo(
    () => keyBy("id", getSlotsQueryResult.data ?? []),
    [getSlotsQueryResult.data]
  );

  const queryKey = useMemo(() => {
    if (isSuccess) {
      return [
        "useGetResidentBookingsQuery",
        {clinicId, residentId, dataUpdatedAt},
      ];
    }

    return ["useGetResidentBookingsQuery", {clinicId, residentId}];
  }, [isSuccess, clinicId, residentId, dataUpdatedAt]);

  const getResidentBookingsQuery = useQuery<Booking[], Error>(
    queryKey,
    () => {
      if (isSuccess) {
        return (
          getResidentAppointmentsQueryResult.data?.map((appointment) => {
            const slot = slotsMap[appointment.slot.id];
            const slotType = slotTypesMap[slot.slotType.id];

            return {appointment, slot, slotType};
          }) ?? []
        );
      }

      return [];
    },
    // Do not cache compound queries
    {
      ...options,
      cacheTime: 0,
    }
  );

  return {
    ...getResidentBookingsQuery,
    error: error || getResidentBookingsQuery.error,
    isError: isError || getResidentBookingsQuery.isError,
    isFetching: isFetching || getResidentBookingsQuery.isFetching,
    isIdle: isIdle,
    isLoading: isLoading || getResidentBookingsQuery.isLoading,
    isLoadingError: isLoadingError,
    isRefetchError: isRefetchError,
    isSuccess: isSuccess && getResidentBookingsQuery.isSuccess,
  } as UseQueryResult<Booking[], Error>;
}

function getSlotIdsFromAppointments(appointments: Appointment[]) {
  return appointments.map((appointment) => {
    return appointment.slot.id;
  });
}
