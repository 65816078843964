/**
 * NOTE!! JavaScript Date has some quirks. These tests used to do
 * `new Date('2020-01-01')` but it turns out new Date(dateString) is treated as
 * UTC time, not local!! So now we do toDate(dateString)
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#Timestamp_string
 */

export {
  toLocalDate,
  // TODO: Rename where used
  toLocalISODateString as toLocalIsoDateString,
};

/** Takes a date string like 2020-01-01 and returns a local date */
function toLocalDate(isoDateString: string) {
  const [, yyyy, mm, dd] = /(\d\d\d\d)-(\d\d)-(\d\d)/.exec(isoDateString) || [];

  return new Date(Number(yyyy), Number(mm) - 1, Number(dd));
}

/** Takes a Date and returns the local date (NOT UTC!) in short ISO date format */
function toLocalISODateString(date: Date) {
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}`;
}

function pad(n: number) {
  return String(n).padStart(2, "0");
}
