import React, {ReactNode} from "react";
import SessionClient from "./session-client";
import SessionClientContext from "./session-client-context";

export default SessionClientProvider;

interface SessionClientProviderProps {
  children: ReactNode;
  client: SessionClient;
}

function SessionClientProvider(props: SessionClientProviderProps) {
  const {children, client} = props;

  return (
    <SessionClientContext.Provider value={client}>
      {children}
    </SessionClientContext.Provider>
  );
}
