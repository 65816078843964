import {MouseEvent, useCallback, useRef, useState} from "react";
import {usePatchAppointmentMutation} from "../../../services/api/appointments";
import {
  Appointment,
  AppointmentStatus,
} from "../../../services/time-book-scheduling-api";
import {createReplaceOperation} from "../../../utils/json-patch";
import {Overlay, Popover} from "../../bootstrap";
import AppointmentStatusButton from "../appointment-status-button";
import AppointmentStatusToggleButtonGroup from "../appointment-status-toggle-button-group";
import styles from "./appointment-status-quick-mutator.module.scss";

export default AppointmentStatusQuickMutator;

const name = "appointment-status";

interface AppointmentStatusQuickMutatorProps {
  appointment: Appointment;
}

function AppointmentStatusQuickMutator(
  props: AppointmentStatusQuickMutatorProps
) {
  const {appointment} = props;

  const overlayContainerRef = useRef<HTMLDivElement>(null);
  const [overlayShow, setOverlayShow] = useState<boolean>(false);
  const [overlayTarget, setOverlayTarget] = useState<HTMLButtonElement | null>(
    null
  );

  const patchAppointmentMutation = usePatchAppointmentMutation({
    appointmentId: appointment.id,
    appointmentVersion: appointment.version,
    clinicId: appointment.clinic.id,
  });

  const onAppointmentStatusChange = useCallback(
    (appointmentStatus: AppointmentStatus) => {
      const patch = [createReplaceOperation("/status", appointmentStatus)];

      patchAppointmentMutation.mutate({input: patch});

      setOverlayShow(false);
    },
    [patchAppointmentMutation]
  );

  const onAppointmentStatusButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setOverlayShow((show) => {
        return !show;
      });
      setOverlayTarget(event.currentTarget);
    },
    [setOverlayShow, setOverlayTarget]
  );

  const onOverlayHide = useCallback(() => {
    setOverlayShow(false);
  }, [setOverlayShow]);

  const onRootClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }, []);

  // Hack for when a fast re-render of a cancelled appointment happens before react-query cache has been updated
  if (appointment.status === AppointmentStatus.CANCELLED) {
    return null;
  }

  return (
    <div
      ref={overlayContainerRef}
      className={styles.root}
      onClick={onRootClick}
    >
      <AppointmentStatusButton
        loading={patchAppointmentMutation.isLoading}
        onClick={onAppointmentStatusButtonClick}
        value={appointment.status}
      />
      <Overlay
        container={overlayContainerRef.current}
        target={overlayTarget}
        onHide={onOverlayHide}
        placement="left"
        rootClose={true}
        show={overlayShow}
      >
        <Popover id="popover-contained">
          <div style={{padding: "8px"}}>
            <AppointmentStatusToggleButtonGroup
              name={name}
              onValueChange={onAppointmentStatusChange}
              value={appointment.status}
            />
          </div>
        </Popover>
      </Overlay>
    </div>
  );
}
