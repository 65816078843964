import React from "react";
import {Translation} from "../../services/i18n";
import {Alert, AlertLink} from "../bootstrap";

export const PageMissingMessage = () => (
  <Alert variant="warning">
    <Translation tKey="page-missing-message-pt1" />
    <AlertLink href="/">
      <Translation tKey="page-missing-message-pt2" />
    </AlertLink>
    <Translation tKey="page-missing-message-pt3" />
  </Alert>
);
