import {forwardRef, useImperativeHandle} from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {isFunction} from "../../utils";
import type {
  ReactTableProps,
  ReactTableRef,
  ReactTableWithForwardedRef,
} from "./types";

export default forwardRef(ReactTable) as ReactTableWithForwardedRef;

function ReactTable<T>(props: ReactTableProps<T>, ref: ReactTableRef<T>) {
  const {className, options, rowProps = {}} = props;

  const reactTable = useReactTable<T>({
    ...options,
    getCoreRowModel: getCoreRowModel(),
  });

  useImperativeHandle(
    ref,
    () => {
      const {getState, resetRowSelection} = reactTable;

      return {
        getState,
        resetRowSelection,
      };
    },
    [reactTable]
  );

  return (
    <table className={className}>
      <thead>
        {reactTable.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {reactTable.getRowModel().rows.map((row) => {
          const {key = row.id, ...otherRowProps} = isFunction(rowProps)
            ? rowProps(row)
            : rowProps;

          return (
            <tr key={key} {...otherRowProps}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
