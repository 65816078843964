import {ReactNode} from "react";
import classNames from "classnames";
import {SlotType} from "../../services/time-book-scheduling-api";
import styles from "./slot-type-plaque.module.scss";

export default SlotTypePlaque;

interface SlotTypePlaqueProps {
  active?: boolean;
  children: ReactNode;
  className?: string;
  slotType: SlotType;
}

function SlotTypePlaque(props: SlotTypePlaqueProps) {
  const {active, children, className, slotType} = props;

  const colorStyles = {
    ...(active ? {backgroundColor: slotType.color} : null),
    borderColor: slotType.color,
  };

  const rootClassNames = classNames(styles.root, className);

  return (
    <div className={rootClassNames}>
      <div className={styles.colorBar} style={colorStyles} />
      <div className={styles.body}>{children}</div>
    </div>
  );
}
