import {useQuery} from "react-query";
import {
  GetSlotQueryOptions,
  GetSlotQueryVariables,
  useSlotQueryCreators,
} from "./use-slot-query-creators";

export default useGetSlotQuery;

function useGetSlotQuery(
  variables: GetSlotQueryVariables,
  options?: GetSlotQueryOptions
) {
  const {createGetSlotQuery} = useSlotQueryCreators();

  return useQuery(createGetSlotQuery(variables, options));
}
