import {Fragment, FormEvent, useCallback, useState} from "react";
import {useSearchResidentQuery} from "../../../../services/api/residents";
import {getPersonalIdentityNumberValidity} from "../../../../services/api/utils/personal-identity-number";
import {Translation} from "../../../../services/i18n";
import {Slot, SlotStatus} from "../../../../services/time-book-scheduling-api";
import {getFormDataEntryValue} from "../../../../utils/form-data";
import {Alert} from "../../../bootstrap";
import BlockSlotButton from "../../../calendar/calendar-event-details/block-slot-button";
import {ButtonContainer} from "../../../calendar/calendar-event-details/button-container";
import {LoadingError} from "../../../loading-error";
import ResidentInfo from "../../../resident-info";
import SearchResidentForm, {
  FormDataEntryName,
} from "../../../search-resident-form";
import CreateAppointmentPanel from "../forms/create-appointment-panel";
import styles from "./booking-app-aside-available-slot.module.scss";

export default BookingAppAsideAvailableSlot;

interface BookingAppAsideAvailableSlotProps {
  slot: Slot;
}

function BookingAppAsideAvailableSlot(
  props: BookingAppAsideAvailableSlotProps
) {
  const {slot} = props;

  const [personalIdentityNumber, setPersonalIdentityNumber] = useState("");

  const searchResidentQuery = useSearchResidentQuery(
    {personalIdentityNumber},
    {
      enabled: getPersonalIdentityNumberValidity(personalIdentityNumber).valid,
    }
  );

  const onSearchResidentFormSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const formData = new FormData(event.currentTarget);
      const pin = getFormDataEntryValue(formData, FormDataEntryName.PIN);

      setPersonalIdentityNumber(pin);
    },
    [setPersonalIdentityNumber]
  );

  return (
    <div className={styles.root}>
      {isBlocked(slot) ? (
        <Alert variant="warning">
          <Translation tKey="feedback-slot-is-blocked" />
        </Alert>
      ) : null}
      <Fragment>
        <SearchResidentForm
          loading={searchResidentQuery.isLoading}
          onSubmit={onSearchResidentFormSubmit}
          isBookable={isBookable(slot)}
        />
        {searchResidentQuery.isError ? (
          <LoadingError error={searchResidentQuery.error} />
        ) : null}
        {searchResidentQuery.isSuccess ? (
          <Fragment>
            <ResidentInfo resident={searchResidentQuery.data} />
            <CreateAppointmentPanel
              resident={searchResidentQuery.data}
              slot={slot}
            />
          </Fragment>
        ) : null}
      </Fragment>

      <ButtonContainer className={styles.buttonContainer}>
        <BlockSlotButton slot={slot} />
      </ButtonContainer>
    </div>
  );
}

function isBlocked(slot: Slot) {
  return slot.status === SlotStatus.BLOCKED;
}

function isBookable(slot: Slot) {
  const startDateTime = new Date(slot.start);
  const currentDateTime = new Date();
  const timeIsInThePast = startDateTime <= currentDateTime;

  return slot.status === SlotStatus.CREATED && !timeIsInThePast;
}
