import {FormEvent, useCallback} from "react";
import {useCreateAppointmentMutation} from "../../../../services/api/appointments";
import {Resident, Slot} from "../../../../services/time-book-scheduling-api";
import {trim} from "../../../../utils";
import {getFormDataEntryValue} from "../../../../utils/form-data";
import {LoadingError} from "../../../loading-error";
import NewAppointmentForm, {FormDataEntryName} from "./new-appointment-form";

export default CreateAppointmentPanel;

interface CreateAppointmentPanelProps {
  resident: Resident;
  slot: Slot;
}

function CreateAppointmentPanel(props: CreateAppointmentPanelProps) {
  const {resident, slot} = props;

  const createAppointmentMutation = useCreateAppointmentMutation({
    clinicId: slot.clinic.id,
  });

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const formData = new FormData(event.currentTarget);
      const clinicNotes = getFormDataEntryValue(
        formData,
        FormDataEntryName.CLINIC_NOTES
      );

      const input = {
        clinic: {id: slot.clinic.id},
        clinicNotes: trim(clinicNotes),
        resident: {id: resident.id},
        slot: {id: slot.id},
      };

      createAppointmentMutation.mutate({input});
    },
    [createAppointmentMutation, resident, slot]
  );

  return (
    <NewAppointmentForm
      error={
        createAppointmentMutation.isError ? (
          <LoadingError error={createAppointmentMutation.error} />
        ) : null
      }
      loading={createAppointmentMutation.isLoading}
      onSubmit={onSubmit}
      resident={resident}
      slot={slot}
    />
  );
}
