import {useCallback} from "react";
import {Booking} from "../../../../services/api/bookings";
import {
  BookingType,
  useBookingAppFilter,
} from "../../services/booking-app-filter";

export default useFilterPredicate;

function useFilterPredicate() {
  const [filter] = useBookingAppFilter();

  return useCallback(
    (booking: Booking) => {
      if (
        (filter.bookingType === BookingType.AVEC_APPOINTMENT &&
          booking.appointment == null) ||
        (filter.bookingType === BookingType.SANS_APPOINTMENT &&
          booking.appointment != null)
      ) {
        return false;
      }
      // else check next filter

      if (
        filter.resource != null &&
        filter.resource !== String(booking.slot.resourceIndex)
      ) {
        return false;
      }
      // else check next filter

      if (filter.slotType != null && filter.slotType !== booking.slotType.id) {
        return false;
      }
      // else check next filter

      if (
        filter.slotStatus != null &&
        filter.slotStatus !== booking.slot.status
      ) {
        return false;
      }
      // else check next filter

      if (
        filter.appointmentStatus != null &&
        filter.appointmentStatus !== booking.appointment?.status
      ) {
        return false;
      }

      return true;
    },
    [filter]
  );
}
