import {getNumberOfDaysInMonth} from "./calendar";
import {getDatePartsArray} from "./date-time-units";
import {isValidDate} from "./is-date";
import {isFiniteNumber} from "./is-number";

const wrapModifier =
  (modifier: (date: Date, units: number) => Date) =>
  (date: Date, units: number) => {
    const copy = new Date(date);

    if (!isValidDate(date)) {
      throw new TypeError("Invalid date");
    }
    if (!isFiniteNumber(units)) {
      throw new TypeError("Invalid number");
    }
    return modifier(copy, units);
  };

/** Returns a new Date with the number of seconds added. */
export const addSeconds = wrapModifier((date: Date, seconds: number) => {
  date.setSeconds(date.getSeconds() + seconds);
  return date;
});

/** Returns a new Date with the number of minutes added. */
export const addMinutes = wrapModifier((date: Date, minutes: number) => {
  date.setMinutes(date.getMinutes() + minutes);
  return date;
});

/** Returns a new Date with the number of hours added. */
export const addHours = wrapModifier((date: Date, hours: number) => {
  date.setHours(date.getHours() + hours);
  return date;
});

/** Returns a new Date with the number of days added. */
export const addDays = wrapModifier((date: Date, days: number) => {
  date.setDate(date.getDate() + days);
  return date;
});

/** Returns a new Date with the number of weeks added. */
export const addWeeks = wrapModifier((date: Date, weeks: number) => {
  date.setDate(date.getDate() + weeks * 7);
  return date;
});

/**
 * Returns a new Date with the number of months added.
 *
 * NOTE! This function corrects for days that falls 'outside' of the month!
 *
 * @example
 * // If you add one month to the last day of October (31 days),
 * // you will get back the last of November (30 days).
 * addMonth(new Date(2021, 9, 31), 1) -> new Date(2021, 10, 30)
 */
export const addMonths = wrapModifier((date: Date, months: number) => {
  const [year, month, day, ...time] = getDatePartsArray(date);
  const candidate = new Date(year, month + months, 1, ...time);

  candidate.setDate(Math.min(getNumberOfDaysInMonth(candidate), day));
  return candidate;
});

/**
 * Returns a new Date with the number of years added.
 *
 * NOTE! This function corrects for days that falls 'outside' of the month!
 *
 * @example
 * // If you add one year to the last day of February 2020 (29 days),
 * // you will get back the last day of February 2021 (28 days).
 * addMonth(new Date(2020, 1, 29), 1) -> new Date(2021, 1, 28)*/
export const addYears = wrapModifier((date: Date, years: number) => {
  const [year, month, day, ...time] = getDatePartsArray(date);
  const candidate = new Date(year + years, month, 1, ...time);

  candidate.setDate(Math.min(getNumberOfDaysInMonth(candidate), day));
  return candidate;
});
