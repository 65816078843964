import {Action} from "@reduxjs/toolkit";
import {
  setBookingDateAction,
  setClinicIdAction,
  setRouteDateAction,
  setRoutePathAction,
  setSelectedSlotIdsAction,
  setSlotIdAction,
} from "./booking-app-state-actions";
import {BookingAppState} from "./booking-app-state-context";

export default bookingAppStateReducer;

function bookingAppStateReducer(
  state: BookingAppState,
  action: Action
): BookingAppState {
  if (setBookingDateAction.match(action)) {
    return {
      ...state,
      bookingDate: action.payload,
    };
  }

  if (setClinicIdAction.match(action)) {
    return {
      ...state,
      clinicId: action.payload,
    };
  }

  if (setRouteDateAction.match(action)) {
    return {
      ...state,
      routeDate: action.payload,
    };
  }

  if (setRoutePathAction.match(action)) {
    return {
      ...state,
      routePath: action.payload,
    };
  }

  if (setSelectedSlotIdsAction.match(action)) {
    return {
      ...state,
      selectedSlotIds: action.payload,
    };
  }

  if (setSlotIdAction.match(action)) {
    return {
      ...state,
      slotId: action.payload,
    };
  }

  return state;
}
