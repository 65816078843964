import {Translation} from "../../../services/i18n";
import {
  SlotType,
  SlotTypeVisibility,
} from "../../../services/time-book-scheduling-api";

export default SlotTypeVisibleForResidents;

type SlotTypeVisibleForResidentsProps = {
  className?: string;
  slotType: SlotType;
};

export function SlotTypeVisibleForResidents(
  props: SlotTypeVisibleForResidentsProps
) {
  const {className, slotType} = props;

  return (
    <span className={className}>
      {isVisibleForResidents(slotType) ? (
        <Translation tKey="slot-type-visible-for-residents-true" />
      ) : (
        <Translation tKey="slot-type-visible-for-residents-false" />
      )}
    </span>
  );
}

function isVisibleForResidents(slotType: SlotType) {
  return (slotType.visibility & SlotTypeVisibility.RESIDENT_PORTAL) !== 0;
}
