import React, {useCallback, useMemo} from "react";
import {Redirect} from "react-router-dom";
import {CenteredCardLayout} from "../../components/layouts";
import {Loading} from "../../components/loading";
import {LoadingError} from "../../components/loading-error";
import {useGetClinicsQuery} from "../../services/api/clinics/hooks";
import {
  getPermissionsForClaims,
  Permission,
  selectClaimsByHsaId,
} from "../../services/auth";
import {Translation, useTranslation} from "../../services/i18n";
import {
  generatePathToBooking,
  generatePathToClinic,
  generatePathToResidents,
  generatePathToScheduling,
  useRouting,
} from "../../services/routing";
import {useSessionClient} from "../../services/session";
import {Clinic} from "../../services/time-book-scheduling-api";
import ClinicSelector from "./clinic-selector";

export default SelectClinicRoute;

function SelectClinicRoute() {
  const {redirect} = useRouting();
  const {claims} = useSessionClient();
  const getSessionUserClinicsQuery = useGetSessionUserClinicsQuery();
  const loadingErrorTitle = useTranslation("select-clinic-route-loading-error");

  const redirectBasedOnPermissions = useCallback(
    (clinic: Clinic) => {
      const pathname = getPathnameBasedOnPermissions(claims, clinic);

      redirect(pathname);
    },
    [claims, redirect]
  );

  if (getSessionUserClinicsQuery.isLoading) {
    return (
      <CenteredCardLayout>
        <Loading />
      </CenteredCardLayout>
    );
  }

  if (getSessionUserClinicsQuery.isError) {
    return (
      <CenteredCardLayout>
        <LoadingError
          error={getSessionUserClinicsQuery.error}
          title={loadingErrorTitle}
        />
      </CenteredCardLayout>
    );
  }

  if (getSessionUserClinicsQuery.isSuccess) {
    if (getSessionUserClinicsQuery.data.length === 1) {
      const clinic = getSessionUserClinicsQuery.data[0];
      const pathname = getPathnameBasedOnPermissions(claims, clinic);

      return <Redirect to={pathname} />;
    }
    return (
      <CenteredCardLayout>
        <p>
          <Translation tKey="select-clinic-route-info" />
        </p>
        <ClinicSelector
          clinics={getSessionUserClinicsQuery.data}
          onClinicClick={redirectBasedOnPermissions}
        />
      </CenteredCardLayout>
    );
  }
  return null;
}

function useGetSessionUserClinicsQuery() {
  const {claims} = useSessionClient();
  const tNoClaims = useTranslation("select-clinic-route-no-claims");

  const claimedClinicHsaIds = useMemo(() => {
    return claims.map((claim) => getClinicHsaIdByClaim(claim));
  }, [claims]);

  return useGetClinicsQuery(undefined, {
    select(allClinics) {
      const userClinics = allClinics.filter((clinic) => {
        return claimedClinicHsaIds.includes(clinic.hsaId);
      });

      if (userClinics.length === 0) {
        throw new Error(tNoClaims);
      }
      return userClinics;
    },
  });
}

function getClinicHsaIdByClaim(claim: string): string {
  return claim.split("_")[0];
}

function getPathnameBasedOnPermissions(claims: string[], clinic: Clinic) {
  const claimsForClinic = selectClaimsByHsaId(claims, clinic.hsaId);
  const permissionsForClinic = getPermissionsForClaims(claimsForClinic);
  const clinicId = clinic.id;

  if (permissionsForClinic.includes(Permission.BOOK_EXTERNAL)) {
    return generatePathToResidents({clinicId});
  }
  if (permissionsForClinic.includes(Permission.BOOK)) {
    return generatePathToBooking({clinicId});
  }
  if (permissionsForClinic.includes(Permission.SCHEDULE)) {
    return generatePathToScheduling({clinicId});
  }
  if (permissionsForClinic.includes(Permission.ADMINISTRATE)) {
    return generatePathToClinic({clinicId});
  }
  throw new Error("No permission found for this clinic");
}
