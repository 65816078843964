import React from "react";
import {AppointmentStatus} from "../../../services/time-book-scheduling-api/entities/appointment-status";
import {
  ColorName,
  COLOR_GRASS,
  COLOR_PLUM,
  COLOR_SKY,
  COLOR_STONE,
  COLOR_SUN,
} from "../../../styles/color-name";
import {Tag} from "../../tag";
import AppointmentStatusText from "../appointment-status-text";

export default AppointmentStatusTag;

interface AppointmentStatusTagProps {
  className?: string;
  status: AppointmentStatus;
}

function AppointmentStatusTag(props: AppointmentStatusTagProps) {
  const {className, status} = props;

  return (
    <Tag className={className} variant={getColorNameFromStatus(status)}>
      <AppointmentStatusText status={status} />
    </Tag>
  );
}

function getColorNameFromStatus(status: AppointmentStatus): ColorName {
  switch (status) {
    case AppointmentStatus.BOOKED:
      return COLOR_SKY;
    case AppointmentStatus.CANCELLED:
      return COLOR_SUN;
    case AppointmentStatus.FULFILLED:
      return COLOR_GRASS;
    case AppointmentStatus.NO_SHOW:
      return COLOR_PLUM;
    default:
      console.error(`Unknown appointment status: '${status}'`);
      return COLOR_STONE;
  }
}
