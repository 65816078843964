import {useQuery} from "react-query";
import {
  useSlotQueryCreators,
  CountSlotsQueryOptions,
  CountSlotsQueryVariables,
} from "./use-slot-query-creators";

export const useCountSlotsQuery = (
  variables: CountSlotsQueryVariables,
  options?: CountSlotsQueryOptions
) => {
  const {createCountSlotsQuery} = useSlotQueryCreators();

  return useQuery(createCountSlotsQuery(variables, options));
};
