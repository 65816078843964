import {Fragment, useCallback, useMemo, useState} from "react";
import {usePatchResidentAppointmentMutation} from "../../services/api/appointments/resident-appointments";
import {Booking} from "../../services/api/bookings";
import {Translation, useTranslation} from "../../services/i18n";
import {Appointment, Resident} from "../../services/time-book-scheduling-api";
import {AppointmentStatus} from "../../services/time-book-scheduling-api";
import {createReplaceOperation} from "../../utils/json-patch";
import {useToday} from "../../utils/react";
import {Required} from "../../utils/types";
import AppointmentOrigin from "../appointment-origin";
import {AppointmentStatusTag} from "../appointment-status";
import {RescheduleBookingModal} from "../book-appointment-modal";
import {useRescheduleBookingMutation} from "../book-appointment-modal/use-reschedule-booking-mutation";
import {Button, Table} from "../bootstrap";
import {ClinicNote} from "../calendar/clinic-note";
import {DateStyle, DateText, TimeStyle, TimeText} from "../date-time";
import {useConfirm} from "../dialogs";
import SlotTypeNameplate from "../slot-type-nameplate";
import styles from "./resident-bookings-table.module.scss";

export default ResidentBookingsTable;

interface ResidentBookingsTableProps {
  bookings: Booking[];
  resident: Resident;
}

function ResidentBookingsTable(props: ResidentBookingsTableProps) {
  const {bookings: unsortedBookings, resident} = props;
  const confirm = useConfirm();
  const todayAtMidnight = useToday();

  const [bookingToReschedule, setBookingToReschedule] =
    useState<Required<Booking> | null>(null);

  // Sort bookings in reverse chronological order
  const sortedBookings = useMemo(
    () => unsortedBookings.sort(bookingBySlotStartDateComparer).reverse(),
    [unsortedBookings]
  );

  const confirmMessage = useTranslation("generic-cancel-appointment-confirm");

  const patchAppointmentMutation = usePatchResidentAppointmentMutation();
  const rescheduleBookingMutation = useRescheduleBookingMutation();

  const cancelAppointment = useCallback(
    async (appointment: Appointment) => {
      if (await confirm(confirmMessage)) {
        const identifier = {
          appointmentId: appointment.id,
          appointmentVersion: appointment.version,
          clinicId: appointment.clinic.id,
          residentId: appointment.resident.id,
        };
        const change = createReplaceOperation(
          "status",
          AppointmentStatus.CANCELLED
        );

        patchAppointmentMutation.mutate({
          identifier,
          input: [change],
        });
      }
    },
    [confirm, confirmMessage, patchAppointmentMutation]
  );

  const onRescheduleClose = () => setBookingToReschedule(null);

  const aboutToBeCanceledAppointmentId =
    patchAppointmentMutation.variables?.identifier?.appointmentId;

  return (
    <div className={styles.root} data-testid="resident-booking-table">
      <Table className={styles.table}>
        <thead>
          <tr>
            <th>{/* Index */}</th>
            <th>
              <Translation tKey="resident-appointments-list-th-date" />
            </th>
            <th>
              <Translation tKey="resident-appointments-list-th-time" />
            </th>
            <th>
              <Translation tKey="resident-appointments-list-th-slot-type" />
            </th>
            <th>
              <Translation tKey="resident-appointments-list-th-resource" />
            </th>
            <th>
              <Translation tKey="resident-appointments-list-th-origin" />
            </th>
            <th>
              <Translation tKey="resident-appointments-list-th-note" />
            </th>
            <th>
              <Translation tKey="resident-appointments-list-th-status" />
            </th>
            <th>{/* Actions */}</th>
          </tr>
        </thead>
        <tbody>
          {sortedBookings.map((booking, index) => {
            const {appointment, slot, slotType} = booking;
            const startDateTime = new Date(slot.start);
            const isStaleAppointment = new Date(slot.start) < todayAtMidnight;
            const isAnyMutationLoading =
              patchAppointmentMutation.isLoading ||
              rescheduleBookingMutation.isLoading;
            const isAboutToBeCancelled =
              aboutToBeCanceledAppointmentId === appointment?.id;

            return (
              <tr key={appointment?.id}>
                <td className={styles.index}>{index + 1}</td>
                <td>
                  <DateText date={startDateTime} dateStyle={DateStyle.SHORT} />
                </td>
                <td>
                  <TimeText date={startDateTime} timeStyle={TimeStyle.SHORT} />
                </td>
                <td>
                  {slotType != null ? (
                    <SlotTypeNameplate slotType={slotType} />
                  ) : null}
                </td>
                <td>
                  <Translation
                    tKey="slot-resource"
                    tValues={{number: slot.resourceIndex + 1}}
                  />
                </td>
                <td>
                  {appointment != null ? (
                    <AppointmentOrigin appointment={appointment} />
                  ) : null}
                </td>
                <td>
                  {appointment != null ? (
                    <ClinicNote
                      id={appointment.id}
                      note={appointment.clinicNotes}
                    />
                  ) : null}
                </td>
                <td>
                  {appointment != null ? (
                    <AppointmentStatusTag status={appointment.status} />
                  ) : null}
                </td>
                <td className={styles.actions}>
                  {appointment != null && isBookedAppointment(appointment) ? (
                    <Fragment>
                      <Button
                        data-testid="reschedule-appointment-button"
                        disabled={isAnyMutationLoading || isStaleAppointment}
                        isLoading={rescheduleBookingMutation.isLoading}
                        onClick={() =>
                          setBookingToReschedule(booking as Required<Booking>)
                        }
                        variant="primary"
                      >
                        <Translation tKey="call-to-action-reschedule-appointment" />
                      </Button>

                      <Button
                        data-testid="cancel-appointment-button"
                        disabled={isAnyMutationLoading || isStaleAppointment}
                        isLoading={
                          patchAppointmentMutation.isLoading &&
                          isAboutToBeCancelled
                        }
                        onClick={() => cancelAppointment(appointment)}
                        variant="danger"
                      >
                        <Translation tKey="call-to-action-cancel-appointment" />
                      </Button>
                    </Fragment>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      {bookingToReschedule !== null ? (
        <RescheduleBookingModal
          booking={{...bookingToReschedule, resident}}
          onHide={onRescheduleClose}
          onSuccess={onRescheduleClose}
          show={true}
        />
      ) : null}
    </div>
  );
}

function isBookedAppointment(appointment: Appointment) {
  return appointment.status === AppointmentStatus.BOOKED;
}

function bookingBySlotStartDateComparer(a: Booking, b: Booking) {
  const aStart = new Date(a.slot.start);
  const bStart = new Date(b.slot.start);

  if (aStart > bStart) {
    return 1;
  }
  if (aStart < bStart) {
    return -1;
  }
  return 0;
}
