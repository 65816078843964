import React from "react";
import {useTranslation} from "../../services/i18n";
import {classNames} from "../../utils";
import styles from "./search-resident-instruction.module.scss";

type SearchResidentInstructionProps = {
  className?: string;
};

export const SearchResidentInstruction = (
  props: SearchResidentInstructionProps
) => {
  const {className} = props;
  const message = useTranslation("search-resident-instruction");
  const textPosition = "translate(143 147)";

  return (
    <svg
      className={classNames(styles.root, className)}
      xmlns="http://www.w3.org/2000/svg"
      width="700"
      height="157"
    >
      <g className={styles.group}>
        <g className={styles.arrow}>
          <path d="M134.076 133.584c-53.565 0-95.9-39.2-125.848-116.472l9.895 1.671L.748.001 0 25.581l6.081-7.428c30.337 78.161 73.39 117.812 128 117.812a1.19 1.19 0 0 0 0-2.381" />
        </g>
        <text className={styles.text} transform={textPosition}>
          {message}
        </text>
      </g>
    </svg>
  );
};
