import {Fragment, useCallback, useMemo, useRef, useState} from "react";
import {
  useGetAppointmentQuery,
  useSetAppointmentStatusMutation,
} from "../../../../services/api/appointments";
import {Booking} from "../../../../services/api/bookings";
import {useGetResidentQuery} from "../../../../services/api/residents";
import {useGetSlotTypeQuery} from "../../../../services/api/slot-types";
import {Translation, useTranslation} from "../../../../services/i18n";
import {
  Appointment,
  AppointmentStatus,
  Resident,
  Slot,
} from "../../../../services/time-book-scheduling-api";
import {Required} from "../../../../utils/types";
import AppointmentOrigin from "../../../appointment-origin";
import {RescheduleBookingModal} from "../../../book-appointment-modal";
import {ButtonContainer} from "../../../calendar/calendar-event-details/button-container";
import {useConfirm} from "../../../dialogs";
import {ModalImperativeHandle} from "../../../dialogs/modal";
import {ActionButton, Button} from "../../../form";
import {Loading} from "../../../loading";
import {LoadingError} from "../../../loading-error";
import ResidentInfo from "../../../resident-info";
import UpdateAppointmentPanel from "../forms/update-appointment-panel";
import styles from "./booking-app-aside-appointment.module.scss";

export default BookingAppAsideAppointment;

interface Props {
  slot: Slot;
  appointmentId: string;
  clinicId: string;
  slotStart: string;
}

function selectResidentIdFromAppointment(appointment: Appointment | undefined) {
  return appointment?.resident.id ?? "";
}

function BookingAppAsideAppointment(props: Props) {
  const {slot, appointmentId, clinicId} = props;

  const isCancelEnabled = canCancel(slot.start);

  const getAppointmentQuery = useGetAppointmentQuery({appointmentId, clinicId});
  const getResidentQuery = useGetResidentQuery(
    {residentId: selectResidentIdFromAppointment(getAppointmentQuery.data)},
    {enabled: getAppointmentQuery.isSuccess}
  );

  if (getAppointmentQuery.isLoading || getResidentQuery.isLoading) {
    return <Loading />;
  }

  if (getAppointmentQuery.isError || getResidentQuery.isError) {
    return (
      <LoadingError
        error={getAppointmentQuery.error ?? getResidentQuery.error}
      />
    );
  }

  if (getAppointmentQuery.isSuccess && getResidentQuery.isSuccess) {
    return (
      <div className={styles.root}>
        <AppointmentOrigin appointment={getAppointmentQuery.data} />

        <ResidentInfo resident={getResidentQuery.data} />

        <UpdateAppointmentPanel appointment={getAppointmentQuery.data} />

        {isCancelEnabled && (
          <ButtonContainer className={styles.buttonContainer}>
            <RescheduleAppointmentButton
              appointment={getAppointmentQuery.data}
              className={styles.rescheduleButton}
              slot={slot}
              resident={getResidentQuery.data}
              slotTypeId={slot.slotType.id}
            />
            <CancelAppointmentButton
              appointment={getAppointmentQuery.data}
              className={styles.cancelButton}
            />
          </ButtonContainer>
        )}
      </div>
    );
  }

  return null;
}

interface CancelAppointmentButtonProps {
  appointment: Appointment;
  className?: string;
}

function CancelAppointmentButton(props: CancelAppointmentButtonProps) {
  const {appointment, className} = props;
  const confirm = useConfirm();
  const cancelAppointmentMessage = useTranslation(
    "generic-cancel-appointment-confirm"
  );

  const setAppointmentStatusMutation = useSetAppointmentStatusMutation({
    appointment,
  });

  const onClick = useCallback(async () => {
    if (await confirm(cancelAppointmentMessage)) {
      setAppointmentStatusMutation.mutate({
        status: AppointmentStatus.CANCELLED,
      });
    }
  }, [cancelAppointmentMessage, confirm, setAppointmentStatusMutation]);

  return (
    <ActionButton
      className={className}
      data-testid="cancel-appointment-button"
      loading={setAppointmentStatusMutation.isLoading}
      onClick={onClick}
      variant="outline-danger"
    >
      <Translation tKey="call-to-action-cancel-appointment" />
    </ActionButton>
  );
}

function canCancel(slotStart: string) {
  const startDateTime = new Date(slotStart);
  const currentDateTime = new Date();

  return startDateTime >= currentDateTime;
}
interface RescheduleAppointmentButtonProps {
  appointment: Appointment;
  className?: string;
  resident: Resident;
  slot: Slot;
  slotTypeId: string;
}

function RescheduleAppointmentButton(props: RescheduleAppointmentButtonProps) {
  const {appointment, className, resident, slot, slotTypeId} = props;
  const clinicId = appointment.clinic.id;
  const modalDialogRef = useRef<ModalImperativeHandle>(null);
  const [bookingToReschedule, setBookingToReschedule] =
    useState<Required<Booking> | null>(null);
  const onRescheduleClose = () => setBookingToReschedule(null);
  const getSlotTypeQuery = useGetSlotTypeQuery({clinicId, slotTypeId});
  const booking = useMemo(() => {
    return {
      resident: resident,
      appointment: appointment,
      slot: slot,
      slotType: getSlotTypeQuery.data!,
    };
  }, [resident, appointment, slot, getSlotTypeQuery.data]);

  const onClick = useCallback(() => {
    setBookingToReschedule(booking as Required<Booking>);
    modalDialogRef.current?.showModal();
  }, [booking]);

  return (
    <Fragment>
      <Button
        className={className}
        data-testid="reschedule-appointment-button"
        onClick={onClick}
        variant="outline-primary"
      >
        <Translation tKey="call-to-action-reschedule-appointment" />
      </Button>
      {bookingToReschedule !== null ? (
        <RescheduleBookingModal
          booking={{...booking, resident}}
          onHide={onRescheduleClose}
          onSuccess={onRescheduleClose}
          show={true}
        />
      ) : null}
    </Fragment>
  );
}
