import React, {ReactNode} from "react";
import {classNames} from "../../utils";
import styles from "./not-found.module.scss";

type NotFoundProps = {
  children: ReactNode;
  className?: string;
};

export const NotFound = (props: NotFoundProps) => {
  const {children, className} = props;

  return <div className={classNames(styles.root, className)}>{children}</div>;
};
