export interface WizardCalendarEvent {
  title: string;
  start: Date;
  end: Date;
  resource: {id: string};
  slotType: {id: string};
}

export const clampCalendarEvents = (
  calendarEvents: WizardCalendarEvent[],
  start: Date,
  end: Date
) => calendarEvents.filter((event) => event.start >= start && event.end <= end);
