import {isNonEmptyString} from "../../../utils";
import {
  Appointment,
  Resident,
  Slot,
  SlotType,
} from "../../time-book-scheduling-api";

export {
  selectAppointmentById,
  selectAppointmentBySlotId,
  selectAppointmentIdsFromSlots,
  selectResidentById,
  selectResidentIdsFromAppointments,
  selectSlotTypeById,
};

function selectAppointmentById(
  appointments: Appointment[],
  appointmentId: string
) {
  return appointments.find((appointment) => appointment.id === appointmentId);
}

function selectAppointmentBySlotId(
  appointments: Appointment[],
  slotId: string
) {
  return appointments.find((appointment) => appointment.slot.id === slotId);
}

function selectAppointmentIdsFromSlots(slots: Slot[] = []) {
  return slots
    .filter((slot) => slot.appointment != null)
    .map((slot) => slot.appointment.id);
}

function selectResidentById(
  residents: Resident[],
  residentId: string | undefined
) {
  if (isNonEmptyString(residentId)) {
    return residents.find((resident) => resident.id === residentId);
  }
}

function selectResidentIdsFromAppointments(appointments: Appointment[] = []) {
  return appointments
    .filter((appointment) => appointment.resident != null)
    .map((appointment) => appointment.resident.id);
}

function selectSlotTypeById(
  slotTypes: SlotType[],
  slotTypeId: string | undefined
) {
  if (isNonEmptyString(slotTypeId)) {
    return slotTypes.find((slotType) => slotType.id === slotTypeId);
  }
}
