// This file re-exports some components that normally would require you to
// type import a parent component and use like so: <Navbar.Collapse />.
import {Alert, Form, InputGroup, Modal, NavDropdown} from "react-bootstrap";

// Rename default exports
export {default as FormCheckbox} from "react-bootstrap/FormCheck";
export {default as NavbarCollapse} from "react-bootstrap/NavbarCollapse";
export {default as NavbarToggle} from "react-bootstrap/NavbarToggle";

// Alert
export const AlertHeading = Alert.Heading;
export const AlertLink = Alert.Link;

// Form
export const FormRow = Form.Row;
export const FormSwitch = Form.Switch;

// InputGroup
export const InputGroupAppend = InputGroup.Append;
export const InputGroupCheckbox = InputGroup.Checkbox;
export const InputGroupPrepend = InputGroup.Prepend;
export const InputGroupRadio = InputGroup.Radio;
export const InputGroupText = InputGroup.Text;

// Modal
export const ModalBody = Modal.Body;
export const ModalDialog = Modal.Dialog;
export const ModalFooter = Modal.Footer;
export const ModalHeader = Modal.Header;
export const ModalTitle = Modal.Title;

// NavDropdown
export const NavDropdownDivider = NavDropdown.Divider;
