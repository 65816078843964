import React, {ReactNode} from "react";
import {Link} from "react-router-dom";
import {
  getEditResidentRoute,
  getViewResidentRoute,
} from "../../../services/routing";

export default ResidentLink;

interface ResidentLinkProps {
  children: ReactNode;
  clinicId: string;
  edit?: boolean;
  residentId: string;
}

function ResidentLink(props: ResidentLinkProps) {
  const {children, clinicId, residentId, edit} = props;

  const pathname = edit
    ? getEditResidentRoute({clinicId, residentId})
    : getViewResidentRoute({clinicId, residentId});

  return <Link to={pathname}>{children}</Link>;
}
