/*
 * Each column is a bit flag that you can use to create visible columns bit masks.
 * This makes it easy to choose what columns should be visible
 *
 * Examples:
 *
 * // Render table with columns ROW_NUMBER, START_DATE and START_TIME
 * const visibleColumns = ROW_NUMBER | START_DATE | START_TIME;
 *
 * // Render table with ALL columns except ROW_ACTIONS
 * const visibleColumns = ALL ^ ROW_ACTIONS;
 */

export const ROW_NUMBER = 1 << 0;
export const ROW_SELECT = 1 << 1;
export const START_DATE = 1 << 2;
export const START_TIME = 1 << 3;
export const SLOT_TYPE = 1 << 4;
export const RESIDENT_PERSONAL_IDENTITY_NUMBER = 1 << 5;
export const RESIDENT_NAME = 1 << 6;
export const RESOURCE_INDEX = 1 << 7;
export const APPOINTMENT_ORIGIN = 1 << 8;
export const APPOINTMENT_CLINIC_NOTES = 1 << 9;
export const APPOINTMENT_STATUS = 1 << 10;
export const ROW_ACTIONS = 1 << 11;
export const ALL =
  0 |
  ROW_NUMBER |
  ROW_SELECT |
  START_DATE |
  START_TIME |
  SLOT_TYPE |
  RESIDENT_PERSONAL_IDENTITY_NUMBER |
  RESIDENT_NAME |
  RESOURCE_INDEX |
  APPOINTMENT_ORIGIN |
  APPOINTMENT_CLINIC_NOTES |
  APPOINTMENT_STATUS |
  ROW_ACTIONS;
