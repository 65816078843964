import {useContext} from "react";
import BookingAppStateContext from "./booking-app-state-context";

export default useBookingAppState;

class UseBookingAppStateContextTypeError extends TypeError {
  constructor() {
    super(
      "Cannot access BookingAppState context outside BookingAppStateProvider"
    );
  }
}

function useBookingAppState() {
  const context = useContext(BookingAppStateContext);

  if (context == null) {
    throw new UseBookingAppStateContextTypeError();
  }

  return context;
}
