import {isFunction, toLower} from "../../../utils";
import {APPLICATION_JSON, CONTENT_TYPE} from "./headers";

export type HttpMethod =
  | "CONNECT"
  | "DELETE"
  | "GET"
  | "HEAD"
  | "OPTIONS"
  | "PATCH"
  | "POST"
  | "PUT"
  | "TRACE";

export type HeadersFactoryOrObject =
  | Record<string, string>
  | ((headers?: Record<string, string>) => Record<string, string>);

export type HttpRequesterConfig = {
  defaultHeaders?: HeadersFactoryOrObject;
};

export type RequestBody =
  | ArrayBuffer
  | ArrayBufferView
  | Blob
  | FormData
  | null
  | ReadableStream<Uint8Array>
  | string
  | undefined
  | URLSearchParams;

export interface HttpRequestConfig {
  requestHeaders?: HeadersFactoryOrObject;
}

export const httpRequesterFactory = (
  method: HttpMethod,
  requesterConfig: HttpRequesterConfig = {}
) => {
  const {defaultHeaders} = requesterConfig;

  return <HttpResult>(
    path: string,
    body?: RequestBody,
    requestConfig: HttpRequestConfig = {}
  ) => {
    const {requestHeaders} = requestConfig;
    const headers = mergeHeaders(defaultHeaders, requestHeaders);
    const request = new Request(path, {body, headers, method});

    return fetchRequest<HttpResult>(request);
  };
};

function fetchRequest<HttpResult>(request: Request): Promise<HttpResult> {
  return fetch(request).then((response) => {
    const contentType = toLower(response.headers.get(CONTENT_TYPE));
    const isJsonResponse = contentType?.includes(APPLICATION_JSON);

    return isJsonResponse ? response.json() : response.text();
  });
}

function mergeHeaders(
  defaults: HeadersFactoryOrObject = {},
  overrides: HeadersFactoryOrObject = {}
) {
  const defaultsObject = isFunction(defaults) ? defaults() : defaults;
  const overridesObject = isFunction(overrides)
    ? overrides(defaultsObject)
    : overrides;

  return Object.assign({}, defaultsObject, overridesObject);
}
