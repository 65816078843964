import React from "react";
import {Translation} from "../../../services/i18n";
import {Resident} from "../../../services/time-book-scheduling-api";
import {useToday} from "../../../utils/react";
import {Loading} from "../../loading";
import {LoadingError} from "../../loading-error";
import {NotFound} from "../../not-found";
import {ResidentBookingsTable} from "../../resident-bookings-table";
import useGetResidentBookingsQuery from "./use-get-resident-bookings-query";

export default ResidentBookings;

interface ResidentBookingsProps {
  clinicId: string;
  resident: Resident;
}

function ResidentBookings(props: ResidentBookingsProps) {
  const {clinicId, resident} = props;
  const todayAtMidnight = useToday();

  const getResidentBookingsQueryResult = useGetResidentBookingsQuery(
    {clinicId, residentId: resident.id},
    {
      select(bookings) {
        return bookings.filter((booking) => {
          return new Date(booking.slot.start) > todayAtMidnight;
        });
      },
    }
  );

  if (getResidentBookingsQueryResult.isLoading) {
    return <Loading />;
  }

  if (getResidentBookingsQueryResult.isLoadingError) {
    return <LoadingError error={getResidentBookingsQueryResult.error} />;
  }

  if (getResidentBookingsQueryResult.isSuccess) {
    if (getResidentBookingsQueryResult.data.length === 0) {
      return (
        <NotFound>
          <Translation tKey="site-search-resident-no-appointments" />
        </NotFound>
      );
    }

    return (
      <ResidentBookingsTable
        bookings={getResidentBookingsQueryResult.data}
        resident={resident}
      />
    );
  }
  return null;
}
