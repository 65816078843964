import {useMutation, useQueryClient} from "react-query";
import {
  Appointment,
  CreateAppointmentInput,
} from "../../../time-book-scheduling-api";
import {createSlotDetailsKey} from "../../slots";
import useApi from "../../use-api";
import {createAppointmentsListKey} from "../query-key-creators";

export default useCreateAppointmentMutation;

interface CreateAppointmentMutationProps {
  clinicId: string;
}

interface CreateAppointmentMutationVariables {
  input: CreateAppointmentInput;
}

function useCreateAppointmentMutation(props: CreateAppointmentMutationProps) {
  const api = useApi();
  const queryClient = useQueryClient();

  const {clinicId} = props;

  return useMutation<Appointment, Error, CreateAppointmentMutationVariables>(
    (variables) => {
      const {input} = variables;

      return api
        .createAppointment({clinicId}, input)
        .then((appointmentData) => Appointment.fromJSON(appointmentData));
    },
    {
      onSuccess(appointment) {
        const {id: slotId} = appointment.slot;

        void queryClient.invalidateQueries(createAppointmentsListKey(clinicId));
        void queryClient.invalidateQueries(
          createSlotDetailsKey(clinicId, slotId)
        );
      },
    }
  );
}
