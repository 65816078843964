import {useMemo} from "react";
import {useTranslation} from "../../services/i18n";
import {SlotType} from "../../services/time-book-scheduling-api";
import {Option} from "../bootstrap";

export const SLOT_TYPE_FILTER_OPTION_ALL = "all";

export const useSlotTypeFilterOptions = (slotTypes: SlotType[]) => {
  const all = useTranslation("calendar-slot-type-option-all");

  return useMemo<Option[]>(
    () => [
      {label: all, value: "all"},
      ...slotTypes.map(({id, name}) => ({label: name, value: id})),
    ],
    [slotTypes, all]
  );
};
