import {Permission} from "./permission";
import {Role} from "./role";

export {getPermissionsForClaims, selectClaimsByHsaId};

function getPermissionsForClaims(claims: string[]) {
  const accumulatedPermissions = claims.reduce<Permission[]>(
    (previousValue, currentValue /*, currentIndex*/) => {
      const role = getRoleByClaim(currentValue);
      const rolePermissions = getPermissionsByRole(role);

      return previousValue.concat(rolePermissions);
    },
    []
  );

  return [...new Set(accumulatedPermissions)];
}

function getPermissionsByRole(role: Role): Permission[] {
  if (role === Role.EXTERNBOKNING) {
    return [Permission.BOOK_EXTERNAL];
  }

  if (role === Role.BOOKING) {
    return [Permission.BOOK];
  }

  if (role === Role.SCHEDULING) {
    return [Permission.BOOK, Permission.SCHEDULE];
  }

  if (role === Role.ADMIN) {
    return [Permission.BOOK, Permission.SCHEDULE, Permission.ADMINISTRATE];
  }

  return [];
}

function getRoleByClaim(claim: string): Role {
  return splitClaim(claim)[1] as Role;
}

function selectClaimsByHsaId(claims: string[], hsaId: string) {
  return claims.filter((claim) => {
    return claim.includes(hsaId);
  });
}

function splitClaim(claim: string): string[] {
  return claim.split("_");
}
