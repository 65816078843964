import {useQuery} from "react-query";
import useResidentQueryCreators, {
  GetResidentQueryOptions,
  GetResidentQueryVariables,
} from "./use-resident-query-creators";

export default useGetResidentQuery;

function useGetResidentQuery(
  variables: GetResidentQueryVariables,
  options?: GetResidentQueryOptions
) {
  const {createGetResidentQuery} = useResidentQueryCreators();

  return useQuery(createGetResidentQuery(variables, options));
}
