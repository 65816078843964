import {useMemo} from "react";
import {UseQueryOptions} from "react-query";
import {isNonEmptyArray, isNull} from "../../../../utils";
import {Appointment} from "../../../time-book-scheduling-api";
import {createSearchEntitiesByIdFilter as createSearchAppointmentsByIdFilter} from "../../search-query";
import useSearchAppointmentsQuery from "./use-search-appointments-query";

export default useGetAppointmentsQuery;

interface GetAppointmentsQueryVariables {
  clinicId: string;
  appointmentIds: string[];
}

type GetAppointmentsQueryOptions = UseQueryOptions<Appointment[], Error>;

function useGetAppointmentsQuery(
  variables: GetAppointmentsQueryVariables,
  options?: GetAppointmentsQueryOptions
) {
  const {clinicId, appointmentIds} = variables;

  const filter = useMemo(() => {
    return isNonEmptyArray(appointmentIds)
      ? createSearchAppointmentsByIdFilter(appointmentIds)
      : null;
  }, [appointmentIds]);

  return useSearchAppointmentsQuery(
    {clinicId, filter},
    {
      ...(isNull(filter)
        ? {
            queryFn() {
              return [];
            },
          }
        : null),
      ...options,
    }
  );
}
