import invariant from "invariant";
import {useMutation, useQueryClient} from "react-query";
import {isUndefined} from "../../../../utils";
import {
  ApiError,
  Appointment,
  PatchAppointmentInput,
} from "../../../time-book-scheduling-api";
import {StrictResidentAppointmentIdentifier} from "../../../time-book-scheduling-api/api-client";
import {useApi} from "../../use-api";
import {
  createAppointmentDetailsKey,
  createAppointmentsListKey,
} from "../query-key-creators";

type PatchResidentAppointmentMutationVariables = {
  input: PatchAppointmentInput;
  identifier?: StrictResidentAppointmentIdentifier;
};

export const usePatchResidentAppointmentMutation = (
  initIdentifier?: StrictResidentAppointmentIdentifier
) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<
    Appointment,
    ApiError,
    PatchResidentAppointmentMutationVariables
  >(
    (variables) => {
      const {input, identifier} = variables;

      invariant(
        !isUndefined(initIdentifier) || !isUndefined(identifier),
        "An appointment identifier is required"
      );

      const {appointmentId, appointmentVersion, clinicId, residentId} =
        Object.assign({}, initIdentifier, identifier);

      return api
        .patchResidentAppointment(
          {appointmentId, appointmentVersion, clinicId, residentId},
          input
        )
        .then((appointmentData) => Appointment.fromJSON(appointmentData));
    },
    {
      onSuccess(nextAppointment) {
        const {clinic, id} = nextAppointment;

        void queryClient.invalidateQueries(
          createAppointmentsListKey(clinic.id)
        );
        void queryClient.invalidateQueries(
          createAppointmentDetailsKey(clinic.id, id)
        );
      },
    }
  );
};
