import {useCallback, useMemo} from "react";
import {UseQueryOptions} from "react-query";
import {isNull} from "../../../../utils";
import {CountByDate, Slot} from "../../../time-book-scheduling-api";
import {Filter as ApiSearchFilter} from "../../search-query";
import useApi from "../../use-api";
import {createCountSlotsKey} from "../query-key-creators";
import {
  createSlotDetailsKey,
  createSlotsSearchKey,
} from "../query-key-creators";

export default useSlotQueryCreators;

export type GetSlotQueryVariables = {
  clinicId: string;
  slotId: string;
};

export type GetSlotQueryOptions = UseQueryOptions<Slot, Error>;

export type SearchSlotsQueryVariables = {
  clinicId: string;
  filter: ApiSearchFilter | null;
};

export type SearchSlotsQueryOptions = UseQueryOptions<Slot[], Error>;

export type CountSlotsQueryVariables = SearchSlotsQueryVariables;
export type CountSlotsQueryOptions = UseQueryOptions<CountByDate[], Error>;

export function useSlotQueryCreators() {
  const api = useApi();

  const createGetSlotQuery = useCallback(
    (variables: GetSlotQueryVariables, options: GetSlotQueryOptions = {}) => {
      const {clinicId, slotId} = variables;
      const {
        queryKey = createSlotDetailsKey(clinicId, slotId),
        ...otherOptions
      } = options;

      return {
        ...otherOptions,
        queryKey,
        queryFn() {
          return api
            .getSlot({clinicId, slotId})
            .then((data) => Slot.fromJSON(data));
        },
      };
    },
    [api]
  );

  const createSearchSlotsQuery = useCallback(
    (
      variables: SearchSlotsQueryVariables,
      options: SearchSlotsQueryOptions = {}
    ) => {
      const {clinicId, filter} = variables;
      const {
        queryKey = createSlotsSearchKey(clinicId, filter),
        ...otherOptions
      } = options;

      return {
        ...otherOptions,
        queryKey,
        queryFn() {
          return isNull(filter)
            ? Promise.reject(new TypeError("Provided `filter` is `null"))
            : api
                .searchSlots({clinicId}, {filter})
                .then(({data}) => data.map((item) => Slot.fromJSON(item)));
        },
      };
    },
    [api]
  );

  const createCountSlotsQuery = useCallback(
    (
      variables: CountSlotsQueryVariables,
      options: CountSlotsQueryOptions = {}
    ) => {
      const {clinicId, filter} = variables;

      return {
        ...options,
        queryKey: createCountSlotsKey(clinicId, filter),
        queryFn() {
          return isNull(filter)
            ? Promise.reject(new TypeError("Provided `filter` is `null"))
            : api.countSlots({clinicId}, {filter}).then(({data}) => data);
        },
      };
    },
    [api]
  );

  return useMemo(
    () => ({
      createCountSlotsQuery,
      createGetSlotQuery,
      createSearchSlotsQuery,
    }),
    [createGetSlotQuery, createSearchSlotsQuery, createCountSlotsQuery]
  );
}
